import { getDatabase, goOffline } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { fetchConfig } from '../callApi';
import { setAttendanceMode } from '../../helpers/attendanceModeHelper';
import { setDarkMode, isInDarkMode } from '../../helpers/darkMode';

export const LOGOUT = 'LOGOUT';
export const logout = () => {
  const auth = getAuth();
  const db = getDatabase();
  if (db) goOffline(db);
  if (auth.currentUser) auth.signOut()
    .then(() => {
      console.log('FBRTDB: Signed out');
    })
    .catch(() => {
      console.error('FBRTDB: Error signing out');
    });
  return { type: LOGOUT };
};

const [, loginSucess] = fetchConfig.login.types;
export const loginWithToken = (authToken) => ({
  type: loginSucess, response: { auth_token: authToken },
});

export const TRACK_TIME = 'track';

export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const toggleDarkMode = () => {
  const newDarkMode = !isInDarkMode();
  setDarkMode(newDarkMode);
  return {
    type: TOGGLE_DARK_MODE,
    darkMode: newDarkMode,
  };
};

export const SET_FIREBASE_SESSION_ID = 'SET_FIREBASE_SESSION_ID';
export const setFirebaseSessionId = (firebaseSessionId) => ({
  type: SET_FIREBASE_SESSION_ID,
  firebaseSessionId,
});

export const SET_ACTIVE_SUBPAGE = 'SET_ACTIVE_SUBPAGE';
export const setActiveSubpage = (subpage) => ({
  type: SET_ACTIVE_SUBPAGE,
  subpage,
});

export const goToMainStage = () => setActiveSubpage({
  module: 'mainStage',
});

export const goToAttendees = () => setActiveSubpage({
  module: 'Attendees',
});

export const setActiveAttendee = (activeAttendeeId) => setActiveSubpage({
  module: 'Attendees',
  activeAttendeeId,
});

export const goToWorkshops = () => setActiveSubpage({
  module: 'Workshops',
});

export const setActiveWorkshop = (workshopId) => setActiveSubpage({
  module: 'Workshops',
  workshopId,
});

export const setActiveMeeting = (meetingId) => setActiveSubpage({
  module: 'Workshops',
  meetingId,
});

export const setSlotId = (slotId) => setActiveSubpage({
  module: 'Workshops',
  slotId,
});

export const setActiveMeetingEvaluation = (meetingId, activeSurvey) => setActiveSubpage({
  module: 'Workshops',
  meetingId,
  activeSurvey,
});

export const setActiveWorkshopSpeaker = (workshopId, speakerId) => setActiveSubpage({
  module: 'Workshops',
  workshopId,
  speakerId,
});

export const setActiveSpeaker = (speakerId) => setActiveSubpage({
  module: 'Speakers',
  speakerId,
});

export const setActiveSponsor = (sponsorId) => setActiveSubpage({
  module: 'Sponsors',
  sponsorId,
});

export const goToSurveys = () => setActiveSubpage({
  module: 'Surveys',
});

export const setActiveSurvey = (surveyId) => setActiveSubpage({
  module: 'Surveys',
  surveyId,
});

export const setActiveQuiz = (quizId) => setActiveSubpage({
  module: 'Quizzes',
  quizId,
});

export const setActiveChannel = (activeChannel) => setActiveSubpage({
  module: 'Messages',
  activeChannel,
});

export const setActivePost = (postId) => setActiveSubpage({
  module: 'SocialWall',
  postId,
});

export const setActiveLead = (uuid) => setActiveSubpage({
  module: 'AttendeeLeads',
  uuid,
});

export const setActiveQaSession = (sessionId) => setActiveSubpage({
  module: 'QASessions',
  sessionId,
});

export const GO_TO_PREVIOUS_SUBPAGE = 'GO_TO_PREVIOUS_SUBPAGE';
export const goToPreviousSubpage = () => ({
  type: GO_TO_PREVIOUS_SUBPAGE,
});

export const SET_ATTENDANCE_TYPE = 'SET_ATTENDANCE_TYPE';
export const setAttendanceType = (attendanceType) => {
  setAttendanceMode(attendanceType);
  return {
    type: SET_ATTENDANCE_TYPE,
    attendanceType,
  };
};
