import React from 'react';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  deleteButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: theme.palette.common.white,
  },
  image: {
    borderRadius: 4,
    maxWidth: 108,
    maxHeight: 84,
  },
}));

const ImagePreview = ({
  file,
  // onDelete,
}) => {
  const classes = useStyles();
  // const { t } = useTranslation('global');

  const src = URL.createObjectURL(file);

  return (
    // <div className={classes.root}>
    //   <IconButton
    //     aria-label={t('actions.deleteImage')}
    //     onClick={onDelete}
    //     size="small"
    //     className={classes.deleteButton}
    //   >
    //     <CloseIcon />
    //   </IconButton>
    <img src={src} alt="" className={classes.image} />
    // </div>
  );
};

ImagePreview.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  // onDelete: PropTypes.func.isRequired,
};

export default ImagePreview;
