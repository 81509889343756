import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Message from 'eventtia-ui-components/lib/Message';
import Loader from 'eventtia-ui-components/lib/Loader';
import { baseMoment, getEventTimeFormat } from '../../helpers/dates';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import Schedule from '../Schedule';
import DayPicker from '../DayPicker';
import useTimer from '../../hooks/useTimer';
import { getCurrentAttendee } from '../../helpers/getters';
import { ATTENDANCE_MODE, MEETING_REQUEST_STATUSES } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  message: {
    width: 465,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      minWidth: '100%',
      margin: theme.spacing(0, -theme.subpageDrawer.paddings.desktop.horizontal + 2, 2),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, -theme.subpageDrawer.paddings.mobile.horizontal + 2, 2),
    },
  },
  title: {
    fontSize: 16,
    color: theme.palette.darkGrey.dark,
    fontWeight: 'bold',
    textAlign: 'start',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  scheduleWrapper: {
    width: 465,
    display: 'flex',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  loader: {
    minWidth: 400,
  },
}));

const { REQUESTED } = MEETING_REQUEST_STATUSES;

const sortActivities = (
  slots, mySlots, openParticipant, meetings, alreadyHasRequest, selfBlockedSlots = [],
  disabled = false, secondaryAction, meetingStatuses, openParticipantId, occupiedSlotsByWorkshops,
  meetingsRequestCounter
) => {
  const { blockedSlots, id: participantId } = openParticipant;
  const days = {};
  const { requested, accepted } = meetingStatuses;
  const meetingSlots = Object.values(meetings)
    .filter(({ status }) => (status === requested || status === accepted))
    .map(({ slotId }) => slotId);

  Object.keys(mySlots).sort().forEach((slotEpoch) => {
    const [startDate, endDate, slotId] = mySlots[slotEpoch];
    const day = baseMoment(startDate).format('YYYY-MM-DD');

    const skip = !slots[slotEpoch];

    const slotHasRequests = meetingsRequestCounter[slotId]?.length >= 1;

    let activity;
    if (!skip) {
      const isBlockedSlot = blockedSlots.includes(slotEpoch);

      const isOcuppiedSlot = meetingSlots.includes(slotId)
      || isBlockedSlot
      || selfBlockedSlots.includes(slotEpoch)
      || slotId === alreadyHasRequest?.slotId
      || occupiedSlotsByWorkshops[slotId];

      const slotType = (!!isOcuppiedSlot && 'blockedSlot') || 'availableSlot';

      activity = {
        startDate,
        endDate,
        participantId,
        slotId,
        slotHasRequests,
        id: slotEpoch,
        key: slotEpoch,
        type: slotType,
        disabled: !isBlockedSlot && disabled,
        onClick: () => {},
        secondaryAction,
        openParticipantId,
      };
    }

    if (days[day] && activity) days[day].push(activity);
    if (!days[day] && activity) days[day] = [activity];
  });
  return days;
};
const { VIRTUAL } = ATTENDANCE_MODE;
const Agenda = ({
  openParticipantId, currentParticipant, meetings, meetingRequests, participantTypes,
  fetchingParticipant, participants, conferences, setMessage, meetingStatuses,
  openAttendeeTypeId, events, scrollToTop, entities, attendeeTypes, workshops, attendeeWorkshops,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['meeting', 'global', 'attendees']);
  const timeFormat = getEventTimeFormat(events);

  const [conference] = Object.values(conferences);
  const schedulingStartsOn = conference?.schedulingStartsOn;
  const bookingDeadlineDate = conference?.bookingDeadlineDate;
  const automaticScheduling = conference?.automaticScheduling;
  const maxRepeatedMeetings = conference?.maxRepeatedMeetings || 1;
  const [now, setNow] = useState(baseMoment().format());
  useTimer({ onTick: setNow, step: 5000 });

  const schedulingStarted = useMemo(() => (
    baseMoment(now).isSameOrAfter(schedulingStartsOn)
  ), [now, schedulingStartsOn]);
  const schedulingEnded = useMemo(() => (
    baseMoment(now).isSameOrAfter(bookingDeadlineDate)
  ), [now, bookingDeadlineDate]);
  const openParticipant = participants?.[openParticipantId] ?? {};
  const { agenda } = openParticipant?.participantType
    ? participantTypes[openParticipant.participantType.id] : {};

  const {
    agenda: myAgenda,
    canRequestMeetingToAttendeeTypes,
    scheduleRestrictions,
  } = (currentParticipant?.participantType
    && participantTypes) ? participantTypes[currentParticipant.participantType.id] : {};
  const { blockedSlots: selfBlockedSlots } = currentParticipant || {};
  const alreadyHasMeeting = Object.values(meetings).filter(({
    host: { id: hostId },
    participant: { id: participantId },
    status,
  }) => (
    [meetingStatuses.requested, meetingStatuses.accepted].includes(status)
    && (openParticipantId === hostId || openParticipantId === participantId)
  )).length >= maxRepeatedMeetings;
  const alreadyHasRequest = Object.values(meetingRequests)
    .find((
      { receivedBy: { id: receivedById }, sentBy: { id: sentById }, status }
    ) => (receivedById === openParticipantId || sentById === openParticipantId)
    && status === REQUESTED);

  const occupiedSlotsByWorkshops = useMemo(() => {
    const attendeeWorhopsIds = [];
    Object.values(attendeeWorkshops)
      .forEach(({ workshop }) => attendeeWorhopsIds.push(workshop.id));

    const workshopsDurationRange = {};
    attendeeWorhopsIds.forEach((id) => {
      workshopsDurationRange[id] = [workshops[id]?.startDate, workshops[id]?.endDate];
    });

    const occupiedSlots = {};
    if (!scheduleRestrictions) return {};
    Object.values(agenda).forEach(([slotStartDate, slotEndDate, slotId]) => {
      Object.values(workshopsDurationRange).forEach((occupiedInterval) => {
        const [workshopStartDate, workshopEndDate] = occupiedInterval;
        if (baseMoment(workshopEndDate).isAfter(slotStartDate)
          && baseMoment(workshopStartDate).isBefore(slotEndDate)
        )occupiedSlots[slotId] = true;
      });
    });
    return occupiedSlots;
  }, [agenda, workshops, attendeeWorkshops, scheduleRestrictions]);

  const [alreadyMeetingMsg, setAlreadyMeetingMsg] = useState(alreadyHasMeeting);
  const [alreadyRequestMsg, setAlreadyRequestMsg] = useState(alreadyHasRequest);
  const [scheduleDisabledMsg, setScheduleDisabledMsg] = useState(
    !schedulingStarted || schedulingEnded
  );
  const cannotRequestMeeting = !(canRequestMeetingToAttendeeTypes || [])
    .includes(Number(openAttendeeTypeId));
  const disabled = !schedulingStarted || schedulingEnded
    || alreadyHasMeeting || alreadyHasRequest || cannotRequestMeeting;

  useEffect(() => {
    setAlreadyMeetingMsg(alreadyHasMeeting);
  }, [alreadyHasMeeting]);

  useEffect(() => {
    setAlreadyRequestMsg(alreadyHasRequest);
  }, [alreadyHasRequest]);

  const noParticipant = !openParticipantId || !currentParticipant;
  const getArtificialActivities = () => {
    const todayDateString = baseMoment().format('YYYY-MM-DD');
    const startDate = baseMoment().subtract(1, 'day').hour(0).minute(0);
    const endDate = baseMoment().subtract(1, 'day').hour(2).minute(0);
    return {
      [todayDateString]: [{
        startDate: startDate.format(),
        endDate: endDate.format(),
        disabled: true,
        type: 'emptyBlock',
        onClick: () => {},
      }],
    };
  };
  const setRequestStatus = useCallback((message) => {
    setMessage(message);
    scrollToTop();
  }, [setMessage, setAlreadyMeetingMsg]);
  const artificialActivities = noParticipant ? getArtificialActivities() : undefined;
  const activitiesByDay = useMemo(() => {
    if (noParticipant) return artificialActivities;

    const meetingsRequestCounter = {};
    Object.values(meetingRequests).filter(({ status }) => status === REQUESTED)
      .forEach((request) => {
        if (!meetingsRequestCounter[request.slotId]) meetingsRequestCounter[request.slotId] = [];
        meetingsRequestCounter[request.slotId].push(request.id);
      });

    return (!fetchingParticipant && agenda && myAgenda) ? sortActivities(
      agenda, myAgenda, openParticipant, meetings, alreadyHasRequest, selfBlockedSlots,
      disabled, setRequestStatus, meetingStatuses, openParticipantId, occupiedSlotsByWorkshops,
      meetingsRequestCounter
    ) : {};
  }, [noParticipant, fetchingParticipant, agenda, myAgenda, meetingRequests]);

  const days = Object.keys(activitiesByDay);
  const [day, setDay] = useState(days[0]);
  useEffect(() => {
    if (days.length && !days.includes(day)) setDay(days[0]);
  }, [day, days]);

  const activities = activitiesByDay[day];

  if (fetchingParticipant) return (
    <Loader loading className={classes.loader} />
  );
  const onCloseMessage = (type) => {
    if (type === 'alreadyScheduled') setAlreadyMeetingMsg();
    if (type === 'alreadyRequested') setAlreadyRequestMsg();
    else setScheduleDisabledMsg();
  };

  const currentAttendee = getCurrentAttendee(entities);
  const currentAttendeeTypeId = currentAttendee.attendeeType.id;
  const userAttendanceMode = attendeeTypes[currentAttendeeTypeId].attendanceMode;
  return (
    <div className={classes.root}>
      {conference && scheduleDisabledMsg && !alreadyHasMeeting && (
        <Message
          className={classes.message}
          type="warning"
          title={t('global:title.reminder')}
          onClose={onCloseMessage}
          message={!schedulingStarted ? (
            baseMoment(schedulingStartsOn)
              .format(t(automaticScheduling ? 'meeting.ratingDisabled' : 'meeting.schedulingDisabled', { timeFormat }))
          ) : (
            t(automaticScheduling ? 'meeting.ratingEnded' : 'meeting.schedulingEnded')
          )}
        />
      )}
      {conference && alreadyMeetingMsg && (
        <Message
          className={classes.message}
          type="warning"
          title={t('global:title.reminder')}
          message={t(
            userAttendanceMode === VIRTUAL ? 'meeting.alreadyScheduled' : 'meeting.alreadyScheduledPhysical',
            { maxRepeatedMeetings }
          )}
          onClose={() => onCloseMessage('alreadyScheduled')}
        />
      )}
      {alreadyRequestMsg && (
        <Message
          className={classes.message}
          type="warning"
          title={t('global:title.reminder')}
          message={t('meeting.alreadyRequested')}
          onClose={() => onCloseMessage('alreadyRequested')}
        />
      )}
      {!automaticScheduling && (
        <>
          {!!activities?.length && (
            <Typography variant="subtitle1" className={classes.title}>
              {t('attendees:filter.meetingAvailability')}
            </Typography>
          )}
          {day && !alreadyHasMeeting && openParticipantId && currentParticipant && (
            <DayPicker
              options={days}
              onChange={setDay}
              day={day}
            />
          )}
          {!!activities?.length && (
            <div className={classes.scheduleWrapper}>
              <Schedule
                activities={activities}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

Agenda.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  currentParticipant: CustomPropTypes.participant,
  participantTypes: PropTypes.objectOf(CustomPropTypes.participantType),
  openParticipantId: PropTypes.string,
  fetchingParticipant: PropTypes.bool.isRequired,
  participants: PropTypes.objectOf(CustomPropTypes.participant),
  meetings: PropTypes.objectOf(CustomPropTypes.meeting),
  meetingRequests: PropTypes.objectOf(CustomPropTypes.meetingRequest),
  conferences: PropTypes.objectOf(CustomPropTypes.conference),
  setMessage: PropTypes.func,
  meetingStatuses: PropTypes.objectOf(
    PropTypes.number
  ),
  openAttendeeTypeId: PropTypes.string.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ),
  workshops: PropTypes.objectOf(
    CustomPropTypes.workshop
  ),
  attendeeWorkshops: PropTypes.objectOf(
    CustomPropTypes.attendeeWorkshops
  ),
};

Agenda.defaultProps = {
  participantTypes: {},
  participants: {},
  currentParticipant: {},
  meetings: {},
  meetingRequests: {},
  conferences: {},
  setMessage: undefined,
  meetingStatuses: {},
  openParticipantId: undefined,
  attendeeTypes: {},
  workshops: {},
  attendeeWorkshops: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    businessConferenceMeetings,
    businessConferenceParticipantTypes,
    businessConferenceParticipants,
    businessConferences,
    events,
    attendeeTypes,
    meetingRequests,
    workshops,
    attendeeWorkshops,
  },
  fetchStatus: {
    participant: {
      isFetching,
    },
  },
  meta: {
    meetingStatuses,
  },
}) => ({
  entities,
  events,
  attendeeTypes,
  meetings: businessConferenceMeetings,
  meetingRequests,
  conferences: businessConferences,
  participantTypes: businessConferenceParticipantTypes,
  participants: businessConferenceParticipants,
  fetchingParticipant: isFetching,
  meetingStatuses,
  workshops,
  attendeeWorkshops,
});

export default connect(mapStateToProps)(Agenda);
