import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import filters from '../middlewares/filters';
import api from '../middlewares/api';
import viewedAttendees from '../middlewares/viewedAttendees';
import auth from '../middlewares/auth';
import deepLinks from '../middlewares/deepLinks';

const configureStore = (preloadedState) => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunk, filters, viewedAttendees, api, auth, deepLinks)
);

export default configureStore;
