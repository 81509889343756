import stand1 from '../../images/stand_1.png';
import stand2 from '../../images/stand_2.png';
import stand3 from '../../images/stand_3.png';
import stand4 from '../../images/stand_4.png';
import stand5 from '../../images/stand_5.png';

const StandImages = [
  stand1,
  stand2,
  stand3,
  stand4,
  stand5,
];

export default StandImages;
