import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Workshop from './Workshop';
import Meeting from './Meeting';
import MeetingRequest from './MeetingRequest';
import BackButton from '../../components/BackButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(
      theme.subpageDrawer.paddings.desktop.vertical,
      theme.subpageDrawer.paddings.desktop.horizontal
    ),
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(
        theme.subpageDrawer.paddings.mobile.vertical,
        theme.subpageDrawer.paddings.mobile.horizontal
      ),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-2),
    marginBottom: 20,
    paddingRight: theme.spacing(1),
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  mobileModuleName: {
    width: '100%',
    textAlign: 'center',
  },
  contentWrapper: {
    color: theme.palette.darkGrey.light,
  },
  tabletContentWrapper: {
    width: 'auto',
  },
}));

const contentTypes = {
  workshop: Workshop,
  meeting: Meeting,
  requests: MeetingRequest,
};

const EventScheduleShow = ({
  type, showHeader, ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation('schedule');
  const Content = contentTypes[type];
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      {showHeader && (
        <div className={classes.header}>
          <BackButton />
          <Typography
            className={clsx(classes.moduleName, mobile && classes.mobileModuleName)}
            variant="subtitle1"
          >
            {t('title.detail')}
          </Typography>
        </div>
      )}
      <div className={clsx(classes.contentWrapper,
        tablet && classes.tabletContentWrapper)}
      >
        <Content {...props} />
      </div>
    </div>
  );
};

EventScheduleShow.propTypes = {
  type: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  app: {
    activeSubpage: {
      speakerId,
      activeSurvey,
    },
  },
}) => ({
  showHeader: !(speakerId || activeSurvey),
});

export default connect(mapStateToProps)(EventScheduleShow);
