import documentIcon from '../../assets/Doc_Icon.svg';
import spreadSheetIcon from '../../assets/Excel_Icon.svg';
import imageIcon from '../../assets/IMG_Icon.svg';
import pdfIcon from '../../assets/Icon_PDF_2.svg';
import zipIcon from '../../assets/Zip_Icon.svg';

const fileIcon = {
  pdf: {
    name: 'pdf',
    icon: pdfIcon,
  },
  xls: {
    name: 'xls',
    icon: spreadSheetIcon,
  },
  csv: {
    name: 'csv',
    icon: spreadSheetIcon,
  },
  jpg: {
    name: 'jpg',
    icon: imageIcon,
  },
  png: {
    name: 'png',
    icon: imageIcon,
  },
  other: {
    name: 'other',
    icon: documentIcon,
  },
  zip: {
    name: 'zip',
    icon: zipIcon,
  },
  rar: {
    name: 'rar',
    icon: zipIcon,
  },
};

export default fileIcon;
