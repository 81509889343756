/* eslint-disable comma-dangle */
export default {
  en: {
    title: {
      surveys: 'Surveys',
      description: 'We value your opinion, let us know what you think.',
    },
    messages: {
      success: 'Your survey has been successfully completed.',
    },
  },
  es: {
    title: {
      surveys: 'Encuestas',
      description: 'Valoramos su opinión, háganos saber lo que piensa.',
    },
    messages: {
      success: 'Su encuesta fue respondida con éxito.',
    },
  },
  fr: {
    title: {
      surveys: 'Enquêtes',
      description: 'Votre avis est important pour nous, partagez le.',
    },
    messages: {
      success: 'Votre enquête a été complétée avec succès',
    },
  },
  ca: {
    title: {
      surveys: 'Enquestes',
      description: 'Valorem la vostra opinió, feu-nos saber què en penseu',
    },
    messages: {
      success: 'La vostra enquesta s’ha completat correctament.',
    },
  },
  pt: {
    title: {
      surveys: 'Pesquisas',
      description: 'Valorizamos sua opinião, deixe-nos saber o que você pensa.',
    },
    messages: {
      success: 'Seu questionário foi concluído com sucesso.',
    },
  },
  it: {
    title: {
      surveys: 'Sondaggi',
      description: 'Apprezziamo la tua opinione, facci sapere cosa ne pensi.'
    },
    messages: {
      success: 'La tua indagine è stata completata con successo.'
    }
  },
  ja: {
    title: {
      surveys: 'アンケート',
      description: '私たちはあなたの意見を大切にし、あなたの考えを教えてください。'
    },
    messages: {
      success: 'アンケートは正常に完了しました。'
    }
  },
  zh: {
    title: {
      surveys: '调查',
      description: '我们重视你的意见，让我们知道你的想法。'
    },
    messages: {
      success: '您的调查已成功完成。'
    }
  },
  ar: {
    title: {
      surveys: 'الاستطلاعات',
      description: 'نحن نقدر رأيك، واسمحوا لنا أن نعرف ما رأيك.',
    },
    messages: {
      success: 'تم الانتهاء من الاستبيان الخاص بك بنجاح.',
    },
  },
  tr: {
    title: {
      surveys: 'Anketler',
      description: 'Fikrinize değer veriyoruz, ne düşündüğünüzü bize bildirin.',
    },
    messages: {
      success: 'Anketiniz başarıyla tamamlandı.',
    },
  },
  de: {
    title: {
      surveys: 'Umfragen',
      description: 'Wir schätzen Ihre Meinung, lassen Sie uns wissen, was Sie denken.'
    },
    messages: {
      success: 'Ihre Umfrage wurde erfolgreich abgeschlossen.'
    }
  },
};
