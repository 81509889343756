import { camelize } from 'humps';
import INPUT_TYPES from './constants';
import { getCompatibleDateFormat } from './dates';

const { DATE_TIME } = INPUT_TYPES;
export default (fields, customFields) => {
  const parsedFields = {};
  Object.keys(fields).forEach((field) => {
    if (customFields[field]) parsedFields[camelize(customFields[field].name)] = fields[field];
  });
  return parsedFields;
};

export const defaultAttendeeFieldsValues = (fields, customFields) => {
  const attendee = { customFields: {} };
  Object.keys(fields).forEach((field) => {
    if (customFields[field]?.default) attendee[customFields[field].name] = fields[field];
    else if (!!customFields[field]
      && !customFields[field].default) if (customFields[field]
      .inputType === DATE_TIME) {
      const { includeTime } = customFields[field];
      const value = getCompatibleDateFormat(fields[field], includeTime);
      attendee.customFields[`_${field}`] = value;
    } else attendee.customFields[`_${field}`] = fields[field];
  });
  return { attendee };
};
