import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from 'eventtia-ui-components/lib/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ResponsiveButton = ({ icon, onClick, label, disabled }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (mobile) return (
    <Tooltip title={label} placement="top">
      <IconButton
        onClick={onClick}
        aria-label={label}
        color="primary"
        disabled={disabled}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
  return (
    <Button
      onClick={onClick}
      variant="tertiary"
      icon={icon}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

ResponsiveButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};

ResponsiveButton.defaultProps = {
  disabled: false,
};

export default ResponsiveButton;
