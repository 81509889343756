import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DevicesIcon from '@material-ui/icons/Devices';
import { Typography } from '@material-ui/core';
import Card from 'eventtia-ui-components/lib/Card';
import Button from 'eventtia-ui-components/lib/Button';
import EventtiaLogo from 'eventtia-ui-components/lib/EventtiaLogo';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { selectLogo } from '../../helpers/selectors';
import { setAttendanceType } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  cleanBack: {
    background: '#ffffff',
    zIndex: '3000',
    position: 'absolute',
    minHeight: '100%',
    minWidth: '100%',
    color: theme.palette.darkGrey.light,
  },
  hybridModal: {
    display: 'flex',
    zIndex: '4500',
    position: 'absolute',
    minHeight: '100%',
    right: 0,
    left: 0,
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
  },
  hybridSelector: {
    backgroundColor: theme.palette.lightGrey.main,
    width: 600,
    maxWidth: '100%',
    borderRadius: theme.spacing(1),
    margin: 0,
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  hybridButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(6),
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modalFonts: {
    font: 'normal normal bold 22px/28px Lato',
    marginBottom: theme.spacing(3),
  },
  selectingButton: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 3px 6px #00000029',
    width: '45%',
    margin: theme.spacing(0, 1),
    maxWidth: '320px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  logo: {
    maxWidth: '33%',
    maxHeight: '7em',
    marginBottom: theme.spacing(4),
  },
}));

const VenueSelector = ({
  entities, setAttendanceType: dispatchSetAttendanceType,
}) => {
  const classes = useStyles();
  const { appSettings, events } = entities;
  const [{ name: eventName }] = Object.values(events);
  const [settings] = Object.values(appSettings);
  const {
    backgroundImage: { filename: backgroundFilename, large: backgroundMediumImage },
  } = settings;
  const loginBackgroundImage = backgroundFilename ? backgroundMediumImage : '/default-background.jpg';
  const { PHYSICAL, VIRTUAL } = ATTENDANCE_MODE;
  const { t } = useTranslation('settings');

  const logo = useSelector(selectLogo('medium'));

  return (
    <div className={classes.cleanBack}>
      <div className={classes.hybridModal} style={{ backgroundImage: `url('${loginBackgroundImage}')` }}>
        <Card className={classes.hybridSelector}>
          {logo ? (
            <img src={logo} alt={eventName} className={classes.logo} />
          ) : (
            <EventtiaLogo className={classes.logo} />
          )}
          <Typography
            className={classes.modalFonts}
            variant="body2"
            component="div"
          >
            {t('attendanceTypeQuestion')}
          </Typography>
          <Typography
            variant="body2"
          >
            {t('hybridSelectorHelper')}
          </Typography>
          <div className={classes.hybridButtons}>
            <Button
              className={classes.selectingButton}
              onClick={() => {
                dispatchSetAttendanceType(PHYSICAL);
              }}
              icon={<StorefrontIcon />}
            >
              {t('attendanceType.inPerson')}
            </Button>
            <Button
              className={classes.selectingButton}
              onClick={() => {
                dispatchSetAttendanceType(VIRTUAL);
              }}
              icon={<DevicesIcon />}
            >
              {t('attendanceType.virtually')}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

VenueSelector.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  setAttendanceType: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  entities,
}) => ({
  entities,
});

export default connect(mapStateToProps, { setAttendanceType })(VenueSelector);
