import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from '../Switch';
import { toggleDarkMode } from '../../actions/app';

const DarkModeSwitch = ({
  darkMode,
  toggleDarkMode: dispatchToggleDarkMode,
}) => (
  <Switch
    color="secondary"
    onChange={dispatchToggleDarkMode}
    checked={darkMode}
  />
);

DarkModeSwitch.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: {
    darkMode,
  },
}) => ({
  darkMode,
});

export default connect(mapStateToProps, { toggleDarkMode })(DarkModeSwitch);
