import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { goToMainStage } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    zIndex: 1900,
    top: theme.spacing(3),
    right: theme.spacing(6),
    backgroundColor: theme.palette.background.light,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.main,
    },
  },
}));

const TabletExitButton = ({
  goToMainStage: dispatchGoToMainStage,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  return (tablet && !mobile) ? (
    <IconButton
      className={classes.iconButton}
      variant="subtitle1"
      onClick={dispatchGoToMainStage}
      small="true"
    >
      <CloseIcon />
    </IconButton>
  ) : null;
};

TabletExitButton.propTypes = {
  goToMainStage: PropTypes.func.isRequired,
};

export default connect(null, { goToMainStage })(TabletExitButton);
