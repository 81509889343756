import { getCurrentAttendeeId } from './getters';
import { getEventTimeFormat } from './dates';

export const selectTextTransformSettings = (state) => !Object.values(
  state.entities.appSettings || {}
)[0]?.capsDisabled;

export const selectCurrentAttendee = (state) => {
  const attendeeId = getCurrentAttendeeId();
  return state.entities.attendees[attendeeId];
};

export const selectCurrentEvent = (state) => {
  const { entities: { events } } = state;
  return Object.values(events)[0];
};

export const selectEventTimeFormat = (options) => (state) => {
  const { entities: { events } } = state;
  return getEventTimeFormat(events, options);
};

export const selectLogo = (size = 'small') => (state) => {
  const { entities: { events, appSettings } } = state;
  const [event] = Object.values(events);
  const [settings] = Object.values(appSettings);
  const { logo: { filename: settingsFilename, [size]: settingsLogo } } = settings;
  const { logo: { filename: eventFilename, [size]: eventLogo } } = event;

  if (settingsFilename) return settingsLogo;
  if (eventFilename) return eventLogo;
  return undefined;
};

export const selectFirebaseSessionId = (state) => state.app.firebaseSessionId;
