import React from 'react';

const root = {
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: '#F6F6FA',
  backgroundSize: 'cover',
  position: 'absolute',
  right: 0,
  left: 0,
  top: 0,
};
const container = {
  display: 'flex',
  width: 600,
  flexDirection: 'column',
};
const logoCont = {
  color: '#631A64',
  width: 135,
  marginBottom: 40,
};
const alertStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 365,
  padding: 10,
  backgroundColor: '#FCD4D8',
  color: '#C73241',
  borderRadius: 8,
  fontFamily: 'Lato, sans-serif',
};
const exclamation = {
  color: '#C73241',
  fontWeight: 600,
  fontSize: 18,
  margin: '22px 0px 16px',
};
const message = {
  width: 400,
  color: '#C73241',
  margin: 0,
  textAlign: 'center',
  marginBottom: 24,
};
const link = {
  color: '#C73241',
  fontWeight: 600,
};
const img = {
  height: 208,
};
const locale = (window.location.pathname.split('/')[1] || 'en');

const google = <a style={link} href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">Google Chrome</a>;
const firefox = <a style={link} href="https://www.mozilla.org/en-US/firefox/new/" rel="noopener noreferrer" target="_blank">Mozilla Firefox</a>;

let text;
switch (locale) {
  case 'en':
    text = (
      <p style={message}>
        Your current browser is not supported, you can use the latest version
        of {google}, {firefox}, Edge or Safari to enter this event.
      </p>
    );
    break;
  case 'es':
    text = (
      <p style={message}>
        Tu navegador actual no es compatible, puedes usar la última versión
        de {google}, {firefox}, Edge o Safari para entrar a este evento.
      </p>
    );
    break;
  case 'fr':
    text = (
      <p style={message}>
        Votre navigateur actuel n&apos;est pas supporté. Vous pouvez utiliser la dernière version
        de {google}, {firefox}, Edge ou Safari pour entrer dans l&apos;événement.
      </p>
    );
    break;
  default:
    text = (
      <p style={message}>
        Your current browser is not supported, you can use the latest version
        of {google}, {firefox}, Edge or Safari to enter this event.
      </p>
    );
}

const IEAlert = () => (
  <div style={root}>
    <div style={container}>
      <div style={logoCont}>
        <img src="/eventtia-logo.svg" alt="Eventtia logo" />
      </div>
      <div style={alertStyle}>
        <p style={exclamation}>
          Oh no!
        </p>
        {text}
        <img style={img} src="/SadIllustration.png" alt="Error browser not supported" />
      </div>
    </div>
  </div>
);

export default IEAlert;
