/* eslint-disable comma-dangle */
export default {
  en: {
    timeline: {
      viewingNow: 'Viewing now',
      comingUpNext: 'Coming up next',
      timeRemaining: 'Time remaining',
      positionAt: '{{position}} at ',
    },
    interactivity: {
      interactivity: 'Interactivity',
      titlewarning: 'This feature is Unavailable',
      safariwarning: 'This feature may not work correctly in Safari browser on Iphone/Ipad, try using a different browser',
    },
    activityTimer: {
      before: 'This activity hasn’t started yet! You have {{time}} before it begins.',
      ended: 'This activity ended {{when}}',
      remaining: 'Time remaining',
    },
    actions: {
      register: 'Register',
      unregister: 'Unregister',
      join: 'Join',
      purchase: 'Purchase',
    },
    activity: {
      register: 'Want to participate in this activity? Click on register',
      freeWorkshopAdvice: 'Want to participate in this activity? Complete the registration process',
      paymentAdvice: 'Want to participate in this activity? First you must complete the payment of the activity cost, you can do so by clicking on the "{{button}}" button',
      payPendingCharges: 'Pay pending charges',
      unregisterConfirm: 'Are you sure you want to unregister to {{activity}}?',
      registerToPaidConfirm: 'Are you sure you want to purchase this activity?',
      registerToPaidHelperText: 'Once you register for this activity, you must pay its cost before joining any other paid activities.',
      itCosts: 'It costs {{price}}.',
      notInterested: 'No longer interested in participating in the activity?',
      invitation: 'We’re live on {{streamingType}}, click on <3>join</3> to get started',
      waiting: 'Welcome!\nStay tuned, the activity will start {{timeRemaining}}',
      ended: 'This activity has ended, thank you for joining us!',
      limitedRegistration: 'Limited to {{attendees}} attendees.',
      mobileZoomWarning: 'Some options are not available for mobile browsers. <1>See more here</1>.',
    },
    translationWidget: {
      button: 'Live translation',
      modalTitle: 'Translation options',
    },
  },
  es: {
    timeline: {
      viewingNow: 'Viendo ahora',
      comingUpNext: 'A continuación',
      timeRemaining: 'Tiempo restante',
      positionAt: '{{position}} en ',
    },
    interactivity: {
      interactivity: 'Interactividades',
      titlewarning: 'Esta función no está disponible',
      safariwarning: 'Es posible que este componente no funcione correctamente en el navegador Safari en Iphone / Ipad, intente usar un navegador diferente',
    },
    activityTimer: {
      before: '¡Esta actividad no ha comenzado todavía! Tienes {{time}} antes de que empiece.',
      ended: 'Esta actividad finalizó {{when}}',
      remaining: 'Tiempo restante',
    },
    actions: {
      register: 'Registrarse',
      unregister: 'Darse de baja',
      join: 'Unirse',
      purchase: 'Comprar',
    },
    activity: {
      register: '¿Deseas participar en esta actividad? Haz clic en registrarse',
      freeWorkshopAdvice: '¿Quieres participar en esta actividad? Complete el proceso de registro',
      paymentAdvice: '¿Quiere participar en esta actividad? Primero debe completar el pago del precio de la actividad, puede hacerlo haciendo clic en el botón «{{button}}»',
      payPendingCharges: 'Pagar los cargos pendientes',
      registerToPaidConfirm: '¿Está seguro de que quiere comprar esta actividad?',
      registerToPaidHelperText: 'Una vez se registre en esta actividad, deberá pagar su precio antes de unirse a cualquier otra actividad de pago.',
      itCosts: 'Tiene un precio de {{price}}.',
      unregisterConfirm: '¿Estás seguro que deseas darte de baja de {{activity}}?',
      notInterested: '¿Ya no estás interesado en participar en la actividad?',
      invitation: 'Estamos en vivo en {{streamingType}}, clic en <3>Unirse</3> para empezar',
      waiting: '¡Bienvenido!\nMantente al tanto, la actividad empezará {{timeRemaining}}',
      ended: 'Esta actividad ha terminado, ¡gracias por acompañarnos!',
      limitedRegistration: 'Limitado a {{attendees}} participantes.',
      mobileZoomWarning: 'Algunas opciones no están disponibles para los navegadores móviles. <1>Obtenga más información aquí</1>.',
    },
    translationWidget: {
      button: 'Traducción en vivo',
      modalTitle: 'Opciones de traduccion',
    },
  },
  fr: {
    timeline: {
      viewingNow: 'Diffusion en cours',
      comingUpNext: 'À suivre',
      timeRemaining: 'Temps restant',
      positionAt: '{{position}} à ',
    },
    interactivity: {
      interactivity: 'Interactivité',
      titlewarning: 'Cette fonctionnalité n\'est pas disponible',
      safariwarning: 'Cette fonctionnalité peut ne pas fonctionner correctement dans le navigateur Safari sur Iphone/Ipad, essayez d\'utiliser un autre navigateur',
    },
    activityTimer: {
      before: 'Cette activité n\'a pas encore commencé ! Vous avez {{{time}} avant de commencer.',
      ended: 'Cette activité a pris fin {{when}}',
      remaining: 'Temps restant',
    },
    actions: {
      register: 'S\'inscrire',
      unregister: 'Se désinscrire',
      join: 'Rejoignez',
      purchase: 'Acheter',
    },
    activity: {
      register: 'Vous voulez participer à cette activité ? Cliquez sur s\'inscrire',
      freeWorkshopAdvice: 'Vous souhaitez participer à cette activité ? Compléter le processus d\'inscription',
      paymentAdvice: "Vous souhaitez participer à cette activité ? Vous devez d'abord effectuer le paiement du coût de l'activité, en cliquant sur le bouton « {{button}} »",
      payPendingCharges: 'Payez les frais en attente',
      registerToPaidConfirm: 'Êtes-vous sûre de vouloir acheter cette activité ?',
      registerToPaidHelperText: 'Une fois que vous vous êtes inscrite à cette activité, vous devez en payer le coût avant de participer à toute autre activité payante.',
      itCosts: 'Cela coûte {{price}}.',
      unregisterConfirm: 'Êtes-vous sûr de vouloir vous désinscrire de {{activity}} ?',
      notInterested: 'Vous ne souhaitez plus participer à l\'activité ?',
      invitation: 'Nous sommes en direct sur {{{streamingType}}, cliquez sur <3>join</3> pour commencer',
      waiting: 'Bienvenue !\nNous vous donnons rendez-vous {{timeRemaining}}',
      ended: 'Cette activité est terminée, merci de vous joindre à nous !',
      limitedRegistration: 'Limité à {{attendees}} participants.',
      mobileZoomWarning: 'Certaines options ne sont pas disponibles pour les navigateurs mobiles. Pour en <1>savoir plus, cliquez ici</1>.',
    },
    translationWidget: {
      button: 'Traduction en direct',
      modalTitle: 'Options de traduction',
    },
  },
  ca: {
    timeline: {
      viewingNow: 'Visionant ara',
      comingUpNext: 'A continuació',
      timeRemaining: 'Temps restant',
      positionAt: '{{position}} a',
    },
    interactivity: {
      interactivity: 'Interactivitat',
      titlewarning: 'Aquesta característica no està disponible',
      safariwarning:
        'Aquesta característica pot no funcionar correctament en el navegador Safari a l’iPhone/iPad, prova d’emprar un altre navegador',
    },
    activityTimer: {
      before:
        'Aquesta activitat no ha començat encara! Tens {{time}} abans que no comenci.',
      ended: 'Aquesta activitat ha acabat {{when}}',
      remaining: 'Temps restant',
    },
    actions: {
      register: 'Inscriu-te',
      unregister: 'Dona’t de baixa',
      join: 'Uneix-t’hi',
      purchase: 'Comprar',
    },
    activity: {
      register: 'Vols participar en aquesta activitat? Fes clic a «inscriu-te»',
      freeWorkshopAdvice: 'Vols participar en aquesta activitat? Completa el procés de registre',
      paymentAdvice: "Vols participar en aquesta activitat? Primer has de completar el pagament del cost de l'activitat, pots fer-ho fent clic al botó “{{button}}”",
      payPendingCharges: 'Pagar els càrrecs pendents',
      registerToPaidConfirm: 'Estàs segur que vols adquirir aquesta activitat?',
      registerToPaidHelperText: "Un cop us inscriviu a aquesta activitat, haureu d'abonar el seu cost abans d'incorporar-vos a qualsevol altra activitat remunerada.",
      itCosts: 'Té un cost de {{price}}.',
      unregisterConfirm: 'Estàs segur/a que vols donar-te de baixa de {{activity}}?',
      notInterested: 'Ja no estàs interessat a participar en l’activitat?',
      invitation: 'Estam en directe a  {{streamingType}}, fes clic a <3>Uneix-t’hi</3> per començar',
      waiting: 'Benvingut / benvinguda!\nEstigues atent, l\'activitat començarà {{timeRemaining}}',
      ended: 'Aquesta activitat ha acabat, gràcies per assistir-hi!',
      limitedRegistration: 'Limitat a {{attendees}} assistents.',
      mobileZoomWarning: 'Algunes opcions no estan disponibles per als navegadors mòbils <1>Vegeu-ne més aquí</1>.',
    },
    translationWidget: {
      button: 'Traducció en directe',
      modalTitle: 'Opcions de traducció',
    },
  },
  pt: {
    timeline: {
      viewingNow: 'Em direto',
      comingUpNext: 'A seguir',
      timeRemaining: 'Tempo restante',
      positionAt: '{{position}} em ',
    },
    interactivity: {
      interactivity: 'Interatividade',
      titlewarning: 'Este recurso não está disponível',
      safariwarning: 'Este recurso pode não funcionar corretamente no navegador Safari no Iphone/Ipad, tente usar um navegador diferente',
    },
    activityTimer: {
      before: 'Esta atividade ainda não começou! Você tem {{time}} antes de começar.',
      ended: 'Esta atividade terminou {{when}}',
      remaining: 'Tempo restante',
    },
    actions: {
      register: 'Registar',
      unregister: 'Cancelar registro',
      join: 'Entrar',
      purchase: 'Comprar',
    },
    activity: {
      register: 'Quer participar desta atividade? Clique em registar',
      freeWorkshopAdvice: 'Quer participar desta atividade? Conclua o processo de registro',
      paymentAdvice: 'Quer participar dessa atividade? Primeiro você deve concluir o pagamento do custo da atividade, você pode fazer isso clicando no botão “{{button}}”',
      payPendingCharges: 'Pague cobranças pendentes',
      registerToPaidConfirm: 'Tem certeza de que deseja comprar essa atividade?',
      registerToPaidHelperText: 'Depois de se inscrever para esta atividade, você deve pagar o custo antes de participar de qualquer outra atividade paga.',
      itCosts: 'Tem um custo de {{price}}.',
      unregisterConfirm: 'Tem certeza de que deseja cancelar o registro em {{activity}}?',
      notInterested: 'Não está mais interessado em participar da atividade?',
      invitation: 'Estamos ao vivo em {{streamingType}}, clique em <3>participar</3> para começar',
      waiting: 'Bem-vindo!\nA sessão iniciará {{timeRemaining}}.',
      ended: 'Esta atividade terminou, obrigado por se juntar a nós!',
      limitedRegistration: 'Limité à {{attendees}} participants.',
      mobileZoomWarning: 'Algumas opções não estão disponíveis para navegadores móveis. <1>Veja mais aqui</1>.',
    },
    translationWidget: {
      button: 'Tradução ao vivo',
      modalTitle: 'Opções de tradução',
    },
  },
  it: {
    timeline: {
      timeRemaining: 'Tempo rimanente',
      positionAt: '{{position}} presso ',
      viewingNow: 'Stai visualizzando ora',
      comingUpNext: 'Successive',
    },
    interactivity: {
      interactivity: 'Interattività',
      titlewarning: 'Questa funzione non è disponibile',
      safariwarning: 'Questa funzione potrebbe non funzionare correttamente nel browser Safari su Iphone/Ipad, prova a utilizzare un browser diverso'
    },
    activityTimer: {
      before: 'Questa attività non è ancora iniziata! Hai {{time}} prima che inizi.',
      remaining: 'Tempo rimanente',
      ended: 'Questa attività è terminata {{when}}'
    },
    actions: {
      register: 'Registrare',
      unregister: 'Annulla la registrazione',
      join: 'Unisciti',
      purchase: 'Comprare',
    },
    activity: {
      unregisterConfirm: 'Sei sicuro di voler annullare la registrazione a {{activity}}?',
      waiting: "Benvenuti!\nRestate sintonizzati, l'attività avrà inizio {{timeRemaining}}",
      notInterested: "Non sei più interessato a partecipare all'attività?",
      register: 'Vuoi partecipare a questa attività? Clicca su registrati',
      limitedRegistration: 'Limitato a {{attendees}} partecipanti.',
      freeWorkshopAdvice: 'Vuoi partecipare a questa attività? Completa il processo di registrazione',
      paymentAdvice: "Vuole partecipare a questa attività? Per prima cosa deve completare il pagamento del costo dell'attività, può farlo facendo clic sul pulsante «{{button}}»",
      payPendingCharges: 'Pagare gli addebiti pendenti',
      registerToPaidConfirm: 'È sicuro di voler acquistare questa attività?',
      registerToPaidHelperText: 'Una volta registrato a questa attività, deve pagarne il costo prima di partecipare a qualsiasi altra attività a pagamento.',
      itCosts: 'Costa {{price}}.',
      ended: 'Questa attività è terminata, grazie per esserti unito a noi!',
      invitation: 'Siamo attivi su {{streamingType}}, fai clic su <3>iscriviti</3> per iniziare',
      mobileZoomWarning: 'Alcune opzioni non sono disponibili per i browser mobili. <1>Per saperne di più, clicca qui</1>.',
    },
    translationWidget: {
      button: 'Traduzione dal vivo',
      modalTitle: 'Opzioni di traduzione'
    }
  },
  ja: {
    interactivity: {
      interactivity: '双方向性',
      titlewarning: 'この機能は使用できません',
      safariwarning: 'この機能は、iPhone/Ipad の Safari ブラウザでは正しく動作しない可能性があります。別のブラウザを使用してみてください'
    },
    timeline: {
      viewingNow: '今すぐ視聴',
      comingUpNext: '次は来る',
      positionAt: '{{position}} ',
      timeRemaining: '残り時間'
    },
    activityTimer: {
      remaining: '残り時間',
      before: 'このアクティビティはまだ始まっていません！開始前に {{time}} があります。',
      ended: 'このアクティビティは終了しました {{hen}}'
    },
    actions: {
      join: '加入',
      register: '登録',
      unregister: '登録解除',
      purchase: '購入',
    },
    translationWidget: {
      button: 'ライブ翻訳',
      modalTitle: '翻訳オプション'
    },
    activity: {
      notInterested: 'アクティビティへの参加に興味がなくなったのですか？',
      ended: 'この活動は終了しました。ご参加いただきありがとうございます！',
      unregisterConfirm: '{{activity}} への登録を解除してよろしいですか？',
      freeWorkshopAdvice: 'この活動に参加したいですか？登録プロセスを完了する',
      paymentAdvice: 'このアクティビティに参加したいですか？まず、アクティビティ費用の支払いを完了する必要があります。「{{button}}」ボタンをクリックしてください',
      payPendingCharges: '保留中の料金を支払う',
      registerToPaidConfirm: 'このアクティビティを購入してよろしいですか？',
      registerToPaidHelperText: 'このアクティビティに登録したら、他の有料アクティビティに参加する前にその費用を支払う必要があります。',
      itCosts: 'それには {{price}} の費用がかかります。',
      waiting: 'ようこそ！\nしばらくお待ちください。アクティビティが開始されます {{timeReaining}}',
      invitation: '{{streamingType}} にライブ配信しています。<3>参加をクリックして開始してください</3>',
      limitedRegistration: '{{attendees}} 人の参加者に制限されます。',
      register: 'このアクティビティに参加したいですか？[登録] をクリックします。',
      mobileZoomWarning: '一部のオプションはモバイルブラウザでは利用できません。<1>詳細はこちらをご覧ください</1>。',
    }
  },
  zh: {
    timeline: {
      viewingNow: '现在查看',
      timeRemaining: '剩余时间',
      comingUpNext: '接下来',
      positionAt: '{{position}} at '
    },
    activityTimer: {
      remaining: '剩余时间',
      before: '这项活动尚未开始！在它开始之前你有 {{time}}。',
      ended: '此活动已结束 {{when}}'
    },
    actions: {
      register: '註冊',
      unregister: '取消注册',
      join: '加入',
      purchase: '买',
    },
    translationWidget: {
      modalTitle: '翻译选项',
      button: '实时翻译'
    },
    activity: {
      notInterested: '不再有兴趣参加活动？',
      limitedRegistration: '仅限 {{与会者}} 位与会者。',
      unregisterConfirm: '你确定要取消注册到 {{activity}} 吗？',
      ended: '本次活动已经结束，谢谢你加入我们！',
      freeWorkshopAdvice: '想参加这个活动吗？完成注册程序',
      paymentAdvice: '想参加这个活动吗？首先，您必须完成活动费用的支付，您可以通过单击 “{{button}}” 按钮来完成',
      payPendingCharges: '支付待处理费用',
      registerToPaidConfirm: '您确定要购买此活动吗？',
      registerToPaidHelperText: '一旦您注册了此活动，您必须先支付其费用，然后才能参加任何其他付费活动。',
      itCosts: '它的消耗为 {{price}}。',
      invitation: '我们在 {{StreamingType}} 上线，点击<3>加入</3>开始',
      waiting: '欢迎！\n请继续关注，活动将开始 {{timeLatim余}}',
      register: '想参加这项活动？点击注册',
      mobileZoomWarning: '某些选项不适用于移动浏览器。<1>在此处查看更多</1>。',
    },
    interactivity: {
      interactivity: '互动性',
      titlewarning: '此功能不可用',
      safariwarning: '此功能可能无法在 Iphone 上的 Safari 浏览器中正常运行，请尝试使用其他浏览器'
    }
  },
  ar: {
    timeline: {
      viewingNow: 'عرض الآن',
      comingUpNext: 'قادم بعد ذلك',
      timeRemaining: 'الوقت المتبقي',
      positionAt: '{{position}} في ',
    },
    interactivity: {
      interactivity: 'التفاعل',
      titlewarning: 'هذه الميزة غير متوفرة',
      safariwarning: 'قد لا تعمل هذه الميزة بشكل صحيح في متصفح Safari على Iphone/Ipad، حاول استخدام متصفح مختلف',
    },
    activityTimer: {
      before: 'لم يبدأ هذا النشاط بعد! لديك {{time}} قبل أن تبدأ.',
      ended: 'انتهى هذا النشاط {{when}}',
      remaining: 'الوقت المتبقي',
    },
    actions: {
      register: 'سجل',
      unregister: 'إلغاء التسجيل',
      join: 'انضم',
      purchase: 'يشترى',
    },
    activity: {
      register: 'هل تريد المشاركة في هذا النشاط؟ انقر فوق التسجيل',
      freeWorkshopAdvice: 'هل تريد المشاركة في هذا النشاط؟ أكمل عملية التسجيل',
      paymentAdvice: 'هل تريد المشاركة في هذا النشاط؟ يجب عليك أولاً إكمال دفع تكلفة النشاط، ويمكنك القيام بذلك بالنقر فوق الزر «{{button}}»',
      payPendingCharges: 'دفع الرسوم المعلقة',
      registerToPaidConfirm: 'هل تريد بالتأكيد شراء هذا النشاط؟',
      registerToPaidHelperText: 'بمجرد التسجيل في هذا النشاط، يجب عليك دفع تكلفته قبل الانضمام إلى أي أنشطة مدفوعة أخرى.',
      itCosts: 'يكلف {{price}}.',
      unregisterConfirm: 'هل تريد بالتأكيد إلغاء التسجيل في {{activity}}؟',
      notInterested: 'لم تعد مهتمة بالمشاركة في النشاط؟',
      invitation: 'نحن نعيش على {{streamingType}}، انقر فوق <3>الانضمام</3> للبدء',
      waiting: 'أهلا بك!\nترقبوا، سيبدأ النشاط {{timeRemaining}}',
      ended: 'انتهى هذا النشاط، شكرًا لك على انضمامك إلينا!',
      limitedRegistration: 'يقتصر على الحضور {{attendees}}.',
      mobileZoomWarning: 'لا تتوفر بعض الخيارات لمتصفحات الأجهزة المحمولة. <1>شاهد المزيد هنا</1>.',
    },
    translationWidget: {
      button: 'ترجمة حية',
      modalTitle: 'خيارات الترجمة',
    },
  },
  tr: {
    timeline: {
      viewingNow: 'Yayında',
      comingUpNext: 'Sıradaki',
      timeRemaining: 'Kalan süre',
      positionAt: '{{position}} '
    },
    interactivity: {
      interactivity: 'Etkileşim',
      titlewarning: 'Bu özellik kullanılamıyor',
      safariwarning: 'Bu özellik Iphone/Ipad Safari tarayıcısında düzgün çalışmayabilir, farklı bir tarayıcı kullanmayı deneyin'
    },
    activityTimer: {
      before: 'Bu etkinlik henüz başlamadı! Başlamadan önce {{time}} var.',
      ended: 'Bu etkinlik sona erdi {{when}}',
      remaining: 'Kalan süre'
    },
    actions: {
      register: 'Kaydolun',
      unregister: 'Kaydını kaldır',
      join: 'Katıl',
      purchase: 'Satın al',
    },
    activity: {
      register: 'Bu etkinliğe katılmak ister misiniz? Kayıt üzerine tıklayınız',
      freeWorkshopAdvice: 'Bu aktiviteye katılmak ister misiniz? Kayıt işlemini tamamlayın',
      paymentAdvice: 'Bu aktiviteye katılmak ister misiniz? Öncelikle etkinlik maliyetinin ödenmesini tamamlamanız gerekir, bunu “{{button}}” düğmesine tıklayarak yapabilirsiniz',
      payPendingCharges: 'Bekleyen ücretleri öde',
      registerToPaidConfirm: 'Bu etkinliği satın almak istediğinizden emin misiniz?',
      registerToPaidHelperText: 'Bu aktiviteye kaydolduktan sonra, diğer ücretli faaliyetlere katılmadan önce maliyetini ödemelisiniz.',
      itCosts: 'Maliyeti {{price}}.',
      unregisterConfirm: '{{activity}} adresine kaydını kaldırmak istediğinizden emin misiniz?',
      notInterested: 'Artık faaliyete katılmakla ilgilenmiyor musunuz?',
      invitation: "{{streamingType}} tarihinde canlı yayındayız, başlamak için <3>katıl'a</3> tıklayın",
      waiting: 'Hoş geldiniz!\nBizi izlemeye devam edin, etkinlik başlayacak {{timeRemaining}}',
      ended: 'Bu etkinlik sona erdi, bize katıldığınız için teşekkür ederiz!',
      limitedRegistration: '{{attendees}} katılımcılarla sınırlıdır.',
      mobileZoomWarning: '<1>Mobil tarayıcılar için bazı seçenekler mevcut değildir.</1>',
    },
    translationWidget: {
      button: 'Canlı çeviri',
      modalTitle: 'Çeviri seçenekleri'
    }
  },
  de: {
    timeline: {
      viewingNow: 'Jetzt ansehen',
      comingUpNext: 'Kommt als Nächstes',
      timeRemaining: 'Verbleibende Zeit',
      positionAt: '{{position}} bei '
    },
    interactivity: {
      interactivity: 'Interaktivität',
      titlewarning: 'Diese Funktion ist nicht verfügbar',
      safariwarning: 'Diese Funktion funktioniert möglicherweise im Safari-Browser auf iPhone/iPad nicht richtig. Versuchen Sie es mit einem anderen Browser'
    },
    activityTimer: {
      before: 'Diese Aktivität hat noch nicht begonnen! Sie haben {{time}} bevor es beginnt.',
      ended: 'Diese Aktivität wurde beendet {{when}}',
      remaining: 'Verbleibende Zeit'
    },
    actions: {
      register: 'registrieren',
      unregister: 'Registrierung aufheben',
      join: 'Join',
      purchase: 'Kaufen',
    },
    activity: {
      register: 'Möchten Sie an dieser Aktivität teilnehmen? Klicke auf „Anmelden“',
      freeWorkshopAdvice: 'Meedoen aan deze activiteit? Voltooi het registratieproces',
      paymentAdvice: 'Möchten Sie an dieser Aktivität teilnehmen? Zuerst müssen Sie die Zahlung der Aktivitätskosten abschließen, Sie können dies tun, indem Sie auf die Schaltfläche „{{button}}“ klicken.',
      payPendingCharges: 'Zahlung ausstehender Gebühren',
      registerToPaidConfirm: 'Sind Sie sicher, dass Sie diese Aktivität kaufen möchten?',
      registerToPaidHelperText: 'Sobald Sie sich für diese Aktivität registriert haben, müssen Sie die Kosten bezahlen, bevor Sie an anderen kostenpflichtigen Aktivitäten teilnehmen können.',
      itCosts: 'Es kostet {{price}}.',
      unregisterConfirm: 'Möchten Sie sich wirklich bei {{activity}} abmelden?',
      notInterested: 'Sie sind nicht mehr daran interessiert, an der Aktivität teilzunehmen?',
      invitation: 'Wir sind live auf {{streamingType}}, klicke auf <3>Beitreten</3>, um loszulegen',
      waiting: 'Willkommen!\nBleib dran, die Aktivität beginnt {{timeRemaining}}',
      ended: 'Diese Aktivität ist beendet. Vielen Dank, dass Sie zu uns gekommen sind!',
      limitedRegistration: 'Beschränkt auf {{attendees}} Teilnehmer.',
      mobileZoomWarning: 'Einige Optionen sind für mobile Browser nicht verfügbar. <1>Weitere Informationen finden Sie hier</1>.',
    },
    translationWidget: {
      button: 'Live-Übersetzung',
      modalTitle: 'Optionen für die Übersetzung'
    }
  },
};
