import { useSelector } from 'react-redux';
import { selectTextTransformSettings } from '../../helpers/selectors';

const useStringTransforms = () => {
  const capsEnabled = useSelector(selectTextTransformSettings);

  const toSentenceCase = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  };

  const ALL_CAPS = /^[^a-zà-ÿ]+$/;

  const toTitleCase = (str) => {
    if (!str) return '';
    if (capsEnabled) return str.replace(/[^\s]+/g, (txt) => ((ALL_CAPS.test(txt)) ? txt : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()));
    return str;
  };

  const toSnakeCase = (str) => {
    if (!str) return '';
    return str.toLowerCase().replace(/ /g, '_');
  };

  const toKebabCase = (str) => {
    if (!str) return '';
    return str.toLowerCase().replace(/ /g, '-');
  };

  return {
    toSentenceCase,
    toTitleCase,
    toSnakeCase,
    toKebabCase,
  };
};

export default useStringTransforms;
