import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import useTimer from '../../hooks/useTimer';
import { baseMoment } from '../../helpers/dates';
import { selectEventTimeFormat } from '../../helpers/selectors';
import BlockGroup from './BlockGroup';
import Workshop from './Workshop';
import Meeting from './Meeting';
import PendingMeeting from './PendingMeeting';
import PendingRequests from './PendingRequests';
import BlockedSlot from './BlockedSlot';
import AvailableSlot from './AvailableSlot';
import OwnSlot from './OwnSlot';
import { minutesToPixels, SLOT_HEIGHT, DIVIDER_HEIGHT } from './Block';

const HOUR_COLUMN_WIDTH = 56;

const blocksIntersect = (
  b1, // less than or equal to b2 in the array
  b2
) => !!b1 && !!b2 && baseMoment(b2.startDate).isBefore(b1.endDate);

const distributeBlocks = (blocks) => {
  if (!blocks || !blocks.length) return { distribution: {}, groups: {} };
  const distribution = {};
  const intersections = {};
  const colsForGroup = {};
  const groups = {};
  let group = 0;
  blocks.forEach((block, i) => {
    const pastBlocks = blocks.slice(0, i);
    const pastIntersections = pastBlocks.filter(
      (pastBlock) => blocksIntersect(pastBlock, block)
    );

    if (pastIntersections.length === 0) group += 1;

    if (!colsForGroup[group]) colsForGroup[group] = 1;
    const colsNeeded = pastIntersections.length + 1;
    if (colsForGroup[group] < (colsNeeded)) colsForGroup[group] = colsNeeded;

    distribution[block.key] = {
      group: String(group),
    };
    intersections[block.key] = pastIntersections;

    if (!groups[group]) groups[group] = {
      blockIds: [block.key],
      startDate: block.startDate,
    };
    else groups[group].blockIds.push(block.key);
    groups[group].endDate = block.endDate;
  });

  blocks.forEach((block) => {
    let offset = 0;
    const usedOffsets = intersections[block.key].map(
      ({ key }) => distribution[key].offset
    );
    while (usedOffsets.includes(offset)) offset += 1;
    distribution[block.key].offset = offset;
    distribution[block.key].colsForGroup = colsForGroup[distribution[block.key].group];
  });

  return { distribution, groups };
};

const contentTypes = {
  workshop: Workshop,
  meeting: Meeting,
  blockedSlot: BlockedSlot,
  availableSlot: AvailableSlot,
  ownSlot: OwnSlot,
  pendingMeeting: PendingMeeting,
  request: PendingRequests,
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.lightGrey.main,
    position: 'relative',
    width: '100%',
    borderRadius: 10,
    marginTop: theme.spacing(1),
    marginLeft: HOUR_COLUMN_WIDTH,
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(-theme.subpageDrawer.paddings.desktop.horizontal),
      borderRadius: '10px 0 0 10px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-theme.subpageDrawer.paddings.mobile.horizontal),
    },
  },
  unextendedContainer: {
    marginRight: 0,
    borderRadius: 10,
  },
  slot: {
    color: theme.palette.darkGrey.light,
    position: 'relative',
    height: SLOT_HEIGHT,
    '&:first-of-type $divider': {
      visibility: 'hidden',
    },
    '&:last-of-type': {
      height: 0,
      '& $divider': {
        visibility: 'hidden',
      },
    },
  },
  divider: {
    height: DIVIDER_HEIGHT,
  },
  timeSlot: {
    fontSize: theme.spacing(1.5),
    lineHeight: 1,
    height: theme.spacing(1.5),
    position: 'absolute',
    left: -HOUR_COLUMN_WIDTH,
    width: HOUR_COLUMN_WIDTH,
    top: -theme.spacing(0.75),
  },
  currentTimeMarker: {
    position: 'absolute',
    width: '100%',
    height: 16,
    zIndex: 1,
    pointerEvents: 'none',
  },
  currentTimeCircle: {
    padding: theme.spacing(0, 1.5),
    transform: 'translate(calc(-100% - 12px), -3px)',
    height: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.white,
    lineHeight: 1.3,
    display: 'inline-block',
    fontSize: 12,
  },
  currentTimeLine: {
    height: 2,
    backgroundColor: theme.palette.green.main,
    width: 'calc(100% + 13px)',
    position: 'absolute',
    left: -13,
    top: 7,
  },
}));

const Schedule = ({
  activities,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [inRange, setInRange] = useState(false);

  const [currentTime, setCurrentTime] = useState('');
  const stepSize = useMemo(() => {
    const minDuration = Math.min(...(activities.map(
      ({ startDate, endDate }) => baseMoment(endDate).diff(startDate, 'minutes')
    )));

    if (minDuration <= 10) return isMobile ? 3 : 5;
    if (minDuration <= 20) return isMobile ? 6 : 10;
    return isMobile ? 15 : 30;
  }, [activities, isMobile]);
  const { distribution, groups } = useMemo(() => distributeBlocks(activities), [activities]);

  const fullWidthBlocks = useMemo(() => (
    Math.max(...(activities.map((activity) => (
      distribution[activity.key].offset
    )))) === 0
  ), [activities, distribution]);

  const visibleHoursInterval = (stepSize < 30) ? 30 : 60;
  const hourFormat = useSelector(selectEventTimeFormat({ includeMinutes: stepSize < 30 }));
  const currentTimeFormat = useSelector(selectEventTimeFormat({ includeMinutes: true }));

  const { startDate } = activities.slice(0, 1)[0] || {};
  const scheduleStart = useMemo(() => {
    const start = baseMoment(startDate);
    start.minute(0);
    return start;
  }, [startDate]);

  const endDates = activities.map(({ endDate }) => baseMoment(endDate).valueOf());
  const endDate = endDates.length ? Math.max(...endDates) : undefined;

  const scheduleEnd = useMemo(() => {
    const end = baseMoment(endDate);
    end.subtract(1, 'minute');
    end.minute(0);
    end.add(1, 'hour');
    // add some steps in the end and guarantee minimum height
    const steps = end.diff(startDate, 'minutes') / stepSize;
    const stepsToAdd = Math.max(4 - steps, 0) + 2;
    end.add(stepsToAdd * stepSize, 'minutes');
    return end;
  }, [endDate, startDate, stepSize]);

  const hours = useMemo(() => {
    const timeDiff = scheduleEnd.diff(scheduleStart, 'minutes');
    const span = Math.ceil(timeDiff / stepSize);
    return [...Array(span + 1).keys()].map(
      (step) => scheduleStart.clone().add(stepSize * step, 'minutes')
    );
  }, [scheduleStart, scheduleEnd, stepSize]);

  const markerTop = useMemo(() => {
    const elapsedMinutes = currentTime
      ? baseMoment(currentTime).diff(scheduleStart, 'minutes') : 0;
    return minutesToPixels(elapsedMinutes, stepSize);
  }, [currentTime, scheduleStart, stepSize]);

  const onTick = useCallback((now, start, end) => {
    if (now.isBetween(start, end, null, '[)')) {
      setCurrentTime(now.format());
      setInRange(true);
    } else setInRange(false);
  }, [setInRange, setCurrentTime]);
  useTimer({
    startDate: scheduleStart.format(),
    endDate: scheduleEnd.format(),
    onTick,
    step: 60000,
  });

  return (
    <div
      className={clsx(
        classes.container,
        fullWidthBlocks && classes.unextendedContainer
      )}
    >
      {inRange && (
        <div className={classes.currentTimeMarker} style={{ top: markerTop }}>
          <div className={classes.currentTimeCircle}>
            {baseMoment(currentTime).format(currentTimeFormat)}
          </div>
          <div className={classes.currentTimeLine} />
        </div>
      )}
      <div>
        {hours.map((hour) => (
          <div key={hour.format()} className={classes.slot}>
            <Divider className={classes.divider} />
            {(hour.minutes() % visibleHoursInterval === 0) && (
              <Typography className={classes.timeSlot}>{hour.format(hourFormat)}</Typography>
            )}
          </div>
        ))}
      </div>
      {Object.keys(groups).map((groupId) => {
        const activitiesInGroup = activities.filter((activity) => (
          distribution[activity.key].group === groupId
        ));
        const maxOffset = Math.max(...(activitiesInGroup.map((activity) => (
          distribution[activity.key].offset
        ))));
        return (
          <BlockGroup
            key={groupId}
            startDate={groups[groupId].startDate}
            endDate={groups[groupId].endDate}
            scheduleStart={scheduleStart.format()}
            stepSize={stepSize}
            maxOffset={maxOffset}
          >
            {activitiesInGroup.map((activity) => {
              if (activity.type === 'emptyBlock') return null;
              const Content = contentTypes[activity.type];
              return (
                <Content
                  {...activity}
                  scheduleStart={groups[groupId].startDate}
                  stepSize={stepSize}
                  cols={distribution[activity.key].colsForGroup}
                  offset={distribution[activity.key].offset}
                  fullWidthBlocks={fullWidthBlocks}
                />
              );
            })}
          </BlockGroup>
        );
      })}
    </div>
  );
};

Schedule.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
  })),
};

Schedule.defaultProps = {
  activities: [],
};

export default Schedule;
