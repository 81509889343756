import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from 'eventtia-ui-components/lib/Button';

import withForm from '../../hocs/withForm';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import CustomField from '../CustomField';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  button: {
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    textTransform: 'capitalize',
  },
  disabledButton: {
    backgroundColor: theme.palette.lightGrey.dark,
  },
}));

// TODO: vanish this from existence
const idToWeirdId = (id) => (`${id}`);

const QuizForm = ({
  registerInput, customFields, setFormValue, disabled, watchFormValues,
  activeQuestionId, questionSent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');

  return (
    <Grid container spacing={2}>
      {activeQuestionId && customFields[activeQuestionId] && (
        <Grid item xs={12} key={activeQuestionId}>
          <CustomField
            getIdentifier={idToWeirdId}
            field={customFields[activeQuestionId]}
            inputRef={registerInput}
            watchFormValues={watchFormValues}
            setFormValue={setFormValue}
          />
        </Grid>
      )}
      <Grid className={classes.buttonContainer} item xs={12}>
        <Button
          type="submit"
          small
          disabled={disabled || questionSent}
          classes={clsx(classes.button,
            (disabled || questionSent) && classes.disabledButton)}
        >
          {disabled ? t('status.saving') : t('actions.send')}
        </Button>
      </Grid>
    </Grid>
  );
};

QuizForm.propTypes = {
  registerInput: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  watchFormValues: PropTypes.func.isRequired,
  customFields: PropTypes.objectOf(
    CustomPropTypes.customField
  ),
  disabled: PropTypes.bool,
  activeQuestionId: PropTypes.number,
  questionSent: PropTypes.bool,
};

QuizForm.defaultProps = {
  disabled: false,
  customFields: {},
  activeQuestionId: undefined,
  questionSent: false,
};

const mapStateToProps = ({
  entities: {
    customFields,
  },
}) => ({
  customFields,
});

export default compose(
  withForm({ required: true, autoComplete: 'off' }),
  connect(mapStateToProps, {})
)(QuizForm);
