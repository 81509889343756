import getEndpoint from './endpoints';

const setCustomManifest = (event) => {
  const { eventUri } = event;

  const manifestURL = getEndpoint('manifest', { eventUri });
  document.getElementById('custom-manifest').setAttribute('href', manifestURL);
};

export default setCustomManifest;
