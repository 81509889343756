import { combineReducers } from 'redux';
import { ADD_CHANNEL } from '../../actions/messages';

const initialOpenChannels = [];
const openChannels = (state = initialOpenChannels, action) => {
  switch (action.type) {
    case ADD_CHANNEL: {
      const index = state.map(({ topic }) => topic).indexOf(action.channel.topic);
      if (index > -1) return [
        ...state.slice(0, index),
        { ...action.channel },
        ...state.slice(index + 1),
      ];
      return [
        ...state,
        { ...action.channel },
      ];
    }
    default:
      return state;
  }
};

export default combineReducers({
  openChannels,
});
