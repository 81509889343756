import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ContextMenu from 'eventtia-ui-components/lib/ContextMenu';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    position: 'relative',
  },
  profile: {
    flex: 1,
    alignSelf: 'flex-start',
    textAlign: 'left',
    margin: theme.spacing(0, 2),
  },
  darkText: {
    color: theme.palette.darkGrey.light,
    fontWeight: 'bold',
  },
  lightText: {
    color: fade(theme.palette.darkGrey.light, 0.8),
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.25, 0),
  },
  avatar: {
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.light,
    width: 48,
    height: 48,
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  menuIcon: {
    color: theme.palette.primary.light,
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const AttendeeLeadCardHeader = ({ fullName, company, menuItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Avatar className={classes.avatar} />
      <div className={classes.profile}>
        <Typography className={classes.darkText}>
          {fullName}
        </Typography>
        <Typography className={classes.lightText} variant="caption" component="div">
          {company}
        </Typography>
      </div>
      {!!menuItems && (
        <div role="button" onClick={(e) => { e.stopPropagation(); }}>{/* eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
          <ContextMenu
            items={menuItems}
            iconButtonClassName={classes.menuIcon}
          />
        </div>
      )}
    </div>
  );
};

AttendeeLeadCardHeader.propTypes = {
  fullName: PropTypes.string,
  company: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
  })),
};

AttendeeLeadCardHeader.defaultProps = {
  fullName: undefined,
  company: undefined,
  menuItems: undefined,
};

export default AttendeeLeadCardHeader;
