import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import { baseMoment } from '../../helpers/dates';
import { minutesToPixels, BLOCK_WIDTH, BLOCK_SPACING_X } from './Block';

const useStyles = makeStyles(() => ({
  blockGroup: {
    position: 'absolute',
    overflowY: 'hidden',
    width: '100%',
  },
}));

const BlockGroup = ({
  startDate, endDate, scheduleStart, stepSize, children, maxOffset,
}) => {
  const classes = useStyles();

  const offsetYInMinutes = baseMoment(startDate).diff(scheduleStart, 'minutes');
  const durationInMinutes = baseMoment(endDate).diff(startDate, 'minutes');

  const offsetYInPx = minutesToPixels(offsetYInMinutes, stepSize);
  const totalHeight = minutesToPixels(durationInMinutes, stepSize);

  return (maxOffset > 0) ? (
    <SimpleBar
      className={classes.blockGroup}
      style={{
        top: offsetYInPx,
      }}
    >
      <div
        style={{
          width: `calc(${(maxOffset + 1) * BLOCK_WIDTH}% + ${(maxOffset + 2) * BLOCK_SPACING_X}px)`,
          height: totalHeight,
        }}
      >
        {children}
      </div>
    </SimpleBar>
  ) : (
    <div
      className={classes.blockGroup}
      style={{
        top: offsetYInPx,
        height: totalHeight,
      }}
    >
      {children}
    </div>
  );
};

BlockGroup.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  scheduleStart: PropTypes.string.isRequired,
  stepSize: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  maxOffset: PropTypes.number.isRequired,
};

export default BlockGroup;
