import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { initializeApp } from 'firebase/app';
import {
  getDatabase, connectDatabaseEmulator, goOnline, onValue, ref,
} from 'firebase/database';
import {
  getAuth, setPersistence, signInWithCustomToken, inMemoryPersistence,
} from 'firebase/auth';
import {
  initializeAppCheck, ReCaptchaV3Provider,
} from 'firebase/app-check';

import callApi from '../../actions/callApi';

import FirebaseContext from '../../contexts/FirebaseContext';

import { DEFAULT_ENVIRONMENT } from '../../helpers/endpoints';
import { getEventUri } from '../../helpers/getters';

const firebaseConfig = {
  prod: {
    apiKey: 'AIzaSyAwEk6plUi35BhI9iByMIq9Fnz3Q3t-qbk',
    authDomain: 'eventtia-realtimedata.firebaseapp.com',
    databaseURL: 'https://eventtia-realtimedata-default-rtdb.firebaseio.com',
    projectId: 'eventtia-realtimedata',
    storageBucket: 'eventtia-realtimedata.appspot.com',
    messagingSenderId: '718927924015',
    appId: '1:718927924015:web:aff36d1415ba2da84cafda',
    measurementId: 'G-NZ5HC5YM87',
  },
  dev: {
    apiKey: 'AIzaSyCSMVUm5EdRpnI0V00oOYiaKn-UmJ1He5c',
    authDomain: 'dev---eventtia-realtime.firebaseapp.com',
    databaseURL: 'https://dev---eventtia-realtime-default-rtdb.firebaseio.com',
    projectId: 'dev---eventtia-realtime',
    storageBucket: 'dev---eventtia-realtime.appspot.com',
    messagingSenderId: '528141606262',
    appId: '1:528141606262:web:51712dcac7de43e8382e57',
    measurementId: 'G-JGGKL4JMR8',
  },
  stage: {
    apiKey: 'AIzaSyA_Pt79DLL_cQphUPKn7_r9Jb1f2vvilEA',
    authDomain: 'stage-eventtia-realtime.firebaseapp.com',
    databaseURL: 'https://stage-eventtia-realtime-default-rtdb.firebaseio.com',
    projectId: 'stage-eventtia-realtime',
    storageBucket: 'stage-eventtia-realtime.appspot.com',
    messagingSenderId: '1047362870375',
    appId: '1:1047362870375:web:0a9874df829ead125c88c2',
    measurementId: 'G-VPCSGR5YGH',
  },
};

const usingEmulators = !!process.env.REACT_APP_USE_FIREBASE_EMULATORS;

console.log(usingEmulators ? 'Using Firebase Emulators' : 'Using Firebase Production');

const firebaseApp = initializeApp(firebaseConfig[DEFAULT_ENVIRONMENT]);
if (window.location.hostname === 'localhost' && usingEmulators) {
  const database = getDatabase(firebaseApp);
  connectDatabaseEmulator(database, 'localhost', 9000);
  console.log('Database connected to emulator');
}

const RECAPTCHA_V3_SITE_KEY = '6Le9fTcgAAAAAKS1fL_kfJS56AcovONQKX0lWOmC';
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(RECAPTCHA_V3_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

const FirebaseProvider = ({ children }) => {
  const eventUri = getEventUri();

  const [firebaseAuthComplete, setFirebaseAuthComplete] = useState(false);

  const auth = getAuth();
  const dispatch = useDispatch();
  const currentAttendeeFetched = useSelector((state) => state.fetchStatus.currentAttendee.success);
  const firebaseTokenFetched = useSelector((state) => state.fetchStatus.firebaseToken.success);

  useEffect(() => {
    if (currentAttendeeFetched && !firebaseTokenFetched) dispatch(callApi('firebaseToken', { eventUri }))
      .then((res) => {
        const { firebase_token: token } = res?.response || {};
        if (token) setPersistence(auth, inMemoryPersistence)
          .then(() => {
            signInWithCustomToken(auth, token)
              .then(() => { // userCredentials may be accessed from here
                const db = getDatabase();
                onValue(ref(db, '.info/connected'), (snap) => {
                  if (!snap.val()) goOnline(db);
                });
                setFirebaseAuthComplete(true);
              })
              .catch((error) => {
                console.error('FRTDB SIGN IN ERROR:', error);
              });
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error('FBRTDB Connection Error', errorCode, errorMessage);
          });
      });
  }, [currentAttendeeFetched, firebaseTokenFetched]);

  const firebaseContextValue = {
    firebaseAuthComplete,
    firebaseApp,
    appCheck,
  };

  return (
    <FirebaseContext.Provider value={firebaseContextValue}>
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseProvider;
