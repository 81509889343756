import { NEW_QUESTION } from '../../actions/messages';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_QUESTION: {
      const { channel, payload } = action;
      return {
        ...state,
        [channel]: state[channel] ? [...state[channel], payload] : [payload],
      };
    }
    default:
      return state;
  }
};
