export default {
  en: {
    interfaceSettings: 'Interface Settings',
    attendanceTypeQuestion: 'How are you joining the event?',
    hybridSelectorHelper: 'Based on how you are currently attending, the interface can change to enhance your experience. Other attendees won\'t be able to see your choice and you can change it at any time by visiting the Settings module.',
    settingsHybridSelectorHelper: 'Based on how you are currently attending, the interface can change to enhance your experience. Other attendees won\'t be able to see your choice.',
    attendanceType: {
      virtually: 'Virtually',
      inPerson: 'In-person',
    },
    darkMode: 'Dark mode',
  },
  es: {
    interfaceSettings: 'Configuración de la interfaz',
    attendanceTypeQuestion: '¿Cómo te unes al evento?',
    hybridSelectorHelper: 'Según cómo esté asistiendo actualmente, la interfaz puede cambiar para mejorar su experiencia. Otros asistentes no podrán ver su elección y puede cambiarla en cualquier momento visitando el módulo Configuración.',
    settingsHybridSelectorHelper: 'Según cómo esté asistiendo actualmente, la interfaz puede cambiar para mejorar su experiencia. Otros asistentes no podrán ver su elección.',
    attendanceType: {
      virtually: 'Virtualmente',
      inPerson: 'En persona',
    },
    darkMode: 'Modo oscuro',
  },
  fr: {
    interfaceSettings: 'Paramètres d\'interface',
    attendanceTypeQuestion: 'Comment rejoignez-vous l\'événement ?',
    hybridSelectorHelper: 'En fonction de la façon dont vous participez actuellement, l\'interface peut changer pour améliorer votre expérience. Les autres participants ne pourront pas voir votre choix et vous pouvez le modifier à tout moment en visitant le module Paramètres.',
    settingsHybridSelectorHelper: 'En fonction de la façon dont vous participez actuellement, l\'interface peut changer pour améliorer votre expérience. Les autres participants ne pourront pas voir votre choix.',
    attendanceType: {
      virtually: 'Virtuellement',
      inPerson: 'En personne',
    },
    darkMode: 'Mode sombre',
  },
  ca: {
    interfaceSettings: 'Configuració d’interfície',
    attendanceTypeQuestion: 'Com assitiràs a l’esdeveniment?',
    hybridSelectorHelper: 'D’acord amb el teu mode d’assistència, la interfície pot canviar per millorar la teva experiència. Altres assistents no podran veure la teva elecció i pots canviar-la en qualsevol moment en el mòdul de configuració.',
    settingsHybridSelectorHelper: 'D’acord amb el teu mode d’assistència, la interfície pot canviar per millorar la teva experiència. Altres assistents no podran veure la teva elecció.',
    attendanceType: {
      virtually: 'Virtualment',
      inPerson: 'En persona',
    },
    darkMode: 'Mode fosc',
  },
  pt: {
    interfaceSettings: 'Configurações da interface',
    attendanceTypeQuestion: 'Como você está participando do evento?',
    hybridSelectorHelper: 'Com base em como você está participando atualmente, a interface pode mudar para aprimorar sua experiência. Outros participantes não poderão ver sua escolha e você poderá alterá-la a qualquer momento visitando o módulo Configurações.',
    settingsHybridSelectorHelper: 'Com base em como você está participando atualmente, a interface pode mudar para aprimorar sua experiência. Outros participantes não poderão ver sua escolha.',
    attendanceType: {
      virtually: 'Virtualmente',
      inPerson: 'Em pessoa',
    },
    darkMode: 'Modo escuro',
  },
  it: {
    interfaceSettings: 'Impostazioni dell\'interfaccia',
    attendanceTypeQuestion: 'Come ti stai unendo all\'evento?',
    hybridSelectorHelper: 'In base a come stai attualmente partecipando, l\'interfaccia può cambiare per migliorare la tua esperienza. Gli altri partecipanti non potranno vedere la tua scelta e puoi modificarla in qualsiasi momento visitando il modulo Impostazioni.',
    settingsHybridSelectorHelper: 'In base a come stai attualmente partecipando, l\'interfaccia può cambiare per migliorare la tua esperienza. Gli altri partecipanti non potranno vedere la tua scelta.',
    attendanceType: {
      virtually: 'Virtualmente',
      inPerson: 'Di persona',
    },
    darkMode: 'Modalità oscura',
  },
  ja: {
    interfaceSettings: 'ページ設定',
    attendanceTypeQuestion: 'どうやってイベントに参加しますか？',
    hybridSelectorHelper: '現在の参加方法に基づいて、インターフェイスを変更してエクスペリエンスを向上させることができます。 他の参加者はあなたの選択を見ることができず、設定モジュールにアクセスしていつでも変更することができます。',
    settingsHybridSelectorHelper: '現在の参加方法に基づいて、インターフェイスを変更してエクスペリエンスを向上させることができます。 他の参加者はあなたの選択を見ることができなくなります。',
    attendanceType: {
      virtually: 'オンライン参加',
      inPerson: 'オフライン参加',
    },
    darkMode: 'ダークモード',
  },
  zh: {
    interfaceSettings: '接口设置',
    attendanceTypeQuestion: '你是如何参加活动的？',
    hybridSelectorHelper: '根据您当前的参加方式，界面可以更改以增强您的体验。 其他与会者将无法看到您的选择，您可以随时通过访问“设置”模块进行更改。',
    settingsHybridSelectorHelper: '根据您当前的参加方式，界面可以更改以增强您的体验。 其他与会者将无法看到您的选择。',
    attendanceType: {
      virtually: '几乎',
      inPerson: '亲自',
    },
    darkMode: '暗模式',
  },
  ar: {
    interfaceSettings: 'إعدادات الواجهة',
    attendanceTypeQuestion: 'كيف حالك الانضمام الى الحدث؟',
    hybridSelectorHelper: 'بناءً على كيفية حضورك حاليًا ، يمكن أن تتغير الواجهة لتحسين تجربتك. لن يتمكن الحاضرون الآخرون من رؤية اختيارك ويمكنك تغييره في أي وقت من خلال زيارة وحدة الإعدادات.',
    settingsHybridSelectorHelper: 'بناءً على كيفية حضورك حاليًا ، يمكن أن تتغير الواجهة لتحسين تجربتك. لن يتمكن الحاضرون الآخرون من رؤية اختيارك.',
    attendanceType: {
      virtually: 'عمليا',
      inPerson: 'شخصيا',
    },
    darkMode: 'الوضع المظلم',
  },
  tr: {
    interfaceSettings: 'Arayüz Ayarları',
    attendanceTypeQuestion: 'Etkinliğe nasıl katılıyorsunuz?',
    hybridSelectorHelper: 'Şu anda nasıl katıldığınıza bağlı olarak arayüz, deneyiminizi geliştirmek için değişebilir. Diğer katılımcılar seçiminizi göremez ve istediğiniz zaman Ayarlar modülünü ziyaret ederek değiştirebilirsiniz.',
    settingsHybridSelectorHelper: 'Şu anda nasıl katıldığınıza bağlı olarak arayüz, deneyiminizi geliştirmek için değişebilir. Diğer katılımcılar seçiminizi göremez.',
    attendanceType: {
      virtually: 'Sanal olarak',
      inPerson: 'Şahsen',
    },
    darkMode: 'Karanlık mod',
  },
};
