import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'eventtia-ui-components/lib/Button';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.main,
    maxWidth: 330,
    minWidth: 280,
    padding: theme.spacing(2, 4, 2, 4),
    borderRadius: 10,
    cursor: 'auto',
    boxShadow: theme.customShadows.default,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.darkGrey.light,
    marginBottom: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
  },
  messageInput: {
    fontSize: 12,
  },
}));

const RequestContactInfo = ({
  action, open, anchorEl, closeDialog, isChatOpen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees');
  const [message, setMessage] = useState(t('requestInfo.defaultMessage'));
  const [messageError, setMessageError] = useState({ error: false, errorMessage: '' });

  const messageHandleChange = (event) => {
    setMessage(event.target.value);
    if (event.target.value === '') setMessageError({ ...messageError, error: true, errorMessage: t('info.detailsRequired') });
    if (event.target.value !== '') setMessageError({ ...messageError, error: false, errorMessage: 'null' });
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={closeDialog}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.dialog}>
        <div className={classes.form}>
          <Typography className={classes.title}>
            {t('requestInfo.title')}
          </Typography>
          <Typography className={classes.subtitle} gutterBottom>
            {t('requestInfo.subtitle')}
          </Typography>
          <TextField
            className={classes.messageInput}
            id="request-contact-info-input"
            onChange={(e) => messageHandleChange(e)}
            value={message}
            variant="outlined"
            multiline
            rows={3}
            rowsMax={4}
            inputProps={{ maxLength: '300', required: true }}
            error={messageError.error}
            helperText={messageError.error ? messageError.errorMessage : null}
          />
          <Button
            small
            type="submit"
            variant="primary"
            className={classes.action}
            onClick={() => action(message)}
            disabled={!message || !isChatOpen}
          >
            {t('actions.sendRequest')}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

RequestContactInfo.propTypes = {
  action: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.element,
  ]),
  isChatOpen: PropTypes.bool,
};

RequestContactInfo.defaultProps = {
  anchorEl: undefined,
  isChatOpen: false,
};

export default RequestContactInfo;
