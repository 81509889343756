import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'eventtia-ui-components/lib/Button';
import { goToAttendees } from '../../actions/app';
import noActivitiesImgSrc from '../../assets/noActivities.png';

const useStyles = makeStyles((theme) => ({
  noActivitiesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: theme.spacing(10),
  },
  noActivitiesImg: {
    height: 159,
  },
  noActivitiesMessage: {
    width: 300,
    marginTop: theme.spacing(3),
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    color: theme.palette.darkGrey.light,
  },
  actions: {
    margin: theme.spacing(4.5, 0),
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const ScheduleNoActivities = ({
  activeType,
  canBlockTimeSlots,
  goToAttendees: dispatchGoToAttendees,
  setShowMeetingSpace,
  canScheduleMeetings,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['schedule']);

  return (
    <div className={classes.noActivitiesContainer}>
      <img
        src={noActivitiesImgSrc}
        alt="no-activities"
        className={classes.noActivitiesImg}
      />
      <Typography className={classes.noActivitiesMessage}>
        {activeType === 'all' ? t('info.noActivities')
          : t('info.noSuchActivities')}
      </Typography>
      {activeType === 'meetings' && (
        <div className={classes.actions}>
          {canScheduleMeetings && (
            <Button
              fullWidth
              onClick={dispatchGoToAttendees}
              className={classes.button}
            >
              {t('actions.scheduleMeeting')}
            </Button>
          )}
          {canBlockTimeSlots && (
            <Button
              fullWidth
              onClick={() => setShowMeetingSpace(true)}
              className={classes.button}
              variant="secondary"
            >
              {t('actions.blockMeetingSpace')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

ScheduleNoActivities.propTypes = {
  activeType: PropTypes.string.isRequired,
  canBlockTimeSlots: PropTypes.bool,
  goToAttendees: PropTypes.func.isRequired,
  setShowMeetingSpace: PropTypes.func,
  canScheduleMeetings: PropTypes.bool.isRequired,
};

ScheduleNoActivities.defaultProps = {
  canBlockTimeSlots: false,
  setShowMeetingSpace: undefined,
};

export default connect(null, { goToAttendees })(ScheduleNoActivities);
