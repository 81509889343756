import { identifyParticipants } from './auth';
import { baseMoment, getEventTimeFormat, showInEventTimezone } from './dates';

const printAgenda = (
  meetings, eventUri, participants, meetingStatuses,
  notes, events, appSettings
) => {
  const timeFormat = getEventTimeFormat(events);
  const [event] = Object.values(events);
  const [settings] = Object.values(appSettings);
  const inEventTimezone = showInEventTimezone(settings);
  const { virtualTimezone } = event;

  const notesByMeeting = {};
  Object.values(notes).forEach((note) => {
    notesByMeeting[note.reviewed.id] = note.body;
  });

  let agenda = `
    <!DOCTYPE html>
    <html lang="en" dir="ltr">
      <head>
        <meta charset="utf-8">
        <title>Agenda</title>
        <style>
          @media print {
            @page {
              margin: 0;
            }
            body {
              margin: 1.6cm;
            }
          }
          body {
            font-family: Lato, sans-serif;
          }
          h1 {
            color: #333333;
          }
          h2 {
            margin-top: 0;
            margin-bottom: 0.5rem;
            color: #555555;
          }
          h4 {
            margin-bottom: 0;
            margin-top: 0.5rem;
          }
          .notes {
            margin-top: 0;
            white-space: pre-wrap;
          }
        </style>
      </head>
      <body>
        <h1>Agenda</h1>
  `;
  meetings.sort((a1, a2) => {
    const diff = baseMoment(a1.startDate).diff(a2.startDate);
    if (diff !== 0) return diff;
    return baseMoment(a1.endDate).diff(a2.endDate);
  }).forEach((meeting) => {
    const {
      id,
      startDate,
      endDate,
      status,
      location,
    } = meeting;
    const { otherParticipant } = identifyParticipants(
      eventUri,
      meeting,
      participants
    );
    const start = baseMoment(startDate);
    const end = baseMoment(endDate);
    if (inEventTimezone) {
      // change the timezone without changing the date
      start.tz(virtualTimezone, true);
      end.tz(virtualTimezone, true);
    }

    agenda += `
      <h2>${otherParticipant.profileContactName}</h2>
      <div>${otherParticipant.profileDisplayName}</div>
      <div>${status === meetingStatuses.accepted ? 'Confirmed' : 'Pending'}</div>
      <div>${start.format(`MMM Do, YYYY ${timeFormat}`)} - ${end.format(`${timeFormat} [GMT]Z`)}</div>
      <div>${location || ''}</div>
    `;

    if (notesByMeeting[id]) agenda += `
      <h4>Notes:</h4>
      <p class="notes">${notesByMeeting[id]}</p>
    `;

    agenda += '<hr />';
  });
  agenda += `
      </body>
    </html>
  `;

  const tab = window.open('about:blank', '_blank');
  tab.document.write(agenda);
  tab.document.close(); // to finish loading the page
};

export default printAgenda;
