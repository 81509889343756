export default {
  en: {
    actions: {
      download: 'Download',
      syncLeads: 'Sync leads',
      copyEmail: 'Copy email',
      deleteLead: 'Delete lead',
      addLead: 'Add to My leads',
      scanQr: 'Scan Lead QR code',
      seeLeadDetails: 'See lead details',
      exploreAttendees: 'Explore attendees',
    },
    qr: {
      personalQr: 'Event personal QR',
      description: 'Show this code to the attendees you wantto share your contact info with, so they can scan it and add you to their leads.',
      scanDescription: 'Scan the QR code in attendees badges to capture their contact information.',
      scanAddSuccess: 'You can now find {{leadName}} in your leads list!',
      scanUpdateSuccess: '{{leadName}}\'s contact information has been updated!',
      invalidQr: 'Invalid QR code.',
    },
    info: {
      noLeads: 'There are no leads saved yet. Collect them by requesting their contact through attendees\' profiles or scanning their QR code',
      downloadSuccess: 'You will get an email with a list of your leads shortly.',
      syncSuccess: 'Successfully synced your leads.',
      shareAndAllow: 'By sharing your contact information below, you allow {{participant}} to save your info in their leads list.',
      savedSuccessfully: 'Successfully saved your changes',
    },
  },
  es: {
    actions: {
      download: 'Descargar',
      syncLeads: 'Sincronizar leads',
      copyEmail: 'Copiar correo electrónico',
      deleteLead: 'Eliminar lead',
      addLead: 'Agregar a Mis leads',
      scanQr: 'Escanea el código QR del lead',
      seeLeadDetails: 'Ver detalles del leads',
      exploreAttendees: 'Explora los asistentes',
    },
    qr: {
      personalQr: 'QR personal del evento',
      description: 'Muestre este código a los asistentes con los que desea compartir su información de contacto, para que puedan escanearlo y agregarlo a sus leads.',
      scanDescription: 'Escanee el código QR en los distintivos de los asistentes para capturar su información de contacto.',
      scanAddSuccess: '¡Ahora puedes encontrar {{leadName}} en tu lista de leads!',
      scanUpdateSuccess: 'La información de contacto de {{leadName}} se ha actualizado.',
      invalidQr: 'Código QR no válido.',
    },
    info: {
      noLeads: 'Aún no hay leads guardados. Agréguelos solicitando su contacto a través de los perfiles de los asistentes o escaneando su código QR',
      downloadSuccess: 'En breve recibirás un correo electrónico con una lista de tus leads.',
      syncSuccess: 'Sincronizó correctamente sus leads.',
      shareAndAllow: 'Al compartir tu información de contacto a continuación, permites que {{participant}} guarde tu información en su lista de leads.',
      savedSuccessfully: 'Los cambios se han guardado correctamente',
    },
  },
  fr: {
    actions: {
      download: 'Télécharger',
      syncLeads: 'Fils de synchronisation',
      copyEmail: "Copier l'e-mail",
      deleteLead: 'Supprimer le contact',
      addLead: 'Ajouter à Mes pistes',
      scanQr: 'Scannez le code QR du contact',
      seeLeadDetails: 'Voir les détails du contact',
      exploreAttendees: 'Découvrez les participants',
    },
    qr: {
      personalQr: "QR personnel de l'événement",
      description: "Montrez ce code aux participants avec lesquels vous souhaitez partager vos informations de contact, afin qu'ils puissent le scanner et vous ajouter à leurs pistes.",
      scanDescription: 'Scannez le code QR sur les badges des participants pour saisir leurs coordonnées.',
      scanAddSuccess: 'Vous pouvez maintenant trouver {{leadName}} dans votre liste de prospects !',
      scanUpdateSuccess: 'Les coordonnées de {{leadName}} ont été mises à jour !',
      invalidQr: 'Code QR non valide.',
    },
    info: {
      noLeads: "Il n'y a pas encore de pistes enregistrées. Collectez-les en demandant leur contact via les profils des participants ou en scannant leur code QR",
      downloadSuccess: 'Vous recevrez sous peu un e-mail avec la liste de vos prospects.',
      syncSuccess: 'Vos pistes ont été synchronisées avec succès.',
      shareAndAllow: 'En partageant vos informations de contact ci-dessous, vous autorisez {{participant}} à enregistrer vos informations dans leur liste de prospects.',
      savedSuccessfully: 'Vos modifications ont été enregistrées avec succès',
    },
  },
  ca: {
    actions: {
      download: 'Descarrega',
      syncLeads: 'Sincronitza destacats',
      copyEmail: 'Copia correu-e',
      deleteLead: 'Esborra destacat',
      addLead: 'Afegeix als meus destacats',
      scanQr: 'Escaneja codi QR de destacat',
      seeLeadDetails: 'Detalls de destacat',
      exploreAttendees: 'Explora assistents',
    },
    qr: {
      personalQr: "QR personal d'esdeveniment",
      description:
        'Mostra aquest codi als assistents amb qui vols compartir el teu contacte, perquè puguin escanejar-lo i afegir-lo als seus destacats.',
      scanDescription:
        'Escaneja el codi QR en les acreditacions dels assistents per capturar la seva informació de contacte.',
      scanAddSuccess:
        'Ara pots trobar en/na {{leadName}} a la teva llista de destacats!',
      scanUpdateSuccess:
        "La informació de contacte d'en/na {{leadName}} s'ha actualitzat!",
      invalidQr: 'Codi QR invàlid.',
    },
    info: {
      noLeads:
        "Encara no has desat cap destacat. Afegeix-los demanant el seu contacte mitjançant el perfil de l'assistent o escanejant el seu codi QR",
      downloadSuccess:
        'Rebràs un correu-e amb una llista amb els teus destacats aviat.',
      syncSuccess: 'Sincronització dels teus destacats completada.',
      shareAndAllow:
        'Compartint la teva informació de contacte a sota, permets que en/na {{participant}} la guardi en la seva llista de destacats.',
      savedSuccessfully: "S'han desat els teus canvis correctament",
    },
  },
  pt: {
    actions: {
      download: 'Baixar',
      syncLeads: 'Sincronizar leads',
      copyEmail: 'Copiar e-mail',
      deleteLead: 'Excluir lead',
      addLead: 'Adicionar aos meus leads',
      scanQr: 'Digitalize o código QR do lead',
      seeLeadDetails: 'Veja os detalhes do lead',
      exploreAttendees: 'Explore os participantes',
    },
    qr: {
      personalQr: 'QR pessoal do evento',
      description: 'Mostre esse código aos participantes com os quais você deseja compartilhar suas informações de contato, para que eles possam digitalizá-lo e adicioná-lo aos leads deles.',
      scanDescription: 'Digitalize o código QR nos crachás dos participantes para capturar suas informações de contato.',
      scanAddSuccess: 'Agora você pode encontrar {{leadName}} em sua lista de leads!',
      scanUpdateSuccess: 'As informações de contato do {{leadName}} foram atualizadas!',
      invalidQr: 'Código QR inválido.',
    },
    info: {
      noLeads: 'Ainda não há leads salvos. Colete-os solicitando seu contato por meio dos perfis dos participantes ou digitalizando seu código QR',
      downloadSuccess: 'Você receberá um e-mail com uma lista de seus leads em breve.',
      syncSuccess: 'Sincronizou com sucesso seus leads.',
      shareAndAllow: 'Ao compartilhar suas informações de contato abaixo, você permite que {{participant}} salve suas informações na lista de leads deles.',
      savedSuccessfully: 'Suas alterações foram salvas com sucesso',
    },
  },
  it: {
    actions: {
      download: 'Scarica',
      syncLeads: 'Lead di sincronizzazione',
      copyEmail: 'Copia e-mail',
      deleteLead: 'Elimina lead',
      addLead: 'Aggiungi a I miei lead',
      scanQr: 'Codice QR Scan Lead',
      seeLeadDetails: 'Vedi i dettagli dei lead',
      exploreAttendees: 'Esplora i partecipanti',
    },
    qr: {
      personalQr: "QR personale dell'evento",
      description: 'Mostra questo codice ai partecipanti con cui desideri condividere le tue informazioni di contatto, in modo che possano scansionarlo e aggiungerti ai loro lead.',
      scanDescription: 'Scansiona il codice QR nei badge dei partecipanti per acquisire le informazioni di contatto.',
      scanAddSuccess: 'Ora puoi trovare {{leadName}} nella tua lista di lead!',
      scanUpdateSuccess: 'Le informazioni di contatto di {{leadName}} sono state aggiornate!',
      invalidQr: 'Codice QR non valido.',
    },
    info: {
      noLeads: 'Non ci sono ancora lead salvati. Raccoglili richiedendo il loro contatto tramite i profili dei partecipanti o scansionando il loro codice QR',
      downloadSuccess: "A breve riceverai un'email con un elenco dei tuoi lead.",
      syncSuccess: 'Hai sincronizzato con successo i tuoi lead.',
      shareAndAllow: 'Condividendo le tue informazioni di contatto di seguito, consenti a {{participant}} di salvare le tue informazioni nella loro lista di lead.',
      savedSuccessfully: 'Hai salvato con successo le modifiche',
    },
  },
  de: {
    actions: {
      download: 'Herunterladen',
      syncLeads: 'Leads synchronisieren',
      copyEmail: 'E-Mail kopieren',
      deleteLead: 'Lead löschen',
      addLead: 'Zu meinen Leads hinzufügen',
      scanQr: 'Lead-QR-Code scannen',
      seeLeadDetails: 'Details zum Lead anzeigen',
      exploreAttendees: 'Teilnehmer entdecken',
    },
    qr: {
      personalQr: 'Veranstaltung: persönliche QR',
      description: 'Zeigen Sie diesen Code den Teilnehmern, mit denen Sie Ihre Kontaktinformationen teilen möchten, damit sie ihn scannen und Sie zu ihren Leads hinzufügen können.',
      scanDescription: 'Scannen Sie den QR-Code in den Teilnehmer-Badges, um deren Kontaktinformationen zu erfassen.',
      scanAddSuccess: 'Du kannst jetzt {{leadName}} in deiner Lead-Liste finden!',
      scanUpdateSuccess: 'Die Kontaktinformationen von {{leadName}} s wurden aktualisiert!',
      invalidQr: 'Ungültiger QR-Code',
    },
    info: {
      noLeads: 'Es sind noch keine Leads gespeichert. Sammeln Sie sie, indem Sie ihren Kontakt über die Profile der Teilnehmer anfordern oder ihren QR-Code scannen',
      downloadSuccess: 'Sie erhalten in Kürze eine E-Mail mit einer Liste Ihrer Leads.',
      syncSuccess: 'Deine Leads wurden erfolgreich synchronisiert.',
      shareAndAllow: 'Indem Sie unten Ihre Kontaktinformationen teilen, erlauben Sie {{participant}}, Ihre Informationen in der Lead-Liste zu speichern.',
      savedSuccessfully: 'Ihre Änderungen wurden erfolgreich gespeichert',
    },
  },
  ja: {
    actions: {
      download: 'ダウンロード',
      syncLeads: 'リードの同期',
      copyEmail: 'メールをコピー',
      deleteLead: 'リードを削除',
      addLead: 'マイリードに追加',
      scanQr: 'リードQRコードをスキャン',
      seeLeadDetails: 'リードの詳細を見る',
      exploreAttendees: '参加者の探索',
    },
    qr: {
      personalQr: 'イベントパーソナル QR',
      description: '連絡先情報を共有したい出席者にこのコードを見せて、彼らがスキャンしてあなたをリードに追加できるようにします。',
      scanDescription: '出席者バッジの QR コードをスキャンして、出席者の連絡先情報を記録します。',
      scanAddSuccess: 'リードリストに {{leadName}} が見つかるようになりました！',
      scanUpdateSuccess: '{{leadName}} さんの連絡先情報が更新されました！',
      invalidQr: 'QR コードが無効です。',
    },
    info: {
      noLeads: 'まだ保存されているリードはありません。出席者のプロフィールから連絡先をリクエストするか、QRコードをスキャンして集めましょう',
      downloadSuccess: '間もなくリードのリストが記載されたメールが届きます。',
      syncSuccess: 'リードが正常に同期されました。',
      shareAndAllow: '以下の連絡先情報を共有することで、{{participant}} があなたの情報をリードリストに保存することを許可することになります。',
      savedSuccessfully: '変更内容は正常に保存されました',
    },
  },
  zh: {
    actions: {
      download: '下載',
      syncLeads: '同步线索',
      copyEmail: '复制电子邮件',
      deleteLead: '删除潜在客户',
      addLead: '添加到我的销售线索',
      scanQr: '扫描潜在客户二维码',
      seeLeadDetails: '查看潜在客户详情',
      exploreAttendees: '探索参与者',
    },
    qr: {
      personalQr: '赛事个人二维码',
      description: '向希望与之共享联系信息的与会者显示此代码，以便他们扫描并将您添加到潜在客户中。',
      scanDescription: '扫描与会者徽章中的二维码以获取他们的联系信息。',
      scanAddSuccess: '现在，您可以在销售线索列表中找到 {{leadName}}！',
      scanUpdateSuccess: '{{leadName}} 的联系信息已更新！',
      invalidQr: '二维码无效。',
    },
    info: {
      noLeads: '还没有保存线索。通过与会者的个人资料请求联系人或扫描他们的二维码来收集他们',
      downloadSuccess: '您很快就会收到一封包含潜在客户列表的电子邮件。',
      syncSuccess: '已成功同步您的潜在客户。',
      shareAndAllow: '通过在下面分享您的联系信息，您允许 {{participant}} 将您的信息保存在他们的潜在客户列表中。',
      savedSuccessfully: '已成功保存您的更改',
    },
  },
  tr: {
    actions: {
      download: 'Indir',
      syncLeads: 'Müşteri adayları senkronize',
      copyEmail: 'E-posta kopyalama',
      deleteLead: 'İpucu silme',
      addLead: 'Müşteri adaylarıma ekle',
      scanQr: 'Kurşun QR kodunu tara',
      seeLeadDetails: 'İpucunu inceleyin',
      exploreAttendees: 'Teyit verenleri keşfedin',
    },
    qr: {
      personalQr: 'Olay kişisel QR',
      description: 'Bu kodu, iletişim bilgilerinizi paylaşmak istediğiniz katılımcılara gösterin, böylece bu kodu tarayıp sizi müşteri adaylarına ekleyebilirler.',
      scanDescription: 'İletişim bilgilerini yakalamak için katılımcı rozetlerindeki QR kodunu tarayın.',
      scanAddSuccess: 'Artık müşteri adayları listenizde {{leadName}} bulabilirsiniz!',
      scanUpdateSuccess: '{{leadName}} iletişim bilgileri güncellendi!',
      invalidQr: 'Geçersiz QR kodu.',
    },
    info: {
      noLeads: 'Henüz kaydedilmiş ipucu yok. Katılımcıların profilleri aracılığıyla kişilerini talep ederek veya QR kodlarını tarayarak bunları toplayın',
      downloadSuccess: 'Kısa bir süre içinde müşteri adaylarınızın listesini içeren bir e-posta alacaksınız.',
      syncSuccess: 'Müşteri adaylarınızı başarıyla senkronize etti.',
      shareAndAllow: 'Aşağıdaki iletişim bilgilerinizi paylaşarak, {{participant}}} bilgilerinizi müşteri adayları listesine kaydetmesine izin verirsiniz.',
      savedSuccessfully: 'Değişikliklerinizi başarıyla kaydettik',
    },
  },
  ar: {
    actions: {
      download: 'تنزيل',
      syncLeads: 'العملاء المتوقعين',
      copyEmail: 'نسخ البريد الإلكتروني',
      deleteLead: 'حذف العميل المتوقع',
      addLead: 'إضافة إلى العملاء المتوقعين',
      scanQr: 'مسح رمز الاستجابة السريعة للرصاص',
      seeLeadDetails: 'انظر تفاصيل العملاء المحتملين',
      exploreAttendees: 'اكتشف الحاضرين',
    },
    qr: {
      personalQr: 'حدث شخصي QR',
      description: 'اعرض هذا الرمز للحاضرين الذين تريد مشاركة معلومات الاتصال الخاصة بك معهم، حتى يتمكنوا من مسحه ضوئيًا وإضافتك إلى العملاء المحتملين.',
      scanDescription: 'امسح رمز الاستجابة السريعة في شارات الحضور لالتقاط معلومات الاتصال الخاصة بهم.',
      scanAddSuccess: 'يمكنك الآن العثور على {{leadName}} في قائمة العملاء المحتملين!',
      scanUpdateSuccess: 'تم تحديث معلومات الاتصال الخاصة بـ {{leadName}}!',
      invalidQr: 'رمز الاستجابة السريعة غير صالح.',
    },
    info: {
      noLeads: 'لا توجد خيوط محفوظة حتى الآن. اجمعها عن طريق طلب جهات الاتصال الخاصة بهم من خلال الملفات الشخصية للحضور أو مسح رمز الاستجابة السريعة الخاص بهم',
      downloadSuccess: 'ستتلقى بريدًا إلكترونيًا يحتوي على قائمة بالعملاء المتوقعين قريبًا.',
      syncSuccess: 'تمت مزامنة العملاء المتوقعين بنجاح.',
      shareAndAllow: 'من خلال مشاركة معلومات الاتصال الخاصة بك أدناه، تسمح {{participant}} بحفظ معلوماتك في قائمة العملاء المتوقعين الخاصة بهم.',
      savedSuccessfully: 'حفظ التغييرات بنجاح',
    },
  },
};
