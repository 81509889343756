import getDistinctIds from './getDistinctIds';
import { getModuleByType, getCurrentParticipant, getCurrentAttendee } from './getters';

const getAttendeeTypesOptions = (entities, order) => {
  const {
    appSettings,
    attendeeTypes,
    businessConferenceParticipantTypes: participantTypes,
  } = entities;
  const [settings] = Object.values(appSettings);
  const currentParticipant = getCurrentParticipant(entities);
  const currentAttendee = getCurrentAttendee(entities);
  const participantTypeId = currentParticipant?.participantTypeId;
  const currentParticipantType = participantTypes?.[participantTypeId];
  const canRequestMeetingTo = currentParticipantType?.canRequestMeetingToAttendeeTypes;

  const attendeesModule = getModuleByType(settings.modules, 'Attendees');
  const chatEnabledFor = attendeesModule?.customParams?.chatEnabledParticipantTypes || [];
  const showAllAttendees = (
    attendeesModule?.customParams?.attendeesListEnabled || []
  ).includes(currentAttendee.attendeeType.id);

  if (!attendeeTypes) return [];
  if (showAllAttendees) return Object.values(attendeeTypes);
  const options = [];
  if (!currentParticipant) {
    chatEnabledFor.forEach((attendeeTypeId) => {
      const { id } = attendeeTypes[attendeeTypeId] || {};
      if (id) options.push(attendeeTypes[attendeeTypeId]);
    });
    options.sort((a, b) => (a.order - b.order));
    return options;
  }
  const typesICanInteractWith = getDistinctIds(!chatEnabledFor
    .includes(currentAttendee.attendeeType.id), canRequestMeetingTo, chatEnabledFor);
  (order || []).forEach((attendeeTypeId) => {
    if (typesICanInteractWith
      .some((currentId) => (currentId === attendeeTypeId)))options
      .push(attendeeTypes[attendeeTypeId]);
  });
  return options;
};

export default getAttendeeTypesOptions;
