import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Modal from 'eventtia-ui-components/lib/Modal';
import Dialog from 'eventtia-ui-components/lib/Dialog';
import FileInput from 'eventtia-ui-components/lib/FileInput';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
  },
  inputImageWrapper: {
    maxWidth: '100%',
    padding: theme.spacing(5),
  },
  paper: {
    maxWidth: '100%',
  },
}));

const FileModal = ({
  open, handleClose, title, action, secondaryAction, fileInputProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <Dialog
        title={title}
        className={classes.dialog}
        handleClose={handleClose}
        action={action}
        secondaryAction={secondaryAction}
      >
        <div className={classes.inputImageWrapper}>
          <FileInput
            {...fileInputProps}
          />
        </div>
      </Dialog>
    </Modal>
  );
};

FileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }).isRequired,
  secondaryAction: PropTypes.shape({
    name: PropTypes.string,
    onClick: PropTypes.func,
  }),
  fileInputProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    handleChange: PropTypes.func,
  }).isRequired,
};

FileModal.defaultProps = {
  secondaryAction: undefined,
};

export default FileModal;
