export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const setFilters = (entity, filters, fetchParams) => ({
  type: SET_FILTERS,
  entity,
  filters,
  fetchParams,
});

export const clearFilters = (entity, fetchParams) => ({
  type: CLEAR_FILTERS,
  entity,
  fetchParams,
});
