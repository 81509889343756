import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Rater from '../Rater';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.red.main,
  },
  label: {
    color: theme.palette.darkGrey.light,
  },
}));

const RatingField = ({
  label, error, value: inputValue, handleChange, disabled,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.label}>{label}</Typography>
        {error && (
          <Typography
            variant="body2"
            className={classes.error}
          >
            {error}
          </Typography>
        )}
        <Rater
          value={inputValue}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

RatingField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
};

RatingField.defaultProps = {
  disabled: undefined,
  value: 0,
  handleChange: undefined,
  error: undefined,
};

export default RatingField;
