const INPUT_TYPES = {
  TEXT_FIELD: 1,
  TEXT_AREA: 2,
  CHECKBOX: 3,
  SELECT: 4,
  RADIO: 5,
  IMAGE: 6,
  LABEL: 7,
  CITY: 8,
  FILE: 9,
  DATE_TIME: 10,
  RATING: 11,
  TERMS_CONDITIONS: 20,
};

export default INPUT_TYPES;

export const ATTENDANCE_MODE = {
  PHYSICAL: 'offline',
  VIRTUAL: 'online',
  HYBRID: 'mixed',
};

export const STAGE_TAB_NAMES = {
  TIMELINE: 0,
  CHAT: 1,
};

export const MEETING_REQUEST_STATUSES = {
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
};

export const MEETING_REQUEST_TYPES = {
  RECEIVED: 'received',
  SENT: 'sent',
};
