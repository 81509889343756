import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import PlaceIcon from '@material-ui/icons/Place';
import DevicesIcon from '@material-ui/icons/Devices';
import { identifyParticipants } from '../../helpers/auth';
import useStringTransforms from '../../hooks/useStringTransforms';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import Block from './Block';
import DownloadActivity from '../DownloadActivity';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& *': {
      color: theme.palette.common.white,
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 900,
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  location: {
    fontWeight: 'normal',
  },
  summaryLine: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    verticalAlign: 'bottom',
  },
  divider: {
    fontWeight: 'normal',
    margin: theme.spacing(0, 1),
  },
}));

const Meeting = ({ businessConferenceParticipants, businessConferences, ...meeting }) => {
  const classes = useStyles();
  const { eventUri } = useParams();
  const { toTitleCase } = useStringTransforms();
  const { t } = useTranslation('global', 'meeting', 'schedule');
  const { otherParticipant } = identifyParticipants(
    eventUri,
    meeting,
    businessConferenceParticipants
  );
  const { id: meetingId, businessConferenceId, location: meetingLocation, isVirtual } = meeting;
  const { location: conferenceLocation } = businessConferences[businessConferenceId];

  const { profileContactName, profileDisplayName } = otherParticipant;
  const meetingTitle = t('meeting:meeting.with', { participant: toTitleCase(profileContactName) });

  const mainLine = (
    <>
      {meetingTitle}
      {profileDisplayName && (
        <>
          <span className={classes.divider}>|</span>
          {toTitleCase(profileDisplayName, true)}
        </>
      )}
    </>
  );

  let location;
  let LocationIcon;
  if (isVirtual) {
    location = `${t('meeting:meeting.onlineMeeting')}`;
    LocationIcon = DevicesIcon;
  } else {
    if (meetingLocation && conferenceLocation) location = `${conferenceLocation}, ${meetingLocation}`;
    else if (meetingLocation) location = meetingLocation;
    else if (conferenceLocation) location = conferenceLocation;
    else location = t('schedule:activities.onsite');
    LocationIcon = PlaceIcon;
  }
  const locationInfo = eventUri !== 'recntm2023' ? (
    <span className={classes.location}>
      <LocationIcon color="primary" className={classes.icon} />
      {location}
    </span>
  ) : null;

  const summaryLine = (
    <div className={classes.summaryLine}>
      {mainLine}
      {eventUri !== 'recntm2023' && (
        <>
          <span className={classes.divider}>|</span>
          {locationInfo}
        </>
      )}
    </div>
  );

  const tooltipContent = (
    <>
      <Typography className={classes.text}>
        {mainLine}
      </Typography>
      {eventUri !== 'recntm2023' ? locationInfo : null}
    </>
  );

  return (
    <Block
      {...meeting}
      className={classes.root}
      tooltipTitle={tooltipContent}
      downloadActivity={(
        <DownloadActivity
          id={meetingId}
          name={meetingTitle}
          businessConferenceId={businessConferenceId}
        />
      )}
      mainLine={mainLine}
      secondaryLine={locationInfo}
      summaryLine={summaryLine}
    />
  );
};

Meeting.propTypes = {
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  businessConferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ).isRequired,
};

const mapStateToProps = ({
  entities: {
    businessConferenceParticipants,
    businessConferences,
  },
}) => ({
  businessConferenceParticipants,
  businessConferences,
});

export default connect(mapStateToProps)(Meeting);
