import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import Loader from 'eventtia-ui-components/lib/Loader';
import Button from 'eventtia-ui-components/lib/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChannelList from './ChannelList';
import NewChannelDialog from './NewChannelDialog';
import ChatRoom from '../../components/ChatRoom';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import moduleEnabledForCurrentAttendeeType from '../../helpers/moduleEnabledForCurrentAttendeeType';
import { setActiveChannel } from '../../actions/app';
import { unreadCount, extractOtherAttendeeId } from '../../helpers/chat';
import { getCurrentAttendee, getModuleNames } from '../../helpers/getters';
import SubpageTitle from '../../components/SubpageTitle';
import TabletExitButton from '../../components/TabletExitButton';
import useParams from '../../hooks/useParams'; // VS-PT-HACK
import callApi from '../../actions/callApi';

const MAX_NUMBER_CHANNELS_PER_ATTENDEE = 2;
// const MAX_CUSTOM_CHANNELS = 120;

const useStyles = makeStyles((theme) => ({
  root: {
    width: 785,
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  tabletRoot: {
    width: '100%',
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
  },
  tabletChannelName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.main,
  },
  mobileModuleDescription: {
    textAlign: 'center',
  },
  main: {
    flex: 1,
    marginTop: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
  },
  channelMenuContainer: {
    marginRight: theme.spacing(-1),
    height: '100%',
    width: 280,
    color: theme.palette.darkGrey.light,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  tabletChannelMenuContainer: {
    width: '100%',
    marginRight: 0,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.lightGrey.main,
  },
  channelListContainer: {
    marginBottom: theme.spacing(1),
    maxHeight: 'calc(100% - 64px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  activeChannel: {
    flex: 1,
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tabletActiveChannel: {
    zIndex: 555,
    backgroundColor: theme.palette.background.light,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  chat: {
    height: '100%',
  },
  createButton: {
    fontSize: 12,
    display: 'flex',
    '& > span': {
      justifyContent: 'normal',
    },
  },
  channelList: {
    marginBottom: theme.spacing(1),
    maxHeight: 'calc(100% / 2)',
    height: 'auto',
    minHeight: theme.spacing(12),
    '&:last-of-type': {
      marginBottom: theme.spacing(0),
      flexGrow: 1,
      height: 0,
      maxHeight: 'none',
    },
  },
  channelListSection: {
    maxHeight: 'calc(100% - 50px)',
    overflowY: 'auto',
  },
}));

const EventChat = ({
  broadcasts, openChannels, activeChannel, setActiveChannel: dispatchSetActiveChannel,
  messages, lastReadMessages, appSettings, attendees, entities, callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('chat');
  const { locale, eventUri } = useParams(); // VS-PT-HACK
  const moduleTitle = getModuleNames(appSettings, 'Chat');

  const [settings] = Object.values(appSettings);
  const { modules } = settings;
  const chatModule = modules?.find(({ type }) => type === 'Chat');
  const leadsModule = modules?.find(({ type }) => type === 'AttendeeLeads');

  const currentAttendee = getCurrentAttendee(entities);
  const otherAttendeeId = activeChannel ? extractOtherAttendeeId(
    activeChannel, currentAttendee.id
  ) : undefined;

  const otherAttendee = otherAttendeeId ? attendees[otherAttendeeId] : undefined;

  const { customParams: { allowParticipantsCreateChannels } = {} } = chatModule || {};
  const [openedChannelModal, setOpenedChannelModal] = useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const tablet = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const groupChannels = openChannels.filter(({ type }) => type === 'group');
  const privateChannels = openChannels.filter(({ type }) => type === 'private');

  const unreadMessages = {};
  openChannels.forEach(({ topic }) => {
    unreadMessages[topic] = unreadCount(
      messages, topic, lastReadMessages
    );
  });

  const newChannels = {};
  openChannels.forEach(({ topic, type }) => {
    newChannels[topic] = (
      type === 'group'
      && !messages[topic]?.length
    );
  });

  const showLeadsOptions = moduleEnabledForCurrentAttendeeType(entities, 'AttendeeLeads');

  useEffect(() => {
    if (!activeChannel && !tablet) {
      const firstUsableChannel = openChannels.find(({ type }) => type === 'private' || type === 'group')?.topic;
      if (firstUsableChannel) dispatchSetActiveChannel(firstUsableChannel);
    }
  }, [tablet, activeChannel, dispatchSetActiveChannel, openChannels]);

  useEffect(() => {
    if (otherAttendeeId && showLeadsOptions && !otherAttendee) dispatchCallApi('singleeAttendeeSimple', { eventUri, id: otherAttendeeId });
  }, [activeChannel, otherAttendeeId]);

  // This should be implemented to limit total number of custom channels.
  // However, has to be really optimized.
  // Right now its getting done once each time a channel gets added to redux.
  // const countTotalCreatedCustomChannels = useCallback((accumulator, currentValue) => (
  //   currentValue.createdBy ? accumulator + 1 : accumulator
  // ), []);

  // const totalCreatedCustomChannels = useMemo(() => Object.values(openChannels).reduce(
  //   countTotalCreatedCustomChannels,
  //   0
  // ), [openChannels, countTotalCreatedCustomChannels]);

  const countMyCreatedChannels = useCallback((accumulator, currentValue) => (
    currentAttendee?.id === currentValue
      ? accumulator + 1 : accumulator), [currentAttendee]);

  const channelCreators = useMemo(() => openChannels.map(({
    createdBy,
  }) => createdBy), [openChannels]);

  const myCreatedChannelsCount = useMemo(() => channelCreators.reduce(
    countMyCreatedChannels,
    0
  ), [channelCreators, countMyCreatedChannels]);

  const canCreateMoreCustomChannels = myCreatedChannelsCount < MAX_NUMBER_CHANNELS_PER_ATTENDEE;

  const activeChannelName = useMemo(() => {
    const name = openChannels.find(({ topic }) => activeChannel === topic)?.name;
    return (typeof name === 'object') ? name[locale || 'en'] : name;
  }, [openChannels, activeChannel, locale]);
  const activeChannelType = useMemo(() => (
    openChannels.find(({ topic }) => activeChannel === topic)?.type
  ), [openChannels, activeChannel]);

  const activeChannelDescription = useMemo(() => {
    const description = openChannels.find(({ topic }) => activeChannel === topic)?.description;
    return description;
  }, [openChannels, activeChannel]);

  const otherAttendeeHasLeads = leadsModule.customParams.availableForAttendeeTypes
  ?.includes(otherAttendee?.attendeeType?.id);

  const showShareContactButton = activeChannelType === 'private' && leadsModule?.enabled
    && showLeadsOptions && otherAttendeeHasLeads;

  return (
    <div className={clsx(classes.root, tablet && classes.tabletRoot)}>
      <NewChannelDialog
        open={openedChannelModal}
        onClose={() => setOpenedChannelModal(false)}
      />
      {!activeChannel && (
        <TabletExitButton />
      )}
      <SubpageTitle text={moduleTitle} />
      <Typography
        className={clsx(classes.moduleDescription,
          mobile && classes.mobiletModuleDescription)}
        variant="body1"
        gutterBottom
      >
        {t('description')}
      </Typography>
      <div className={classes.main}>
        <div
          className={clsx(
            classes.channelMenuContainer,
            tablet && classes.tabletChannelMenuContainer
          )}
        >
          <div className={classes.channelListContainer}>
            {groupChannels?.length > 0 && (
              <ChannelList
                className={classes.channelList}
                listClassName={classes.channelListSection}
                title={t('sections.channels')}
                channels={groupChannels}
                activeChannel={activeChannel}
                setActiveChannel={dispatchSetActiveChannel}
                unreadMessages={unreadMessages}
                newChannels={newChannels}
              />
            )}
            {(groupChannels?.length > 0 && privateChannels?.length > 0) && (
              <Divider className={classes.divider} />
            )}
            {privateChannels?.length > 0 && (
              <ChannelList
                className={classes.channelList}
                listClassName={classes.channelListSection}
                title={t('sections.privateChats')}
                channels={privateChannels}
                activeChannel={activeChannel}
                setActiveChannel={dispatchSetActiveChannel}
                unreadMessages={unreadMessages}
                newChannels={newChannels}
                showAvatar
              />
            )}
          </div>
          {allowParticipantsCreateChannels && (
            <div>
              <Divider className={classes.divider} />
              <Button
                variant="tertiary"
                icon={(<AddCircleOutlineIcon />)}
                className={classes.createButton}
                onClick={() => setOpenedChannelModal(true)}
                fullWidth
                disabled={!canCreateMoreCustomChannels}
              >
                {t('actions.createChannel')}
              </Button>
            </div>
          )}
        </div>
        {activeChannel && broadcasts[activeChannel] && (
          <div className={tablet ? classes.tabletActiveChannel : classes.activeChannel}>
            <ChatRoom
              className={classes.chat}
              channelTopic={activeChannel}
              broadcast={broadcasts[activeChannel]}
              title={activeChannelName}
              description={activeChannelDescription}
              backButton
              showShareContactButton={showShareContactButton}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EventChat.propTypes = {
  broadcasts: PropTypes.objectOf(PropTypes.func).isRequired,
  openChannels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  activeChannel: PropTypes.string,
  setActiveChannel: PropTypes.func.isRequired,
  messages: PropTypes.objectOf(PropTypes.arrayOf(CustomPropTypes.message)).isRequired,
  lastReadMessages: PropTypes.objectOf(PropTypes.string).isRequired,
  appSettings: CustomPropTypes.appSettings.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  callApi: PropTypes.func.isRequired,
  attendees: PropTypes.objectOf(CustomPropTypes.attendee).isRequired,
};

EventChat.defaultProps = {
  activeChannel: undefined,
};

const mapStateToProps = ({
  entities,
  entities: { appSettings, attendees },
  app: {
    activeSubpage: {
      activeChannel,
    },
  },
  messages,
  lastReadMessages,
}) => ({
  entities,
  activeChannel,
  messages,
  lastReadMessages,
  appSettings,
  attendees,
});

export default connect(mapStateToProps, { callApi, setActiveChannel })(EventChat);
