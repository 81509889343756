import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1, 3.25, 0),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: theme.customShadows.default,
    border: '1px solid #70707019',
    borderRadius: 10,
  },
  sponsorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 116,
    width: '100%',
    borderRadius: 10,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  sponsorLogo: {
    maxWidth: '80%',
    maxHeight: '60%',
  },
  defaultLogo: {
    width: 40,
    height: 40,
    color: theme.palette.primary.main,
  },
  category: {
    fontSize: 12,
    margin: theme.spacing(1.5, 0, 0, 1),
    textAlign: 'start',
    color: theme.palette.darkGrey.light,
  },
  name: {
    margin: theme.spacing(0, 0, 1, 1),
    color: theme.palette.primary.main,
    textAlign: 'start',
    fontWeight: 'bold',
    width: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nameMargin: {
    margin: theme.spacing(2, 0, 1, 1),
  },
}));

const SponsorCard = ({ sponsor, onBlockClick, category }) => {
  const {
    logo: {
      filename,
      medium,
    },
    name,
  } = sponsor;
  const classes = useStyles();

  return (
    <button
      type="button"
      className={classes.container}
      onClick={() => onBlockClick(sponsor)}
    >
      <div className={classes.sponsorWrapper}>
        {filename ? (
          <img src={medium} alt={name} className={classes.sponsorLogo} />
        ) : (
          <BusinessIcon className={classes.defaultLogo} />
        )}
      </div>
      {category && (
        <Typography className={classes.category} variant="body2">
          {category}
        </Typography>
      )}
      <Typography className={clsx(classes.name, !category && classes.nameMargin)} variant="body2">
        {name}
      </Typography>
    </button>
  );
};

SponsorCard.propTypes = {
  sponsor: PropTypes.shape({
    logo: PropTypes.shape({
      medium: PropTypes.string,
      filename: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  onBlockClick: PropTypes.func.isRequired,
  category: PropTypes.string,
};

SponsorCard.defaultProps = {
  sponsor: {},
  category: '',
};

export default SponsorCard;
