import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

export default (state = {}, action) => {
  if (!action?.response?.meta) return state;
  const newState = cloneDeep(state);

  const mergeCustomizer = (objValue, srcValue, key) => {
    if (action?.replaceMeta?.includes(key)) return srcValue;
    return isArray(objValue) ? [...objValue, ...srcValue] : undefined;
  };
  return mergeWith(newState, action.response.meta, mergeCustomizer);
};
