import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CampariProfileIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m610 637.5c112.5-5 202.5-98.75 202.5-212.5 0-117.5-95-212.5-212.5-212.5s-212.5 95-212.5 212.5c0 113.75 90 206.25 202.5 212.5-185 6.25-327.5 153.75-327.5 350h50c0-175 123.75-300 287.5-300s287.5 125 287.5 300h50c0-196.25-142.5-343.75-327.5-350zm-172.5-211.25c0-90 72.5-162.5 162.5-162.5s162.5 72.5 162.5 162.5-72.5 162.5-162.5 162.5-162.5-73.75-162.5-162.5z" />
    </svg>
  </SvgIcon>
);

export default CampariProfileIcon;
