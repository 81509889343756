import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
// import { useParams } from 'react-router-dom';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useStringTransforms from '../../hooks/useStringTransforms';
import useParams from '../../hooks/useParams'; // VS-PT-HACK

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  title: {
    padding: theme.spacing(1, 2),
    fontSize: 'inherit',
    fontWeight: 'bold',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.12)',
  },
  item: {
    padding: theme.spacing(1, 2),
    '&$active': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  active: {
    '& $text': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  avatarWrapper: {
    minWidth: theme.spacing(5),
  },
  tinyAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.lightGrey.dark,
  },
  text: {
    fontSize: '0.775rem',
    overflow: 'hidden',
    wordBreak: 'break-word',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    color: theme.palette.darkGrey.light,
  },
  badge: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: 10,
    padding: 0,
    height: '2em',
    marginLeft: theme.spacing(1),
  },
}));

const ChannelList = ({
  title, channels, activeChannel, setActiveChannel, showAvatar, unreadMessages, newChannels,
  className, listClassName,
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const { t } = useTranslation('chat');
  const { locale } = useParams(); // VS-PT-HACK

  return (
    <div className={clsx(classes.root, className)}>
      {!!title && (
        <Typography className={classes.title} gutterBottom>
          {title}
        </Typography>
      )}
      <List
        className={listClassName}
        dense
        disablePadding
      >
        {channels.map((channel) => (
          <ListItem
            button
            disableGutters
            key={channel.topic}
            selected={channel.topic === activeChannel}
            onClick={() => setActiveChannel(channel.topic)}
            classes={{ root: classes.item, selected: classes.active }}
          >
            {showAvatar && (
              <ListItemAvatar className={classes.avatarWrapper}>
                <Avatar
                  src={channel.avatar}
                  className={classes.tinyAvatar}
                />
              </ListItemAvatar>
            )}
            <ListItemText
              classes={{ primary: classes.text }}
              primary={toTitleCase(typeof channel.name === 'string' ? channel.name
                : channel.name[locale || 'en'])}
            />
            {(!!unreadMessages[channel.topic] || newChannels[channel.topic]) && (
              <Chip
                clickable
                className={classes.badge}
                color="secondary"
                size="small"
                label={unreadMessages[channel.topic] || t('channels.new')}
              />
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

ChannelList.propTypes = {
  title: PropTypes.string,
  channels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  activeChannel: PropTypes.string,
  setActiveChannel: PropTypes.func.isRequired,
  showAvatar: PropTypes.bool,
  unreadMessages: PropTypes.objectOf(PropTypes.number).isRequired,
  newChannels: PropTypes.objectOf(PropTypes.bool).isRequired,
  className: PropTypes.string,
  listClassName: PropTypes.string,
};

ChannelList.defaultProps = {
  title: undefined,
  activeChannel: undefined,
  showAvatar: false,
  className: '',
  listClassName: '',
};

export default ChannelList;
