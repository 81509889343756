import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'eventtia-ui-components/lib/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.main,
    position: 'absolute',
    top: -theme.spacing(1),
    left: -theme.spacing(2),
    right: -theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 10,
    zIndex: 1,
    cursor: 'auto',
    boxShadow: theme.customShadows.default,
  },
  title: {
    fontSize: theme.typography.body1.fontSize,
  },
  action: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}));

const ConfirmDialog = ({
  title, children, action, secondaryAction, closeDialog, disabled, error,
}) => {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={closeDialog}>
      <Fade in>
        <div className={classes.dialog}>
          {title && (
            <Typography className={classes.title}>
              {title}
            </Typography>
          )}
          {error && (
            <Typography className={classes.error} color="error">
              {error}
            </Typography>
          )}
          {children}
          <div className={classes.buttons}>
            {secondaryAction && (
              <Button
                small
                variant="tertiary"
                className={classes.action}
                onClick={secondaryAction.onClick}
              >
                {secondaryAction.name}
              </Button>
            )}
            <Button
              small
              variant="primary"
              className={classes.action}
              onClick={action.onClick}
              disabled={disabled}
            >
              {action.name}
            </Button>
          </div>
        </div>
      </Fade>
    </ClickAwayListener>
  );
};

ConfirmDialog.propTypes = {
  error: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  secondaryAction: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  closeDialog: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  title: undefined,
  error: undefined,
  disabled: false,
  secondaryAction: undefined,
};

export default ConfirmDialog;
