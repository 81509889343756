/* eslint-disable comma-dangle */
export default {
  en: {
    title: {
      myProfile: 'My Profile',
    },
    updateSuccess: 'Your profile has been saved successfully.',
    overSizeLimit: 'Upload size limit ({{sizeLimit}}MB) exceeded. If you are trying to upload multiple files at once, you can try doing it one by one instead.',
  },
  es: {
    title: {
      myProfile: 'Mi Perfil',
    },
    updateSuccess: 'Tu perfil se ha guardado correctamente.',
    overSizeLimit: 'Se excedió el límite de tamaño de carga ({{sizeLimit}} MB). Si está intentando cargar varios archivos a la vez, puede intentar hacerlo uno por uno.',
  },
  fr: {
    title: {
      myProfile: 'Mon Profil',
    },
    updateSuccess: 'Votre profil a été enregistré avec succès.',
    overSizeLimit: 'La limite de taille de téléchargement ({{sizeLimit}} MB) a été dépassée. Si vous essayez de télécharger plusieurs fichiers à la fois, vous pouvez essayer de le faire un par un à la place.',
  },
  ca: {
    title: {
      myProfile: 'El meu perfil',
    },
    updateSuccess: 'S’ha desat el teu perfil correctament.',
    overSizeLimit: 'Límit de pujada ({{sizeLimit}}MB) excedit. Si intentes pujar múltiples arxius al mateix temps, pots intentar fer-ho un a un.',
  },
  pt: {
    title: {
      myProfile: 'Meu perfil',
    },
    updateSuccess: 'Seu perfil foi salvo com sucesso.',
    overSizeLimit: 'Limite de tamanho de upload ({{sizeLimit}} MB) excedido. Se você estiver tentando fazer upload de vários arquivos de uma vez, tente fazer isso um por um.',
  },
  it: {
    title: {
      myProfile: 'Il mio profilo',
    },
    updateSuccess: 'Il tuo profilo è stato salvato con successo.',
    overSizeLimit: 'È stato superato il limite di dimensione del caricamento ({{sizeLimit}} MB). Se stai cercando di caricare più file contemporaneamente, puoi invece provare a farlo uno per uno.',
  },
  ja: {
    title: {
      myProfile: 'マイプロフィール',
    },
    updateSuccess: 'プロフィールは正常に保存されました。',
    overSizeLimit: 'アップロードサイズの制限（{{sizeLimit}}MB）を超えました。 一度に複数のファイルをアップロードしようとしている場合は、代わりに1つずつアップロードしてみてください。',
  },
  zh: {
    title: {
      myProfile: '我的简介',
    },
    updateSuccess: '您的个人资料已成功保存。',
    overSizeLimit: '已超出上传大小限制 ({{sizeLimit}}MB)。 如果您尝试一次上传多个文件，则可以尝试一个一个地进行。',
  },
  ar: {
    title: {
      myProfile: 'ملفي الشخصي',
    },
    updateSuccess: 'تم حفظ ملف التعريف الخاص بك بنجاح.',
    overSizeLimit: 'تم تجاوز حد حجم التحميل ({{sizeLimit}} ميجابايت). إذا كنت تحاول تحميل ملفات متعددة في وقت واحد، فيمكنك محاولة القيام بذلك واحدًا تلو الآخر بدلاً من ذلك.',
  },
  tr: {
    title: {
      myProfile: 'Profilim'
    },
    updateSuccess: 'Profiliniz başarıyla kaydedildi.',
    overSizeLimit: 'Yükleme boyutu sınırı ({{sizeLimit}} MB) aşıldı. Aynı anda birden fazla dosya yüklemeye çalışıyorsanız, bunun yerine tek tek yapmayı deneyebilirsiniz.'
  },
  de: {
    title: {
      myProfile: 'Mein Profil'
    },
    updateSuccess: 'Ihr Profil wurde erfolgreich gespeichert.',
    overSizeLimit: 'Die Größenbeschränkung für Uploads ({{sizeLimit}} MB) wurde überschritten. Wenn Sie versuchen, mehrere Dateien gleichzeitig hochzuladen, können Sie stattdessen versuchen, dies nacheinander zu tun.'
  },
};
