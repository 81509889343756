import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CampariHomeIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m225 546.94-48.516 48.516c-14.625 14.672-38.344 14.672-52.969 0-14.672-14.625-14.672-38.391 0-53.016l396.94-396.98c43.922-43.922 115.17-43.922 159.1 0l396.94 396.98c14.672 14.625 14.672 38.391 0 53.016-14.625 14.672-38.344 14.672-52.969 0l-397.03-396.98c-14.625-14.625-38.344-14.625-52.969 0l-273.52 273.52v240.52c0 20.719-16.781 37.5-37.5 37.5s-37.5-16.781-37.5-37.5zm675 90.562c0-20.672 16.781-37.5 37.5-37.5s37.5 16.828 37.5 37.5v337.5c0 29.812-11.859 58.453-32.953 79.547s-49.734 32.953-79.547 32.953h-525c-29.812 0-58.453-11.859-79.547-32.953s-32.953-49.734-32.953-79.547v-37.5c0-20.719 16.781-37.453 37.5-37.453s37.5 16.734 37.5 37.453v37.5c0 9.9844 3.9375 19.5 10.969 26.531s16.594 11.016 26.531 11.016h525c9.9375 0 19.5-3.9844 26.531-11.016s10.969-16.547 10.969-26.531zm-375 262.55c-20.672 0-37.5-16.828-37.5-37.547 0-20.672 16.828-37.5 37.5-37.5h150c20.672 0 37.5 16.828 37.5 37.5 0 20.719-16.828 37.547-37.5 37.547z" fillRule="evenodd" />
    </svg>
  </SvgIcon>
);

export default CampariHomeIcon;
