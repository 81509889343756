import { ATTENDANCE_MODE } from './constants';

const ATTENDANCE_MODE_KEY = 'vs-attendanceMode-key';
const store = localStorage;

export const setAttendanceMode = (vsAttendanceMode) => {
  store.setItem(ATTENDANCE_MODE_KEY, vsAttendanceMode);
};

export const getAttendanceMode = () => {
  const attendanceMode = store.getItem(ATTENDANCE_MODE_KEY);
  return attendanceMode;
};

const { PHYSICAL, VIRTUAL, HYBRID } = ATTENDANCE_MODE;

const getMap = (workshopAttendanceMode) => {
  const showMap = (workshopAttendanceMode === PHYSICAL
    || workshopAttendanceMode === HYBRID);
  return showMap;
};

const canJoinWorkshopStream = (
  workshopAttendanceMode, currentAttendanceMode, attendeeTypeAttendanceMode
) => {
  const showJoinButton = (workshopAttendanceMode === VIRTUAL && (
    attendeeTypeAttendanceMode === VIRTUAL || currentAttendanceMode === VIRTUAL
  )) || (
    workshopAttendanceMode === HYBRID && attendeeTypeAttendanceMode !== PHYSICAL
  );

  return showJoinButton;
};

const showWorkshopAccordingToAttendace = (
  workshopAttendanceMode, currentAttendanceMode, attendeeTypeAttendanceMode, sponsorId
) => {
  const showStageWorkshop = sponsorId
  || (workshopAttendanceMode === HYBRID)
  || (workshopAttendanceMode === VIRTUAL && attendeeTypeAttendanceMode === VIRTUAL)
  || (workshopAttendanceMode === VIRTUAL && currentAttendanceMode === VIRTUAL)
  || (workshopAttendanceMode === PHYSICAL && attendeeTypeAttendanceMode === PHYSICAL)
  || (workshopAttendanceMode === PHYSICAL && currentAttendanceMode === PHYSICAL);

  return showStageWorkshop;
};

const canRegisterByAttendanceMode = (
  workshopAttendanceMode, attendeeTypeAttendanceMode
) => {
  const showRegisterButton = (
    (workshopAttendanceMode === HYBRID)
      || (attendeeTypeAttendanceMode === HYBRID)
      || (workshopAttendanceMode === VIRTUAL && attendeeTypeAttendanceMode === VIRTUAL)
      || (workshopAttendanceMode === PHYSICAL && attendeeTypeAttendanceMode === PHYSICAL)
  );

  return showRegisterButton;
};

const showWrongAttendanceModeMessage = (
  workshopAttendanceMode, currentAttendanceMode, attendeeTypeAttendanceMode
) => {
  const showMessage = (workshopAttendanceMode === VIRTUAL && currentAttendanceMode === PHYSICAL)
  || (workshopAttendanceMode === PHYSICAL && currentAttendanceMode === VIRTUAL)
  || (workshopAttendanceMode === VIRTUAL && attendeeTypeAttendanceMode === PHYSICAL)
  || (workshopAttendanceMode === PHYSICAL && attendeeTypeAttendanceMode === VIRTUAL)
  || (workshopAttendanceMode === HYBRID && attendeeTypeAttendanceMode === VIRTUAL)
  || (workshopAttendanceMode === HYBRID && attendeeTypeAttendanceMode === PHYSICAL);

  return showMessage;
};

export default {
  getMap,
  canJoinWorkshopStream,
  canRegisterByAttendanceMode,
  showWrongAttendanceModeMessage,
  showWorkshopAccordingToAttendace,
};
