export default {
  en: {
    typeQuestion: 'Type your question here...',
    askAnonymously: 'Ask anonymously',
    anonymous: 'Anonymous',
    pendingApproval: 'Your question has been sent and is pending approval',
    status: {
      answered: 'Answered',
    },
    filters: {
      all: 'All',
      myQuestions: 'My questions',
      recent: 'Recent',
      mostLiked: 'Most liked',
    },
  },
  es: {
    typeQuestion: 'Escriba aquí su pregunta...',
    askAnonymously: 'Preguntar anónimamente',
    anonymous: 'Anónimo',
    pendingApproval: 'Su pregunta se ha enviado y está pendiente de aprobación',
    status: {
      answered: 'Respondido',
    },
    filters: {
      all: 'Todas',
      myQuestions: 'Mis preguntas',
      recent: 'Reciente',
      mostLiked: 'Más gustado',
    },
  },
  fr: {
    typeQuestion: 'Tapez votre question ici...',
    askAnonymously: 'Demandez de manière anonyme',
    anonymous: 'Anonyme',
    pendingApproval: "Votre question a été envoyée et est en attente d'approbation",
    status: {
      answered: 'Répondu',
    },
    filters: {
      all: 'Tout',
      myQuestions: 'Mes questions',
      recent: 'Récent',
      mostLiked: 'Les plus aimés',
    },
  },
  ca: {
    typeQuestion: 'Escriu la teva pregunta aquí...',
    askAnonymously: 'Demana anònimament',
    anonymous: 'Anònim',
    pendingApproval: 'S’ha enviat la teva pregunta i està pendent d’aprovació',
    status: { answered: 'Contestat' },
    filters: {
      all: 'Tot',
      myQuestions: 'Les meves preguntes',
      recent: 'Recent',
      mostLiked: 'Amb més m’agrada',
    },
  },
  pt: {
    typeQuestion: 'Digite sua pergunta aqui...',
    askAnonymously: 'Pergunte anonimamente',
    anonymous: 'Anônimo',
    pendingApproval: 'Sua pergunta foi enviada e está pendente de aprovação',
    status: {
      answered: 'Respondido',
    },
    filters: {
      all: 'Todos',
      myQuestions: 'Minhas perguntas',
      recent: 'Recentes',
      mostLiked: 'Mais curtiu',
    },
  },
  it: {
    typeQuestion: 'Digiti qui la sua domanda...',
    askAnonymously: 'Chieda in forma anonima',
    anonymous: 'Anonimo',
    pendingApproval: 'La sua domanda è stata inviata ed è in attesa di approvazione',
    status: {
      answered: 'Risposto',
    },
    filters: {
      all: 'Tutti',
      myQuestions: 'Le mie domande',
      recent: 'Recenti',
      mostLiked: 'I più apprezzati',
    },
  },
  de: {
    typeQuestion: 'Geben Sie Ihre Frage hier ein...',
    askAnonymously: 'Fragen Sie anonym',
    anonymous: 'Anonym',
    pendingApproval: 'Ihre Frage wurde gesendet und steht noch aus.',
    status: {
      answered: 'Beantwortet',
    },
    filters: {
      all: 'Alles',
      myQuestions: 'Meine Fragen',
      recent: 'Kürzlich',
      mostLiked: 'Am liebsten',
    },
  },
  ja: {
    typeQuestion: 'ここに質問を入力してください...',
    askAnonymously: '匿名で聞いてください',
    anonymous: '匿名です',
    pendingApproval: 'あなたの質問は送信されましたが、承認待ちです',
    status: {
      answered: '答えました',
    },
    filters: {
      all: '全て',
      myQuestions: '私の質問は',
      recent: '最近',
      mostLiked: '最も気に入ったのは',
    },
  },
  zh: {
    typeQuestion: '在这里输入你的问题...',
    askAnonymously: '匿名提问',
    anonymous: '匿名',
    pendingApproval: '您的问题已发送，正在等待批准',
    status: {
      answered: '已回答',
    },
    filters: {
      all: '全部',
      myQuestions: '我的问题',
      recent: '最近',
      mostLiked: '最喜欢的',
    },
  },
  tr: {
    typeQuestion: 'Sorunuzu buraya yazın...',
    askAnonymously: 'İsimsiz olarak sor',
    anonymous: 'Anonim',
    pendingApproval: 'Sorunuz gönderildi ve onay bekliyor',
    status: {
      answered: 'Cevaplandı',
    },
    filters: {
      all: 'Tümü',
      myQuestions: 'Sorularım',
      recent: 'Son',
      mostLiked: 'En çok sevilen',
    },
  },
  ar: {
    typeQuestion: 'اكتب سؤالك هنا...',
    askAnonymously: 'اسأل بشكل مجهول',
    anonymous: 'مجهول',
    pendingApproval: 'تم إرسال سؤالك وهو في انتظار الموافقة',
    status: {
      answered: 'تمت الإجابة',
    },
    filters: {
      all: 'جميع',
      myQuestions: 'أسئلتي',
      recent: 'الأخيرة',
      mostLiked: 'الأكثر إعجابًا',
    },
  },
};
