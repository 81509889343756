import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import Button from 'eventtia-ui-components/lib/Button';
import Typography from '@material-ui/core/Typography';
import { baseMoment } from '../../helpers/dates';
import { selectEventTimeFormat } from '../../helpers/selectors';

export const SLOT_HEIGHT = 56;
export const BLOCK_SPACING_Y = 6;
export const BLOCK_SPACING_X = 16;
export const BLOCK_WIDTH = 80; // percent
export const DIVIDER_HEIGHT = 1;

export const minutesToPixels = (
  minutes, stepSize
) => Math.round((minutes / stepSize) * SLOT_HEIGHT);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    paddingLeft: 0,
    width: `${BLOCK_WIDTH}%`,
  },
  fullWidth: {
    width: `calc(100% - ${2 * BLOCK_SPACING_X}px)`,
  },
  block: {
    overflow: 'hidden',
    fontSize: 14,
    border: 'none',
    textAlign: 'left',
    height: '100%',
    width: '100%',
    minWidth: 0,
    display: 'flex',
    position: 'relative',
    boxShadow: theme.customShadows.small,
    padding: 0,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.main,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.light,
    },
    transition: theme.transitions.create([
      'background-color',
      'border-color',
      'opacity',
    ], {
      duration: theme.transitions.duration.standard,
    }),
  },
  smallBlock: {
    alignItems: 'center',
    '& $secondaryAction': {
      marginTop: 0,
    },
  },
  blockDetails: {
    flex: 1,
    overflowX: 'hidden',
    padding: theme.spacing(1.5, 2.5),
  },
  extendedBlockDetails: {
    paddingRight: 0,
  },
  mainLine: {
    color: 'inherit',
    fontWeight: 900,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondaryLine: {
    color: theme.palette.darkGrey.light,
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(1.5),
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  tertiaryLine: {
    color: theme.palette.darkGrey.light,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  summaryLine: {
    color: 'inherit',
    fontWeight: 900,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondaryAction: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  requestsButton: {
    alignSelf: 'center',
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(1.5),
  },
  requestsButtonBigSlot: {
    alignSelf: 'baseline',
    marginTop: theme.spacing(1),
  },
  tooltip: {
    maxWidth: 'none',
  },
  tooltipContent: {
    '& *': {
      color: theme.palette.common.white,
    },
    '& span': {
      whiteSpace: 'pre-wrap',
    },
  },
  time: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

const THREE_LINES_THRESHOLD = 96;
const TWO_LINES_THRESHOLD = 76;

const Block = ({
  offset, stepSize, fullWidthBlocks,
  startDate, endDate, scheduleStart,
  onClick, disabled,
  className,
  mainLine, secondaryLine, tertiaryLine, summaryLine,
  tooltipTitle, alternativeText,
  downloadActivity, seeRequests,
}) => {
  const classes = useStyles();
  const timeFormat = useSelector(selectEventTimeFormat());
  const { t } = useTranslation('meeting');

  const minutesFromStart = baseMoment(startDate).diff(scheduleStart, 'minutes');
  const durationInMinutes = baseMoment(endDate).diff(startDate, 'minutes');

  const offsetX = `calc(${(BLOCK_WIDTH * offset)}% + ${(offset + 1) * BLOCK_SPACING_X}px)`;
  const offsetY = minutesToPixels(minutesFromStart, stepSize)
    + BLOCK_SPACING_Y + DIVIDER_HEIGHT;

  const height = minutesToPixels(durationInMinutes, stepSize)
  - (2 * BLOCK_SPACING_Y) - DIVIDER_HEIGHT;

  let content;
  if (height >= THREE_LINES_THRESHOLD) content = (
    <>
      {tertiaryLine && (
        <Typography component="div" className={classes.tertiaryLine}>
          {tertiaryLine}
        </Typography>
      )}
      <Typography component="div" className={classes.mainLine}>
        {mainLine}
      </Typography>
      {secondaryLine && (
        <Typography component="div" className={classes.secondaryLine}>
          {secondaryLine}
        </Typography>
      )}
    </>
  );
  else if (height >= TWO_LINES_THRESHOLD) content = (
    <>
      <Typography component="div" className={classes.mainLine}>
        {mainLine}
      </Typography>
      {secondaryLine && (
        <Typography component="div" className={classes.secondaryLine}>
          {secondaryLine}
        </Typography>
      )}
    </>
  );
  else content = (
    <Typography component="div" className={classes.summaryLine}>
      {summaryLine || mainLine}
    </Typography>
  );

  return (
    <Tooltip
      className={classes.tooltip}
      title={(
        <div className={classes.tooltipContent}>
          {tooltipTitle}
          {alternativeText}
          <Typography className={classes.time}>
            {`${baseMoment(startDate).format(timeFormat)} - ${baseMoment(endDate).format(timeFormat)}`}
          </Typography>
        </div>
      )}
    >
      <div
        className={clsx(
          classes.wrapper,
          fullWidthBlocks && classes.fullWidth
        )}
        style={{
          height,
          left: offsetX,
          top: offsetY,
        }}
      >
        <button
          className={clsx(
            classes.block,
            (height < TWO_LINES_THRESHOLD) && classes.smallBlock,
            className
          )}
          // disabled={disabled} // removed this to prevent tooltip bug
          onClick={!disabled ? onClick : undefined}
          type="button"
        >
          <div
            className={clsx(
              classes.blockDetails,
              downloadActivity && classes.extendedBlockDetails
            )}
          >
            {content}
          </div>
          {seeRequests && (
            <Button
              small
              className={clsx(
                classes.requestsButton,
                height >= TWO_LINES_THRESHOLD && classes.requestsButtonBigSlot
              )}
              variant="secondary"
              onClick={onClick}
            >
                {t('requests.seeRequests')}
            </Button>
          )}
          {downloadActivity && (
            <div className={classes.secondaryAction}>
              {downloadActivity}
            </div>
          )}
        </button>
      </div>
    </Tooltip>
  );
};

Block.propTypes = {
  mainLine: PropTypes.node.isRequired,
  secondaryLine: PropTypes.node,
  tertiaryLine: PropTypes.node,
  summaryLine: PropTypes.node,
  downloadActivity: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  scheduleStart: PropTypes.string.isRequired,
  stepSize: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  alternativeText: PropTypes.string,
  disabled: PropTypes.bool,
  tooltipTitle: PropTypes.node,
  fullWidthBlocks: PropTypes.bool,
  seeRequests: PropTypes.bool,
};

Block.defaultProps = {
  secondaryLine: undefined,
  tertiaryLine: undefined,
  summaryLine: undefined,
  tooltipTitle: undefined,
  alternativeText: undefined,
  disabled: false,
  downloadActivity: undefined,
  fullWidthBlocks: true,
  seeRequests: false,
  className: undefined,
};

export default Block;
