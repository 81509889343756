const fallback = (text, onSuccess) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful && onSuccess) onSuccess();
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
};

const copyToClipboard = (text, onSuccess) => {
  if (!navigator.clipboard) fallback(text);
  else navigator.clipboard.writeText(text).then(() => {
    if (onSuccess) onSuccess();
  }, (err) => {
    console.error(err);
  });
};

export default copyToClipboard;
