import { mergeLocales } from 'eventtia-ui-components/lib/locales';
import global from './global';
import stage from './stage';
import sidebar from './sidebar';
import schedule from './schedule';
import attendees from './attendees';
import myProfile from './myProfile';
import meeting from './meeting';
import sponsors from './sponsors';
import chat from './chat';
import callSettings from './callSettings';
import surveys from './surveys';
import speakers from './speakers';
import events from './events';
import socialWall from './socialWall';
import settings from './settings';
import attendeeLeads from './attendeeLeads';
import qaSessions from './qaSessions';
import quizzes from './quizzes';

const USED_LANGUAGES = ['en', 'es', 'fr', 'de', 'ca', 'pt', 'it', 'ar', 'tr', 'ja'];
export { USED_LANGUAGES as usedLanguages };

export default mergeLocales({
  global,
  stage,
  schedule,
  sidebar,
  attendees,
  myProfile,
  meeting,
  sponsors,
  chat,
  callSettings,
  surveys,
  speakers,
  events,
  socialWall,
  settings,
  attendeeLeads,
  qaSessions,
  quizzes,
}, USED_LANGUAGES);
