import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from 'eventtia-ui-components/lib/Button';
import Card from 'eventtia-ui-components/lib/Card';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileModal from '../FileModal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: theme.palette.darkGrey.light,
    marginBottom: theme.spacing(0.5),
  },
  error: {
    color: theme.palette.red.main,
    marginBottom: theme.spacing(0.5),
  },
  fileNameAnchor: {
    textDecoration: 'none',
    overflow: 'hidden',
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 150,
  },
  fileName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editIcon: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  button: {
    maxWidth: '100%',
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const FileField = ({
  id, name, label, error, value, image, setFormValue, disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const [fileModalOpened, setFileModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const theme = useTheme();
  const fixButtonStyles = {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '0 16px',
    borderRadius: '12px',
    textTransform: 'none',
    fontWeight: 'bold',
  };
  const title = t(`actions.add${image ? 'Image' : 'File'}`);
  const filename = useMemo(() => value?.name, [value]);
  const modalClose = () => {
    setFileModalOpened(false);
    setSelectedFile([]);
    if (!value) setFormValue(name, []);
  };

  useEffect(() => {
    setSelectedFile([]);
  }, [filename]);

  return (
    <div>
      <FileModal
        title={title}
        open={fileModalOpened}
        handleClose={modalClose}
        action={{
          name: t('actions.accept'),
          onClick: () => setFileModalOpened(false),
          disabled: !selectedFile.length,
        }}
        secondaryAction={{
          name: t('actions.cancel'),
          onClick: modalClose,
        }}
        fileInputProps={{
          id,
          name,
          error,
          label: error || t('forms.photo'),
          type: image ? 'image' : 'file',
          accept: image ? 'image/*' : undefined,
          value: selectedFile,
          handleChange: (val) => {
            setSelectedFile(val);
            setFormValue(name, val);
          },
        }}
      />
      <Typography className={error ? classes.error : classes.label} variant="body1">
        {label}
      </Typography>
      {error && (
        <Typography className={classes.error} variant="body1">{error}</Typography>
      )}
      {value?.name ? (
        <Card className={classes.root}>
          <AttachFileIcon />
          <a
            className={classes.fileNameAnchor}
            href={value.originalUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography className={classes.fileName} variant="subtitle2">{value.name}</Typography>
          </a>
          <IconButton
            aria-label="edit"
            onClick={() => setFileModalOpened(true)}
            color="primary"
            size="small"
            className={classes.editIcon}
            disabled={disabled}
          >
            <CreateIcon />
          </IconButton>
        </Card>
      ) : (
        <Button
          id="add-file"
          small
          variant="secondary"
          icon={<AddIcon />}
          onClick={() => setFileModalOpened(true)}
          className={classes.button}
          style={fixButtonStyles}
        >
          {image ? t('actions.addImage') : t('actions.addFile')}
        </Button>
      )}
    </div>
  );
};

FileField.propTypes = {
  setFormValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    originalUrl: PropTypes.string,
  }),
  image: PropTypes.bool,
  disabled: PropTypes.bool,
};

FileField.defaultProps = {
  error: undefined,
  value: {},
  image: false,
  disabled: false,
};

export default FileField;
