import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  list: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    display: 'flex',
  },
}));

const ScrollableItems = ({ children, className }) => {
  const classes = useStyles();
  const listRef = useRef(null);

  const scroll = () => {
    if (!listRef.current) return;
    const animateScroll = (move) => {
      if (!listRef.current) return;
      const start = listRef.current.scrollLeft;
      const movement = start + 1;
      listRef.current.scrollLeft = movement;
      if (move !== movement) setTimeout(animateScroll, 20, movement);
      else setTimeout(() => {
        if (!listRef.current) return;
        listRef.current.scrollLeft = 0;
        animateScroll();
      }, 2000);
    };
    animateScroll();
  };

  useEffect(() => {
    if (!listRef.current) return;
    setTimeout(scroll, 2000);
  }, []);

  return (
    <div className={className}>
      <div
        className={classes.list}
        ref={listRef}
      >
        {children}
      </div>
    </div>
  );
};

ScrollableItems.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ScrollableItems.defaultProps = {
  className: undefined,
};

export default ScrollableItems;
