import { getCurrentAttendee } from './getters';

const moduleEnabledForCurrentAttendeeType = (entities, moduleType) => {
  const { appSettings } = entities;
  const [settings] = Object.values(appSettings);
  const { modules } = settings;

  const currentAttendee = getCurrentAttendee(entities);
  const currentModule = Object.values(modules).find((mod) => mod.type === moduleType);

  const enabledForAttendeeType = !currentModule.customParams.availableForAttendeeTypes
    || currentModule.customParams.availableForAttendeeTypes
    ?.includes(currentAttendee.attendeeType.id);

  return currentModule.enabled && enabledForAttendeeType;
};

export default moduleEnabledForCurrentAttendeeType;
