import React, { useMemo, useState, useRef } from 'react';
import { useSelector, connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from 'eventtia-ui-components/lib/Card';
import Message from 'eventtia-ui-components/lib/Message';
import MyProfileForm from '../../components/MyProfileForm';
import callApi, { fetchConfig } from '../../actions/callApi';
import { getModuleNames } from '../../helpers/getters';
import { getEventtiaDateFormat } from '../../helpers/dates';
import INPUT_TYPES from '../../helpers/constants';
import { defaultAttendeeFieldsValues } from '../../helpers/customFields';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { SIZE_LIMIT_MB } from '../../helpers/files';
import { selectCurrentAttendee } from '../../helpers/selectors';
import SubpageTitle from '../../components/SubpageTitle';
import TabletExitButton from '../../components/TabletExitButton';

const {
  IMAGE,
  FILE,
  CITY,
  DATE_TIME,
  SELECT,
  CHECKBOX,
} = INPUT_TYPES;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  header: {
    marginBottom: theme.spacing(5),
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
  },
  card: {
    maxWidth: 785,
  },
  message: {
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(2),
    maxWidth: 785,
  },
}));

const MyProfile = ({
  callApi: dispatchCallApi, attendeeTypeCustomFields, appSettings,
}) => {
  const { eventUri } = useParams();
  const classes = useStyles();
  const { t } = useTranslation(['myProfile', 'global']);
  const moduleTitle = getModuleNames(appSettings, 'AttendeeProfile');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentAttendee = useSelector(selectCurrentAttendee);

  const containerRef = useRef(null);

  const [customErrors, setCustomErrors] = useState([]);
  const [updating, setUpdating] = useState(false);
  const defaultValues = useMemo(() => (
    defaultAttendeeFieldsValues(currentAttendee.fields, attendeeTypeCustomFields)
  ), []);

  const [message, setMessage] = useState();

  const getRequiredFieldErrorTemplate = (stringKey) => ({
    type: 'manual',
    name: `attendee[customFields].${stringKey}`,
    message: t('global:formError.requiredField'),
  });

  const countryFieldHasNewValue = (fieldValue) => (
    fieldValue && typeof fieldValue === 'string'
  );

  const fileFieldHasNewValue = (fieldValue) => (
    Array.isArray(fieldValue) && fieldValue[0] instanceof File
  );

  const onUpdateProfile = (values) => {
    const data = new FormData();
    const {
      attendee: {
        city_id: cityId,
        first_name: firstName,
        last_name: lastName,
        job_title: jobTitle,
        company,
        telephone,
        email,
        headshot,
        customFields,
      },
    } = values;

    data.append('attendee[first_name]', firstName);
    data.append('attendee[last_name]', lastName);
    data.append('attendee[email]', email);
    data.append('attendee[company]', company);
    data.append('attendee[job_title]', jobTitle);
    data.append('attendee[telephone]', telephone);

    if (countryFieldHasNewValue(cityId)) data.append('attendee[city_id]', cityId);
    if (fileFieldHasNewValue(headshot)) data.append('attendee[headshot]',
      headshot[0], headshot[0].name);

    const attendeeFieldValues = customFields || {};
    const fieldsErrors = [];

    Object.keys(attendeeFieldValues).forEach((stringKey) => {
      const key = stringKey.substring(1);
      const field = attendeeTypeCustomFields[key];
      const fieldValue = attendeeFieldValues[stringKey];
      const isEmpty = !fieldValue || (Array.isArray(fieldValue) && !fieldValue.length);
      const formDataKey = `attendee[custom_fields][${key}]`;
      const { inputType, includeTime } = field;

      switch (inputType) {
        case IMAGE:
        case FILE:
          if (fileFieldHasNewValue(fieldValue)) data.append(formDataKey, fieldValue[0],
            fieldValue[0].name); else if (field.required && isEmpty
              && !(fieldValue?.originalUrl)) fieldsErrors
            .push(getRequiredFieldErrorTemplate(stringKey));
          break;
        case CITY:
          if (fieldValue
            && typeof fieldValue === 'string') data.append(formDataKey, fieldValue);
          else if (fieldValue?.cityId) data.append(formDataKey, fieldValue.cityId);
          else if (field.required) fieldsErrors
            .push(getRequiredFieldErrorTemplate(stringKey));
          break;
        case DATE_TIME:
          if (field.required && isEmpty) fieldsErrors
            .push(getRequiredFieldErrorTemplate(stringKey));
          else if (!isEmpty) data.append(formDataKey, getEventtiaDateFormat(fieldValue,
            includeTime));
          break;
        default:
          if (field.required && isEmpty) fieldsErrors
            .push(getRequiredFieldErrorTemplate(stringKey));
          else if (isEmpty && [SELECT, CHECKBOX].includes(inputType)) data.append(formDataKey,
            '');
          else if (!isEmpty) data.append(formDataKey, fieldValue);
      }
    });
    setCustomErrors(fieldsErrors);

    let totalSize = 0;
    [...data.values()].forEach((item) => {
      if (typeof item === 'string') totalSize += item.length;
      else totalSize += item.size;
    });
    const overSizeLimit = totalSize > (SIZE_LIMIT_MB * 1024 * 1024);

    if (overSizeLimit) setMessage({
      type: 'error',
      text: t('overSizeLimit', { sizeLimit: SIZE_LIMIT_MB }),
    });
    else if (!fieldsErrors.length && !updating) {
      setUpdating(true);
      dispatchCallApi('updateCurrentAttendee', { eventUri, id: currentAttendee.id, data })
        .then(({ type, error: errors }) => {
          setUpdating(false);
          const [, updateSuccess, updateFailure] = fetchConfig.updateCurrentAttendee.types;
          if (type === updateSuccess) setMessage({
            type: 'success',
            text: t('updateSuccess'),
          });
          if (type === updateFailure && Array.isArray(errors)) setMessage({
            type: 'error',
            text: errors.join(', '),
          });
        });
    }

    if (containerRef.current) containerRef.current.parentElement.scrollTo(0, 0);
  };

  return (
    <div className={classes.root} ref={containerRef}>
      <TabletExitButton />
      <div className={!mobile ? classes.header : undefined}>
        <SubpageTitle text={moduleTitle} />
      </div>
      {message?.text && (
        <Message
          className={classes.message}
          type={message?.type}
          onClose={() => setMessage()}
          message={message?.text}
        />
      )}
      <Card className={classes.card}>
        <MyProfileForm
          disabled={updating}
          customErrors={customErrors}
          defaultValues={defaultValues}
          onSubmit={onUpdateProfile}
        />
      </Card>
    </div>
  );
};

MyProfile.propTypes = {
  callApi: PropTypes.func.isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  appSettings: CustomPropTypes.appSettings.isRequired,
};

MyProfile.defaultProps = {
  attendeeTypeCustomFields: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    attendeeTypeCustomFields,
    appSettings,
  },
}) => ({
  entities,
  attendeeTypeCustomFields,
  appSettings,
});

export default connect(mapStateToProps, { callApi })(MyProfile);
