export const isMediaDevicesSupported = () => {
  const mediaDevicesSupported = typeof navigator !== 'undefined' && !!navigator.mediaDevices;

  if (!mediaDevicesSupported) console.warn(
    '[ReactQrReader]: MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"'
  );

  return isMediaDevicesSupported;
};

export const isValidType = (value, name, type) => {
  // eslint-disable-next-line valid-typeof
  const isValid = typeof value === type;

  if (!isValid) console.warn(
    `[ReactQrReader]: Expected "${name}" to be a of type "${type}".`
  );

  return isValid;
};
