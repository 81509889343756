import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import FloatingDialog from '../FloatingDialog';
import AttendeeLeadCardHeader from '../AttendeeLeadCard/AttendeeLeadCardHeader';
import AttendeeLeadCardContactInfo from '../AttendeeLeadCard/AttendeeLeadCardContactInfo';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { getCurrentAttendee } from '../../helpers/getters';
import { buildQrContent, parseQrContent } from '../../helpers/attendeeLeads';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 2000,
    maxWidth: 350,
    textAlign: 'center',
    '& > div': {
      padding: theme.spacing(3, 4),
    },
  },
  leadCard: {
    margin: theme.spacing(2, 0),
    borderRadius: 10,
    border: `1px solid ${theme.palette.lightGrey.main}`,
  },
}));

const ShareContactDialog = ({
  send, anchorEl, closeDialog, otherAttendeeName, entities,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');

  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });
  const qrInfo = buildQrContent(
    currentAttendee.uuid,
    currentAttendee.firstName,
    currentAttendee.lastName,
    currentAttendee.email,
    currentAttendee.company,
    currentAttendee.jobTitle,
    currentAttendee.telephone
  );
  const contactInfo = parseQrContent(qrInfo);
  const shareContactInfo = () => {
    send(`::lead::${qrInfo}`);
    closeDialog();
  };

  return (
    <Popper
      className={classes.popper}
      id={anchorEl ? 'share-info-popper' : undefined}
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement="top-start"
    >
      <FloatingDialog
        closeDialog={closeDialog}
        title={t('attendeeLeads:info.shareAndAllow', { participant: otherAttendeeName })}
        action={{
          name: t('actions.share'),
          onClick: shareContactInfo,
        }}
        secondaryAction={{
          name: t('actions.cancel'),
          onClick: closeDialog,
        }}
      >
        <div
          className={classes.leadCard}
        >
          <AttendeeLeadCardHeader
            fullName={contactInfo.fullName}
            company={contactInfo.company}
          />
          <AttendeeLeadCardContactInfo
            email={contactInfo.email}
            phone={contactInfo.phone}
          />
        </div>
      </FloatingDialog>
    </Popper>
  );
};

ShareContactDialog.propTypes = {
  send: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  closeDialog: PropTypes.func.isRequired,
  otherAttendeeName: PropTypes.string.isRequired,
  entities: CustomPropTypes.entities.isRequired,
};

ShareContactDialog.defaultProps = {
  anchorEl: undefined,
};

const mapStateToProps = ({
  entities,
}) => ({
  entities,
});

export default connect(mapStateToProps)(ShareContactDialog);
