export default {
  en: {
    deleteConfirmation: 'Delete confirmation',
    deleteConfirmationText: 'Are you sure you want to delete this?',
    delete: 'Delete',
    cancel: 'Cancel',
    eventNewsfeed: 'Event newsfeed',
    myPosts: 'My posts',
    comment: 'Comment',
    viewAllComments: 'View all {{count}} comments',
    commentPlaceholder: 'Write a comment here…',
    newPostPlaceholder: 'Share your thoughts here…',
    fileSizeError: 'The file size must be less than {{sizeLimit}} MB.',
    addImage: 'Add image',
    createPost: 'Post',
  },
  es: {
    deleteConfirmation: 'Confirmar eliminar',
    deleteConfirmationText: '¿Estás seguro que deseas eliminar esto?',
    delete: 'Eliminar',
    cancel: 'Cancelar',
    eventNewsfeed: 'Mural del evento',
    myPosts: 'Mis publicaciones',
    comment: 'Comentar',
    viewAllComments: 'Ver los {{count}} comentarios',
    commentPlaceholder: 'Escribe un comentario aquí…',
    newPostPlaceholder: 'Comparte tus pensamientos aquí…',
    fileSizeError: 'El tamaño del archivo debe ser inferior a {{sizeLimit}} MB.',
    addImage: 'Añadir imagen',
    createPost: 'Publicar',
  },
  fr: {
    deleteConfirmation: 'Confirmation de suppression',
    deleteConfirmationText: 'Êtes-vous sûr de vouloir supprimer cela ?',
    delete: 'Supprimer',
    cancel: 'Annuler',
    eventNewsfeed: 'Mur de l\'événement',
    myPosts: 'Mes publications',
    comment: 'Commenter',
    viewAllComments: 'Voir tous {{count}} les commentaires',
    commentPlaceholder: 'Rédiger un commentaire ici…',
    newPostPlaceholder: 'Partagez vos pensées ici…',
    fileSizeError: 'La taille du fichier doit être inférieure à {{sizeLimit}} MB.',
    addImage: 'Ajouter une image',
    createPost: 'Poster',
  },
  ca: {
    deleteConfirmation: 'Confirmació d’esborrament',
    deleteConfirmationText: 'Estàs segur/a que vols esborrar això?',
    delete: 'Esborra',
    cancel: 'Cancel·la',
    eventNewsfeed: 'Notícies de l’esdeveniment',
    myPosts: 'Les meves publicacions',
    comment: 'Comenta',
    viewAllComments: 'Tots els {{count}} comentaris',
    commentPlaceholder: 'Escriu un comentari aquí...',
    newPostPlaceholder: 'Comparteix els teus pensaments aquí...',
    fileSizeError: 'La mida de l’arxiu ha de ser menys de {{sizeLimit}} MB.',
    addImage: 'Afegeix imatge',
    createPost: 'Publica',
  },
  pt: {
    deleteConfirmation: 'Confirmação de exclusão',
    deleteConfirmationText: 'Tem certeza de que deseja excluir isso?',
    delete: 'Excluir',
    cancel: 'Cancelar',
    eventNewsfeed: 'Parede do evento',
    myPosts: 'Minhas postagens',
    comment: 'Comente',
    viewAllComments: 'Ver todos os {{count}} comentários',
    commentPlaceholder: 'Escreva um comentário aqui…',
    newPostPlaceholder: 'Compartilhe suas idéias aqui…',
    fileSizeError: 'O tamanho do arquivo deve ser inferior a {{sizeLimit}} MB.',
    addImage: 'Adicionar imagem',
    createPost: 'Publicar',
  },
  it: {
    deleteConfirmation: 'Elimina conferma',
    deleteConfirmationText: 'Sei sicuro di voler cancellare questo?',
    delete: 'Eliminare',
    cancel: 'Anulla',
    eventNewsfeed: "Notizie dell'evento",
    myPosts: 'I miei post',
    comment: 'Commento',
    viewAllComments: 'Visualizza tutto {{count}} commenti',
    commentPlaceholder: 'Scrivi un commento qui…',
    newPostPlaceholder: 'Condividi i tuoi pensieri qui…',
    fileSizeError: 'La dimensione del file deve essere inferiore a {{sizeLimit}} MB.',
    addImage: 'Aggiungi immagine',
    createPost: 'Inviare',
  },
  ja: {
    deleteConfirmation: '確認の削除',
    deleteConfirmationText: 'これを削除してもよろしいですか？',
    delete: '削除',
    cancel: 'キャンセル',
    eventNewsfeed: 'イベントニュースフィード',
    myPosts: '私の投稿',
    comment: 'コメント',
    viewAllComments: 'すべての{{count}}コメントを表示',
    commentPlaceholder: 'ここにコメントを書いてください…',
    newPostPlaceholder: 'ここであなたの考えを共有してください…',
    fileSizeError: 'ファイルサイズは {{sizeLimit}} MB 未満である必要があります。',
    addImage: '画像を追加',
    createPost: '投稿',
  },
  zh: {
    deleteConfirmation: '删除确认',
    deleteConfirmationText: '你确定要删除这个吗？',
    delete: '删除',
    cancel: '取消',
    eventNewsfeed: '事件新闻源',
    myPosts: '我的帖子',
    comment: '评论',
    viewAllComments: '查看所有 {{count}} 条评论',
    commentPlaceholder: '在这里写评论...',
    newPostPlaceholder: '在这里分享你的想法......',
    fileSizeError: '文件大小必须小于 {{sizeLimit}} MB。',
    addImage: '添加图片',
    createPost: '发布',
  },
  ar: {
    deleteConfirmation: 'تأكيد حذف',
    deleteConfirmationText: 'هل تريد بالتأكيد حذف هذا؟',
    delete: 'حذف',
    cancel: 'إلغاء',
    eventNewsfeed: 'موجز أخبار الحدث',
    myPosts: 'مشاركاتي',
    comment: 'تعليق',
    viewAllComments: 'عرض جميع التعليقات {{count}}',
    commentPlaceholder: 'اكتب تعليقًا هنا...',
    newPostPlaceholder: 'شارك أفكارك هنا...',
    fileSizeError: 'يجب أن يكون حجم الملف أقل من {{sizeLimit}} ميجابايت.',
    addImage: 'إضافة صورة',
    createPost: 'بوست',
  },
  tr: {
    deleteConfirmation: 'Onayı sil',
    deleteConfirmationText: 'Bunu silmek istediğinizden emin misiniz?',
    delete: 'Sil',
    cancel: 'İptal',
    eventNewsfeed: 'Etkinlik haber akışı',
    myPosts: 'Gönderilerim',
    comment: 'Yorum',
    viewAllComments: 'Tüm {{count}} yorumlarını görüntüle',
    commentPlaceholder: 'Buraya bir yorum yaz...',
    newPostPlaceholder: 'Düşüncelerini burada paylaş...',
    fileSizeError: "Dosya boyutu {{sizeLimit}} MB'den küçük olmalıdır.",
    addImage: 'Resim ekle',
    createPost: 'Mesaj',
  },
  de: {
    deleteConfirmation: 'Bestätigung löschen',
    deleteConfirmationText: 'Bist du sicher, dass du das löschen willst?',
    delete: 'Löschen',
    cancel: 'Stornieren',
    eventNewsfeed: 'Event-Newsfeed',
    myPosts: 'Meine Beiträge',
    comment: 'Kommentar',
    viewAllComments: 'Alle {{count}} Kommentare ansehen',
    commentPlaceholder: 'Schreibe hier einen Kommentar...',
    newPostPlaceholder: 'Teile deine Gedanken hier...',
    fileSizeError: 'Die Dateigröße muss kleiner als {{sizeLimit}} MB sein.',
    addImage: 'Bild hinzufügen',
    createPost: 'Post',
  },
};
