import { baseMoment, getEventTimeFormat } from './dates';
import { getCurrentParticipant } from './getters';

export const getMySlots = (entities) => {
  const {
    businessConferenceParticipantTypes: participantTypes = {},
  } = entities;

  const currentParticipant = getCurrentParticipant(entities) || {};
  const { blockedSlots: selfBlockedSlots = [] } = currentParticipant;
  const {
    agenda: mySlots,
  } = participantTypes[currentParticipant.participantType?.id] || {};

  return {
    mySlots,
    selfBlockedSlots,
  };
};

export const getSlotLabel = (entities, slot) => {
  const [startDate, endDate] = slot;
  const { events } = entities;
  const timeFormat = getEventTimeFormat(events);
  return `${baseMoment(startDate).format(timeFormat)} - ${baseMoment(endDate).format(timeFormat)}`;
};

const getSlotFilterOptions = (entities, meta) => {
  const {
    businessConferenceMeetings: meetings = {},
  } = entities;
  const { meetingStatuses = {} } = meta;

  const { mySlots, selfBlockedSlots } = getMySlots(entities);

  if (!mySlots) return {};

  const days = {};
  const { requested, accepted } = meetingStatuses;
  const meetingSlots = Object.values(meetings)
    .filter(({ status }) => [requested, accepted].includes(status))
    .map(({ slotId }) => slotId);
  Object.keys(mySlots).sort().forEach((slotEpoch) => {
    const slot = mySlots[slotEpoch];
    const [startDate, , slotId] = slot;
    const day = baseMoment(startDate).format('YYYY-MM-DD');

    const skip = !mySlots[slotEpoch]
    || selfBlockedSlots.includes(slotEpoch)
    || meetingSlots.includes(slotId);

    if (!skip) {
      const activity = {
        label: getSlotLabel(entities, slot),
        value: slotId,
      };
      if (days[day]) days[day].push(activity);
      else days[day] = [activity];
    }
  });
  return days;
};

export default getSlotFilterOptions;
