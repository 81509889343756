import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import callApi from '../../actions/callApi';

const usePolling = ({
  endpoint,
  params = {},
  duration = 60000,
  active = true,
}) => {
  const dispatch = useDispatch();

  const serializedParams = JSON.stringify(params);

  useEffect(() => {
    let interval;
    const parsedParams = JSON.parse(serializedParams);

    if (active) interval = setInterval(() => {
      dispatch(callApi(endpoint, parsedParams));
    }, duration);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [endpoint, serializedParams, duration, active, dispatch]);
};

export default usePolling;
