export default {
  en: {
    type: {
      online: 'Virtual',
      offline: 'In person',
      mixed: 'Hybrid',
    },
  },
  es: {
    type: {
      online: 'Virtual',
      offline: 'Presencial',
      mixed: 'Híbrido',
    },
  },
  fr: {
    type: {
      online: 'Virtuel',
      offline: 'En personne',
      mixed: 'Hybride',
    },
  },
  ca: {
    type: {
      online: 'Virtual',
      offline: 'En persona',
      mixed: 'Híbrid',
    },
  },
  pt: {
    type: {
      online: 'Virtual',
      offline: 'Em pessoa',
      mixed: 'Híbrido',
    },
  },
  it: {
    type: {
      online: 'Virtuale',
      offline: 'Di persona',
      mixed: 'Ibrido',
    },
  },
  ja: {
    type: {
      online: 'バーチャル',
      offline: '直接会って',
      mixed: 'ハイブリッド',
    },
  },
  zh: {
    type: {
      online: '虚拟的',
      offline: '亲自',
      mixed: '杂交种',
    },
  },
  ar: {
    type: {
      online: 'افتراضية',
      offline: 'شخصيًا',
      mixed: 'هايبرد',
    },
  },
  tr: {
    type: {
      online: 'Sanal',
      offline: 'Şahsen',
      mixed: 'Hibrid',
    },
  },
  de: {
    type: {
      online: 'Virtuell',
      offline: 'Persönlich',
      mixed: 'Hybrid',
    },
  },
};
