import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ErrorIcon from '@material-ui/icons/Error';
import Modal from 'eventtia-ui-components/lib/Modal';
import Button from 'eventtia-ui-components/lib/Button';
import { parseQrContent, isLeadAdded } from '../../helpers/attendeeLeads';
import { addAttendeeLead } from '../../actions/attendeeLeads';
import { setActiveLead } from '../../actions/app';

import QrReader from '../../forks/QrReader';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  scanner: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    textAlign: 'center',
    position: 'absolute',
    color: 'white',
    backgroundColor: theme.palette.darkGrey.light,
    padding: theme.spacing(8, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2000,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 2,
  },
  scanDescription: {
    margin: theme.spacing(1, 4),
    zIndex: 2,
    position: 'absolute',
    top: theme.spacing(8),
  },
  reader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 0,
    '& video': {
      objectFit: 'cover',
      objectPosition: 'center',
    },
    '&>div': {
      height: '100%',
    },
  },
  overlay: {
    position: 'absolute',
    width: 208,
    height: 208,
    top: '50%',
    marginTop: -104,
    boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    '&:before, &:after, &>$overlayBottomCorners:before, &>$overlayBottomCorners:after': {
      position: 'absolute',
      content: '""',
      width: 33,
      height: 33,
      borderColor: theme.palette.green.main,
      borderStyle: 'solid',
    },
    '&:before': {
      top: -5,
      left: -5,
      borderWidth: '5px 0 0 5px',
      borderRadius: '3px 0 0 0',
    },
    '&:after': {
      top: -5,
      right: -5,
      borderWidth: '5px 5px 0 0',
      borderRadius: '0 3px 0 0',
    },
  },
  overlayBottomCorners: {
    '&:before': {
      bottom: -5,
      left: -5,
      borderWidth: '0 0 5px 5px',
      borderRadius: '0 0 0 3px',
    },
    '&:after': {
      bottom: -5,
      right: -5,
      borderWidth: '0 5px 5px 0',
      borderRadius: '0 0 3px 0',
    },
  },
  dialog: {
    color: theme.palette.darkGrey.main,
    textAlign: 'center',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
  },
  dialogMessage: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: theme.spacing(3),
  },
  dialogIcon: {
    fontSize: 80,
    marginTop: theme.spacing(3),
  },
  success: {
    color: theme.palette.green.main,
  },
  error: {
    color: theme.palette.red.main,
  },
}));

const ScanQrFab = ({
  eventUri,
  setActiveLead: dispatchSetActiveLead,
  addAttendeeLead: dispatchAddAttendeeLead,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendeeLeads');

  const [scannerOpen, setScannerOpen] = useState(false);
  const openScanner = () => {
    setScannerOpen(true);
  };
  const closeScanner = () => {
    setScannerOpen(false);
  };

  const [message, setMessage] = useState();

  const [scannedLead, setScannedLead] = useState();
  const closeDialog = () => {
    setMessage();
    setScannedLead();
  };

  const onScan = (scanResult, scanError) => {
    if (scanResult) {
      closeScanner();
      const attendeeLead = parseQrContent(scanResult?.text);
      if (attendeeLead?.uuid && attendeeLead?.fullName && attendeeLead?.email) {
        const forUpdate = isLeadAdded(attendeeLead?.uuid);
        dispatchAddAttendeeLead(attendeeLead, eventUri).then(() => {
          setScannedLead(attendeeLead);
          setMessage({
            type: 'success',
            text: t(
              `qr.${forUpdate ? 'scanUpdateSuccess' : 'scanAddSuccess'}`,
              { leadName: attendeeLead.fullName }
            ),
          });
        });
      } else setMessage({
        type: 'error',
        text: t('qr.invalidQr'),
      });
    } else if (scanError) console.error(scanError);
  };

  return (
    <>
      <Fab
        onClick={openScanner}
        color="secondary"
        aria-label={t('actions.scanQr')}
        className={classes.fab}
      >
        <CameraAltIcon />
      </Fab>
      {scannerOpen && (
        <div className={classes.scanner}>
          <IconButton
            className={classes.closeButton}
            aria-label={t('global:actions.close')}
            onClick={closeScanner}
            edge="end"
            color="inherit"
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.scanDescription}>
            {t('qr.scanDescription')}
          </Typography>
          <div className={classes.overlay}>
            <div className={classes.overlayBottomCorners} />
          </div>
          <QrReader
            onResult={onScan}
            className={classes.reader}
            constraints={{
              facingMode: 'environment',
            }}
            scanDelay={500}
          />
        </div>
      )}
      {!!message && (
        <Modal
          open
          handleClose={closeDialog}
          maxWidth="sm"
        >
          <Paper
            className={classes.dialog}
          >
            <IconButton
              className={classes.closeButton}
              aria-label={t('global:actions.close')}
              onClick={closeDialog}
              edge="end"
              color="inherit"
              size="small"
            >
              <CloseIcon />
            </IconButton>
            {message?.type === 'error' ? (
              <ErrorIcon
                className={clsx(classes.dialogIcon, classes.error)}
              />
            ) : (
              <ThumbUpIcon
                className={clsx(classes.dialogIcon, classes.success)}
              />
            )}
            <Typography className={classes.dialogMessage}>
              {message.text}
            </Typography>
            {message?.type === 'error' ? (
              <Button
                variant="primary"
                onClick={() => {
                  closeDialog();
                }}
                fullWidth
              >
                {t('global:actions.close')}
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  closeDialog();
                  dispatchSetActiveLead(scannedLead.uuid);
                }}
                fullWidth
              >
                {t('actions.seeLeadDetails')}
              </Button>
            )}
          </Paper>
        </Modal>
      )}
    </>
  );
};

ScanQrFab.propTypes = {
  eventUri: PropTypes.string.isRequired,
  addAttendeeLead: PropTypes.func.isRequired,
  setActiveLead: PropTypes.func.isRequired,
};

export default connect(null, {
  addAttendeeLead,
  setActiveLead,
})(ScanQrFab);
