import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from 'eventtia-ui-components/lib/Button';
import DateInput from 'eventtia-ui-components/lib/DateInput';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useParams from '../../hooks/useParams';

const useStyles = makeStyles((theme) => ({
  buttonMobile: {
    marginTop: theme.spacing(2),
  },
}));

const ScheduleListFilters = ({
  filters,
  setFilters,
  workshopCategories,
  speakers: workshopSpeakers,
  events,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { startDate: eventStartDate, endDate: eventEndDate } = Object.values(events)[0];
  const [dateFrom, setDateFrom] = useState(filters.dateFrom || '');
  const [categories, setCategories] = useState(filters.categories || []);
  const [speakers, setSpeakers] = useState(filters.speakers || []);
  const { t } = useTranslation(['global']);
  const { locale } = useParams();

  const filterableCategories = Object.values(workshopCategories).map((category) => {
    const { name, id } = category;
    return { label: name, value: id };
  });

  const filterableSpeakers = Object.values(workshopSpeakers).map((speaker) => {
    const { fullName, id } = speaker;
    return { label: fullName, value: id };
  }).sort((a, b) => a.label.trim().localeCompare(b.label.trim(), locale, { numeric: true }));

  const renderCategories = (values) => values.map((value) => workshopCategories[value].name).join(', ');
  const renderSpeakers = (values) => values.map((value) => workshopSpeakers[value].fullName).join(', ');
  const haveAnyFilter = Object.values(filters).some((filter) => filter?.length > 0);

  const clearFilters = () => {
    setFilters({});
    setDateFrom('');
    setCategories([]);
    setSpeakers([]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <DateInput
          label={t('dates.from')}
          id="date-from"
          value={dateFrom}
          handleChange={setDateFrom}
          timePicker
          timeFormat="24h"
          minDate={eventStartDate}
          maxDate={eventEndDate}
        />
      </Grid>
      {filterableCategories.length > 0 && (
        <Grid item xs={12} md={3}>
          <SelectInput
            renderValue={() => renderCategories(categories)}
            id="workshops-categories"
            handleChange={setCategories}
            value={categories}
            label={t('filters.categories.categoriesTypes')}
            options={filterableCategories}
            multiple
          />
        </Grid>
      )}
      {filterableSpeakers.length > 0 && (
        <Grid item xs={12} md={3}>
          <SelectInput
            renderValue={() => renderSpeakers(speakers)}
            id="speakers"
            handleChange={setSpeakers}
            value={speakers}
            label={t('speakers.speakers')}
            options={filterableSpeakers}
            multiple
          />
        </Grid>
      )}
      <Grid container item xs={12} spacing={!mobile && 2}>
        <Grid item xs={12} md={3}>
          <Button
            small
            fullWidth
            variant="secondary"
            onClick={() => setFilters({
              dateFrom,
              categories,
              speakers,
            })}
          >
            {t('actions.filter')}
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          {haveAnyFilter && (
            <Button
              small
              fullWidth
              variant="tertiary"
              className={clsx(mobile && classes.buttonMobile)}
              onClick={() => clearFilters()}
            >
                {t('actions.clear')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ScheduleListFilters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  workshopCategories: PropTypes.objectOf(
    CustomPropTypes.workshopCategory
  ),
  speakers: PropTypes.objectOf(
    CustomPropTypes.speaker
  ),
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.string
    ),
    speakers: PropTypes.arrayOf(
      PropTypes.string
    ),
  }),
  events: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
};

ScheduleListFilters.defaultProps = {
  workshopCategories: {},
  speakers: {},
  filters: {},
};

const mapStateToProps = ({
  entities: {
    workshopCategories,
    speakers,
    events,
  },
}) => ({
  workshopCategories,
  speakers,
  events,
});

export default connect(mapStateToProps)(ScheduleListFilters);
