import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { addChannel, NEW_CHAT_REQUEST } from '../../actions/messages';
import { setActiveChannel } from '../../actions/app';
import { buildPrivateTopic, canChat } from '../../helpers/chat';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';
import DefaultBody from './DefaultBody';
import LeadBody from './LeadBody';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: theme.spacing(2, 0),
  },
  body: {
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.light,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, 1.5),
    boxShadow: theme.customShadows.small,
  },
  own: {
    justifyContent: 'flex-end',
    '& $body': {
      borderBottomRightRadius: 0,
      backgroundColor: theme.palette.lightGrey.dark,
      marginLeft: theme.spacing(2),
    },
    '& $avatar': {
      order: 1,
    },
  },
  received: {
    justifyContent: 'flex-start',
    '& $body': {
      borderBottomLeftRadius: 0,
      marginRight: theme.spacing(2),
    },
    '& $avatar': {
      order: 0,
    },
  },
  bot: {
    '& $body': {
      width: '100%',
      borderBottomLeftRadius: 10,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      marginLeft: theme.spacing(2),
    },
  },
  avatar: {
    backgroundColor: theme.palette.lightGrey.dark,
  },
  iconButton: {
    padding: 0,
  },
}));

const MessageBubble = ({
  id, content, sender, info, type, avatar, displayAvatar, currentAttendee,
  setActiveChannel: dispatchSetActiveChannel,
  attendeeID, openChannels, activeSubpage,
  addChannel: dispatchAddChannel, attendeeTypeId, appSettings,
}) => {
  const classes = useStyles();
  const attendeeBroadcast = useContext(AttendeeBroadcastContext);
  const [settings] = Object.values(appSettings);
  const currentCanChat = canChat(settings, currentAttendee.attendeeType?.id)
  && canChat(settings, attendeeTypeId);
  const topic = buildPrivateTopic(attendeeID, currentAttendee.id);
  const handleClick = () => {
    const isChatOpen = openChannels.some(({ topic: openTopic }) => openTopic === topic);
    if (!isChatOpen) {
      attendeeBroadcast(NEW_CHAT_REQUEST, { attendee_id: attendeeID });
      dispatchAddChannel({
        type: 'private',
        topic,
        name: sender,
        avatar,
      });
    }
    dispatchSetActiveChannel(topic);
  };

  let body;
  const [, bodyType, specialContent] = content.split('::');
  switch (bodyType) {
    case 'lead':
      body = (
        <LeadBody
          type={type}
          content={specialContent}
          info={info}
        />
      );
      break;
    default:
      body = (
        <DefaultBody
          type={type}
          sender={sender}
          content={content}
          info={info}
        />
      );
  }

  return (
    <div id={id} className={clsx(classes.root, classes[type])}>
      {type !== 'bot' && displayAvatar && type === 'own' && (
        <Tooltip title={sender} placement="top">
          <Avatar src={avatar} className={classes.avatar} />
        </Tooltip>
      )}
      {type !== 'bot' && displayAvatar && type !== 'own' && !currentCanChat && (
        <Tooltip title={sender} placement="top">
          <Avatar src={avatar} className={classes.avatar} />
        </Tooltip>
      )}
      {type !== 'bot' && displayAvatar && type !== 'own' && currentCanChat && (
        <IconButton
          className={classes.iconButton}
          key={id}
          onClick={handleClick}
          disabled={activeSubpage?.module === 'Messages'
            && activeSubpage?.activeChannel === topic}
        >
          <Tooltip title={sender} placement="top">
            <Avatar src={avatar} className={classes.avatar} />
          </Tooltip>
        </IconButton>
      )}

      <div className={classes.body}>
        {body}
      </div>
    </div>
  );
};

MessageBubble.propTypes = {
  attendeeTypeId: PropTypes.string,
  currentAttendee: CustomPropTypes.attendee.isRequired,
  id: PropTypes.string.isRequired,
  attendeeID: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  sender: PropTypes.string,
  info: PropTypes.node,
  avatar: PropTypes.string,
  displayAvatar: PropTypes.bool,
  type: PropTypes.oneOf(['own', 'received', 'bot']).isRequired,
  addChannel: PropTypes.func.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
  openChannels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  activeSubpage: PropTypes.shape({
    module: PropTypes.string,
    activeChannel: PropTypes.string,
  }).isRequired,
  appSettings: PropTypes.objectOf(
    CustomPropTypes.appSettings
  ).isRequired,
};

MessageBubble.defaultProps = {
  attendeeTypeId: null,
  sender: undefined,
  info: undefined,
  avatar: undefined,
  displayAvatar: false,
};

const mapStateToProps = ({
  chat: {
    openChannels,
  },
  entities: {
    appSettings,
  },
  app: {
    activeSubpage,
  },
}) => ({
  openChannels,
  appSettings,
  activeSubpage,
});

export default connect(mapStateToProps, { addChannel, setActiveChannel })(MessageBubble);
