import { useEffect, useRef } from 'react';
import { BrowserQRCodeReader } from '@zxing/browser';

import { isMediaDevicesSupported, isValidType } from './utils';

// TODO: add support for debug logs

const useQrReader = ({
  scanDelay,
  constraints: video,
  onResult,
  videoId,
}) => {
  const controlsRef = useRef(null);

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader(null, {
      delayBetweenScanAttempts: scanDelay,
    });

    if (
      !isMediaDevicesSupported()
      && isValidType(onResult, 'onResult', 'function')
    ) {
      const message = 'MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"';

      onResult(null, new Error(message), codeReader);
    }

    if (isValidType(video, 'constraints', 'object')) codeReader
      .decodeFromConstraints({ video }, videoId, (result, error) => {
        if (isValidType(onResult, 'onResult', 'function') && controlsRef.current) onResult(result, error, codeReader);
      })
      .then((controls) => {
        controlsRef.current = controls;
      })
      .catch((error) => {
        if (isValidType(onResult, 'onResult', 'function')) onResult(null, error, codeReader);
      });

    return () => {
      if (controlsRef.current) controlsRef.current.stop();
    };
  }, []);
};

export default useQrReader;
