import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import Card from 'eventtia-ui-components/lib/Card';
import callApi from '../../actions/callApi';
import usePrevious from '../../hooks/usePrevious';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  textarea: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  successMessage: {
    backgroundColor: theme.palette.green.light,
    color: theme.palette.green.dark,
    position: 'absolute',
    top: theme.spacing(8),
    left: '50%',
    transform: 'translateX(-50%)',
    '& svg': {
      color: 'inherit',
    },
  },
}));

const Notes = ({
  meeting,
  note,
  event,
  saving,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['global', 'meeting']);
  const valueRef = useRef('');
  useEffect(() => {
    valueRef.current.value = note?.body || '';
  }, [note]);
  const [changed, setChanged] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const onChange = () => {
    setSuccessMessage('');
    setChanged((!note && !!valueRef.current.value)
      || (!!note && note.body !== valueRef.current.value));
  };

  const prevSuccessMessage = usePrevious(successMessage);

  const save = () => {
    if (valueRef.current) dispatchCallApi('updateNote', {
      body: valueRef.current.value,
      businessConferenceId: meeting.businessConferenceId,
      meetingId: meeting.id,
      eventUri: event.eventUri,
    }).then((res) => {
      if (res.error) setSuccessMessage('');
      else setSuccessMessage(t('meeting:notes.saveSuccess'));
      setChanged(false);
    });
  };

  // Auto-save on unmount (when meeting changes)
  useEffect(() => (() => {
    if ((!note && !!valueRef.current.value)
      || (!!note && note.body !== valueRef.current.value)) save();
  }), [note]);

  // useEffect(() => {
  //   if (!changed) return;
  //   const handleBeforeUnload = (e) => {
  //     e.preventDefault();
  //     save();
  //   }
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  // }, [note]);

  return (
    <Card className={classes.card}>
      <Loader loading={saving} variant="absolute" />
      <TextField
        inputRef={valueRef}
        onChange={onChange}
        inputProps={{ classes: { input: classes.textarea }, maxLength: 700 }}
        id="notes"
        placeholder={t('meeting:notes.placeholder')}
        rows={5}
        multiline
        fullWidth
      />
      <Fade in={!!successMessage}>
        <Chip
          className={classes.successMessage}
          icon={<CheckCircleIcon />}
          label={successMessage || prevSuccessMessage}
        />
      </Fade>
      <Button
        onClick={save}
        className={classes.button}
        disabled={saving || !changed}
      >
        {t('global:actions.save')}
      </Button>
    </Card>
  );
};

Notes.propTypes = {
  meeting: CustomPropTypes.meeting.isRequired,
  event: CustomPropTypes.event.isRequired,
  note: CustomPropTypes.note,
  saving: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
};

Notes.defaultProps = {
  note: undefined,
};

const mapStateToProps = (
  {
    entities: { notes },
    fetchStatus: {
      updateNote: { isFetching },
    },
  },
  { meeting }
) => ({
  note: Object.values(notes || {}).find(
    ({ reviewed }) => reviewed.id === meeting.id
  ),
  saving: isFetching,
});

export default connect(mapStateToProps, { callApi })(Notes);
