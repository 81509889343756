import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Card from 'eventtia-ui-components/lib/Card';
import Divider from '@material-ui/core/Divider';
import EventtiaLogo from 'eventtia-ui-components/lib/EventtiaLogo';
import FeedbackScreen from 'eventtia-ui-components/lib/FeedbackScreen';
import Sponsors from '../Sponsors';
import { getPublicRegistrationUrl } from '../../helpers/endpoints';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import defaultLoginDisabledImage from '../../assets/loginDisabled.png';
import VideoBackground, { useVideoBackground } from '../VideoBackground';
import { setAttendanceMode } from '../../helpers/attendanceModeHelper';
import useEventUriHack from '../../hooks/hacks/useEventUriHack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    minHeight: '100%',
    right: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.common.white,
  },
  hackedRoot: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  loginDisabledRoot: {
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.darkGrey.main,
    '& $pipe': {
      color: theme.palette.lightGrey.dark,
    },
    '& $poweredByContainer': {
      color: theme.palette.darkGrey.light,
      opacity: 0.6,
    },
  },
  main: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
  },
  container: {
    width: 542,
    maxWidth: '100%',
  },
  titleContainer: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    color: 'inherit',
    alignItems: 'center',
    '& > h1': {
      fontWeight: 'bold',
    },
  },
  mobileTitleContainer: {
    flexDirection: 'column',
  },
  logo: {
    maxHeight: '7em',
    maxWidth: '33%',
  },
  browserError: {
    margin: theme.spacing(2),
  },
  pipe: {
    margin: theme.spacing(0, 2),
    fontSize: theme.spacing(4),
    lineHeight: '1em',
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 2),
    },
  },
  name: {
    fontSize: '1.4em',
    fontWeight: 600,
  },
  poweredBy: {
    fontSize: theme.typography.body2.fontSize,
  },
  eventtia: {
    height: 13,
    width: 'auto',
    marginLeft: theme.spacing(1),
  },
  contentRoot: {
    backgroundColor: theme.palette.lightGrey.main,
    maxWidth: '100%',
    borderRadius: theme.spacing(1),
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  content: {
    padding: theme.spacing(6),
    minWidth: '45%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },
  mobileContent: {
    padding: theme.spacing(6, 0),
  },
  imageContainer: {
    marginLeft: theme.spacing(6),
    width: theme.spacing(50),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '100%',
    },
  },
  registerText: {
    color: theme.palette.darkGrey.light,
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0, 1),
  },
  registerHelper: {
    margin: '0 4px 0 0',
  },
  registerCallToAction: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  poweredByContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
    color: 'inherit',
  },
  loginDisabled: {
    width: '100%',
  },
}));

const LoggedOutLayout = ({
  children, currentEvent, settings,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('global');
  const smallMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { name, logo, whiteLabel, eventUri } = currentEvent;
  const {
    enabled, registerButtonEnabled, registerButtonHelper, registerButtonText,
    loginDisabledImage, loginDisabledText, backgroundImage: { filename, large },
  } = settings;
  const loginTitleColor = settings.loginViewSettings?.loginTitleColor || '#FFFFFF';
  const image = filename ? large : '/default-background.jpg';
  const loginDisabledImageSrc = loginDisabledImage?.filename && loginDisabledImage.medium;
  const { filename: settingsFilename, medium: settingsMedium } = settings.logo;
  const { filename: eventFilename, medium: eventMedium } = logo;
  const videoBg = useVideoBackground(eventUri, 'login');
  const style = (enabled && !videoBg) ? { backgroundImage: `url('${image}')` } : undefined;
  const registerURL = getPublicRegistrationUrl(eventUri);
  const loggedOutBgLayout = useEventUriHack('loggedOutBgLayout');

  useEffect(() => {
    setAttendanceMode('');
  }, []);

  let medium;
  if (settingsFilename) medium = settingsMedium;
  else if (eventFilename) medium = eventMedium;

  return (
    <div
      className={clsx(
        classes.root,
        loggedOutBgLayout && classes.hackedRoot,
        !enabled && classes.loginDisabledRoot
      )}
      style={style}
    >
      <VideoBackground src={videoBg} />
      <main className={classes.main}>
        <div className={classes.container}>
          <div
            className={clsx(
              classes.titleContainer,
              mobile && classes.mobileTitleContainer
            )}
          >
            {medium ? (
              <img src={medium} alt={name} className={classes.logo} />
            ) : (
              <EventtiaLogo className={classes.logo} />
            )}
            {!mobile && (
              <span className={classes.pipe} style={{ color: loginTitleColor }}>|</span>
            )}
            <div>
              <Typography
                variant={smallMobile ? 'subtitle1' : 'h3'}
                component="h3"
                style={{ color: loginTitleColor, textAlign: mobile ? 'center' : 'start' }}
              >
                {name}
              </Typography>
            </div>
          </div>

          {enabled ? (
            <Card className={classes.contentRoot}>
              <div className={clsx(classes.content, mobile && classes.mobileContent)}>
                {children}
              </div>
              {registerButtonEnabled && (
                <div className={classes.registerText}>
                  {registerButtonHelper && (
                    <p className={classes.registerHelper}>
                      {registerButtonHelper}
                    </p>
                  )}
                  <a
                    className={classes.registerCallToAction}
                    href={registerURL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {registerButtonText || t('forms.notRegisteredLink')}
                  </a>
                </div>
              )}
              <Divider flex="true" />
              <Sponsors showTitle={false} hasPadding isLogin />
            </Card>
          ) : (
            <FeedbackScreen
              image={loginDisabledImageSrc || defaultLoginDisabledImage}
              alt="login disabled"
              description={loginDisabledText || t('info.loginDisabled', { event: name })}
              className={classes.loginDisabled}
            />
          )}

          {!whiteLabel && (
            <div className={classes.poweredByContainer}>
              <Typography variant="caption" className={classes.poweredBy}>
                {t('sponsors.poweredBy')}
              </Typography>
              <EventtiaLogo className={classes.eventtia} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

LoggedOutLayout.propTypes = {
  currentEvent: CustomPropTypes.event.isRequired,
  children: PropTypes.node.isRequired,
  settings: CustomPropTypes.appSettings.isRequired,
};

export default LoggedOutLayout;
