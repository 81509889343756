import React, { useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    display: '-webkit-box',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    scrollSnapType: 'x proximity',
    position: 'relative',
  },
  arrowRight: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  arrowLeft: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Carrousel = ({ children }) => {
  const classes = useStyles();
  const parentRef = useRef(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const scroll = (move) => {
    const ratio = mobile ? 1 : 0.7;
    const parentWidth = parentRef?.current?.clientWidth;
    parentRef.current.scrollLeft += parentWidth * ratio * move;
  };

  return (
    <>
      <div
        className={classes.root}
        ref={parentRef}
      >
        {children}
      </div>
      {(children?.length >= 3 || (mobile && children?.length >= 2)) && (
        <div className={classes.buttons}>
          <IconButton
            className={classes.arrowLeft}
            onClick={() => scroll(-1)}
            color="primary"
          >
            <ChevronLeftIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            className={classes.arrowRight}
            onClick={() => scroll(1)}
            color="primary"
          >
            <ChevronRightIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </>
  );
};

Carrousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carrousel;
