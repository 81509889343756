import { withStyles } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';

const Switch = withStyles((theme) => ({
  switchBase: {
    color: '#B5B5B5',
  },
  track: {
    background: theme.palette.lightGrey.dark,
  },
}))(MuiSwitch);

export default Switch;
