import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import Block from './Block';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightGrey.light,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.dark,
    },
  },
  blockContent: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& b': {
      fontWeight: 900,
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 22,
  },
  downloadIcon: {
    opacity: 1,
    '& svg': {
      opacity: 1,
      fill: theme.palette.primary.main,
      '&:hover': {
        fill: theme.palette.primary.dark,
      },
    },
  },
}));

const PendingRequests = ({
  ...request
}) => {
  const classes = useStyles();
  const { t } = useTranslation('meeting');

  const blockContent = (
    <Typography className={classes.blockContent} component="div">
      <InfoIcon className={classes.icon} />
      <div className={classes.text}>
        <b>{t('requests.waitingApproval')}</b>
      </div>
    </Typography>
  );

  return (
    <Block
      {...request}
      className={classes.root}
      tooltipTitle={blockContent}
      mainLine={blockContent}
      summaryLine={blockContent}
      seeRequests
    />
  );
};

PendingRequests.propTypes = {
  pendingEvaluation: PropTypes.bool,
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
};

PendingRequests.defaultProps = {
  pendingEvaluation: false,
};

const mapStateToProps = ({
  entities: {
    businessConferenceParticipants,
  },
}) => ({
  businessConferenceParticipants,
});

export default connect(mapStateToProps)(PendingRequests);
