import { useContext, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';
import { TRACK_TIME } from '../../actions/app';

export const ENTITY_TYPES = {
  SPONSOR: 'Sponsor',
  EVENT: 'Event',
  WORKSHOP: 'Workshop',
  BUSINESS_CONFERENCE_MEETING: 'BusinessConferenceMeeting',
};

const DEFAULT_TIME = 120;
const useTraces = (entityId, entityType, active = true, time = DEFAULT_TIME) => {
  const attendeeBroadcast = useContext(AttendeeBroadcastContext);
  const events = useSelector((state) => state.entities.events);
  const [{ id: eventId }] = Object.values(events);

  const trackOnce = useCallback((elapsedTime, extra) => (
    (!!entityId && !!entityType) ? attendeeBroadcast(TRACK_TIME, {
      event_id: eventId,
      entity_id: entityId,
      entity_type: entityType,
      time: elapsedTime,
      ...extra,
    }) : null
  ), [attendeeBroadcast, eventId, entityId, entityType]);

  useEffect(() => {
    let timer;
    if (active) {
      trackOnce(0);

      timer = setInterval(() => {
        trackOnce(time);
      }, time * 1000);
    }

    return () => clearInterval(timer);
  }, [trackOnce, active, time]);

  return { trackOnce };
};

export default useTraces;
