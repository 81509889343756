import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import useQrReader from './hooks';

const QrReader = ({
  videoContainerStyle,
  containerStyle,
  videoStyle,
  constraints,
  ViewFinder,
  scanDelay,
  className,
  onResult,
  videoId,
}) => {
  useQrReader({
    constraints,
    scanDelay,
    onResult,
    videoId,
  });

  return (
    <section className={className} style={containerStyle}>
      <div
        style={{
          ...styles.container,
          ...videoContainerStyle,
        }}
      >
        {!!ViewFinder && <ViewFinder />}
        <video
          muted
          id={videoId}
          style={{
            ...styles.video,
            ...videoStyle,
            transform: constraints?.facingMode === 'user' && 'scaleX(-1)',
          }}
        />
      </div>
    </section>
  );
};

QrReader.displayName = 'QrReader';
QrReader.defaultProps = {
  constraints: {
    facingMode: 'user',
  },
  videoId: 'video',
  scanDelay: 500,
  className: '',
  containerStyle: {},
  videoContainerStyle: {},
  videoStyle: {},
  ViewFinder: undefined,
};

QrReader.propTypes = {
  constraints: PropTypes.shape({}),
  ViewFinder: PropTypes.func,
  scanDelay: PropTypes.number,
  videoId: PropTypes.string,
  className: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  videoContainerStyle: PropTypes.shape({}),
  videoStyle: PropTypes.shape({}),
  onResult: PropTypes.func.isRequired,
};

export default QrReader;
