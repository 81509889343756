import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useChannel from '../../hooks/useChannel';

const ChannelConnection = ({
  topic, addBroadcast, removeBroadcast,
}) => {
  const broadcast = useChannel(topic);

  useEffect(() => {
    addBroadcast(topic, broadcast);

    return () => {
      removeBroadcast(topic);
    };
  }, [topic, broadcast, addBroadcast, removeBroadcast]);

  return null;
};

ChannelConnection.propTypes = {
  topic: PropTypes.string.isRequired,
  addBroadcast: PropTypes.func.isRequired,
  removeBroadcast: PropTypes.func.isRequired,
};

export default ChannelConnection;
