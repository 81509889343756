import { fetchConfig } from '../../actions/callApi';
import { READ_MESSAGE, READ_QUESTION } from '../../actions/messages';
import { getLastReadMessages } from '../../helpers/chat';
import { getEventUri } from '../../helpers/getters';

const initialState = getLastReadMessages(getEventUri());
const [LOGIN_SUCCESS] = fetchConfig.login.types;

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return getLastReadMessages(getEventUri());
    case READ_QUESTION:
    case READ_MESSAGE: {
      const { channel, uuid } = action;
      return {
        ...state,
        [channel]: uuid,
      };
    }
    default:
      return state;
  }
};
