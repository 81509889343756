import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from 'eventtia-ui-components/lib/Chip';
import StarIcon from '@material-ui/icons/Star';
import DenyIcon from '@material-ui/icons/HighlightOff';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RecommendationIcon from '@material-ui/icons/NewReleases';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { connect } from 'react-redux';
import clsx from 'clsx';
import useStringTransforms from '../../hooks/useStringTransforms';
import useAccountIdHack from '../../hooks/hacks/useAccountIdHack';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  personCard: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    width: '100%',
    height: 222,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 12px #00000019',
    border: '1px solid #70707019',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: theme.spacing(2, 0, 1, 2),
      alignItems: 'stretch',
    },
  },
  avatarWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      flexDirection: 'row',
      paddingBottom: theme.spacing(1.25),
    },
  },
  avatar: {
    height: 84,
    width: 84,
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      height: 56,
      width: 56,
    },
  },
  notViewedWrapper: {
    borderRadius: '50%',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    fontSize: 30,
    padding: theme.spacing(0.25),
    '& > img': {
      borderRadius: '50%',
    },
  },
  name: {
    fontSize: 14,
    marginBottom: theme.spacing(0.8),
    textAlign: 'start',
    fontWeight: 'bold',
    color: theme.palette.darkGrey.light,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  description: {
    padding: theme.spacing(1.5, 0.5, 0, 0.5),
    width: 'inherit',
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0, 2),
    },
  },
  detail: {
    textAlign: 'start',
    fontSize: 12,
    color: theme.palette.darkGrey.light,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  infoChip: {
    flexDirection: 'row',
    backgroundColor: theme.palette.lightGrey.main,
    boxShadow: '0px 1px 2px #0000001F',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    display: 'flex',
    top: 4,
    left: 20,
    width: 'auto',
    height: 20,
    '& > svg': {
      color: theme.palette.primary.main,
      width: 14,
      height: 14,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      left: 0,
      marginLeft: 'auto',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(),
    },
  },
  deniedChip: {
    left: 0,
  },
  recommendation: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  attendanceModeChip: {
    fontSize: 12,
    width: 84,
    height: 20,
    marginBottom: theme.spacing(),
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(8.75),
    },
  },
  fieldAttendanceMode: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const PersonCard = ({
  person, onBlockClick, type, events, viewedAttendees,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees', 'global');
  const { toTitleCase } = useStringTransforms();
  const {
    id,
    firstName,
    lastName,
    fullName,
    company,
    position,
    headshot,
    picture,
    attendeeTypeName,
    rating,
    denied,
    recommended,
    attendanceMode,
  } = person;

  const viewed = !!viewedAttendees?.[id];
  const stringAttendanceMode = `attendanceMode.${attendanceMode}`;
  const [{ attendanceMode: eventAttendanceMode }] = Object.values(events);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pipe = position && company ? ' | ' : '';
  const job = (position || company) ? `${toTitleCase(company, true)}${pipe}${toTitleCase(position, true)}` : '';
  const { HYBRID } = ATTENDANCE_MODE;

  const hideAttendanceModeBadge = useAccountIdHack('hideAttendanceModeBadge');

  return (
    <button
      type="button"
      className={classes.personCard}
      onClick={() => {
        onBlockClick(type === 'attendee' ? person : id);
      }}
    >
      <div className={classes.avatarWrapper}>
        <Avatar
          src={type === 'attendee' ? (headshot?.filename && headshot.medium)
            : (picture?.filename && picture.large)}
          variant="circle"
          className={clsx(classes.avatar, !viewed && classes.notViewedWrapper)}
        />
        {!!rating && !mobile && (
          <Chip
            className={classes.infoChip}
            label={rating.toString()}
            icon={<StarIcon />}
            size="small"
          />
        )}
        {!!denied && !mobile && (
          <Chip
            className={clsx(classes.infoChip, classes.deniedChip)}
            label={t('actions.denyParticipant')}
            icon={<DenyIcon />}
            size="small"
          />
        )}
        {recommended && (
          <RecommendationIcon
            color="primary"
            className={classes.recommendation}
          />
        )}

        <div className={classes.description}>
          {type === 'attendee' ? (
            <Typography className={classes.name} variant="subtitle2">
              {firstName || lastName
                ? `${toTitleCase(firstName)} ${toTitleCase(lastName)}` : <br />}
            </Typography>
          ) : (
            <Typography className={classes.name} variant="subtitle2">
              {toTitleCase(fullName)}
            </Typography>
          )}
          {job && (
            <Typography className={classes.detail} variant="body2">
              {toTitleCase(job, true)}
            </Typography>
          )}
          {attendeeTypeName && (
            <Typography className={classes.detail} variant="body2">
              {toTitleCase(attendeeTypeName, true)}
            </Typography>
          )}
        </div>

      </div>
      <div className={classes.fieldAttendanceMode}>
        {(eventAttendanceMode === HYBRID && attendanceMode && !hideAttendanceModeBadge) && (
          <Chip
            label={t(`global:${stringAttendanceMode}`)}
            size="small"
            className={classes.attendanceModeChip}
          />
        )}
        {!!rating && mobile && (
          <Chip
            className={classes.infoChip}
            label={rating.toString()}
            icon={<StarIcon />}
            size="small"
          />
        )}
        {!!denied && mobile && (
          <Chip
            className={clsx(classes.infoChip, classes.deniedChip)}
            label={t('actions.denyParticipant')}
            icon={<DenyIcon />}
            size="small"
          />
        )}
      </div>
    </button>
  );
};

PersonCard.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    company: PropTypes.string,
    attendanceMode: PropTypes.string,
    headshot: PropTypes.shape({
      filename: PropTypes.string,
      medium: PropTypes.string,
    }),
    picture: PropTypes.shape({
      filename: PropTypes.string,
      large: PropTypes.string,
    }),
    position: PropTypes.string,
    attendeeTypeName: PropTypes.string,
    rating: PropTypes.number,
    denied: PropTypes.bool,
    recommended: CustomPropTypes.recommendation,
  }).isRequired,
  onBlockClick: PropTypes.func.isRequired,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  type: PropTypes.string,
  viewedAttendees: CustomPropTypes.viewedAttendess,
};

PersonCard.defaultProps = {
  type: 'attendee',
  viewedAttendees: {},
};

const mapStateToProps = ({
  entities: {
    events,
  },
  viewedAttendees,
}) => ({
  events,
  viewedAttendees,
});

export default connect(mapStateToProps)(PersonCard);
