import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'eventtia-ui-components/lib/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from 'eventtia-ui-components/lib/Chip';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { addChannel, NEW_CHAT_REQUEST } from '../../actions/messages';
import { setActiveChannel } from '../../actions/app';
import useStringTransforms from '../../hooks/useStringTransforms';
import { buildPrivateTopic } from '../../helpers/chat';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  RepresentativeCard: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 183,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '0px 0px 34px #00000019',
    border: '1px solid #70707019',
    borderRadius: 10,
    alignItems: 'center',
  },
  avatarWrapper: {
    height: 116,
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  name: {
    fontSize: 14,
    textAlign: 'start',
    fontWeight: 'bold',
    color: theme.palette.darkGrey.light,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  description: {
    padding: theme.spacing(1.5, 2, 0, 2),
    width: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  detail: {
    textAlign: 'start',
    fontSize: 12,
    color: theme.palette.lightGrey.dark,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  details: {
    height: theme.spacing(4.5),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  attendanceModeDiv: {
    display: 'block',
  },
  attendanceModeChip: {
    fontSize: 12,
    margin: theme.spacing(0.5, 0, 0.5, 0),
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const RepresentativeCard = ({
  disabledScheduling,
  currentRepresentative,
  onBlockClick,
  setActiveChannel: dispatchSetActiveChannel,
  addChannel: dispatchAddChannel,
  openChannels,
  activeChat,
  currentLoggedInId,
  events,
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const {
    attendanceMode,
    firstName,
    lastName,
    position,
    headshot,
    id: representativeId,
  } = currentRepresentative;
  const attendeeBroadcast = useContext(AttendeeBroadcastContext);
  const { t } = useTranslation(['sponsors', 'attendees', 'meeting']);
  const fullName = `${firstName} ${lastName}`;
  const topic = buildPrivateTopic(representativeId, currentLoggedInId);
  const isChatOpen = openChannels.some(({ topic: openTopic }) => openTopic === topic);

  const startChat = () => {
    if (!isChatOpen) {
      attendeeBroadcast(NEW_CHAT_REQUEST, { attendee_id: representativeId });
      dispatchAddChannel({
        type: 'private',
        topic,
        name: fullName,
        avatar: headshot?.filename ? headshot.small : undefined,
      });
    }
    dispatchSetActiveChannel(topic);
  };

  const stringAttendanceMode = `attendanceMode.${attendanceMode}`;
  const [{ attendanceMode: eventAttendanceMode }] = Object.values(events);

  const { HYBRID } = ATTENDANCE_MODE;

  return (
    <div
      className={classes.RepresentativeCard}
    >
      <Avatar src={headshot?.filename && headshot.small} variant="square" className={classes.avatarWrapper} />
      <div className={classes.description}>
        <div className={classes.details}>
          <Typography className={classes.name} variant="subtitle2">
            {toTitleCase(fullName)}
          </Typography>
          {position && (
            <Typography className={classes.detail} variant="body2">
              {toTitleCase(position, true)}
            </Typography>
          )}
        </div>
        {eventAttendanceMode === HYBRID && attendanceMode && (
          <div className={classes.attendanceModeDiv}>
            <Chip
              label={t(`global:${stringAttendanceMode}`)}
              size="small"
              className={classes.attendanceModeChip}
            />
          </div>
        )}
        <div className={classes.buttons}>
          {activeChat && (
            <Button
              small
              onClick={startChat}
              className={classes.button}
            >
              {t(isChatOpen ? 'data.actions.goToChat' : 'data.actions.startChat')}
            </Button>
          )}
          {!disabledScheduling && (
            <Button
              small
              className={classes.button}
              onClick={onBlockClick}
              variant="secondary"
            >
              {t('data.actions.scheduleMeeting')}
            </Button>
          ) }
        </div>
      </div>
    </div>
  );
};

RepresentativeCard.propTypes = {
  currentLoggedInId: PropTypes.number.isRequired,
  activeChat: PropTypes.bool.isRequired,
  disabledScheduling: PropTypes.bool.isRequired,
  addChannel: PropTypes.func.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
  onBlockClick: PropTypes.func.isRequired,
  currentRepresentative: PropTypes.shape({
    attendanceMode: PropTypes.string,
    attendeeTypeId: PropTypes.string,
    participantId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
    headshot: PropTypes.shape({
      filename: PropTypes.string,
      small: PropTypes.string,
    }),
    id: PropTypes.string,
  }).isRequired,
  openChannels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
};

const mapStateToProps = ({
  entities: {
    events,
  },
  chat: {
    openChannels,
  },
}) => ({
  events,
  openChannels,
});

export default connect(mapStateToProps, {
  addChannel,
  setActiveChannel,
})(RepresentativeCard);
