import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from 'eventtia-ui-components/lib/Modal';
import Dialog from 'eventtia-ui-components/lib/Dialog';
import Input from 'eventtia-ui-components/lib/Input';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';
import { CREATE_CUSTOM_CHANNEL } from '../../actions/messages';

const MAX_CHANNEL_NAME_LENGTH = 75;
const MAX_CHANNEL_DESCRIPTION_LENGTH = 255;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
  },
  wrapper: {
    maxWidth: 467,
    padding: theme.spacing(2, 3),
  },
  bottomSpacer: {
    marginBottom: theme.spacing(2),
  },
}));

const NewChannelDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation(['chat', 'global']);

  const [channelName, setChannelName] = useState('');
  const [channelDescription, setChannelDescription] = useState('');
  const [error, setError] = useState('');
  const [enabled, setEnabled] = useState(true);

  const attendeeBroadcast = useContext(AttendeeBroadcastContext);
  const onCreateChannel = () => {
    setEnabled(false);
    attendeeBroadcast(CREATE_CUSTOM_CHANNEL, { name: channelName, description: channelDescription })
      .receive('ok', () => {
        setEnabled(true);
        setChannelName('');
        setChannelDescription('');
        setError('');
        onClose();
      })
      .receive('error', ({ error: receivedError }) => {
        // eslint-disable-next-line no-console
        console.error('failed to create channel', receivedError);
        setEnabled(true);
        // setError(receivedError);
        if (receivedError === 'channel_name_exists') setError(t('errors.channelNameExists'));
        else setError(receivedError);
      });
  };

  return (
    <Modal
      classes={{ paper: classes.root }}
      open={open}
      handleClose={onClose}
      maxWidth="md"
    >
      <Dialog
        title={t('actions.createChannel')}
        handleClose={onClose}
        action={{
          name: t('global:actions.create'),
          onClick: onCreateChannel,
          disabled: !enabled || !channelName.trim(),
        }}
      >
        <div className={classes.wrapper}>
          <Typography className={classes.bottomSpacer}>
            {t('messages.channelInfo')}
          </Typography>
          <div
            className={classes.bottomSpacer}
          >
            <Input
              id="channelName"
              value={channelName}
              handleChange={(value) => setChannelName(value)}
              name="channelName"
              label={t('channelName')}
              placeholder={t('messages.channelNamePlaceholder')}
              error={error}
              inputProps={{ maxLength: MAX_CHANNEL_NAME_LENGTH }}
              helperText={t('messages.maxChannelLength', { maxLength: MAX_CHANNEL_NAME_LENGTH })}
            />
          </div>
          <Input
            id="channelDescription"
            value={channelDescription}
            handleChange={(value) => setChannelDescription(value)}
            name="channelDescription"
            label={t('channelDescription')}
            placeholder={t('messages.channelDescriptionPlaceholder')}
            multiline
            rows={3}
            rowsMax={6}
            inputProps={{ maxLength: MAX_CHANNEL_DESCRIPTION_LENGTH }}
            helperText={t('messages.maxChannelLength', { maxLength: MAX_CHANNEL_DESCRIPTION_LENGTH })}
          />
        </div>
      </Dialog>
    </Modal>
  );
};

NewChannelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewChannelDialog;
