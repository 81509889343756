import merge from 'lodash/merge';
import getEndpoint from '../../helpers/endpoints';
import { getToken, getTokenContent } from '../../helpers/auth';
import { getEventUri } from '../../helpers/getters';
import getAttendeeTypesOptions from '../../helpers/getAttendeeTypesOptions';
import getSlotFilterOptions from '../../helpers/getSlotFilterOptions';

export const CALL_API = 'Call API';

export const fetchConfig = {
  login: {
    types: ['LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE'],
    options: ({ email, accessToken }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { access_token: accessToken, email },
    }),
  },
  accessToken: {
    types: ['ACCESS_TOKEN_REQUEST', 'ACCESS_TOKEN_SUCCESS', 'ACCESS_TOKEN_FAILURE'],
    options: ({ email }) => ({
      headers: {
        Accept: undefined,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { email },
    }),
  },
  ssoAuth: {
    types: ['SSO_AUTH_REQUEST', 'SSO_AUTH_SUCCESS', 'SSO_AUTH_FAILURE'],
    options: ({ ssoToken }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { ssoToken },
    }),
  },
  attendees: {
    types: ['ATTENDEES_REQUEST', 'ATTENDEES_SUCCESS', 'ATTENDEES_FAILURE'],
    bustCache: true,
    sortOptions: [
      'first_name asc',
      'first_name desc',
      'last_name asc',
      'last_name desc',
      'company asc',
      'company desc',
      'id desc',
      'id asc',
    ],
    filters: {
      search: { clearable: false },
      attendeeType: {
        type: 'AttendeeType',
        isEmpty: (entities, order) => {
          const {
            attendeeTypeCustomFields,
          } = entities;

          const attendeeTypesOptions = getAttendeeTypesOptions(entities, order);
          if (attendeeTypesOptions.length > 1) return false;
          const fieldIds = (attendeeTypesOptions[0]?.attendeeTypeCustomFields || [])
            .map(({ id }) => id);
          const attendeeTypeFields = fieldIds.map((id) => attendeeTypeCustomFields[id])
            .filter(({ filterable }) => filterable);
          return attendeeTypeFields.length === 0;
        },
      },
      slot: {
        type: 'SlotSelector',
        isEmpty: (entities, order, meta) => {
          const [conference] = Object.values(entities.businessConferences || {});
          const slotOptions = getSlotFilterOptions(entities, meta);
          return !conference || !Object.values(slotOptions).length;
        },
      },
      showOnlyRatedBy: {
        type: 'ShowOnlyRatedBy',
        isEmpty: (entities) => {
          const [conference] = Object.values(entities.businessConferences || {});
          return !conference?.automaticScheduling;
        },
      },
    },
    parseFilters: ({ attendeeType, slot, search, ...filters }) => {
      const result = {
        ...filters,
        slotId: slot?.slotId,
        keyWords: search,
      };
      if (attendeeType) {
        const { attendeeTypeValue, groupedFields, ...fields } = attendeeType;
        result.attendeeTypes = attendeeTypeValue;
        Object.keys(fields).forEach((fieldId) => {
          if (fields[fieldId].length) result[`custom_fields[${fieldId}]`] = fields[fieldId];
        });
        if (groupedFields) Object.keys(groupedFields).forEach((fieldId) => {
          if (groupedFields[fieldId].length) result[`custom_internal_ids[${fieldId}]`] = groupedFields[fieldId];
        });
      }
      return result;
    },
  },
  singleAttendee: {
    types: [
      'SINGLE_ATTENDEE_REQUEST',
      'SINGLE_ATTENDEE_SUCCESS',
      'SINGLE_ATTENDEE_FAILURE',
    ],
    bustCache: true,
  },
  singleeAttendeeSimple: {
    types: ['SINGLE_ATTENDEE_SIMPLE_REQUEST', 'SINGLE_ATTENDEE_SIMPLE_SUCCESS', 'SINGLE_ATTENDEE_SIMPLE_FAILURE'],
    bustCache: true,
  },
  attendeeTypes: {
    types: ['ATTENDEE_TYPES_REQUEST', 'ATTENDEE_TYPES_SUCCESS', 'ATTENDEE_TYPES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  currentAttendee: {
    types: [
      'CURRENT_ATTENDEE_REQUEST',
      'CURRENT_ATTENDEE_SUCCESS',
      'CURRENT_ATTENDEE_FAILURE',
    ],
    bustCache: true,
  },
  refetchCurrentAttendee: {
    types: [
      'REFETCH_CURRENT_ATTENDEE_REQUEST',
      'REFETCH_CURRENT_ATTENDEE_SUCCESS',
      'REFETCH_CURRENT_ATTENDEE_FAILURE',
    ],
    bustCache: true,
  },
  settings: {
    types: ['SETTINGS_REQUEST', 'SETTINGS_SUCCESS', 'SETTINGS_FAILURE'],
    auth: false,
  },
  meeting: {
    types: ['MEETING_REQUEST', 'MEETING_SUCCESS', 'MEETING_FAILURE'],
    bustCache: true,
  },
  tags: {
    types: ['TAGS_REQUEST', 'TAGS_SUCCESS', 'TAGS_FAILURE'],
    fetchAllPages: true,
  },
  participant: {
    types: ['PARTICIPANT_REQUEST', 'PARTICIPANT_SUCCESS', 'PARTICIPANT_FAILURE'],
    bustCache: true,
  },
  speakers: {
    types: ['SPEAKERS_REQUEST', 'SPEAKERS_SUCCESS', 'SPEAKERS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  sponsors: {
    types: ['SPONSORS_REQUEST', 'SPONSORS_SUCCESS', 'SPONSORS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  countries: {
    types: ['COUNTRIES_REQUEST', 'COUNTRIES_SUCCESS', 'COUNTRIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  regions: {
    types: ['REGIONS_REQUEST', 'REGIONS_SUCCESS', 'REGIONS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  cities: {
    types: ['CITIES_REQUEST', 'CITIES_SUCCESS', 'CITIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  sponsorsFiles: {
    types: ['SPONSORS_FILES_REQUEST', 'SPONSORS_FILES_SUCCESS', 'SPONSORS_FILES_FAILURE'],
    fetchAllPages: true,
  },
  hotspots: {
    types: ['HOTSPOTS_REQUEST', 'HOTSPOTS_SUCCESS', 'HOTSPOTS_FAILURE'],
  },
  sponsorProducts: {
    types: ['SPONSOR_PRODUCTS_REQUEST', 'SPONSOR_PRODUCTS_SUCCESS', 'SPONSOR_PRODUCTS_FAILURE'],
  },
  markAsDownloaded: {
    types: ['MARK_AS_DOWNLOADED_REQUEST', 'MARK_AS_DOWNLOADED_SUCCESS', 'MARK_AS_DOWNLOADED_FAILURE'],
    options: {
      method: 'PUT',
    },
  },
  workshops: {
    types: ['WORKSHOPS_REQUEST', 'WORKSHOPS_SUCCESS', 'WORKSHOPS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  workshopDefinitions: {
    types: ['WORKSHOP_DEFINITIONS_REQUEST', 'WORKSHOP_DEFINITIONS_SUCCESS', 'WORKSHOP_DEFINITIONS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  sponsorActivities: {
    types: ['SPONSOR_ACTIVITIES_REQUEST', 'SPONSOR_ACTIVITIES_SUCCESS', 'SPONSOR_ACTIVITIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  attendeeWorkshops: {
    types: ['ATTENDEE_WORKSHOPS_REQUEST', 'ATTENDEE_WORKSHOPS_SUCCESS', 'ATTENDEE_WORKSHOPS_FAILURE'],
    fetchAllPages: true,
    bustCache: true,
  },
  registerAttendee: {
    types: ['REGISTER_ATTENDEE_REQUEST', 'REGISTER_ATTENDEE_SUCCESS', 'REGISTER_ATTENDEE_FAILURE'],
    options: ({ workshopId }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: {
        workshop_id: workshopId,
      },
    }),
  },
  unregisterAttendee: {
    types: ['UNREGISTER_ATTENDEE_REQUEST', 'UNREGISTER_ATTENDEE_SUCCESS', 'UNREGISTER_ATTENDEE_FAILURE'],
    options: ({ workshopId }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      body: {
        workshop_id: workshopId,
      },
    }),
  },
  meetingCheckIn: {
    types: ['MEETING_CHECK_IN_REQUEST', 'MEETING_CHECK_IN_SUCCESS', 'MEETING_CHECK_IN_FAILURE'],
    options: {
      method: 'PUT',
    },
  },
  changeMeetingAttendanceMode: {
    types: ['MEETING_CHANGE_MODE_REQUEST', 'MEETING_CHANGE_MODE_SUCCESS', 'MEETING_CHANGE_MODE_FAILURE'],
    options: ({ mode }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: {
        mode,
      },
    }),
  },
  workshopsFiles: {
    types: ['WORKSHOPS_FILES_REQUEST', 'WORKSHOPS_FILES_SUCCESS', 'WORKSHOPS_FILES_FAILURE'],
    fetchAllPages: true,
  },
  speakersFiles: {
    types: ['SPEAKERS_FILES_REQUEST', 'SPEAKERS_FILES_SUCCESS', 'SPEAKERS_FILES_FAILURE'],
    fetchAllPages: true,
  },
  meetings: {
    types: ['MEETINGS_REQUEST', 'MEETINGS_SUCCESS', 'MEETINGS_FAILURE'],
    fetchAllPages: true,
  },
  activityICS: {
    types: ['ACTIVITY_ICS_REQUEST', 'ACTIVITY_ICS_SUCCESS', 'ACTIVITY_ICS_FAILURE'],
    bustCache: true,
  },
  quizzes: {
    types: ['QUIZZES_REQUEST', 'QUIZZES_SUCCESS', 'QUIZZES_FAILURE'],
  },
  activeQuizQuestion: {
    types: ['ACTIVE_QUESTION_REQUEST', 'ACTIVE_QUESTION_SUCCESS', 'ACTIVE_QUESTION_FAILURE'],
  },
  surveys: {
    types: ['SURVEYS_REQUEST', 'SURVEYS_SUCCESS', 'SURVEYS_FAILURE'],
    bustCache: true,
  },
  singleSurvey: {
    types: ['SINGLE_SURVEY_REQUEST', 'SINGLE_SURVEY_SUCCESS', 'SINGLE_SURVEY_FAILURE'],
    bustCache: true,
  },
  addSurveyAnswer: {
    types: ['ADD_SURVEY_ANSWER_REQUEST', 'ADD_SURVEY_ANSWER_SUCCESS', 'ADD_SURVEY_ANSWER_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body: data,
    }),
  },
  participantTypes: {
    types: ['PARTICIPANT_TYPES_REQUEST', 'PARTICIPANT_TYPES_SUCCESS', 'PARTICIPANT_TYPES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  updateNote: {
    types: ['UPDATE_NOTE_REQUEST', 'UPDATE_NOTE_SUCCESS', 'UPDATE_NOTE_FAILURE'],
    options: ({ body }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: { body },
    }),
  },
  updateCurrentAttendee: {
    types: ['UPDATE_CURRENT_ATTENDEE_REQUEST', 'UPDATE_CURRENT_ATTENDEE_SUCCESS', 'UPDATE_CURRENT_ATTENDEE_FAILURE'],
    options: ({ data }) => {
      data.append('offsetToUtc', true);
      return {
        headers: {
          Accept: '*/*',
        },
        method: 'PATCH',
        body: data,
      };
    },
  },
  requestMeeting: {
    types: ['REQUEST_MEETING_REQUEST', 'REQUEST_MEETING_SUCCESS', 'REQUEST_MEETING_FAILURE'],
    options: ({
      participantId,
      slotId,
      message,
      appRecommendation,
      lastMinute,
    }) => ({
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: {
        participantId,
        slotId,
        message,
        appRecommendation,
        lastMinute,
        offsetToUtc: true,
      },
    }),
  },
  createMeetingRequest: {
    types: ['CREATE_MEETING_REQUEST_REQUEST', 'CREATE_MEETING_REQUEST_SUCCESS', 'CREATE_MEETING_REQUEST_FAILURE'],
    options: ({
      participantId,
      slotId,
      message,
    }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: {
        meetingRequest: {
          slotId,
          receivedById: participantId,
          message,
        },
      },
    }),
  },
  meetingsRequests: {
    types: ['MEETING_REQUESTS_REQUEST', 'MEETING_REQUESTS_SUCCESS', 'MEETING_REQUESTS_FAILURE'],
    fetchAllPages: true,
  },
  approveMeetingRequest: {
    types: ['APPROVE_MEETING_REQUEST_REQUESTS', 'APPROVE_MEETING_REQUEST_SUCCESS', 'APPROVE_MEETING_REQUEST_FAILURE'],
    options: {
      method: 'PUT',
    },
    passToAction: ({ meetingRequestId }) => ({
      additionalReduction: (entities) => {
        const { meetingRequests: { [meetingRequestId]: approvedRequest } } = entities;
        approvedRequest.status = 'accepted';
        const { slotId } = approvedRequest;
        Object.values(entities.meetingRequests)
          .filter((request) => request.slotId === slotId && request.id !== meetingRequestId)
          .forEach((request) => { request.status = 'rejected'; });
        return entities;
      },
    }),
  },
  rejectMeetingRequest: {
    types: ['REJECT_MEETING_REQUEST_REQUESTS', 'REJECT_MEETING_REQUEST_SUCCESS', 'REJECT_MEETING_REQUEST_FAILURE'],
    options: {
      method: 'DELETE',
    },
  },
  updateParticipant: {
    types: ['UPDATE_PARTICIPANT_REQUEST', 'UPDATE_PARTICIPANT_SUCCESS', 'UPDATE_PARTICIPANT_FAILURE'],
    options: ({ blockedSlots }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: {
        blockedSlots,
        offsetToUtc: true,
      },
    }),
  },
  updateMeeting: {
    types: ['UPDATE_MEETING_REQUEST', 'UPDATE_MEETING_SUCCESS', 'UPDATE_MEETING_FAILURE'],
    options: ({ status, rejectionReason }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: {
        status,
        rejectionReason,
        offsetToUtc: true,
      },
    }),
  },
  rateParticipant: {
    types: ['RATE_PARTICIPANT_REQUEST', 'RATE_PARTICIPANT_SUCCESS', 'RATE_PARTICIPANT_FAILURE'],
    options: ({ rating }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: { rating },
    }),
  },
  resetRating: {
    types: ['RESET_RATING_REQUEST', 'RESET_RATING_SUCCESS', 'RESET_RATING_FAILURE'],
    options: {
      method: 'DELETE',
    },
  },
  denyList: {
    types: ['DENY_LIST_REQUEST', 'DENY_LIST_SUCCESS', 'DENY_LIST_FAILURE'],
    fetchAllPages: true,
    bustCache: true,
  },
  denyParticipant: {
    types: ['DENY_PARTICIPANT_REQUEST', 'DENY_PARTICIPANT_SUCCESS', 'DENY_PARTICIPANT_FAILURE'],
    options: {
      method: 'POST',
    },
    passToAction: ({ participantId }) => ({
      additionalReduction: (entities) => {
        const { businessConferenceRatedPossibleMeetings: ratings } = entities;
        if (ratings) Object.keys(ratings).forEach((ratingId) => {
          const { participant: { id } } = ratings[ratingId];
          if (id === participantId) delete ratings[ratingId];
        });
        return entities;
      },
    }),
  },
  allowParticipant: {
    types: ['ALLOW_PARTICIPANT_REQUEST', 'ALLOW_PARTICIPANT_SUCCESS', 'ALLOW_PARTICIPANT_FAILURE'],
    options: {
      method: 'DELETE',
    },
  },
  ratings: {
    types: ['RATINGS_REQUEST', 'RATINGS_SUCCESS', 'RATINGS_FAILURE'],
    fetchAllPages: true,
    bustCache: true,
  },
  recommendedParticipants: {
    types: ['RECOMMENDED_PARTICIPANTS_REQUEST', 'RECOMMENDED_PARTICIPANTS_SUCCESS', 'RECOMMENDED_PARTICIPANTS_FAILURE'],
    fetchAllPages: true,
  },
  singlePost: {
    types: ['SOCIAL_POST_REQUEST', 'SOCIAL_POST_SUCCESS', 'SOCIAL_POST_FAILURE'],
  },
  createSocialPost: {
    types: ['CREATE_SOCIAL_POST_REQUEST', 'CREATE_SOCIAL_POST_SUCCESS', 'CREATE_SOCIAL_POST_FAILURE'],
    options: ({ body }) => ({ // image, message
      method: 'POST',
      body,
    }),
  },
  updateSocialPost: {
    types: ['UPDATE_SOCIAL_POST_REQUEST', 'UPDATE_SOCIAL_POST_SUCCESS', 'UPDATE_SOCIAL_POST_FAILURE'],
    options: ({ likedBy, message }) => ({
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        liked_by: likedBy,
        message,
      },
    }),
    passToAction: ({ postId, likedBy, shouldRemoveLike }) => ({
      additionalReduction: (entities) => {
        const { socialPosts: { [postId]: updatedSocialPost } } = entities;
        if (shouldRemoveLike) updatedSocialPost.likedBy = updatedSocialPost
          .likedBy?.filter((attendeeUuid) => attendeeUuid !== likedBy);
        return entities;
      },
    }),
  },
  socialPosts: {
    types: ['SOCIAL_POSTS_REQUEST', 'SOCIAL_POSTS_SUCCESS', 'SOCIAL_POSTS_FAILURE'],
  },
  createPostComment: {
    types: ['CREATE_POST_COMMENT_REQUEST', 'CREATE_POST_COMMENT_SUCCESS', 'CREATE_POST_COMMENT_FAILURE'],
    options: ({ message }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: {
        message,
      },
    }),
  },
  postComments: {
    types: ['POST_COMMENTS_REQUEST', 'POST_COMMENTS_SUCCESS', 'POST_COMMENTS_FAILURE'],
  },
  deleteSocialPost: {
    types: ['DELETE_SOCIAL_POST_REQUEST', 'DELETE_SOCIAL_POST_SUCCESS', 'DELETE_SOCIAL_POST_FAILURE'],
    options: {
      method: 'DELETE',
    },
  },
  deletePostComment: {
    types: ['DELETE_POST_COMMENT_REQUEST', 'DELETE_POST_COMMENT_SUCCESS', 'DELETE_POST_COMMENT_FAILURE'],
    options: {
      method: 'DELETE',
    },
    passToAction: ({ postId }) => ({
      additionalReduction: (entities) => {
        const { socialPosts: { [postId]: updatedPost } } = entities;
        updatedPost.totalComments -= 1;
        return entities;
      },
    }),
  },
  attendeeLeads: {
    types: ['ATTENDEE_LEADS_REQUEST', 'ATTENDEE_LEADS_SUCCESS', 'ATTENDEE_LEADS_FAILURE'],
    fetchAllPages: true,
    bustCache: true,
  },
  createOrUpdateAttendeeLead: {
    types: [
      'CREATE_OR_UPDATE_ATTENDEE_LEAD_REQUEST',
      'CREATE_OR_UPDATE_ATTENDEE_LEAD_SUCCESS',
      'CREATE_OR_UPDATE_ATTENDEE_LEAD_FAILURE',
    ],
    options: ({ attendeeLead }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: { attendeeLead },
    }),
  },
  deleteAttendeeLead: {
    types: ['DELETE_ATTENDEE_LEAD_REQUEST', 'DELETE_ATTENDEE_LEAD_SUCCESS', 'DELETE_ATTENDEE_LEAD_FAILURE'],
    options: {
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    },
  },
  batchUpdateAttendeeLeads: {
    types: ['BATCH_UPDATE_ATTENDEE_LEADS_REQUEST', 'BATCH_UPDATE_ATTENDEE_LEADS_SUCCESS', 'BATCH_UPDATE_ATTENDEE_LEADS_FAILURE'],
    options: ({ attendeeLeads }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: { attendeeLeads },
    }),
  },
  downloadAttendeeLeads: {
    types: ['DOWNLOAD_ATTENDEE_LEADS_REQUEST', 'DOWNLOAD_ATTENDEE_LEADS_SUCCESS', 'DOWNLOAD_ATTENDEE_LEADS_FAILURE'],
    options: {
      method: 'POST',
    },
  },
  qaSessions: {
    types: ['QA_SESSIONS_REQUEST', 'QA_SESSIONS_SUCCESS', 'QA_SESSIONS_FAILURE'],
    fetchAllPages: true,
  },
  qaSessionQuestions: {
    types: ['QA_SESSION_QUESTIONS_REQUEST', 'QA_SESSION_QUESTIONS_SUCCESS', 'QA_SESSION_QUESTIONS_FAILURE'],
    fetchAllPages: true,
  },
  askQaQuestion: {
    types: ['ASK_QA_QUESTION_REQUEST', 'ASK_QA_QUESTION_SUCCESS', 'ASK_QA_QUESTION_FAILURE'],
    options: ({ question, anonymousQuestion }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { question, anonymousQuestion },
    }),
  },
  toggleQaQuestionLike: {
    types: ['TOGGLE_QA_QUESTION_LIKE_REQUEST', 'TOGGLE_QA_QUESTION_LIKE_SUCCESS', 'TOGGLE_QA_QUESTION_LIKE_FAILURE'],
    options: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    },
  },
  firebaseToken: {
    types: ['FIREBASE_TOKEN_REQUEST', 'FIREBASE_TOKEN_SUCCESS', 'FIREBASE_TOKEN_FAILURE'],
    options: {
      method: 'POST',
    },
  },
  twilioToken: {
    types: ['TWILIO_TOKEN_REQUEST', 'TWILIO_TOKEN_SUCCESS', 'TWILIO_TOKEN_FAILURE'],
    options: ({ forceMeeting }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: {
        increase_exp: forceMeeting && 'true',
      },
    }),
  },
  dailyToken: {
    types: ['DAILY_TOKEN_REQUEST', 'DAILY_TOKEN_SUCCESS', 'DAILY_TOKEN_FAILURE'],
    options: ({ forceWorkshop }) => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'POST',
      body: {
        increase_exp: forceWorkshop && 'true',
      },
    }),
  },
  demodeskToken: {
    types: ['DEMODESK_TOKEN_REQUEST', 'DEMODESK_TOKEN_SUCCESS', 'DEMODESK_TOKEN_FAILURE'],
    options: () => ({
      method: 'POST',
    }),
  },
  registrationToken: {
    types: ['REGISTRATION_TOKEN_REQUEST', 'REGISTRATION_TOKEN_SUCCESS', 'REGISTRATION_TOKEN_FAILURE'],
  },
};

const getDefaultOptions = (eventUri, { auth = true }) => {
  const token = getToken(eventUri);
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
    },
  };
  if (token && auth) defaultOptions.headers.Authorization = `bearer ${token}`;
  return defaultOptions;
};

export default (key, params, page) => (dispatch, getState) => {
  const config = fetchConfig[key];
  const state = getState();
  const eventUri = getEventUri();
  const { attendee_id: attendeeId } = getTokenContent(eventUri) || {};
  const filters = { ...state.filters[key] };
  const filterParams = config.parseFilters ? config.parseFilters(filters) : filters;
  if (config.bustCache) filterParams.bustCache = attendeeId;

  let { options, passToAction } = config;
  if (typeof options === 'function') options = options(params);
  if (typeof passToAction === 'function') passToAction = passToAction(params);

  const requestDetails = {
    types: config.types,
    endpoint: (config.fetchAllPages || !page) ? getEndpoint(key, params, filterParams) : page,
    passToAction: passToAction || {},
    options: merge(getDefaultOptions(eventUri, config), options),
  };

  if (config.fetchAllPages) requestDetails.fetchAllPages = true;
  else if (page) requestDetails.passToAction.extendOrder = true;

  return dispatch({
    [CALL_API]: requestDetails,
  });
};
