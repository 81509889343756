import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useStringTransforms from '../../hooks/useStringTransforms';

const useStyles = makeStyles((theme) => ({
  sender: {
    color: theme.palette.primary.light,
    fontSize: 10,
    fontWeight: 900,
  },
  content: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  info: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: -theme.spacing(0.5),
  },
}));

const DefaultBody = ({ type, sender, content, info }) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();

  return (
    <>
      {type !== 'own' && !!sender && (
        <Typography className={classes.sender}>{toTitleCase(sender)}</Typography>
      )}
      <Typography className={classes.content}>{content}</Typography>
      {type !== 'bot' && !!info && (
        <div className={classes.info}>{info}</div>
      )}
    </>
  );
};

DefaultBody.propTypes = {
  type: PropTypes.string.isRequired,
  sender: PropTypes.string,
  content: PropTypes.string.isRequired,
  info: PropTypes.string,
};

DefaultBody.defaultProps = {
  sender: undefined,
  info: undefined,
};

export default DefaultBody;
