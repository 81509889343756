import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  setFetchMethod,
} from 'darkreader';
import { getEventUri } from './getters';

const DARK_MODE_KEY = 'vs-darkMode';
const store = localStorage;

export const isInDarkMode = () => {
  const eventUri = getEventUri();
  const rawInfo = store.getItem(DARK_MODE_KEY);
  const storedDarkModes = rawInfo ? JSON.parse(rawInfo) : {};
  return !!storedDarkModes[eventUri];
};

export const applyDarkMode = () => {
  const darkMode = isInDarkMode();
  if (darkMode) enableDarkMode({
    brightness: 100,
    contrast: 100,
    sepia: 0,
  });
  else disableDarkMode();
};

export const setDarkMode = (darkMode) => {
  const eventUri = getEventUri();
  const rawInfo = store.getItem(DARK_MODE_KEY);
  const storedDarkModes = rawInfo ? JSON.parse(rawInfo) : {};
  storedDarkModes[eventUri] = darkMode;
  store.setItem(DARK_MODE_KEY, JSON.stringify(storedDarkModes));

  applyDarkMode();
};

setFetchMethod(window.fetch);
applyDarkMode();
