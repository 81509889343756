import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SocketContext from '../../contexts/SocketContext';
import {
  addMessages, addQuestion, addChannelFromMsg, addModeratedMessageAlert, addReaction,
  addAnnouncement, addPreviousMessages, addPrivateChannels, addCustomChannels,
  NEW_MESSAGE, NEW_QUESTION, NEW_CHAT_REQUEST, MODERATED_MESSAGE_RECEIVED,
  SEND_REACTION, NEW_ANNOUNCEMENT, NEW_CUSTOM_CHANNEL,
} from '../../actions/messages';

const mustJoinChannelWarning = () => () => {
  // eslint-disable-next-line no-console
  console.error('useChannel broadcast function cannot be invoked before the channel has been joined');
};

const useChannel = (channelTopic, disable) => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [broadcast, setBroadcast] = useState(mustJoinChannelWarning);

  useEffect(() => {
    const channel = socket.channel(channelTopic, { client: 'browser' });
    if (!disable && channelTopic) {
      channel.onMessage = (event, payload) => {
        switch (event) {
          case NEW_MESSAGE:
            dispatch(addMessages([payload], channelTopic));
            break;
          case NEW_QUESTION:
            dispatch(addQuestion(payload, channelTopic));
            break;
          case NEW_CHAT_REQUEST:
            dispatch(addChannelFromMsg(payload));
            break;
          case NEW_CUSTOM_CHANNEL:
            dispatch(addCustomChannels([payload.custom_channel]));
            break;
          case MODERATED_MESSAGE_RECEIVED:
            dispatch(addModeratedMessageAlert(payload, channelTopic));
            break;
          case SEND_REACTION:
            dispatch(addReaction(payload, channelTopic));
            break;
          case NEW_ANNOUNCEMENT:
            dispatch(addAnnouncement(payload));
            break;
          default:
        }
        return payload;
      };

      channel.join()
        .receive('ok', (payload) => {
          // eslint-disable-next-line no-console
          console.log(`successfully joined channel ${channelTopic}`);
          setBroadcast(() => channel.push.bind(channel));
          if (payload?.messages) dispatch(addPreviousMessages(payload.messages, channelTopic));
          // eslint-disable-next-line camelcase
          if (payload?.private_chats) dispatch(
            addPrivateChannels(payload.private_chats)
          );
          // eslint-disable-next-line camelcase
          if (payload?.custom_channels) dispatch(
            addCustomChannels(payload.custom_channels)
          );
        })
        .receive('error', ({ reason }) => {
          // eslint-disable-next-line no-console
          console.error('failed to join channel', reason);
        });
    }

    return () => {
      if (!disable && channelTopic) {
        // eslint-disable-next-line no-console
        console.log(`left channel ${channelTopic}`);
        channel.leave();
      }
      setBroadcast(mustJoinChannelWarning);
    };
  }, [channelTopic, socket, disable, dispatch]);

  return broadcast;
};

export default useChannel;
