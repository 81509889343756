import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useParams } from 'react-router-dom';
import useStringTransforms from '../../hooks/useStringTransforms';
import { identifyParticipants } from '../../helpers/auth';
import CustomPropTypes from '../../helpers/CustomPropTypes';

import Block from './Block';
import DownloadActivity from '../DownloadActivity';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightGrey.light,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.dark,
    },
  },
  blockContent: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& b': {
      fontWeight: 900,
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 22,
  },
  downloadIcon: {
    opacity: 1,
    '& svg': {
      opacity: 1,
      fill: theme.palette.primary.main,
      '&:hover': {
        fill: theme.palette.primary.dark,
      },
    },
  },
}));

const PendingMeeting = ({
  businessConferenceParticipants, pendingEvaluation, ...meeting
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const { eventUri } = useParams();
  const { t } = useTranslation('global', 'meeting');
  const { currentParticipant, otherParticipant } = identifyParticipants(
    eventUri,
    meeting,
    businessConferenceParticipants
  );
  const { host } = meeting || {};
  const { profileContactName, profileDisplayName } = otherParticipant;
  const isHost = (currentParticipant?.id === host?.id);
  const meetingTitle = t('meeting:meeting.with', { participant: toTitleCase(profileContactName) });
  const waitingTitle = isHost
    ? `${t('meeting:meeting.waitingMyConfirmation')}: ${toTitleCase(profileContactName)}`
    : `${t('meeting:meeting.waitingApproval', { participant: toTitleCase(profileContactName) })}
    ${profileDisplayName && (` | ${toTitleCase(profileDisplayName, true)}`)}`;
  const { id: meetingId, businessConferenceId } = meeting;

  const blockContent = (
    <Typography className={classes.blockContent} component="div">
      {pendingEvaluation ? (
        <>
          <AssignmentIcon className={classes.icon} />
          <div className={classes.text}>
            <b>{t('meeting:meeting.pendingEvaluation')}</b> {meetingTitle}
          </div>
        </>
      ) : (
        <>
          <InfoIcon className={classes.icon} />
          <div className={classes.text}>
            <b>{waitingTitle}</b>
          </div>
        </>
      )}
    </Typography>
  );

  return (
    <Block
      {...meeting}
      className={classes.root}
      tooltipTitle={blockContent}
      downloadActivity={pendingEvaluation ? (
        <DownloadActivity
          id={meetingId}
          name={meetingTitle}
          businessConferenceId={businessConferenceId}
          className={classes.downloadIcon}
        />
      ) : undefined}
      mainLine={blockContent}
      summaryLine={blockContent}
    />
  );
};

PendingMeeting.propTypes = {
  pendingEvaluation: PropTypes.bool,
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
};

PendingMeeting.defaultProps = {
  pendingEvaluation: false,
};

const mapStateToProps = ({
  entities: {
    businessConferenceParticipants,
  },
}) => ({
  businessConferenceParticipants,
});

export default connect(mapStateToProps)(PendingMeeting);
