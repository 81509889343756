import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useFitText from 'use-fit-text';

import Loader from 'eventtia-ui-components/lib/Loader';

import StandImages from './stands';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  standImage: {
    width: '100%',
  },
  logoContainer: {
    position: 'absolute',
    width: '15.5%',
    height: '13%',
    top: '28.5%',
    left: '13%',
    transform: 'skew(0, -30deg)',
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  sloganContainer: {
    position: 'absolute',
    width: '26%',
    height: '27%',
    top: '16%',
    left: '71.1%',
    transform: 'skew(0, 30deg)',
    overflow: 'hidden',
  },
  sloganText: {
    color: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    transition: 'opacity 0.3s',
  },
  hidden: {
    display: 'none',
  },
}));

const Slogan = ({ slogan, textColor, doneFitting }) => {
  const classes = useStyles();
  const { fontSize, ref } = useFitText({ maxFontSize: 400, minFontSize: 8, logLevel: 'info' });

  return (
    <div
      className={classes.sloganText}
      ref={ref}
      style={{ color: textColor, fontSize, opacity: (doneFitting ? 1 : 0) }}
    >
      {slogan}
    </div>
  );
};

Slogan.propTypes = {
  slogan: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  doneFitting: PropTypes.bool.isRequired,
};

const Stand = ({
  className, standImageIndex, slogan,
  sloganTextColor, logo,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isDoneFitting, setIsDoneFitting] = useState(false);

  const standImage = StandImages[standImageIndex];

  const handleLoadingDone = () => setIsLoading(false);

  useEffect(() => {
    if (isLoading || isDoneFitting) return;
    setTimeout(() => {
      setIsDoneFitting(true);
    }, 100);
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <Loader
          loading
        />
      )}
      <div className={clsx(isLoading && classes.hidden, classes.root, className)}>
        <img
          className={classes.standImage}
          src={standImage}
          alt=""
          onLoad={handleLoadingDone}
        />

        {!!logo.name?.trim() && (
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo.image} alt={logo.name} />
          </div>
        )}

        <div className={classes.sloganContainer}>
          <Slogan
            slogan={slogan}
            textColor={sloganTextColor}
            doneFitting={isDoneFitting}
          />
        </div>
      </div>
    </>
  );
};

Stand.propTypes = {
  className: PropTypes.string,
  standImageIndex: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }),
  sloganTextColor: PropTypes.string.isRequired,
  slogan: PropTypes.string,
};

Stand.defaultProps = {
  className: '',
  slogan: '',
  logo: {
    image: '',
    name: '',
  },
};

export default Stand;
