export const SET_VIEWED_ATTENDEE = 'SET_VIEWED_ATTENDEE';
export const UPDATE_VIEWED_ATTENDEES = 'UPDATE_VIEWED_ATTENDEES';

export const setViewedAttendee = (viewedAttendeeId) => ({
  type: SET_VIEWED_ATTENDEE,
  payload: { viewedAttendeeId },
});

export const updateViewedAttendees = (viewedAttendees) => ({
  type: UPDATE_VIEWED_ATTENDEES,
  payload: { viewedAttendees },
});
