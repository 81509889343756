import { SET_VIEWED_ATTENDEE, UPDATE_VIEWED_ATTENDEES } from '../../actions/viewedAttendees';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEWED_ATTENDEE: {
      const { viewedAttendeeId } = action.payload;

      return {
        ...state,
        [viewedAttendeeId]: true,
      };
    }
    case UPDATE_VIEWED_ATTENDEES: {
      const { viewedAttendees } = action.payload;

      return {
        ...state,
        ...viewedAttendees,
      };
    }
    default:
      return state;
  }
};
