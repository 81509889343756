/* eslint-disable comma-dangle */
export default {
  en: {
    title: {
      speakers: 'Speakers',
      description: 'Get to know all the event speakers.',
    },
    biography: {
      title: 'Biography',
    },
  },
  es: {
    title: {
      speakers: 'Ponentes',
      description: 'Conoce a todos los ponentes del evento.',
    },
    biography: {
      title: 'Biografía',
    },
  },
  fr: {
    title: {
      speakers: 'Intervenants',
      description: 'Découvrez les intervenants de l\'événement.',
    },
    biography: {
      title: 'Biographie',
    },
  },
  ca: {
    title: {
      speakers: 'Ponents',
      description: 'Coneix tots els ponents de l’esdeveniment.',
    },
    biography: {
      title: 'Biografia',
    },
  },
  pt: {
    title: {
      speakers: 'Palestrantes',
      description: 'Conheça todos os palestrantes do evento.',
    },
    biography: {
      title: 'Biografia',
    },
  },
  it: {
    biography: {
      title: 'Biografia'
    },
    title: {
      speakers: 'altoparlanti',
      description: "Conosci tutti i relatori dell'evento."
    }
  },
  ja: {
    biography: {
      title: '経歴'
    },
    title: {
      description: 'すべてのイベントスピーカーを知る。',
      speakers: 'スピーカー'
    }
  },
  zh: {
    title: {
      speakers: '演讲者',
      description: '了解所有活动发言人。'
    },
    biography: {
      title: '传记'
    }
  },
  ar: {
    title: {
      speakers: 'مكبرات الصوت',
      description: 'تعرف على جميع المتحدثين بالحدث.',
    },
    biography: {
      title: 'سيرة',
    },
  },
  tr: {
    title: {
      speakers: 'Hoparlörler',
      description: 'Tüm Etkinlik konuşmacılarını tanıyın.',
    },
    biography: {
      title: 'Biyografi',
    },
  },
  de: {
    title: {
      speakers: 'Lautsprecher',
      description: 'Lernen Sie alle Redner der Veranstaltung kennen.'
    },
    biography: {
      title: 'Biografie'
    }
  },
};
