const localeReplacementTable = {
  pt: 'es',
  it: 'fr',
  ja: 'es',
  zh: 'fr',
  ar: 'es',
  tr: 'fr',
  de: 'es',
  ca: 'fr',
};

export default localeReplacementTable;
