import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CampariSocialMediaIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m803.28 112.44h-406.56c-54.48 0-98.879 44.398-98.879 98.879v777.48c0 54.48 44.398 98.879 98.879 98.879h406.44c54.48 0 98.879-44.398 98.879-98.879v-777.48c0-54.48-44.277-98.879-98.758-98.879zm55.559 875.64c0 30.961-25.199 56.281-56.281 56.281h-405.24c-30.961 0-56.281-25.199-56.281-56.281v-776.28c0-30.961 25.199-56.281 56.281-56.281h90.84v24.48c0 16.559 13.441 30 30 30h163.68c16.559 0 30-13.441 30-30v-24.48h90.719c30.961 0 56.281 25.199 56.281 56.281z" />
        <path d="m711.72 442.8h-223.44c-42.602 0-77.16 34.559-77.16 77.16v133.92c0 42.602 34.559 77.16 77.16 77.16h43.922l53.52 62.398c7.4414 8.7617 21 8.7617 28.441 0l53.52-62.398h43.922c42.602 0 77.16-34.559 77.16-77.16l-0.003906-133.92c0.11719-42.723-34.441-77.16-77.043-77.16zm-46.918 158.88c-0.12109 0.12109-11.641 16.559-35.641 34.922-8.2812 6.2383-17.879 12.84-29.16 18.961-11.281-6.2383-21-12.719-29.16-18.961-24.121-18.359-35.52-34.801-35.641-35.039-15-22.801-15.602-42.961-1.6797-59.762 8.3984-10.078 20.52-16.078 33.48-16.68h1.6797c15.121 0 24.359 7.9219 27.719 11.281l3.6016 3.7188 3.7188-3.7188c3.3594-3.3594 12.602-11.281 27.719-11.281h1.6797c12.961 0.48047 25.078 6.6016 33.48 16.68 13.922 16.801 13.324 36.961-1.7969 59.879z" />
      </g>
    </svg>
  </SvgIcon>
);

export default CampariSocialMediaIcon;
