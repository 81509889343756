/* eslint-disable comma-dangle */
export default {
  en: {
    notificationMessage: {
      title: 'Important!',
      description: 'You still haven\'t verified the compatibility of your browser with our video and networking technology. To avoid errors in your meetings, check your browser as soon as possible.',
      button: 'Verify',
      buttonIgnore: 'Ignore',
    },
    settingsModal: {
      title: 'Audio & Video preview',
      description: 'If you can view yourself on screen, your browser is compatible. If you are not able to, please use the latest version of ',
      button: 'Done',
    },
  },
  es: {
    notificationMessage: {
      title: 'Importante!',
      description: 'Aún no has verificado la compatibilidad de tu navegador con nuestra tecnología de vídeo y networking. Para evitar errores en tus citas, verifica tu navegador lo antes posible.',
      button: 'Verificar',
      buttonIgnore: 'Ignorar',
    },
    settingsModal: {
      title: 'Opciones de Audio y Video',
      description: 'Si te puedes observar en la pantalla, el navegador es compatible. De lo contrario porfavor usa la última version de ',
      button: 'Finalizar',
    },
  },
  fr: {
    notificationMessage: {
      title: 'Important!',
      description: 'Vous n\'avez pas vérifié la compatibilité de votre navigateur avec notre technologie de vidéo et networking. Pour éviter les erreurs lors de vos réunions, vérifiez votre navigateur dès que possible.',
      button: 'vérifier',
      buttonIgnore: 'ignorer',
    },
    settingsModal: {
      title: 'Paramètres Audio & Vidéo',
      description: 'Si vous pouvez vous visualiser à l\'écran, votre navigateur est compatible. Si vous ne pouvez pas, veuillez utiliser la dernière version de ',
      button: 'Terminé',
    },
  },
  ca: {
    notificationMessage: {
      title: 'Important!',
      description:
        'Encara no has verificat la compatibilitat del teu navegador amb la nostra tecnologia de vídeo i de networking. Per evitar errors en les teves reunions, comprova el teu navegador al més aviat possible',
      button: 'Verifica',
      buttonIgnore: 'Ignora',
    },
    settingsModal: {
      title: "Vista prèvia d'àudio i vídeo",
      description:
        "Si pots veure't en pantalla, el teu navegador és compatible. Si no, per favor usa'n la darrera versió",
      button: 'Fet',
    },
  },
  pt: {
    notificationMessage: {
      title: 'Importante!',
      description: 'Você ainda não verificou a compatibilidade do seu navegador com nossa tecnologia de vídeo e rede. Para evitar erros em suas reuniões, verifique o seu navegador o mais rápido possível.',
      button: 'Verificar',
      buttonIgnore: 'Ignorar',
    },
    settingsModal: {
      title: 'Visualização de áudio e vídeo',
      description: 'Se você pode ver a si mesmo na tela, seu navegador é compatível. Se você não conseguir, por favor use a versão mais recente do ',
      button: 'Feito',
    },
  },
  it: {
    settingsModal: {
      button: 'Fatto',
      title: 'Anteprima audio e video',
      description: 'Se riesci a visualizzarti sullo schermo, il tuo browser è compatibile. Se non sei in grado di farlo, utilizza la versione più recente di '
    },
    notificationMessage: {
      title: 'Importante!',
      description: 'Non hai ancora verificato la compatibilità del tuo browser con la nostra tecnologia video e di rete. Per evitare errori nelle riunioni, controlla il browser il prima possibile.',
      button: 'Verifica',
      buttonIgnore: 'Ignora'
    }
  },
  ja: {
    notificationMessage: {
      title: '重要！',
      buttonIgnore: '無視',
      button: '検証',
      description: 'お使いのブラウザと当社のビデオおよびネットワーク技術との互換性がまだ確認されていません。会議でエラーが発生しないように、ブラウザをできるだけ早く確認してください。'
    },
    settingsModal: {
      button: '完了',
      title: 'オーディオとビデオのプレビュー',
      description: '画面上で自分自身を表示できる場合、お使いのブラウザは互換性があります。できない場合は、最新バージョンの '
    }
  },
  zh: {
    notificationMessage: {
      buttonIgnore: '忽略',
      button: '验证',
      title: '重要！',
      description: '您还没有验证浏览器与我们的视频和网络技术的兼容性。为避免会议出现错误，请尽快检查浏览器。'
    },
    settingsModal: {
      title: '音频和视频预览',
      button: '完成',
      description: '如果可以在屏幕上查看自己，则说明您的浏览器是兼容的。如果你不能，请使用最新版本的 '
    }
  },
  ar: {
    notificationMessage: {
      title: 'مهم!',
      description: 'ما زلت لم تتحقق من توافق متصفحك مع تقنية الفيديو والشبكات الخاصة بنا. لتجنب الأخطاء في اجتماعاتك، تحقق من متصفحك في أقرب وقت ممكن.',
      button: 'تحقق',
      buttonIgnore: 'تجاهل',
    },
    settingsModal: {
      title: 'معاينة الصوت والفيديو',
      description: 'إذا كان بإمكانك عرض نفسك على الشاشة، فإن متصفحك متوافق. إذا لم تكن قادرًا على ذلك، فيرجى استخدام أحدث إصدار من ',
      button: 'تم',
    },
  },
  tr: {
    notificationMessage: {
      title: 'Önemli!',
      description: 'Tarayıcınızın video ve ağ teknolojimizle uyumluluğunu hala doğrulamadınız. Toplantılarınızdaki hataları önlemek için tarayıcınızı mümkün olan en kısa sürede kontrol edin.',
      button: 'Doğrulayın',
      buttonIgnore: 'Yoksay'
    },
    settingsModal: {
      title: 'Ses ve Video önizleme',
      description: 'Ekranda kendinizi görüntüleyebiliyorsanız, tarayıcınız uyumludur. Eğer yapamıyorsanız, lütfen en son sürümünü kullanın ',
      button: 'Bitti'
    }
  },
  de: {
    notificationMessage: {
      title: 'Wichtig!',
      description: 'Sie haben die Kompatibilität Ihres Browsers mit unserer Video- und Netzwerktechnologie immer noch nicht überprüft. Um Fehler in Ihren Meetings zu vermeiden, überprüfen Sie so schnell wie möglich Ihren Browser.',
      button: 'Verifizieren',
      buttonIgnore: 'ignoriere'
    },
    settingsModal: {
      title: 'Audio- und Videovorschau',
      description: 'Wenn Sie sich auf dem Bildschirm sehen können, ist Ihr Browser kompatibel. Wenn Sie nicht in der Lage sind, verwenden Sie bitte die neueste Version von ',
      button: 'Geschehen'
    }
  },
};
