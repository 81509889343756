export const buildPrivateTopic = (id1, id2) => {
  if (!id1 || !id2) return undefined;
  const id1num = parseInt(id1, 10);
  const id2num = parseInt(id2, 10);
  return `private:${Math.min(id1num, id2num)}-${Math.max(id1num, id2num)}`;
};

export const buildCustomTopic = (eventUri, uuid) => `custom:${eventUri}:${uuid}`;

export const extractOtherAttendeeId = (topic, currentAttendeeId) => {
  const [id1, id2] = topic.split(':')[1].split('-');
  return currentAttendeeId === id1 ? id2 : id1;
};

export const unreadCount = (allEntries, channelTopic, lastRead) => {
  const channelEntries = allEntries[channelTopic] || [];
  const uuids = channelEntries.map(({ uuid }) => uuid);
  return channelEntries.length - uuids.indexOf(lastRead[channelTopic]) - 1;
};

const store = localStorage;

// Local storage HACK for open channels
// TODO: delete in the future
const CHANNELS_KEY = 'v-stage-channels';
store.removeItem(CHANNELS_KEY);

// Local storage HACK for read messages
const READ_MESSAGES_KEY = 'v-stage-read';

export const saveLastReadMessages = (eventUri, channel, messageUuid) => {
  const rawReadMessages = store.getItem(READ_MESSAGES_KEY);
  const readMessages = rawReadMessages ? JSON.parse(rawReadMessages) : {};
  const eventReadMessages = readMessages[eventUri] || {};
  if (eventReadMessages[channel] !== messageUuid) {
    eventReadMessages[channel] = messageUuid;
    readMessages[eventUri] = eventReadMessages;
    store.setItem(READ_MESSAGES_KEY, JSON.stringify(readMessages));
  }
};

export const getLastReadMessages = (eventUri) => {
  const rawReadMessages = store.getItem(READ_MESSAGES_KEY);
  if (!rawReadMessages) return {};
  return JSON.parse(rawReadMessages)[eventUri] || {};
};

export const canChat = (appSettings, attendeeTypeId) => {
  const {
    enabled,
    customParams: { chatEnabledParticipantTypes },
  } = appSettings.modules.find(({ type }) => type === 'Attendees');
  return (
    enabled
    && chatEnabledParticipantTypes?.includes(attendeeTypeId)
  );
};
