import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Card from 'eventtia-ui-components/lib/Card';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { setActiveWorkshop } from '../../actions/app';
import { baseMoment, getEventTimeFormat } from '../../helpers/dates';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.light,
    },
    transition: theme.transitions.create([
      'background-color',
      'border-color',
      'opacity',
    ], {
      duration: theme.transitions.duration.standard,
    }),
  },
  text: {
    overflow: 'hidden',
  },
  timespan: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: '1.3em',
      marginRight: theme.spacing(0.5),
    },
    name: {
      color: theme.palette.darkGrey.light,
    },
  },
  buttonBox: {
    width: '100%',
    overflow: 'hidden',
    border: 'none',
    boxShadow: theme.customShadows.small,
    textAlign: 'left',
    minWidth: 0,
    padding: 0,
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    borderRadius: theme.spacing(2.25),
  },
}));

const TimestampCard = ({
  events,
  setActiveWorkshop: dispatchSetActiveWorkshop,
  id, name, startDate, endDate,
}) => {
  const classes = useStyles();
  const timeFormat = getEventTimeFormat(events);
  const { locale } = useParams();
  const dateFormat = locale === 'fr' ? 'Do MMM' : 'MMM Do';
  const onClick = () => dispatchSetActiveWorkshop(id);
  return (
    <button
      className={classes.buttonBox}
      onClick={onClick}
      type="button"
    >
      <Card className={classes.card}>
        <div className={classes.text}>
          <Typography className={classes.timespan}>
            <AccessTimeIcon />
            <span>{baseMoment(startDate).format(`${dateFormat}, ${timeFormat}`)} - {baseMoment(endDate).format(timeFormat)}</span>
          </Typography>
          <Typography className={classes.name} component="div">
            {name}
          </Typography>
        </div>
      </Card>
    </button>
  );
};

TimestampCard.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  setActiveWorkshop: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

TimestampCard.defaultProps = {
};

const mapStateToProps = ({
  entities: {
    events,
  },
}) => ({
  events,
});

export default connect(mapStateToProps, { setActiveWorkshop })(TimestampCard);
