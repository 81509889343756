import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Input from 'eventtia-ui-components/lib/Input';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Popper from '@material-ui/core/Popper';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import { useParams } from 'react-router-dom';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useStringTransforms from '../../hooks/useStringTransforms';
import { baseMoment, getEventTimeFormat } from '../../helpers/dates';
import callApi from '../../actions/callApi';
import Block from './Block';
import FloatingDialog from '../FloatingDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '& *': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondaryColorBg: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  disabledRoot: {
    backgroundColor: theme.palette.lightGrey.dark,
    cursor: 'auto',
  },
  errorIcon: {
    fontSize: 18,
    marginLeft: theme.spacing(1),
    verticalAlign: 'text-bottom',
  },
  tooltip: {
    maxWidth: 'none',
  },
  popper: {
    zIndex: 2000,
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.darkGrey.light,
    margin: theme.spacing(2, 0),
  },
  time: {
    marginLeft: theme.spacing(1),
  },
}));

const AvailableSlot = (props) => {
  const {
    businessConferenceParticipants, businessConferences, participantId, startDate, endDate, events,
    callApi: dispatchCallApi, id, slotId, requesting, disabled, secondaryAction, creatingRequest,
    slotHasRequests,
  } = props;
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const { eventUri } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const openDialog = (event) => setAnchorEl(event.currentTarget);
  const closeDialog = () => setAnchorEl(null);
  const messageRef = useRef(null);
  const timeFormat = getEventTimeFormat(events);

  const { t } = useTranslation('meeting');
  const {
    profileContactName,
    businessConference: { id: businessConferenceId },
  } = businessConferenceParticipants[participantId];
  const { multipleRequestsEnabled } = businessConferences[businessConferenceId];

  const requestMeeting = () => {
    dispatchCallApi(multipleRequestsEnabled ? 'createMeetingRequest' : 'requestMeeting', {
      eventUri,
      businessConferenceId,
      participantId,
      slotId: multipleRequestsEnabled ? slotId : id,
      message: messageRef.current.value,
      // appRecommendation,
      // lastMinute,
    }).then((response) => {
      if (response.error) {
        let completeErrorMsg = '';
        Object.values(response.error).forEach((currentError) => {
          if (currentError) completeErrorMsg = !completeErrorMsg ? currentError : `${completeErrorMsg}\n${currentError}`;
        });
        if (completeErrorMsg) secondaryAction({ type: 'error', text: completeErrorMsg });
      } else secondaryAction({
        type: 'success',
        text: t('meeting.meetingRequestMessage',
          { participant: toTitleCase(profileContactName) }),
      });
      closeDialog();
    });
  };

  const requestTooltip = (
    <Tooltip
      className={classes.tooltip}
      placement="top-start"
      title={(
        <Typography>
          {t('meeting.slotHasRequests')}
        </Typography>
      )}
    >
      <ErrorIcon className={classes.errorIcon} />
    </Tooltip>
  );

  return (
    <>
      <Block
        {...props}
        className={clsx(disabled ? classes.disabledRoot : classes.root,
          slotHasRequests && classes.secondaryColorBg)}
        onClick={openDialog}
        mainLine={(
          <>
            {t('meeting.requestMeeting')}
            {slotHasRequests && requestTooltip}
          </>
          )}
        summaryLine={(
          <>
            {t('meeting.requestMeeting')}
            {slotHasRequests && requestTooltip}
          </>
          )}
      />
      <Popper className={classes.popper} open={!!anchorEl} anchorEl={anchorEl}>
        <FloatingDialog
          closeDialog={closeDialog}
          loading={requesting || creatingRequest}
          title={t('meeting.with', { participant: toTitleCase(profileContactName) })}
          action={{
            name: t('meeting.sendRequest'),
            onClick: requestMeeting,
          }}
        >
          <div className={classes.timeContainer}>
            <AccessTimeIcon />
            <Typography className={classes.time}>
              {baseMoment(startDate).format(`MMMM Do YYYY ${timeFormat}`)} - {baseMoment(endDate).format(timeFormat)}
            </Typography>
          </div>
          <Input
            style={{ padding: '16px' }}
            label={t('meeting.message')}
            id="meeting-message-input"
            name="meeting-message-input"
            inputRef={messageRef}
            multiline
            rows={3}
            rowsMax={6}
          />
        </FloatingDialog>
      </Popper>
    </>
  );
};

AvailableSlot.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  id: PropTypes.string.isRequired,
  slotId: PropTypes.number.isRequired,
  participantId: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  secondaryAction: PropTypes.func,
  endDate: PropTypes.string.isRequired,
  callApi: PropTypes.func.isRequired,
  requesting: PropTypes.bool.isRequired,
  creatingRequest: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  businessConferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ).isRequired,
  slotHasRequests: PropTypes.bool,
};

AvailableSlot.defaultProps = {
  secondaryAction: undefined,
  slotHasRequests: false,
};

const mapStateToProps = ({
  entities: {
    events,
    businessConferenceParticipants,
    businessConferences,
  },
  fetchStatus: {
    requestMeeting: { isFetching: requesting },
    createMeetingRequest: { isFetching: creatingRequest },
  },
}) => ({
  events,
  businessConferenceParticipants,
  businessConferences,
  requesting,
  creatingRequest,
});

export default connect(mapStateToProps, { callApi })(AvailableSlot);
