import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from 'eventtia-ui-components/lib/Button';
import withForm from '../../hocs/withForm';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import CustomField from '../CustomField';
import CanvasField from '../CanvasField';
import { getCurrentAttendee } from '../../helpers/getters';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  hiddenField: {
    display: 'none',
  },
}));

const HackedMeetingEvaluationForm = ({
  registerInput, errors, entities, customFields, setFormValue, disabled,
  watchFormValues, setError, customErrors, clearError, survey,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const theme = useTheme();
  const surveyId = survey.id;
  const submitButtonStyles = {
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    textTransform: 'capitalize',
  };

  const [expandedForm, setExpandedForm] = useState();

  const customFieldIds = useMemo(() => (
    survey?.customFields.map(({ id }) => (id))
  ), []);

  const fieldsToHide = useMemo(() => {
    if (surveyId === '6714') return ['377496', '377497', '377498', '377499', '377500'];
    if (surveyId === '6708') return ['376798', '376799', '376800', '376801', '376802', '376803', '376804', '376805', '376806'];
    if (surveyId === '6752') return ['379654', '379655', '379656', '379665', '379666', '379667'];
    if (surveyId === '6751') return ['379624', '379657', '379658', '379659', '379660', '379661', '379662', '379663'];
    if (surveyId === '6754') return ['379684', '379685', '379686', '379687', '379688', '379689'];
    if (surveyId === '6855') return ['382366', '382367', '382368', '382369', '382370', '382371', '382372', '382373', '382374'];
    if (surveyId === '6893') return ['383997', '384028', '384032', '384029', '384030', '384031'];
    if (surveyId === '6884') return ['383976', '383977', '383978', '383979', '383980', '383981', '383982', '383983', '383984'];
    if (surveyId === '6912') return ['385145', '385146', '385147', '385148', '385159', '385172', '385177', '385179', '385189'];
    if (surveyId === '6907') return ['385022', '385023', '385024', '385025', '385026', '385027'];
    if (surveyId === '6885') return ['392042', '392043', '392044', '392045', '392046', '392047'];
    if (surveyId === '7124') return ['397322', '397327', '397328', '397329', '397330', '397331', '397347'];
    if (surveyId === '7206') return ['402439', '402440', '402441', '402444', '402446', '402447'];
    if (surveyId === '7288') return ['405946', '405947', '405948', '405949', '405950', '405951', '405952', '405953', '405954'];
    if (surveyId === '7289') return ['405955', '405956', '405957', '405958'];
    if (surveyId === '7292') return ['406101', '406102', '406103', '406104', '406105', '406106', '406107', '406108'];
    if (surveyId === '7341') return ['409375', '409376', '409377', '409378', '409387', '409388', '409389', '409390', '410107', '410108'];
    if (surveyId === '7342') return ['409319', '409320', '409321', '409322', '409323', '409328'];
    if (surveyId === '7612') return ['416369', '416370', '416371', '416372', '416373', '416374', '416375', '416376', '416377'];
    if (surveyId === '7665') return ['417771', '417772', '417773', '417774', '417775', '417792', '417793', '417794', '417795', '417796', '417797', '417802', '417803', '417804'];
    return [];
  }, []);

  const enabledSignature = useMemo(() => (
    survey?.enabledSignature
  ), [survey?.enabledSignature]);

  const currentAttendee = getCurrentAttendee(entities);

  useEffect(() => {
    customErrors.forEach(({ name, type, message }) => setError(name, { type, message }));
  }, [customErrors]);

  useEffect(() => {
    if (expandedForm === 'hidden' && setFormValue) if (surveyId === '6708') {
      setFormValue('_376798', 'NA');
      setFormValue('_376799', 'NA');
      setFormValue('_376800', 'No');
      // setFormValue('_376801', );
      setFormValue('_376802', 0);
      setFormValue('_376803', 0);
      setFormValue('_376804', 0);
      setFormValue('_376805', 0);
      setFormValue('_376806', '');
    } else if (surveyId === '6714') {
      // setFormValue('_377496', );
      setFormValue('_377497', 'NA');
      setFormValue('_377498', 'NA');
      setFormValue('_377499', 'NA');
      setFormValue('_377500', 'No');
    } else if (surveyId === '7665') {
      setFormValue('_417771', 'No');
      setFormValue('_417772', 'No');
      setFormValue('_417774', 0);
      setFormValue('_417775', 0);
      setFormValue('_417792', 'Vacacional');
      setFormValue('_417793', 'Alojamiento - únicamente noches');
      setFormValue('_417794', 'Bodas destino');
      setFormValue('_417795', 'Alojamiento - únicamente noches');
      setFormValue('_417796', 'NA');
      setFormValue('_417802', 0);
      setFormValue('_417803', 0);
      setFormValue('_417804', 'NA');
    } else if (surveyId === '6752') {
      setFormValue('_379654', 'No');
      setFormValue('_379655', 'No');
      setFormValue('_379656', '< 6 Meses');
      setFormValue('_379665', 'NA');
      setFormValue('_379666', 0);
      setFormValue('_379667', 'NA');
    } else if (surveyId === '6751') {
      setFormValue('_379624', 'Sistema moda/Textile &  Apparel');
      setFormValue('_379657', 'NA');
      // setFormValue('_379658', 'NA');
      setFormValue('_379659', 0);
      setFormValue('_379660', 0);
      setFormValue('_379661', 0);
      setFormValue('_379662', 0);
      setFormValue('_379663', 'NA');
    } else if (surveyId === '6754') {
      setFormValue('_379684', 'No');
      setFormValue('_379685', 'No');
      setFormValue('_379686', '< 6 Meses/<6 Months');
      setFormValue('_379687', 'NA');
      setFormValue('_379688', 0);
      setFormValue('_379689', '');
    } else if (surveyId === '6855') {
      setFormValue('_382366', 'METALMECÁNICA Y OTRAS INDUSTRIAS');
      setFormValue('_382367', 'NA');
      setFormValue('_382368', 'NO');
      setFormValue('_382370', 0);
      setFormValue('_382371', 0);
      setFormValue('_382372', 0);
      setFormValue('_382373', 0);
      setFormValue('_382374', 'NA');
    } else if (surveyId === '6893') {
      setFormValue('_383997', 'NO/NÃO');
      setFormValue('_384028', 'NO/NÃO');
      setFormValue('_384032', '< 6 Meses');
      setFormValue('_384029', 'NA');
      setFormValue('_384030', 0);
      setFormValue('_384031', 'NA');
    } else if (surveyId === '6884') {
      setFormValue('_383976', 'Animación/Animation');
      setFormValue('_383977', 'NA');
      setFormValue('_383978', 'NO');
      setFormValue('_383980', 0);
      setFormValue('_383981', 0);
      setFormValue('_383982', 0);
      setFormValue('_383983', 0);
      setFormValue('_383984', 'NA');
    } else if (surveyId === '6912') {
      setFormValue('_385145', 'Naturaleza');
      setFormValue('_385146', 'NA');
      setFormValue('_385147', 'No');
      setFormValue('_385159', 0);
      setFormValue('_385172', 0);
      setFormValue('_385177', 0);
      setFormValue('_385179', 0);
      setFormValue('_385189', 'NA');
    } else if (surveyId === '6907') {
      setFormValue('_385022', 'No');
      setFormValue('_385023', 'No');
      setFormValue('_385024', '< 6 Meses');
      setFormValue('_385025', 'OTRO');
      setFormValue('_385026', 0);
      setFormValue('_385027', 'NA');
    } else if (surveyId === '6885') {
      setFormValue('_392042', 'No');
      setFormValue('_392043', 'No');
      setFormValue('_392044', '< 6 Months');
      setFormValue('_392045', 'NA');
      setFormValue('_392046', 0);
      setFormValue('_392047', 'NA');
    } else if (surveyId === '7124') {
      setFormValue('_397322', 'No');
      setFormValue('_397327', 'No');
      setFormValue('_397328', '2nd semester 2021');
      setFormValue('_397329', 'NA');
      setFormValue('_397330', 0);
      setFormValue('_397331', 0);
      setFormValue('_397347', 'NA');
    } else if (surveyId === '7206') {
      setFormValue('_402439', 'No');
      setFormValue('_402440', 'No');
      setFormValue('_402441', '< 6 Meses / < 6 Months');
      setFormValue('_402444', 'NA');
      setFormValue('_402446', 0);
      setFormValue('_402447', 'NA');
    } else if (surveyId === '7288') {
      setFormValue('_405946', 'Agroalimentos');
      setFormValue('_405947', 'NA');
      setFormValue('_405948', 'No');
      setFormValue('_405950', 0);
      setFormValue('_405951', 0);
      setFormValue('_405952', 0);
      setFormValue('_405953', 0);
      setFormValue('_405954', 'NA');
    } else if (surveyId === '7289') {
      setFormValue('_405955', 'Agroalimentos');
      setFormValue('_405956', 'NA');
      setFormValue('_405957', 'NA');
      setFormValue('_405958', 'No');
    } else if (surveyId === '7292') {
      setFormValue('_406101', 'Agroalimentos');
      setFormValue('_406102', 'NA');
      setFormValue('_406104', 0);
      setFormValue('_406105', 0);
      setFormValue('_406106', 0);
      setFormValue('_406107', 0);
      setFormValue('_406108', 'NA');
    } else if (surveyId === '7341') {
      setFormValue('_409375', 'No');
      setFormValue('_409376', 'No');
      setFormValue('_409377', '< 6 Meses / < 6 Months');
      setFormValue('_409378', 'Vacacional/Lisure');
      setFormValue('_409387', 'NA');
      setFormValue('_409388', 'NA');
      setFormValue('_409389', 0);
      setFormValue('_410108', 0);
      setFormValue('_409390', 'NA');
    } else if (surveyId === '7342') {
      setFormValue('_409319', 'No');
      setFormValue('_409320', 'No');
      setFormValue('_409321', '< 6 Meses');
      setFormValue('_409322', 'Proveedores de Turismo');
      setFormValue('_409323', 0);
      setFormValue('_409328', 'NA');
    } else if (surveyId === '7612') {
      setFormValue('_416369', 'AGROALIMENTOS');
      setFormValue('_416370', 'NA');
      setFormValue('_416371', 'No');
      setFormValue('_416373', 0);
      setFormValue('_416374', 0);
      setFormValue('_416375', 0);
      setFormValue('_416376', 0);
      setFormValue('_416377', 'NA');
    }
  }, [expandedForm, setFormValue]);

  useEffect(() => {
    registerInput({ name: 'signature' });
  }, [registerInput]);

  const affirmativeAnswers = ['Si/Yes', 'Si / Yes', 'Si', 'SI', 'SI/SIM', 'SI/YES', 'Yes'];
  const negativeAnswers = ['NO/NÃO', 'No', 'NO'];
  const conditionalFields = ['376797', '378884', '379653', '379623', '379683', '382365', '383994', '383975', '385144', '385021', '392041', '397321', '402438', '406081', '406097', '406100', '409374', '409318', '416368', '417770'];
  return (
    <>
      <Grid container spacing={2}>
        {customFieldIds.map((fieldId) => {
          const field = customFields[fieldId];
          if (field) {
            const { optionsStatus: { required }, ...otherProps } = field;
            const parsedField = {
              required,
              ...otherProps,
            };
            const answersErrors = errors?.answers || {};
            return (
              <Grid
                item
                xs={12}
                key={fieldId}
                className={(!expandedForm || expandedForm === 'hidden')
                  && fieldsToHide.includes(fieldId) ? classes.hiddenField : undefined}
              >
                <CustomField
                  getIdentifier={(id) => `_${id}`}
                  field={parsedField}
                  value={currentAttendee.fields[fieldId]}
                  inputRef={registerInput}
                  watchFormValues={watchFormValues}
                  error={answersErrors[`_${fieldId}`]?.types?.message}
                  setFormValue={conditionalFields.includes(fieldId) ? (id, val) => {
                    setFormValue(id, val);
                    if (affirmativeAnswers.includes(val)) setExpandedForm('visible');
                    if (negativeAnswers.includes(val)) setExpandedForm('hidden');
                  } : setFormValue}
                />
              </Grid>
            );
          }
          return null;
        })}
        {enabledSignature && (
          <CanvasField
            handleChange={(val) => { setFormValue('signature', val); }}
            label={t('forms.signature')}
            error={errors.signature?.types?.message}
          />
        )}
        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            type="submit"
            small
            disabled={disabled}
            onClick={() => clearError()}
            style={{
              backgroundColor: disabled ? theme.palette.lightGrey.dark
                : theme.palette.secondary.main,
              ...submitButtonStyles,
            }}
          >
            {false ? t('status.saving') : t('actions.saveChanges')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

HackedMeetingEvaluationForm.propTypes = {
  survey: CustomPropTypes.survey,
  registerInput: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  customErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    })
  ),
  watchFormValues: PropTypes.func.isRequired,
  errors: CustomPropTypes.formErrors.isRequired,
  entities: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  customFields: PropTypes.objectOf(
    CustomPropTypes.customField
  ),
  disabled: PropTypes.bool,
};

HackedMeetingEvaluationForm.defaultProps = {
  disabled: false,
  customErrors: [],
  customFields: {},
  survey: undefined,
};

const mapStateToProps = ({
  entities,
  entities: {
    customFields,
  },
}) => ({
  entities,
  customFields,
});

export default compose(
  withForm({ required: false, autoComplete: 'off' }),
  connect(mapStateToProps)
)(HackedMeetingEvaluationForm);
