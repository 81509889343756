import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';

import Collapse from '@material-ui/core/Collapse';

import CloseIcon from '@material-ui/icons/Close';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import DeleteIcon from '@material-ui/icons/Delete';

import ContextMenu from 'eventtia-ui-components/lib/ContextMenu';

import SocialComment from '../SocialComment';
import SocialCommentCreator from '../SocialCommentCreator';

import CustomPropTypes from '../../helpers/CustomPropTypes';
import parseCustomFields from '../../helpers/customFields';
import { baseMoment } from '../../helpers/dates';
import useStringTransforms from '../../hooks/useStringTransforms';
import { getCurrentAttendee } from '../../helpers/getters';

import callApi from '../../actions/callApi';
import { setActivePost } from '../../actions/app';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

const useStyles = makeStyles((theme) => ({
  post: {
    display: 'block',
    maxWidth: 600,
    margin: '0px auto 16px auto',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.common.white,
    '&:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  postInfoContainer: {
    position: 'relative',
  },
  postInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  postInfoMargin: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  attendeeInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  attendeeName: {
    color: theme.palette.darkGrey.light,
    fontWeight: 'bold',
  },
  attendeeDetails: {

  },
  postDate: {
    marginRight: theme.spacing(2),
  },
  contextMenuContainer: {
    '& div > button': {
      marginRight: -9,
    },
  },
  postContent: {
    margin: theme.spacing(2, 0),
  },
  message: {
    color: theme.palette.darkGrey.light,
  },
  imageButton: {
    all: 'unset',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    display: 'block',
    margin: 'auto',
  },
  image: {
    width: '100%',
    display: 'block',
    cursor: 'pointer',
  },
  fullSizeImageModal: {
    zIndex: '1500 !important',
    padding: theme.spacing(6, 0),
  },
  fullSizeImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    pointerEvents: 'all',
  },
  fullSizeImageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: -theme.spacing(5),
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: theme.palette.common.white,
  },
  likesAndComments: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  commentSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.light,
    borderRadius: theme.spacing(1),
  },
  postStats: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  postActionButton: {
    borderRadius: theme.spacing(2),
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.darkGrey.light,
    fontWeight: 'bold',
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
  seeAllComments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  commentCreationDivider: {
    marginBottom: theme.spacing(2),
  },
}));

const SocialPost = ({
  post,
  showAllComments,
  entities,
  postComments,
  attendees,
  attendeeTypeCustomFields,
  isUpdatingSocialPost,
  callApi: dispatchCallApi,
  setActivePost: dispatchSetActivePost,
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const { t } = useTranslation('socialWall');

  const { eventUri } = useParams();

  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });

  const [commentSectionActive, setCommentSectionActive] = useState(showAllComments);
  const [isAttemptingToDelete, setIsAttemptingToDelete] = useState(false);

  const [imageExpanded, setImageExpanded] = useState(false);
  const openFullSizeModal = () => setImageExpanded(true);
  const closeFullSizeModal = () => setImageExpanded(false);

  const {
    id, image, message, createdAt, likedBy,
    totalComments, postComments: commentsInPost, lastComment,
  } = post;
  const attendee = attendees[post?.attendee?.id];
  const attendeeInformation = parseCustomFields(
    attendee?.fields || {}, attendeeTypeCustomFields
  );

  const isOwnPost = currentAttendee.id === attendee.id;
  const likedByAttendee = likedBy.includes(currentAttendee.uuid);

  const attendeeFullName = toTitleCase(`${attendeeInformation.firstName} ${attendeeInformation.lastName}`);
  const attendeeInitials = `${attendeeInformation.firstName?.[0]}${attendeeInformation.lastName?.[0]}`.toLocaleUpperCase();

  const { company, jobTitle } = attendeeInformation;
  const attendeeDetails = toTitleCase(`${company?.trim() ? company : ''} ${(company?.trim() && jobTitle?.trim()) ? '|' : ''} ${jobTitle?.trim() ? jobTitle : ''}`, true);

  const commentsToRender = showAllComments ? commentsInPost || [] : [lastComment];

  const toggleCommentSection = () => setCommentSectionActive((prev) => !prev);

  const postContextMenuItems = [{
    name: t('delete'),
    onClick: () => setIsAttemptingToDelete(true),
    color: 'red',
    icon: <DeleteIcon />,
  }];

  return post ? (
    <div
      className={classes.post}
    >
      <Modal
        className={classes.fullSizeImageModal}
        open={imageExpanded}
        onClose={closeFullSizeModal}
      >
        <div className={classes.fullSizeImageContainer}>
          <img
            src={image.large}
            alt=""
            className={classes.fullSizeImage}
          />
          <IconButton
            aria-label={t('global:actions.cancel')}
            onClick={closeFullSizeModal}
            className={classes.closeButton}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
      <DeleteConfirmationDialog
        open={isAttemptingToDelete}
        handleClose={() => setIsAttemptingToDelete(false)}
        onConfirm={() => dispatchCallApi('deleteSocialPost', { eventUri, postId: id })}
      />
      <div className={classes.postInfoContainer}>
        <div
          className={clsx(classes.postInfo, isOwnPost && classes.postInfoMargin)}
          onClick={() => {
            dispatchSetActivePost(id);
          }}
          onKeyDown={() => {
            dispatchSetActivePost(id);
          }}
          role="button"
          tabIndex={0}
        >
          <Avatar
            className={classes.avatar}
            alt={attendeeFullName}
            src={attendeeInformation?.headshot?.small}
            onClick={() => {
              dispatchSetActivePost(id);
            }}
          >
            {attendeeInitials}
          </Avatar>
          <div className={classes.attendeeInfo}>
            <Typography className={classes.attendeeName} variant="body2" color="textPrimary">
              {attendeeFullName}
            </Typography>
            <Typography className={classes.attendeeDetails} variant="body2" color="textSecondary">
              {attendeeDetails}
            </Typography>
          </div>
          <Typography className={classes.postDate} variant="caption" color="textSecondary">
            {baseMoment(createdAt).fromNow()}
          </Typography>
        </div>
        {isOwnPost && (
          <div
            className={classes.contextMenuContainer}
          >
            <ContextMenu items={postContextMenuItems} />
          </div>
        )}
      </div>
      <div className={classes.postContent}>
        <Typography
          className={classes.message}
          variant="body1"
          color="textPrimary"
          gutterBottom={!!image.filename}
        >
          {message}
        </Typography>
        {!!image.filename && (
          <button
            type="button"
            className={classes.imageButton}
            onClick={openFullSizeModal}
          >
            <img
              src={image.large}
              alt=""
              className={classes.image}
            />
          </button>
        )}
      </div>
      <Divider />
      <div
        className={classes.likesAndComments}
      >
        <div>
          <Button
            className={clsx(
              classes.postActionButton,
              commentSectionActive && classes.activeButton
            )}
            startIcon={<ChatBubbleOutlineIcon />}
            size="small"
            onClick={toggleCommentSection}
          >
            {t('comment')}
          </Button>
          <Button
            className={clsx(classes.postActionButton, likedByAttendee && classes.activeButton)}
            startIcon={likedByAttendee ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            size="small"
            disabled={isUpdatingSocialPost}
            onClick={() => dispatchCallApi('updateSocialPost', {
              eventUri,
              postId: id,
              likedBy: currentAttendee.uuid,
              shouldRemoveLike: likedByAttendee,
            })}
          >
            {likedBy.length}
          </Button>
        </div>
      </div>
      <Collapse in={commentSectionActive}>
        <div className={classes.commentSection}>
          {commentsToRender.map((commentToRender) => {
            const comment = postComments?.[commentToRender?.id];
            return comment ? (
              <SocialComment
                key={comment?.id}
                comment={comment}
                postId={post?.id}
                currentAttendee={currentAttendee}
                attendees={attendees}
                attendeeTypeCustomFields={attendeeTypeCustomFields}
              />
            ) : null;
          })}
          {(totalComments > 1 && !showAllComments) && (
            <Typography
              className={classes.seeAllComments}
              variant="body2"
              color="primary"
              onClick={() => dispatchSetActivePost(id)}
            >
              {t('viewAllComments', { count: totalComments })}
              <ChevronRightIcon />
            </Typography>
          )}
          {totalComments >= 1 && <Divider className={classes.commentCreationDivider} />}
          <SocialCommentCreator
            postId={id}
            currentAttendee={currentAttendee}
          />
        </div>
      </Collapse>
    </div>
  ) : null;
};

SocialPost.propTypes = {
  post: CustomPropTypes.socialPost,
  postComments: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  })),
  showAllComments: PropTypes.bool,
  entities: CustomPropTypes.entities,
  attendees: PropTypes.objectOf(CustomPropTypes.attendee),
  attendeeTypeCustomFields: PropTypes.objectOf(CustomPropTypes.attendeeTypeCustomField),
  isUpdatingSocialPost: PropTypes.bool,
  setActivePost: PropTypes.func.isRequired,
  callApi: PropTypes.func.isRequired,
};

SocialPost.defaultProps = {
  post: {},
  postComments: {},
  showAllComments: false,
  entities: {},
  attendees: {},
  attendeeTypeCustomFields: {},
  isUpdatingSocialPost: false,
};

const mapStateToProps = ({
  entities,
  entities: {
    attendees,
    attendeeTypeCustomFields,
    postComments,
  },
  fetchStatus: {
    updateSocialPost: {
      isFetching: isUpdatingSocialPost,
    },
  },
}) => ({
  entities,
  attendees,
  attendeeTypeCustomFields,
  postComments,
  isUpdatingSocialPost,
});

export default connect(mapStateToProps, { callApi, setActivePost })(SocialPost);
