import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  mobileRoot: {
    textAlign: 'center',
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
}));

const SubpageTitle = ({
  text,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      className={clsx(classes.root, mobile && classes.mobileRoot)}
      variant="subtitle1"
    >
      {text}
    </Typography>
  );
};

SubpageTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SubpageTitle;
