import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  contactInfo: {
    padding: theme.spacing(0, 3, 2, 3),
  },
  lightText: {
    color: fade(theme.palette.darkGrey.light, 0.8),
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.25, 0),
  },
  contactIcon: {
    color: theme.palette.primary.light,
    marginRight: theme.spacing(2),
  },
}));

const AttendeeLeadCardContactInfo = ({ email, phone }) => {
  const classes = useStyles();

  return (
    <div className={classes.contactInfo}>
      <Typography className={classes.lightText} variant="caption" component="div">
        <MailIcon fontSize="small" className={classes.contactIcon} /> {email}
      </Typography>
      <Typography className={classes.lightText} variant="caption" component="div">
        <PhoneIcon fontSize="small" className={classes.contactIcon} /> {phone}
      </Typography>
    </div>
  );
};

AttendeeLeadCardContactInfo.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
};

AttendeeLeadCardContactInfo.defaultProps = {
  email: undefined,
  phone: undefined,
};

export default AttendeeLeadCardContactInfo;
