const dataToBlob = (dataURL, type) => {
  const byteString = atob(dataURL.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i);

  return new Blob([ab], { type });
};
export default dataToBlob;

export const SIZE_LIMIT_MB = 9;
