/* eslint-disable */
export default (function(){
  var C = function(){ return constructor.apply(this,arguments); }

  const AUTH_TOKEN_CACHE_KEY = 'demodesk.authTokenCache';
  const MIN_TOKEN_EXPIRY_GRACE_DURATION = 7 * 24 * 60 * 60; // 7 days (max validity is 14 days)

  var p = C.prototype;
  p.foreignHost; // 'yourdomain.com';
  p.meetingHost; // 'meet.yourdomain.com';
  p.fetchAuthToken = () => void 0; // Function to fetch auth token from 3rd party backend
  p.onAuthenticated = () => void 0; // Callback function to signal successful authentication

  function constructor(options) {
    this.foreignHost = options.foreignHost;
    this.meetingHost = options.meetingHost;
    this.fetchAuthToken = options.fetchAuthToken;
    this.onAuthenticated = options.onAuthenticated;
  }

  // External functions

  p.authenticate = function() {
    console.log('Fetching new auth token');
    writeFreshAuthToken(this);
  }

  p.logout = function() {
    removeCachedAuthToken();
    removeDemodeskCookies(this);
  }

  // Internal functions
  async function writeFreshAuthToken(context) {
    const token = await context.fetchAuthToken();

    const bearerToken = parseAuthToken(token);

    writeDemodeskCookies(bearerToken, context);

    setCachedAuthToken(token);
  }

  function writeDemodeskCookies(bearerToken, context) {
    const frameStyle = {
      display:  'none',
      position: 'absolute',
      top:      '-999px',
      left:     '-999px'
    };

    const frame = window.document.createElement('iframe');
    frame.id = 'demodesk-auth-iframe';
    Object.keys(frameStyle).forEach((key) => frame.style[key] = frameStyle[key]);

    frame.onload = function() {
      setTimeout(() => {
        context.onAuthenticated();
        // frame.remove();
      }, 200);
      setTimeout(() => {
        // context.onAuthenticated();
        frame.remove();
      }, 5000);
    };
    window.document.body.appendChild(frame);

    const authGenerator = "https://" + context.meetingHost + "/manage/static-user/remote-login.html?" +
    "access_token=" + bearerToken['access_token'] +
    "&client=" + bearerToken['client'] +
    "&expiry=" + bearerToken['expiry'] +
    "&token_type=" + bearerToken['token_type'] +
    "&uid=" + encodeURIComponent(bearerToken['uid']) +
    "&viewer_access=true" +
    "&same_site_none=true";

    frame.sandbox = 'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-top-navigation';
    frame.src = authGenerator;
  }

  function removeDemodeskCookies(context) {
    const frameStyle = {
      display:  'none',
      position: 'absolute',
      top:      '-999px',
      left:     '-999px'
    };

    const frame = window.document.createElement('iframe');
    frame.id = 'demodesk-logout-iframe';
    Object.keys(frameStyle).forEach((key) => frame.style[key] = frameStyle[key]);

    frame.onload = function() {
      setTimeout(() => {
        frame.remove();
      }, 200);
    };
    window.document.body.appendChild(frame);
    frame.src = "https://" + context.meetingHost + "/manage/static-user/remote-logout.html"
  }

  function isLastAuthTokenValid() {
    const currentTime = Math.floor(Date.now() / 1000);
    console.log(`Current time: ${currentTime}`);
    const tokenTime = getLastAuthTokenExpiry();
    console.log(`Token time: ${tokenTime}`);

    const remainingTokenDuration = tokenTime - currentTime;
    console.log(`Remaining token duration: ${remainingTokenDuration}`);

    return remainingTokenDuration >= MIN_TOKEN_EXPIRY_GRACE_DURATION;
  }

  function getLastAuthTokenExpiry() {
    const cachedToken = getCachedAuthToken();
    const parsedToken = parseAuthToken(cachedToken);
    return parsedToken['expiry'];
  }

  function getCachedAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_CACHE_KEY);
  }

  function setCachedAuthToken(authToken) {
    localStorage.setItem(AUTH_TOKEN_CACHE_KEY, authToken);
    console.log('New auth token stored.');
  }

  function removeCachedAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_CACHE_KEY);
  }

  function parseAuthToken(authToken) {
    if (!authToken) return false;
    const decodedString = atob(authToken);
    return JSON.parse(decodedString);
  }

  return C;
})();