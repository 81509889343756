import { useParams as useParamsReact } from 'react-router-dom';
import localeReplacementTable from '../../helpers/localeRemaps';

const useParams = () => {
  const { locale, ...other } = useParamsReact();
  const newLocale = Object.keys(localeReplacementTable).includes(locale)
    ? localeReplacementTable[locale] : locale;
  return { locale: newLocale, ...other };
};

export default useParams;
