import { useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDatabase, ref, set, push, serverTimestamp, increment,
  onValue, onDisconnect, remove, off,
} from 'firebase/database';
import { getCurrentAttendeeId } from '../../helpers/getters';
import { setFirebaseSessionId } from '../../actions/app';

const useFirebaseDB = () => {
  const eventId = useSelector((state) => Object.values(state.entities?.events || {})?.[0]?.id);
  const dispatch = useDispatch();
  const attendeeId = getCurrentAttendeeId();
  const db = getDatabase();

  const addEmotion = useCallback(({ emotion, workshopId }) => {
    const emotionStreamRef = ref(db, `emotionEventHistory/${eventId}/${workshopId}/`);
    const newEmotionRef = push(emotionStreamRef);
    const newEmotionEvent = {
      attendeeId,
      timestamp: serverTimestamp(),
      emotion,
    };
    set(newEmotionRef, newEmotionEvent);

    const emotionMetricsRef = ref(db, `emotionMetrics/${eventId}/${workshopId}/${emotion}`);
    set(emotionMetricsRef, increment(1));
  }, [attendeeId, db, eventId]);

  const putAttendeePresence = useCallback(() => {
    const baseAttendeeLastOnlinePath = `attendees/${eventId}/${attendeeId}/lastOnline`;
    const baseStatusPath = `status/${eventId}/${attendeeId}`;
    const baseConnectionEventHistoryPath = `connectionEventHistory/${eventId}/${attendeeId}`;

    const connectionsStreamRef = ref(db, baseConnectionEventHistoryPath);
    const lastOnlineRef = ref(db, baseAttendeeLastOnlinePath);

    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snap) => {
      if (snap.val() && !!getAuth().currentUser) {
        const newConnectionEventTemplate = {
          // attendeeId,,
          userAgent: navigator.userAgent,
          start: serverTimestamp(),
        };

        const newSessionEvent = push(connectionsStreamRef);
        const currentSessionEndRef = ref(db, `${baseConnectionEventHistoryPath}/${newSessionEvent.key}/end`);
        onDisconnect(currentSessionEndRef).set(serverTimestamp()).then(() => {
          set(newSessionEvent, newConnectionEventTemplate);
          dispatch(setFirebaseSessionId(newSessionEvent.key));
        });

        const attendeeCurrentStatusSession = ref(db, `${baseStatusPath}/${newSessionEvent.key}`);
        onDisconnect(attendeeCurrentStatusSession).remove().then(() => {
          set(attendeeCurrentStatusSession, true);
        });

        onDisconnect(lastOnlineRef).set(serverTimestamp());
      }
    });
  }, [attendeeId, db, dispatch, eventId]);

  const startTrace = useCallback(({
    firebaseSessionId, pathname, entityType, entityId,
  }) => {
    const currentSessionTracesPath = `connectionEventHistory/${eventId}/${attendeeId}/${firebaseSessionId}/traces/`;
    const currentSessionTracesRef = ref(db, currentSessionTracesPath);
    const newTraceRef = push(currentSessionTracesRef);

    const newTraceTemplate = {
      pathname,
      entityType,
      entityId,
      start: serverTimestamp(),
    };

    const currentTraceEndPath = `connectionEventHistory/${eventId}/${attendeeId}/${firebaseSessionId}/traces/${newTraceRef.key}/end`;
    const currentTraceEndRef = ref(db, currentTraceEndPath);
    const disconnectionCallback = onDisconnect(currentTraceEndRef);
    disconnectionCallback.set(serverTimestamp()).then(() => {
      set(newTraceRef, newTraceTemplate);
    });

    return {
      traceId: newTraceRef.key,
      endTrace: () => {
        set(currentTraceEndRef, serverTimestamp());
        disconnectionCallback.cancel();
      },
    };
  }, [db, eventId, attendeeId]);

  const markMeetingPresence = useCallback(({ meetingId, firebaseSessionId }) => {
    const meetingStatusPath = `meetingStatus/${eventId}/${meetingId}/${attendeeId}/${firebaseSessionId}`;
    const meetingStatusRef = ref(db, meetingStatusPath);

    const disconnectionCallback = onDisconnect(meetingStatusRef);
    disconnectionCallback.remove().then(() => {
      set(meetingStatusRef, true);
    });

    return {
      endMeetingPresence: () => {
        remove(meetingStatusRef);
        disconnectionCallback.cancel();
      },
    };
  }, [attendeeId, db, eventId]);

  const markAttendeeViewed = useCallback(({ viewedAttendeeId }) => {
    const attendeeViewedPath = `viewedAttendees/${eventId}/${attendeeId}/${viewedAttendeeId}`;
    const attendeeViewedRef = ref(db, attendeeViewedPath);
    set(attendeeViewedRef, true);
  }, [attendeeId, db, eventId]);

  const watchViewedAttendees = useCallback((callback) => {
    const attendeeViewedPath = `viewedAttendees/${eventId}/${attendeeId}/`;
    const attendeeViewedRef = ref(db, attendeeViewedPath);
    onValue(attendeeViewedRef, (snapshot) => {
      const viewedAttendees = snapshot.val();
      callback(viewedAttendees);
    }, (error) => console.error(error));
    return () => {
      off(attendeeViewedRef);
    };
  }, [attendeeId, db, eventId]);

  return {
    addEmotion,
    putAttendeePresence,
    startTrace,
    markMeetingPresence,
    markAttendeeViewed,
    watchViewedAttendees,
  };
};

export default useFirebaseDB;
