import deburr from 'lodash/deburr';
import { getCurrentAttendeeId } from './getters';
import { baseMoment } from './dates';

const ATTENDEE_LEADS = 'attendeeLeads';

const getAllStoredAttendeeLeads = () => {
  const rawAttendeeLeads = localStorage.getItem(ATTENDEE_LEADS);
  return JSON.parse(rawAttendeeLeads) || {};
};

export const getStoredAttendeeLeads = () => {
  const currentAttendeeId = getCurrentAttendeeId();
  const allAttendeeLeads = getAllStoredAttendeeLeads();
  return allAttendeeLeads[currentAttendeeId] || {};
};

export const buildAttendeeLead = (uuid, fullName, email, company, phone) => ({
  uuid,
  fullName,
  email,
  company,
  phone,
});

export const setLeadAsPending = (attendeeLead, config) => {
  const now = baseMoment();
  return {
    ...attendeeLead,
    deleted: config?.deleted,
    updatedAt: now.format(),
    pendingSync: true,
  };
};

export const parseQrContent = (qrContent) => {
  const [uuid, fullName, email, company, phone] = qrContent.split('|');
  return buildAttendeeLead(uuid, fullName, email, company, phone);
};

export const buildQrContent = (uuid, firstName, lastName, email, company, jobTitle, phone) => {
  const fullName = `${firstName} ${lastName}`.trim();
  let job = company;
  if (jobTitle) job += ` - ${jobTitle}`;
  return [uuid, fullName, email, job, phone].map((word) => (
    deburr(word).replaceAll('|', '')
  )).join('|');
};

export const mergeFetchedWithStored = (newFetched) => {
  const stored = getStoredAttendeeLeads();

  const fetched = {};
  if (newFetched) Object.values(newFetched).forEach((newLead) => {
    fetched[newLead.uuid] = newLead;
  });

  const result = {};
  [...Object.keys(stored), ...Object.keys(fetched)].forEach((uuid) => {
    const storedLead = stored[uuid];
    const fetchedLead = fetched[uuid];

    if (!storedLead) result[uuid] = fetchedLead;
    else if (!fetchedLead && !storedLead.deleted) result[uuid] = storedLead;
    else if (storedLead && fetchedLead) {
      const fetchedUpdatedAt = baseMoment(fetchedLead.updatedAt);
      const storedUpdatedAt = baseMoment(storedLead.updatedAt);
      result[uuid] = (fetchedUpdatedAt.isAfter(storedUpdatedAt)) ? fetchedLead : storedLead;
    }
  });
  return result;
};

export const storeAttendeeLeads = (newLeads) => {
  const currentAttendeeId = getCurrentAttendeeId();
  const allAttendeeLeads = getAllStoredAttendeeLeads();

  allAttendeeLeads[currentAttendeeId] = newLeads;

  localStorage.setItem(ATTENDEE_LEADS, JSON.stringify(allAttendeeLeads));
};

export const isLeadAdded = (uuid) => {
  const stored = getStoredAttendeeLeads();
  return !!stored[uuid];
};
