import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CampariPhotoIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m1031.3 375h-37.5c-10.359 0-18.703-8.3906-18.703-18.75s8.3438-18.75 18.703-18.75h37.5v-37.5c0-10.359 8.4375-18.75 18.75-18.75s18.75 8.3906 18.75 18.75v37.5h37.5c10.359 0 18.703 8.3906 18.703 18.75s-8.3438 18.75-18.703 18.75h-37.5v37.5c0 10.359-8.4375 18.75-18.75 18.75s-18.75-8.3906-18.75-18.75zm0 150c0-10.359 8.4375-18.75 18.75-18.75s18.75 8.3906 18.75 18.75v393.74c0 30.984-25.453 56.25-57 56.25h-879.74c-31.547 0-57-25.266-57-56.25v-525c0-30.984 25.453-56.25 57-56.25h254.48c9.4219 0 17.672-6.5625 19.266-15.75l8.3906-49.828c4.5938-27 28.312-46.922 56.25-46.922h202.97c27.938 0 51.656 19.922 56.25 46.922l8.3906 49.828c1.5938 9.1875 9.8438 15.75 19.266 15.75h101.48c10.312 0 18.75 8.3906 18.75 18.75 0 10.359-8.4375 18.75-18.75 18.75h-101.48c-27.938 0-51.656-19.969-56.25-46.969l-8.3906-49.828c-1.5938-9.1406-9.8438-15.703-19.266-15.703h-202.97c-9.4219 0-17.672 6.5625-19.266 15.703l-8.3906 49.828c-4.5938 27-28.312 46.969-56.25 46.969h-254.48c-10.641 0-19.5 8.2969-19.5 18.75v525c0 10.453 8.8594 18.75 19.5 18.75h879.74c10.641 0 19.5-8.2969 19.5-18.75zm-459.37-112.5c134.53 0 243.79 109.22 243.79 243.74 0 134.52-109.27 243.74-243.79 243.74-134.53 0-243.79-109.22-243.79-243.74 0-134.52 109.27-243.74 243.79-243.74zm0 37.5c-113.86 0-206.26 92.391-206.26 206.26 0 113.87 92.391 206.26 206.26 206.26 113.87 0 206.26-92.391 206.26-206.26 0-113.87-92.391-206.26-206.26-206.26zm0 75c72.422 0 131.26 58.828 131.26 131.26 0 72.43-58.828 131.26-131.26 131.26-72.43 0-131.26-58.828-131.26-131.26 0-72.43 58.828-131.26 131.26-131.26zm0 37.5c-51.75 0-93.797 42-93.797 93.75s42.047 93.75 93.797 93.75 93.797-42 93.797-93.75-42.047-93.75-93.797-93.75z" fillRule="evenodd" />
    </svg>
  </SvgIcon>
);

export default CampariPhotoIcon;
