import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'eventtia-ui-components/lib/Button';
import { Checkbox } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.main,
    maxWidth: 330,
    minWidth: 280,
    padding: theme.spacing(2, 4, 2, 4),
    borderRadius: 10,
    cursor: 'auto',
    boxShadow: theme.customShadows.default,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.darkGrey.light,
    marginBottom: theme.spacing(2),
  },
  subtitle2: {
    fontSize: 12,
    color: theme.palette.darkGrey.light,
    fontWeight: 600,
    marginBottom: 4,
  },
  action: {
    marginTop: theme.spacing(1),
  },
  boldedText: {
    fontWeight: 'bold',
  },
  dataCompliance: {
    display: 'flex',
    marginTop: theme.spacing(1),
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  messageInput: {
    fontSize: 12,
  },
}));

const ShareDetailsToSponsor = ({
  action, open, anchorEl, closeDialog, companyName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('sponsors');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [messageError, setMessageError] = useState({ error: false, errorMessage: '' });

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const messageHandleChange = (event) => {
    if (event.target.value === '') setMessageError({ ...messageError, error: true, errorMessage: t('data.actions.detailsRequired') });
    if (event.target.value !== '') {
      setMessageError({ ...messageError, error: false, errorMessage: 'null' });
      setMessage(event.target.value);
    }
  };

  useEffect(() => {
    if (message === '') setMessageError({ ...messageError, error: true, errorMessage: t('data.actions.detailsRequired') });
  }, []);

  const sendDetails = () => {
    if (message !== '') {
      action.onClick(message, checked);
      setMessage('');
      setChecked(false);
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={closeDialog}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.dialog}>
        <div className={classes.form}>
          <Typography className={classes.title}>
            {t('data.shareDetailsContent.title')}
          </Typography>
          <Typography className={classes.subtitle} gutterBottom>
            {t('data.shareDetailsContent.subtitle', { sponsor: companyName })}
          </Typography>
          <Typography className={classes.subtitle2}>
            {t('data.shareDetailsContent.message')}
          </Typography>
          <TextField
            className={classes.messageInput}
            id="share-details-input"
            onChange={(e) => messageHandleChange(e)}
            placeholder="Insert your message"
            variant="outlined"
            multiline
            rows={3}
            rowsMax={4}
            error={messageError.error}
            helperText={messageError.error ? messageError.errorMessage : null}
          />
          <div className={classes.dataCompliance}>
            <Checkbox
              disableRipple
              checked={checked}
              onChange={handleChange}
            />
            <Typography className={classes.subtitle}>
              <Trans t={t} i18nKey="data.shareDetailsContent.shareDetails">
                I want to share my personal details, including
                <span className={classes.boldedText}>my phone number and email</span>
                to be contacted by {{ sponsor: companyName }}
              </Trans>
            </Typography>
          </div>
          <Button
            small
            type="submit"
            variant="primary"
            className={classes.action}
            onClick={sendDetails}
            disabled={!message}
          >
            {action.name}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

ShareDetailsToSponsor.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.element,
  ]),
  companyName: PropTypes.string.isRequired,
};

ShareDetailsToSponsor.defaultProps = {
  anchorEl: undefined,
};

export default ShareDetailsToSponsor;
