import { getEntity } from './getters';
import { baseMoment } from './dates';

const getMeetingVisibility = (entities) => {
  const conference = getEntity(entities, 'businessConferences');
  if (!conference) return {
    canSeeAgenda: false,
    inSchedulingTime: false,
    canScheduleMeetings: false,
  };

  const {
    bookingDeadlineDate, schedulingStartsOn, automaticScheduling, ratedShowParticipantAgendas,
  } = conference;

  const canSeeAgenda = !automaticScheduling || ratedShowParticipantAgendas;

  const currentDate = baseMoment();
  const beforeSchedulingStarts = currentDate.isBefore(schedulingStartsOn, 'minutes');
  const afterBookingDeadline = currentDate.isSameOrAfter(bookingDeadlineDate, 'minutes');
  const inSchedulingTime = !(beforeSchedulingStarts || afterBookingDeadline);
  const canScheduleMeetings = inSchedulingTime && !automaticScheduling;

  return {
    canSeeAgenda,
    inSchedulingTime,
    canScheduleMeetings,
  };
};

export default getMeetingVisibility;
