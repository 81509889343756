import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QrCodeIcon = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path d="M7.85,12.35A3.625,3.625,0,1,0,4.225,8.725h0a3.615,3.615,0,0,0,3.6,3.625h0Zm0,1.807C5.434,14.158.6,15.377.6,17.783V19.6H15.1V17.783C15.1,15.377,10.267,14.158,7.85,14.158ZM23.328,5.195H16.1a.872.872,0,0,0-.9.841h0v5.086a.872.872,0,0,0,.893.851h7.24a.872.872,0,0,0,.9-.841h0V6.046a.872.872,0,0,0-.893-.851Zm0,1.9L19.713,9.2,16.1,7.1V6.046l3.615,2.1,3.615-2.1Z" transform="translate(-0.415 -0.35)" />
      </g>
    </svg>
  </SvgIcon>
);

export default QrCodeIcon;
