import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CampariContactsIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m762.5 690c52.5-45 87.5-115 87.5-190 0-137.5-112.5-250-250-250s-250 112.5-250 250c0 75 35 145 87.5 190-112.5 60-187.5 175-187.5 310 0 27.5 22.5 50 50 50s50-22.5 50-50c0-137.5 112.5-250 250-250s250 112.5 250 250c0 27.5 22.5 50 50 50s50-22.5 50-50c0-135-75-250-187.5-310zm-312.5-190c0-82.5 67.5-150 150-150s150 67.5 150 150-67.5 150-150 150-150-67.5-150-150z" />
        <path d="m350 600c0 27.5-22.5 50-50 50-110 0-200 90-200 200 0 27.5-22.5 50-50 50s-50-22.5-50-50c0-112.5 62.5-212.5 155-262.5-35-35-55-85-55-137.5 0-110 90-200 200-200 27.5 0 50 22.5 50 50s-22.5 50-50 50c-55 0-100 45-100 100s45 100 100 100c27.5 0 50 22.5 50 50z" />
        <path d="m1200 850c0 27.5-22.5 50-50 50s-50-22.5-50-50c0-110-90-200-200-200-27.5 0-50-22.5-50-50s22.5-50 50-50c55 0 100-45 100-100s-45-100-100-100c-27.5 0-50-22.5-50-50s22.5-50 50-50c110 0 200 90 200 200 0 52.5-20 102.5-55 137.5 92.5 50 155 150 155 262.5z" />
      </g>
    </svg>
  </SvgIcon>
);

export default CampariContactsIcon;
