import { useEffect } from 'react';
import { baseMoment } from '../../helpers/dates';

const useTimer = ({
  startDate, endDate, onTick, step = 1000, clearOnEnd = true,
}) => {
  useEffect(() => {
    const start = startDate && baseMoment(startDate);
    const end = endDate && baseMoment(endDate);
    let interval;

    const tick = () => {
      const now = baseMoment();
      onTick(now, start, end);
      if (end && clearOnEnd && now.isAfter(end)) clearInterval(interval);
    };

    tick();
    interval = setInterval(tick, step);

    return () => {
      clearInterval(interval);
    };
  }, [endDate, startDate, onTick, step, clearOnEnd]);
};

export default useTimer;
