import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Message from 'eventtia-ui-components/lib/Message';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Card from 'eventtia-ui-components/lib/Card';
import SurveyForm from '../../components/SurveyForm';
import BackButton from '../../components/BackButton';
import callApi from '../../actions/callApi';
import { goToPreviousSubpage, goToSurveys } from '../../actions/app';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 0),
    marginLeft: theme.spacing(2),
  },
  mobileModuleName: {
    width: '100%',
    textAlign: 'center',
  },
  surveyDescription: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.darkGrey.light,
  },
  tabletSurveyDescription: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(1, 0, 0, 0),
    overflow: 'auto',
    width: '100%',
  },
}));

const SurveyShow = ({
  survey, isFetching, customFields, callApi: dispatchCallApi, onSuccess, backError,
  goToPreviousSubpage: dispatchGoToPreviousSubpage, goToSurveys: dispatchGoToSurveys,
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState();
  const [customErrors, setCustomErrors] = useState([]);
  const [sendingAnswer, setSendingAnswer] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation('global');
  const { eventUri } = useParams();

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getRequiredFieldErrorTemplate = (prefix, stringKey = '') => ({
    type: 'manual',
    name: `${prefix}${stringKey ? '.' : ''}${stringKey}`,
    message: t('formError.requiredField'),
  });
  useEffect(() => {
    if (Array.isArray(backError) && backError.length >= 1) setMessage(backError.join(', '));
    if ((typeof backError) === 'string') setMessage(backError);
  }, [backError]);

  useEffect(() => {
    if (backError && backError.length === 1) setMessage(...backError);
    if (backError && backError.length > 1) setMessage(backError.join(', '));
  }, [backError]);

  useEffect(() => {
    if (success) {
      onSuccess();
      dispatchGoToPreviousSubpage();
    }
  }, [success, dispatchGoToPreviousSubpage]);

  const { answered } = survey;
  useEffect(() => {
    if (answered) dispatchGoToSurveys();
  }, [answered, dispatchGoToSurveys]);

  const onAnswerSurvey = ({ visibleFields, ...fields }) => {
    const errors = [];
    const data = new FormData();
    const answersData = {};

    visibleFields.split('-').forEach((key) => {
      const stringKey = `_${key}`;
      const field = customFields[key];
      const fieldValue = fields[stringKey];
      const isEmpty = (!fieldValue && fieldValue !== 0)
        || (Array.isArray(fieldValue) && !fieldValue.length);
      if (field?.optionsStatus?.required && isEmpty) errors
        .push(getRequiredFieldErrorTemplate('answers', stringKey));
      else if (!isEmpty) answersData[key] = fieldValue;
    });

    setCustomErrors(errors);
    if (!errors.length && !sendingAnswer) {
      data.append('answers', JSON.stringify(answersData));
      data.append('survey_type', 1);
      setSendingAnswer(true);
      dispatchCallApi('addSurveyAnswer', { eventUri, id: survey.id, data }).then((res) => {
        if (!res.error) setSuccess(true);
        setSendingAnswer(false);
      });
    }
  };
  const onClose = () => setMessage('');

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton />
        <Typography
          className={clsx(classes.moduleName, mobile && classes.mobileModuleName)}
          variant="subtitle1"
        >
          {survey.name}
        </Typography>
      </div>
      {survey.description && (
        <Typography
          className={clsx(classes.surveyDescription,
            tablet && classes.tabletSurveyDescription)}
          variant="body2"
        >
          {survey.description}
        </Typography>
      )}
      <Message
        type="error"
        title={t('forms.error')}
        message={message}
        onClose={onClose}
      />
      <Card className={classes.card}>
        <SurveyForm
          survey={survey}
          disabled={isFetching}
          customErrors={customErrors}
          onSubmit={onAnswerSurvey}
        />
      </Card>
    </div>
  );
};

SurveyShow.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  survey: CustomPropTypes.survey,
  onSuccess: PropTypes.func.isRequired,
  customFields: PropTypes.objectOf(
    CustomPropTypes.customField
  ),
  callApi: PropTypes.func.isRequired,
  goToPreviousSubpage: PropTypes.func.isRequired,
  goToSurveys: PropTypes.func.isRequired,
  backError: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

SurveyShow.defaultProps = {
  survey: {},
  customFields: {},
  backError: undefined,
};

const mapStateToProps = ({
  entities: {
    customFields,
  },
  fetchStatus: {
    addSurveyAnswer: { isFetching, error: backError },
  },
}) => ({
  isFetching, customFields, backError,
});

export default connect(mapStateToProps, { callApi, goToPreviousSubpage, goToSurveys })(SurveyShow);
