/* eslint-disable comma-dangle */
export default {
  en: {
    title: {
      sponsors: 'Sponsors',
    },
    data: {
      companyDescription: 'Company description',
      exhibitionStand: 'Exhibition stand',
      contactUs: 'Contact us',
      activities: 'Activities',
      attachments: 'Files',
      marketplace: 'Marketplace',
      videos: 'Videos',
      actions: {
        readMore: 'Read more',
        startChat: 'Start chat',
        goToChat: 'Go to chat',
        contact: 'Contact',
        sendEmail: 'Send email',
        shareDetails: 'Share your details',
        purchase: 'Purchase',
        success: 'Sucess!',
        addEventReminder: 'Add event reminder',
        scheduleMeeting: 'Schedule meeting',
        detailsRequired: 'This field is required',
      },
      shareDetailsContent: {
        title: 'Thanks for sharing your details!',
        subtitle: 'A representative of {{sponsor}} will be contacting you shortly. In the meantime, you can send a short message along with your details in the box below.',
        message: 'Message',
        shareDetails: 'I want to share my personal details, including <1>my phone number and email</1> to be contacted by {{sponsor}}',
      },
      results: {
        shareSuccess: 'Your message has been succesfully sent.',
      },
    },
    forms: {
      searchKeyword: 'Keywords',
      tags: 'Tags',
    },
  },
  es: {
    title: {
      sponsors: 'Patrocinadores',
    },
    data: {
      companyDescription: 'Descripción de la compañía',
      exhibitionStand: 'Stand de exhibición',
      contactUs: 'Contáctenos',
      activities: 'Actividades',
      attachments: 'Archivos',
      marketplace: 'Mercado',
      videos: 'Videos',
      actions: {
        readMore: 'Leer más',
        startChat: 'Iniciar chat',
        goToChat: 'Ir a chat',
        contact: 'Contactar',
        sendEmail: 'Enviar e-mail',
        shareDetails: 'Comparte tus datos',
        purchase: 'Comprar',
        success: '¡Éxito!',
        addEventReminder: 'Agregar recordatorio de evento',
        scheduleMeeting: 'Agendar reunión',
        detailsRequired: 'Este campo es requerido',
      },
      shareDetailsContent: {
        title: '¡Gracias por compartir tus datos!',
        subtitle: 'Un representante del {{sponsor}} se contactará contigo en breve. Mientras tanto, puedes anexarle a tus datos un mensaje corto en la casilla de abajo.',
        message: 'Mensaje',
        shareDetails: 'Deseo compartir mis datos personales, incluyendo <1>mi número de teléfono y correo</1> para ser contacto por {{sponsor}}',
      },
      results: {
        shareSuccess: 'Tu mensaje ha sido enviado exitosamente',
      },
    },
    forms: {
      searchKeyword: 'Palabras clave',
      tags: 'Tags',
    },
  },
  fr: {
    title: {
      sponsors: 'Sponsors',
    },
    data: {
      companyDescription: 'Description de l\'entreprise',
      exhibitionStand: 'Stand d\'exposition',
      contactUs: 'Contactez-nous',
      activities: 'Activités',
      attachments: 'Fichiers',
      marketplace: 'Espace commercial',
      videos: 'Vidéos',
      actions: {
        readMore: 'Lire la suite',
        startChat: 'Démarrer le chat',
        goToChat: 'Accéder au chat',
        contact: 'Contact',
        sendEmail: 'Envoyer l\'email',
        shareDetails: 'Partagez vos coordonnées',
        purchase: 'Acheter',
        success: 'Succès !',
        addEventReminder: 'Ajouter un rappel d\'événement',
        scheduleMeeting: 'Programmer une réunion',
        detailsRequired: 'Ce champ est obligatoire',
      },
      shareDetailsContent: {
        title: 'Merci de partager vos coordonnées !',
        subtitle: 'Un représentant de {{sponsor}} vous contactera sous peu. En attendant, vous pouvez envoyer un court message accompagné de vos coordonnées dans le cadre ci-dessous.',
        message: 'Message',
        shareDetails: 'Je souhaite partager mes données personnelles, notamment <1>mon numéro de téléphone et mon adresse e-mail</1> pour être contacté par {{sponsor}}.',
      },
      results: {
        shareSuccess: 'Votre message a été envoyé avec succès.',
      },
    },
    forms: {
      searchKeyword: 'Mots clés',
      tags: 'Tags',
    },
  },
  ca: {
    title: { sponsors: 'Patrocinadors' },
    data: {
      companyDescription: 'Descripció de l’empresa',
      exhibitionStand: 'Estand d’exhibició',
      contactUs: 'Contacta’ns',
      activities: 'Activitats',
      attachments: 'Fitxers',
      marketplace: 'Mercat',
      videos: 'Vídeos',
      actions: {
        readMore: 'Llegeix-ne més',
        startChat: 'Comença un xat',
        goToChat: 'Vés al xat',
        contact: 'Contacta’ns',
        sendEmail: 'Envia un correu-e',
        shareDetails: 'Comparteix els teus detalls',
        purchase: 'Compra',
        success: 'Ha anat bé!',
        addEventReminder: 'Afegeix un recordatori d’esdeveniment',
        scheduleMeeting: 'Agenda una reunió',
        detailsRequired: 'Aquest camp és obligatori',
      },
      shareDetailsContent: {
        title: 'Gràcies per compartir els teus detalls!',
        subtitle:
          'Un representant de {{sponsor}} et contactarà aviat. Mentrestant, pots enviar un missatge curt juntament amb els teus detalls en la capsa de sota.',
        message: 'Missatge',
        shareDetails:
          'Vull compartir els meus detalls personals, incloent <1>el meu número de telèfon i correu-e</1> perquè {{sponsor}} em pugui contactar.',
      },
      results: { shareSuccess: 'El teu missatge s’ha enviat correctament.' },
    },
    forms: { searchKeyword: 'Paraules clau', tags: 'Etiquetes' },
  },
  pt: {
    title: {
      sponsors: 'Patrocinadores',
    },
    data: {
      companyDescription: 'Descrição da empresa',
      exhibitionStand: 'Stand de exposição',
      contactUs: 'Entre em contato conosco',
      activities: 'Atividades',
      attachments: 'Arquivos',
      marketplace: 'Mercado',
      videos: 'Vídeos',
      actions: {
        readMore: 'Leia mais',
        startChat: 'Iniciar chat',
        goToChat: 'Ir para o chat',
        contact: 'Contato',
        sendEmail: 'Enviar e-mail',
        shareDetails: 'Compartilhe os seus dados',
        purchase: 'Compra',
        success: 'Sucesso!',
        addEventReminder: 'Adicionar lembrete de evento',
        scheduleMeeting: 'Agendar reunião',
        detailsRequired: 'Este campo é obrigatório',
      },
      shareDetailsContent: {
        title: 'Obrigado por compartilhar detalhes yout!',
        subtitle: 'Um representante de {{sponsor}} entrará em contato com você em breve. Enquanto isso, você pode enviar uma pequena mensagem junto com seus detalhes na caixa abaixo.',
        message: 'Mensagem',
        shareDetails: 'Quero compartilhar meus dados pessoais, incluindo <1>meu número de telefone e e-mail</1> para ser contatado por {{sponsor}}',
      },
      results: {
        shareSuccess: 'Sua mensagem foi enviada com sucesso.',
      },
    },
    forms: {
      searchKeyword: 'Palavras-chave',
      tags: 'Tags',
    },
  },
  it: {
    data: {
      activities: 'Attività',
      exhibitionStand: 'Stand espositivo',
      attachments: 'File',
      videos: 'Video',
      companyDescription: "Descrizione dell'azienda",
      actions: {
        addEventReminder: 'Aggiungi promemoria evento',
        success: 'Successo!',
        startChat: 'Inizia la chat',
        readMore: 'Leggi di più',
        contact: 'Contatti',
        goToChat: 'Vai alla chat',
        sendEmail: 'Invia email',
        purchase: 'Acquisto',
        shareDetails: 'Condividi i tuoi dati',
        detailsRequired: 'Questo campo è obbligatorio',
        scheduleMeeting: 'Pianifica la riunione'
      },
      contactUs: 'Contattaci',
      shareDetailsContent: {
        shareDetails: "Voglio condividere i miei dati personali, incluso <1>il mio numero di telefono e l'email</1> per essere contattato da {{sponsor}}",
        message: 'Messaggio',
        title: 'Grazie per aver condiviso i tuoi dati!',
        subtitle: 'Un rappresentante di {{sponsor}} ti contatterà a breve. Nel frattempo, puoi inviare un breve messaggio insieme ai tuoi dati nella casella sottostante.'
      },
      marketplace: 'Mercato',
      results: {
        shareSuccess: 'Il tuo messaggio è stato inviato con successo.'
      }
    },
    title: {
      sponsors: 'Sponsor'
    },
    forms: {
      searchKeyword: 'Parole chiave',
      tags: 'Tag'
    }
  },
  ja: {
    data: {
      companyDescription: '会社概要',
      videos: '動画',
      activities: 'アクティビティ',
      actions: {
        readMore: 'もっと読む',
        goToChat: 'チャットに移動',
        contact: '連絡先',
        startChat: 'チャットを始める',
        success: '成功！',
        sendEmail: 'メールを送る',
        addEventReminder: 'イベントリマインダーを追加する',
        shareDetails: '詳細情報を共有する',
        purchase: '購入',
        scheduleMeeting: 'ミーティングをスケジュールする',
        detailsRequired: 'このフィールドは必須です'
      },
      contactUs: 'お問い合わせ',
      exhibitionStand: '展示スタンド',
      attachments: 'ファイル',
      marketplace: 'マーケットプレイス',
      shareDetailsContent: {
        message: 'メッセージ',
        title: 'あなたの詳細を共有してくれてありがとう！',
        subtitle: '{{sponsor}} の担当者からまもなくご連絡いたします。それまでの間、下のボックスに詳細とともに短いメッセージを送信できます。',
        shareDetails: '{{sponsor}} <1>から連絡を受ける電話番号やメールなどの個人情報を共有したい</1>'
      },
      results: {
        shareSuccess: 'メッセージは正常に送信されました。'
      }
    },
    forms: {
      searchKeyword: 'キーワード',
      tags: 'タグ'
    },
    title: {
      sponsors: 'スポンサー'
    }
  },
  zh: {
    title: {
      sponsors: '赞助商'
    },
    data: {
      exhibitionStand: '展台',
      activities: '活动',
      attachments: '文件',
      marketplace: '市场',
      companyDescription: '公司描述',
      videos: '影片',
      actions: {
        startChat: '开始聊天',
        readMore: '阅读更多',
        contact: '联系',
        goToChat: '去聊天',
        purchase: '購買',
        sendEmail: '发电子邮件',
        shareDetails: '分享你的详情',
        scheduleMeeting: '安排会议',
        addEventReminder: '添加活动提醒',
        success: '成功！',
        detailsRequired: '此字段为必填字段'
      },
      results: {
        shareSuccess: '您的消息已成功发送。'
      },
      shareDetailsContent: {
        title: '感谢您分享您的详细信息！',
        message: '消息',
        subtitle: '{{sponsor}} 的代表将很快与您联系。同时，您可以在下面的框中发送短消息以及您的详细信息。',
        shareDetails: '我想分享我的个人详细信息，包括<1>我的电话号码和电子邮件</1>将由 {{sponsor}} 联系'
      },
      contactUs: '联系我们'
    },
    forms: {
      searchKeyword: '关键词',
      tags: '標籤'
    }
  },
  ar: {
    title: {
      sponsors: 'الرعاة',
    },
    data: {
      companyDescription: 'وصف الشركة',
      exhibitionStand: 'منصة المعرض',
      contactUs: 'اتصل بنا',
      activities: 'الأنشطة',
      attachments: 'ملفات',
      marketplace: 'السوق',
      videos: 'مقاطع فيديو',
      actions: {
        readMore: 'اقرأ المزيد',
        startChat: 'ابدأ الدردشة',
        goToChat: 'انتقل إلى الدردشة',
        contact: 'جهة الاتصال',
        sendEmail: 'إرسال بريد إلكتروني',
        shareDetails: 'شارك التفاصيل الخاصة بك',
        purchase: 'شراء',
        success: 'النجاح!',
        addEventReminder: 'إضافة تذكير بالحدث',
        scheduleMeeting: 'جدول الاجتماع',
        detailsRequired: 'هذا الحقل مطلوب',
      },
      shareDetailsContent: {
        title: 'شكرا لمشاركة التفاصيل الخاصة بك!',
        subtitle: 'سيتصل بك ممثل {{sponsor}} قريبًا. في غضون ذلك، يمكنك إرسال رسالة قصيرة مع التفاصيل الخاصة بك في المربع أدناه.',
        message: 'رسالة',
        shareDetails: 'أرغب في مشاركة بياناتي الشخصية، بما في ذلك <1>رقم هاتفي وبريدي الإلكتروني</1> ليتم الاتصال به من خلال {{sponsor}}'
      },
      results: {
        shareSuccess: 'تم إرسال رسالتك بنجاح.',
      },
    },
    forms: {
      searchKeyword: 'الكلمات الدالة',
      tags: 'العلامات',
    },
  },
  tr: {
    title: {
      sponsors: 'Sponsorlar'
    },
    data: {
      companyDescription: 'Şirket Tanımı',
      exhibitionStand: 'Sergi standı',
      contactUs: 'Bize ulaşın',
      activities: 'Etkinlikler',
      attachments: 'Dosyalar',
      marketplace: 'Pazar Yeri',
      videos: 'Videolar',
      actions: {
        readMore: 'Devamını oku',
        startChat: 'Sohbet başlatin',
        goToChat: 'Sohbet başlatin',
        contact: 'İletişim',
        sendEmail: 'E-posta gönder',
        shareDetails: 'Bilgilerinizi paylaşın',
        purchase: 'Satın Alma',
        success: 'Başarı!',
        addEventReminder: 'Olay anımsatıcısı ekle',
        scheduleMeeting: 'Toplantı zamanlama',
        detailsRequired: 'Bu alan zorunludur'
      },
      shareDetailsContent: {
        title: 'Bilgilerinizi paylaştığınız için teşekkür ederiz!',
        subtitle: '{{sponsor}} temsilcisi kısa bir süre içinde sizinle iletişime geçecektir. Bu arada, aşağıdaki kutuya bilgilerinizle birlikte kısa bir mesaj gönderebilirsiniz.',
        message: 'Mesaj',
        shareDetails: '{{sponsor}} tarafından iletişim kurulacak <1>telefon numaramı ve e-postamı</1> da dahil olmak üzere kişisel bilgilerimi paylaşmak istiyorum'
      },
      results: {
        shareSuccess: 'Mesajınız başarıyla gönderildi.'
      }
    },
    forms: {
      searchKeyword: 'Anahtar kelimeler',
      tags: 'Etiketler'
    }
  },
  de: {
    title: {
      sponsors: 'Förderer'
    },
    data: {
      companyDescription: 'Beschreibung des Unternehmens',
      exhibitionStand: 'Messestand',
      contactUs: 'Kontaktiere uns',
      activities: 'Aktivitäten',
      attachments: 'Akten',
      marketplace: 'Marktplatz',
      videos: 'Videos',
      actions: {
        readMore: 'Lesen Sie mehr',
        startChat: 'Chat starten',
        goToChat: 'Gehe zum Chat',
        contact: 'Kontakt',
        sendEmail: 'E-Mail senden',
        shareDetails: 'Teilen Sie Ihre Daten',
        purchase: 'Kauf',
        success: 'Erfolg!',
        addEventReminder: 'Veranstaltungserinnerung hinzufügen',
        scheduleMeeting: 'Meeting planen',
        detailsRequired: 'Dieses Feld ist ein Pflichtfeld'
      },
      shareDetailsContent: {
        title: 'Danke, dass du deine Daten geteilt hast!',
        subtitle: 'Ein Vertreter von {{sponsor}} wird sich in Kürze mit Ihnen in Verbindung setzen. In der Zwischenzeit können Sie im Feld unten eine kurze Nachricht zusammen mit Ihren Daten senden.',
        message: 'Botschaft',
        shareDetails: 'Ich möchte meine persönlichen Daten teilen, einschließlich <1>meiner Telefonnummer und E-Mail</1>, um von {{sponsor}} kontaktiert zu werden'
      },
      results: {
        shareSuccess: 'Ihre Nachricht wurde erfolgreich gesendet.'
      }
    },
    forms: {
      searchKeyword: 'Schlüsselwörter',
      tags: 'Stichworte'
    }
  },
};
