import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Input from 'eventtia-ui-components/lib/Input';
import RadioButtons from 'eventtia-ui-components/lib/RadioButtons';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';
import DateInput from 'eventtia-ui-components/lib/DateInput';
import Control from 'eventtia-ui-components/lib/Control';
import CountryField from '../CountryField';
import RatingField from '../RatingField';
import FileField from '../FileField';
import INPUT_TYPES from '../../helpers/constants';

const {
  TEXT_FIELD,
  TEXT_AREA,
  CHECKBOX,
  RADIO,
  SELECT,
  CITY,
  DATE_TIME,
  IMAGE,
  FILE,
  RATING,
  LABEL,
  TERMS_CONDITIONS,
} = INPUT_TYPES;

const useStyles = makeStyles((theme) => ({
  simpleInput: {
    background: 'none',
    fontWeight: 'normal',
  },
  menu: {
    '& li': {
      width: '100%',
      display: 'flex',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  select: {
    '& > div': {
      padding: theme.spacing(1.5),
    },
  },
  inputLabel: {
    fontWeight: 'bold',
  },
}));

// HACK for procolombia
const makePositiveInteger = ({ target }) => {
  const num = target?.value;
  // eslint-disable-next-line no-param-reassign
  if (num) target.value = Math.max(0, (parseInt(num, 10) || 0));
};

const isControlledInput = (inputType) => (
  [CITY, DATE_TIME, CHECKBOX, SELECT, RATING].includes(inputType));

const CustomField = ({
  field, value: rawValue, inputRef, error, setFormValue, watchFormValues,
  getIdentifier, disabled,
}) => {
  const classes = useStyles();
  const {
    id,
    name,
    values,
    required,
    inputType: type,
    useRadioButtons,
    includeTime,
    lowerBound,
    upperBound,
  } = field;
  const identifier = useMemo(() => getIdentifier(id), [id]);
  const sharedInputProps = {
    id: identifier,
    name: identifier,
    label: required ? `${name}*` : name,
  };
  const formattedValues = values && values
    .map((val) => ({ label: val, value: val }));

  const value = isControlledInput(type) && watchFormValues ? (watchFormValues(identifier) || rawValue || '')
    : rawValue;

  useEffect(() => {
    if (inputRef) {
      const validations = {};
      if (required) validations.required = true;
      inputRef({ name: identifier }, validations);
    }
  }, [inputRef, identifier]);

  const isCurrency = name.includes('(USD)') || name.includes('(COP)');

  switch (type) {
    case TEXT_FIELD:
    case TEXT_AREA:
      return (
        <Input
          onBlur={isCurrency ? makePositiveInteger : undefined}
          className={classes.simpleInput}
          defaultValue={value}
          inputRef={inputRef}
          error={error}
          multiline={type === TEXT_AREA ? true : undefined}
          rows={type === TEXT_AREA ? 3 : undefined}
          rowsMax={type === TEXT_AREA ? 6 : undefined}
          {...sharedInputProps}
          disabled={disabled}
        />
      );
    case RADIO:
      return (
        <RadioButtons
          {...sharedInputProps}
          options={formattedValues}
          value={value}
          error={error}
          handleChange={(val) => { setFormValue(identifier, val); }}
          disabled={disabled}
        />
      );
    case SELECT:
      if (useRadioButtons) return (
        <RadioButtons
          {...sharedInputProps}
          options={formattedValues}
          value={value}
          error={error}
          handleChange={(val) => { setFormValue(identifier, val); }}
          disabled={disabled}
        />
      );
      return (
        <SelectInput
          className={classes.select}
          MenuProps={{ className: classes.menu }}
          options={[{ value: '', label: '' }, ...formattedValues]}
          handleChange={(val) => { setFormValue(identifier, val); }}
          value={value || ''}
          error={error}
          {...sharedInputProps}
          disabled={disabled}
        />
      );
    case CHECKBOX:
      return (
        <Control
          error={error}
          options={formattedValues}
          controlType="checkbox"
          value={value || []}
          handleChange={(val) => setFormValue(identifier, val)}
          {...sharedInputProps}
          disabled={disabled}
        />
      );
    case CITY:
      return (
        <CountryField
          mainTitle={sharedInputProps.label}
          handleChange={(newCityId) => setFormValue(identifier, newCityId)}
          identifiers={{
            city: identifier,
            region: `${identifier}-region`,
            country: `${identifier}-country`,
          }}
          error={error}
          value={value || {}}
          disabled={disabled}
        />
      );
    case DATE_TIME:
      return (
        <DateInput
          error={error}
          handleChange={(newDate) => setFormValue(identifier, newDate)}
          format="MM/DD/YYYY"
          value={value}
          timePicker={!!includeTime}
          maxDate={upperBound || undefined}
          minDate={lowerBound || undefined}
          {...sharedInputProps}
          disabled={disabled}
        />
      );
    case IMAGE:
    case FILE:
      return (
        <FileField
          {...sharedInputProps}
          image={type === IMAGE}
          value={value}
          error={error}
          setFormValue={setFormValue}
          disabled={disabled}
        />
      );
    case RATING:
      return (
        <RatingField
          label={sharedInputProps.label}
          small
          handleChange={(val) => setFormValue(identifier, val)}
          error={error}
          value={value}
        />
      );
    case LABEL:
      return (
        <Typography
          variant="subtitle2"
          dangerouslySetInnerHTML={{ __html: name }}
        />
      );
    case TERMS_CONDITIONS:
      return null;
    default:
      return (
        <Typography variant="subtitle2" className={classes.inputLabel}>
          {name}
        </Typography>
      );
  }
};

CustomField.propTypes = {
  getIdentifier: PropTypes.func.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.number,
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    inputType: PropTypes.number,
    useRadioButtons: PropTypes.bool,
    includeTime: PropTypes.bool,
    lowerBound: PropTypes.string,
    upperBound: PropTypes.string,
  }),
  value: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.array,
      PropTypes.shape({
        city: PropTypes.string,
        region: PropTypes.string,
        country: PropTypes.string,
      }),
    ]
  ),
  inputRef: PropTypes.func,
  watchFormValues: PropTypes.func,
  setFormValue: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomField.defaultProps = {
  inputRef: undefined,
  watchFormValues: undefined,
  setFormValue: undefined,
  field: {},
  value: undefined,
  error: undefined,
  disabled: false,
};

export default CustomField;
