import { SEND_REACTION, DELETE_REACTION } from '../../actions/messages';

const initialState = [
];

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_REACTION: {
      const { payload } = action;
      // if (state.length >= 60) return state;
      return [
        ...state, payload,
      ];
    }
    case DELETE_REACTION: {
      const { payload } = action;
      return state.filter((reaction) => reaction.uuid !== payload);
    }
    default:
      return state;
  }
};
