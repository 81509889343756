export default class ApiError extends Error {
  constructor(params = {}, ...otherParams) {
    super(...otherParams);

    if (Error.captureStackTrace) Error.captureStackTrace(this, ApiError);

    this.name = 'ApiError';
    this.status = params.status;
    this.message = params.message || params.messages || 'Something bad happened';
  }
}
