import { NEW_MESSAGE, MODERATED_MESSAGE_RECEIVED } from '../../actions/messages';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_MESSAGE: {
      const { channel, payload } = action;
      return {
        ...state,
        [channel]: state[channel] ? [...state[channel], ...payload] : payload,
      };
    }
    case MODERATED_MESSAGE_RECEIVED: {
      const { channel, payload: { uuid } } = action;
      const payload = { moderated: true, uuid: `mod-${uuid}` };
      return {
        ...state,
        [channel]: state[channel] ? [...state[channel], payload] : [payload],
      };
    }
    default:
      return state;
  }
};
