import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import DialogContentText from '@material-ui/core/DialogContentText';
import Modal from 'eventtia-ui-components/lib/Modal';
import Dialog from 'eventtia-ui-components/lib/Dialog';

const useStyles = makeStyles((theme) => ({
  dialogText: {
    padding: theme.spacing(3),
  },
}));

const DeleteConfirmationDialog = ({ open, handleClose, onConfirm }) => {
  const classes = useStyles();
  const { t } = useTranslation('socialWall');

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
      >
        <Dialog
          title={t('deleteConfirmation')}
          handleClose={handleClose}
          action={{ name: t('delete'), onClick: onConfirm }}
          secondaryAction={{ name: t('cancel'), onClick: handleClose }}
        >
          <DialogContentText className={classes.dialogText}>
            {t('deleteConfirmationText')}
          </DialogContentText>
        </Dialog>
      </Modal>
    </>
  );
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteConfirmationDialog;
