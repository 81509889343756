import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QrCodeIcon = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
      <g transform="translate(2.5 2.5)">
        <g>
          <path d="M3,9.667H9.667V3H3Zm1.667-5H8V8H4.667Z" transform="translate(-3 -3)" fill="currentColor" />
          <path d="M3,19.667H9.667V13H3Zm1.667-5H8V18H4.667Z" transform="translate(-3 -4.667)" fill="currentColor" />
          <path d="M13,3V9.667h6.667V3Zm5,5H14.667V4.667H18Z" transform="translate(-4.667 -3)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(13.32 13.32)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(8.334 8.333)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(10.014 10.014)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(8.334 11.694)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(10.014 13.32)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(11.693 11.694)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(11.693 8.333)" fill="currentColor" />
          <rect width="1.68" height="1.68" transform="translate(13.32 10.013)" fill="currentColor" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default QrCodeIcon;
