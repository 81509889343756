import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { flattenedRoutes } from '../../routes';
import { goToMainStage, setActiveSubpage } from '../../actions/app';

export default () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { loggedInRoutes, loggedOutRoutes } = flattenedRoutes;

  const pathTo = useCallback((id) => (
    loggedInRoutes.find(({ id: routeId }) => routeId === id)
    || loggedOutRoutes.find(({ id: routeId }) => routeId === id)
  ).path, [loggedInRoutes, loggedOutRoutes]);

  const urlTo = useCallback((id, paramValues = {}) => pathTo(id).replace(
    /:([^/]+)/g,
    (paramExpr) => {
      const paramName = paramExpr.replace(':', '').replace(/\(.+/, '');
      return paramValues[paramName] || params[paramName];
    }
  ), [pathTo, params]);

  const goTo = useCallback((id, paramValues, queryParams = {}) => {
    history.push(urlTo(id, paramValues));
    const { module } = queryParams;
    dispatch(module ? setActiveSubpage(queryParams) : goToMainStage());
  }, [urlTo, history, dispatch]);

  return {
    goTo,
    pathTo,
    urlTo,
    goBack: history.goBack,
    loggedInRoutes,
    loggedOutRoutes,
  };
};
