import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

const withForm = (formProps = {}) => (Component) => {
  const { autoComplete, required, mode } = formProps;

  const Form = ({
    setSubmitEnabled: outerSetSubmitEnabled, onSubmit,
    formRef, defaultValues, containerClassName, ...otherProps
  }) => {
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const {
      handleSubmit, register, errors, setValue, watch, setError,
      clearError, getValues,
    } = useForm({ defaultValues, mode });

    const formValues = required && Object.values(watch());
    const registerInput = (validations = {}) => {
      if (required) return register({
        ...validations,
        required: 'This field is required',
      });
      return register(validations);
    };

    useEffect(() => {
      if (!required) return;
      const isSubmitEnabled = formValues && formValues.every((value) => (
        Array.isArray(value) ? !!value.length : !!value
      ));

      if (outerSetSubmitEnabled) outerSetSubmitEnabled(isSubmitEnabled);
      else setSubmitEnabled(isSubmitEnabled);
    }, [formValues, outerSetSubmitEnabled]);

    return (
      <form
        className={containerClassName}
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete={autoComplete}
      >
        <Component
          registerInput={registerInput}
          submitEnabled={submitEnabled}
          errors={errors}
          setFormValue={setValue}
          setError={setError}
          clearError={clearError}
          getValues={getValues}
          watchFormValues={watch}
          {...otherProps}
        />
      </form>
    );
  };
  Form.propTypes = {
    setSubmitEnabled: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    formRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    defaultValues: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ])),
      PropTypes.shape({}),
    ])),
    containerClassName: PropTypes.string,
  };
  Form.defaultProps = {
    formRef: undefined,
    setSubmitEnabled: undefined,
    defaultValues: {},
    containerClassName: undefined,
  };
  return Form;
};

withForm.propTypes = {
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  mode: PropTypes.oneOf(['onSubmit', 'onBlur', 'onChange']),
};

withForm.defaultProps = {
  autoComplete: 'off',
  required: true,
  mode: 'onSubmit',
};

export default withForm;
