import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import Button from 'eventtia-ui-components/lib/Button';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from 'eventtia-ui-components/lib/Tooltip';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import clsx from 'clsx';
import { logout, setActiveSubpage } from '../../actions/app';
import subpages, { buildCustomSubpage, hackedIcons } from '../../helpers/subpages';
import getVisibleWorkshops from '../../helpers/workshops';
import {
  getCurrentAttendee,
  getCurrentParticipant,
  getAvailableModules,
  getModuleByType,
} from '../../helpers/getters';
import { canChat } from '../../helpers/chat';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useRoutes from '../../hooks/useRoutes';
import useParams from '../../hooks/useParams'; // VS-PT-HACK
import useEventUriHack from '../../hooks/hacks/useEventUriHack';

const useSidebarButtonStyles = makeStyles((theme) => ({
  mobileMenuItem: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  activeMobileMenuItem: {
    color: theme.palette.primary.main,
  },
  mobileMenuItemText: {
    justifyContent: 'flex-start',
    fontSize: 14,
    margin: theme.spacing(1, 0),
    color: theme.palette.common.white,
  },
  activeMenuItem: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.navbar.main,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.navbar.dark,
    },
  },
  menuItem: {
    color: theme.palette.common.white,
  },
}));

const SidebarButton = ({ text, active, mobile, ...other }) => {
  const classes = useSidebarButtonStyles();
  return mobile ? (
    <div className={clsx(classes.mobileMenuItem, active && classes.activeMobileMenuItem)}>
      <Button
        aria-label={text}
        variant="tertiary"
        className={clsx(classes.mobileMenuItemText, active && classes.activeMenuItem)}
        icon={other.children}
        {...other}
      >
        {text}
      </Button>
    </div>
  ) : (
    <Tooltip
      title={text}
      placement="right"
    >
      <IconButton
        className={active ? classes.activeMenuItem : classes.menuItem}
        aria-label={text}
        {...other}
      />
    </Tooltip>
  );
};

SidebarButton.propTypes = {
  mobile: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

SidebarButton.defaultProps = {
  active: false,
};

const findHackedIcon = (eventUri, type, name, icon) => {
  const hackedIconsForEvent = hackedIcons[eventUri];
  if (!hackedIconsForEvent) return undefined;
  if (type === 'Custom') return hackedIconsForEvent[icon];
  return hackedIconsForEvent[name];
};

const SubpageButtonBase = ({
  subpage: {
    names,
    name,
    Icon,
    icon,
    type,
  },
  setActiveSubpage: dispatchSetActiveSubpage,
  activeSubpage, showBadge, closeMobileSidebar, onClick,
}) => {
  const { t } = useTranslation('sidebar');
  const { locale, eventUri } = useParams(); // VS-PT-HACK
  const theme = useTheme();
  const defaultModuleText = names ? names[locale] : t(`subpages.${name}`);
  const showMobileNavbar = useMediaQuery(theme.breakpoints.down('sm'));

  const text = type === 'Custom' ? name : defaultModuleText;
  const PostHackIcon = findHackedIcon(eventUri, type, name, icon) || Icon;
  return (
    <SidebarButton
      onClick={() => {
        if (onClick) onClick();
        else dispatchSetActiveSubpage({ module: name });
        if (showMobileNavbar && closeMobileSidebar) closeMobileSidebar();
      }}
      text={text}
      active={name === activeSubpage.module}
      mobile={showMobileNavbar}
    >
      <Badge color="secondary" variant="dot" overlap="circle" invisible={!showBadge}>
        <PostHackIcon />
      </Badge>
    </SidebarButton>
  );
};

SubpageButtonBase.propTypes = {
  closeMobileSidebar: PropTypes.func,
  onClick: PropTypes.func,
  subpage: PropTypes.shape({
    names: PropTypes.shape({
      es: PropTypes.string,
      en: PropTypes.string,
      fr: PropTypes.string,
    }),
    name: PropTypes.string,
    Icon: PropTypes.elementType,
    icon: PropTypes.string,
    showBadge: PropTypes.func,
    type: PropTypes.string,
  }).isRequired,
  setActiveSubpage: PropTypes.func.isRequired,
  activeSubpage: PropTypes.shape({
    module: PropTypes.string,
  }).isRequired,
  showBadge: PropTypes.bool.isRequired,
};

SubpageButtonBase.defaultProps = {
  onClick: undefined,
  closeMobileSidebar: undefined,
};

const mapStateToProps = (state, { subpage: { showBadge } }) => ({
  activeSubpage: state.app.activeSubpage,
  showBadge: !!showBadge?.(state),
});
const SubpageButton = connect(mapStateToProps, { setActiveSubpage })(SubpageButtonBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: theme.spacing(8),
    zIndex: theme.zIndex.modal + 1,
    border: 'none',
  },
  mobileRoot: {
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.modal + 1,
  },
  paper: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.palette.navbar.main,
    color: theme.palette.darkGrey.light,
    boxShadow: theme.customShadows.small,
    '& .simplebar-scrollbar:before': {
      background: 'white',
    },
  },
  simplebar: {
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    padding: theme.spacing(3, 1),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  whiteSpace: {
    height: theme.spacing(6),
  },
  mediumWhiteSpace: {
    flex: 0.5,
    minHeight: theme.spacing(4),
  },
  longWhiteSpace: {
    flex: 1,
    minHeight: theme.spacing(4),
  },
  menuTitle: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    margin: theme.spacing(9, 3, 3),
  },
}));

const buildDefaultSubpage = (module) => ({
  ...subpages[module.type],
  names: module.name,
  id: module.id,
});

const Sidebar = ({
  logout: dispatchLogout, entities, appSettings, sponsors, open, onClose, activeSubpage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('sidebar');
  const theme = useTheme();
  const { locale } = useParams();
  const [settings] = Object.values(appSettings);
  const { modules } = settings;
  const currentAttendee = getCurrentAttendee(entities);
  const currentParticipant = getCurrentParticipant(entities);
  const activeModules = ['Workshops', 'Attendees', 'Custom', 'AttendeeProfile', 'Sponsors', 'Surveys', 'Speakers', 'SocialWall', 'AttendeeLeads', 'QASessions', 'Quizzes'];
  const { visibleWorkshops } = getVisibleWorkshops(entities, false);
  const availableModules = getAvailableModules(modules, currentAttendee.attendeeType.id,
    locale, activeModules, currentParticipant, visibleWorkshops);
  const doubleSponsors = getModuleByType(modules, 'Sponsors');
  const hideMainStage = useEventUriHack('hideMainStage');

  const modulesToShow = availableModules.filter(({ type }) => (
    !['AttendeeProfile'].includes(type)
  )).map((module) => {
    if (module.type === 'Custom') return buildCustomSubpage(module, locale, currentAttendee);
    return buildDefaultSubpage(module);
  });

  const homeModule = getModuleByType(modules, 'Home');
  const { goTo } = useRoutes();
  const goToLanding = () => {
    if (activeSubpage.module !== 'Home') goTo('home', {}, { module: 'Home' });
  };

  const goToMainStage = () => {
    goTo('stage');
  };

  const path = window.location.pathname.split('/');
  // this will explode if an event has home as its eventuri.
  // in order to avoid the problem, we created an event with home as its eventUri. EZ.
  const isInHome = path.includes('home');
  const mainStageShouldAlsoRedirect = isInHome || activeSubpage.module === 'Home';

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chatModule = modules?.find(({ type }) => type === 'Chat');
  const channels = chatModule?.customParams?.channels || {};

  const availableChatChannel = Object.values(channels).find(({ availableFor }) => (
    availableFor.includes(currentAttendee.attendeeType.id)
  ));
  const chatEnabled = canChat(settings, currentAttendee.attendeeType.id) || availableChatChannel;

  const myProfileModule = availableModules.find(({ type }) => type === 'AttendeeProfile');
  return (
    <Drawer
      classes={{
        paper: clsx(
          mobile ? classes.mobileRoot : classes.root,
          classes.paper
        ),
      }}
      variant={mobile ? 'temporary' : 'permanent'}
      open={open}
    >
      <SimpleBar className={classes.simplebar}>
        <div className={classes.content}>
          {mobile && (
            <Typography className={classes.menuTitle} variant="subtitle1">
              {t('title.menu')}
            </Typography>
          )}
          {myProfileModule && (
            <>
              <SubpageButton
                subpage={{
                  ...subpages.AttendeeProfile,
                  names: myProfileModule.name,
                }}
                closeMobileSidebar={onClose}
              />
              <div className={classes.mediumWhiteSpace} />
            </>
          )}
          {homeModule?.enabled && (
            <SubpageButton
              subpage={buildDefaultSubpage(homeModule)}
              onClick={goToLanding}
              closeMobileSidebar={onClose}
            />
          )}
          {!hideMainStage && (
            <SubpageButton
              subpage={homeModule?.enabled ? subpages.mainStage : subpages.homelessMainStage}
              onClick={mainStageShouldAlsoRedirect ? goToMainStage : undefined}
              closeMobileSidebar={onClose}
            />
          )}
          {modulesToShow.map((module) => (
            <SubpageButton
              key={module.id}
              subpage={module}
              closeMobileSidebar={onClose}
            />
          ))}
          {!doubleSponsors && Object.keys(sponsors).length > 0 && (
            <SubpageButton subpage={subpages.Sponsors} closeMobileSidebar={onClose} />
          )}
          {chatEnabled && (
            <SubpageButton
              subpage={{
                ...subpages.Messages,
                names: chatModule?.name,
              }}
              closeMobileSidebar={onClose}
            />
          )}
          <div className={classes.longWhiteSpace} />
          <SubpageButton
            subpage={subpages.settings}
            closeMobileSidebar={onClose}
          />
          <SidebarButton
            onClick={() => {
              dispatchLogout();
              if (mobile && onClose) onClose();
            }}
            text={t('actions.logOut')}
            mobile={mobile}
          >
            <PowerSettingsNewIcon />
          </SidebarButton>
        </div>
      </SimpleBar>
    </Drawer>
  );
};

const mapStateToSidebarProps = ({
  entities,
  entities: { appSettings, sponsors },
  app: { activeSubpage },
}) => ({
  entities,
  appSettings,
  sponsors,
  activeSubpage,
});

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  appSettings: PropTypes.objectOf(CustomPropTypes.appSettings).isRequired,
  sponsors: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  activeSubpage: CustomPropTypes.activeSubpage.isRequired,
};

Sidebar.defaultProps = {
  sponsors: {},
  open: undefined,
  onClose: undefined,
};

export default connect(mapStateToSidebarProps, { logout })(Sidebar);
