import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import BackButton from '../../components/BackButton';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import SocialPost from '../../components/SocialPost';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-7.5),
    padding: theme.spacing(0, 4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 0),
    marginLeft: theme.spacing(2),
  },
  mobileModuleName: {
    width: '100%',
    textAlign: 'center',
  },
}));

const SocialPostShow = ({
  post,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BackButton />
        <Typography
          className={clsx(classes.moduleName, mobile && classes.mobileModuleName)}
          variant="subtitle1"
        >
          Social Wall
        </Typography>
      </div>
      <SocialPost
        post={post}
        showAllComments
      />
    </div>
  );
};

SocialPostShow.propTypes = {
  post: CustomPropTypes.socialPost,
};

SocialPostShow.defaultProps = {
  post: {},
};

export default SocialPostShow;
