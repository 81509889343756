import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Chip from 'eventtia-ui-components/lib/Chip';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { connect } from 'react-redux';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3.25),
    width: '100%',
    minWidth: 236,
    height: 150,
    boxShadow: theme.customShadows.default,
    border: '1px solid #70707019',
    borderRadius: 10,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  containerLevelTwo: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  stampContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  stamp: {
    overflow: 'hidden',
    margin: '6px 8px',
    padding: theme.spacing(1, 1.5),
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    backgroundColor: 'white',
    left: -1,
    bottom: -1,
    borderRadius: 10,
    maxWidth: 250,
  },
  sponsorLogo: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
    objectFit: 'contain',
    height: 38,
    maxWidth: 110,
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontSize: 12,
    color: theme.palette.primary.main,
    textAlign: 'start',
    fontWeight: 'bold',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
  },
  attendanceModeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  attendanceModeChip: {
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const SponsorCardAlt = ({ sponsor, onBlockClick, sponsorLevelTwo, events }) => {
  const {
    bannerImage: {
      filename: bannerName,
      large: bannerImage,
    },
    logo: {
      filename: logoName,
      large: logo,
    },
    name,
    attendanceMode,
  } = sponsor;
  const classes = useStyles();
  const { t } = useTranslation('sponsors');

  const stringAttendanceMode = `attendanceMode.${attendanceMode}`;
  const [{ attendanceMode: eventAttendanceMode }] = Object.values(events);

  const { HYBRID } = ATTENDANCE_MODE;

  return (
    <button
      type="button"
      className={clsx(classes.container, sponsorLevelTwo && classes.containerLevelTwo)}
      onClick={() => onBlockClick(sponsor)}
      style={{ backgroundImage: bannerName ? `url('${bannerImage}')` : 'url(/default-banner-sponsors.png)' }}
    >
      <div className={classes.stamp}>
        <div className={classes.stampContainer}>
          {!!logoName && (
            <img className={classes.sponsorLogo} src={logo} alt="" />
          )}
          <div className={classes.nameWrapper}>
            <Typography className={classes.name} variant="body2">
              {name}
            </Typography>
          </div>
        </div>
        {eventAttendanceMode === HYBRID && attendanceMode && (
          <div className={classes.attendanceModeContainer}>
            <Chip
              label={t(`global:${stringAttendanceMode}`)}
              size="small"
              className={classes.attendanceModeChip}
            />
          </div>
        )}
      </div>
    </button>
  );
};

SponsorCardAlt.propTypes = {
  sponsor: CustomPropTypes.sponsor,
  onBlockClick: PropTypes.func.isRequired,
  sponsorLevelTwo: PropTypes.bool,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
};

SponsorCardAlt.defaultProps = {
  sponsor: {},
  sponsorLevelTwo: false,
};

const mapStateToProps = ({
  entities: {
    events,
  },
}) => ({
  events,
});

export default connect(mapStateToProps)(SponsorCardAlt);
