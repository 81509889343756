import React, { useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import SyncIcon from '@material-ui/icons/Sync';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Message from 'eventtia-ui-components/lib/Message';
import SearchInput from 'eventtia-ui-components/lib/SearchInput';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import AttendeeLeadShow from '../AttendeeLeadShow';
import SubpageTitle from '../../components/SubpageTitle';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import AttendeeLeadCard from '../../components/AttendeeLeadCard';
import TabletExitButton from '../../components/TabletExitButton';
import ResponsiveButton from '../../components/ResponsiveButton';
import ScanQrFab from '../../components/ScanQrFab';
import {
  getModuleNames,
  getModuleByType,
  getCurrentAttendee,
} from '../../helpers/getters';
import {
  syncAttendeeLeads,
  downloadAttendeeLeads,
  updateAttendeeLead,
} from '../../actions/attendeeLeads';
import { goToAttendees } from '../../actions/app';
import useDidUpdate from '../../hooks/useDidUpdate';
import noActivitiesImgSrc from '../../assets/noActivities.png';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const { VIRTUAL } = ATTENDANCE_MODE;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
  },
  message: {
    margin: theme.spacing(6, 6, -2),
  },
  listContainer: {
    maxHeight: '100%',
    padding: theme.spacing(3.5, 2.5, 2.5, 2.5),
    [theme.breakpoints.up('md')]: {
      minWidth: 320,
      padding: theme.spacing(6),
    },
  },
  moduleName: {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
  },
  moduleDescription: {
    color: theme.palette.darkGrey.light,
  },
  actions: {
    margin: theme.spacing(4, 0),
    minHeight: 48,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listActions: {
    flexShrink: 0,
    marginRight: 'auto',
  },
  loader: {
    zIndex: 3,
  },
  noLeadsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 80,
  },
  noLeadsImage: {
    height: 170,
  },
  noLeadsDescription: {
    width: 300,
    marginTop: theme.spacing(3),
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    color: theme.palette.darkGrey.light,
  },
  button: {
    marginTop: theme.spacing(6),
  },
}));

const AttendeeLeads = ({
  entities,
  attendeeLeads,
  attendeeTypes,
  isFetching,
  downloading,
  downloaded,
  syncing,
  error,
  appSettings,
  uuid,
  syncAttendeeLeads: dispatchSyncAttendeeLeads,
  downloadAttendeeLeads: dispatchDownloadAttendeeLeads,
  updateAttendeeLead: dispatchUpdateAttendeeLead,
  goToAttendees: dispatchGoToAttendees,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendeeLeads');

  const { eventUri } = useParams();

  const [settings] = Object.values(appSettings);
  const moduleTitle = getModuleNames(appSettings, 'AttendeeLeads');
  const attendeeLeadsModule = getModuleByType(settings.modules, 'AttendeeLeads');
  const description = attendeeLeadsModule.customParams?.description;
  const emptyLeads = !Object.values(attendeeLeads).length;

  const [message, setMessage] = useState();
  useDidUpdate(() => {
    if (error) setMessage({ type: 'error', text: error });
  }, [error]);

  const [downloadDisabled, setDownloadDisabled] = useState(false);
  useDidUpdate(() => {
    let timeout;
    if (downloaded) timeout = setTimeout(() => {
      setDownloadDisabled(false);
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
  }, [downloaded]);

  const searchRef = useRef(null);
  const [keyword, setKeyword] = useState();
  const search = useCallback(() => {
    setKeyword(searchRef.current?.value?.toLocaleLowerCase());
  }, [setKeyword]);
  const clearSearch = useCallback(() => {
    if (searchRef.current) searchRef.current.value = '';
    setKeyword();
  }, [setKeyword]);
  const filteredLeads = keyword ? Object.values(attendeeLeads).filter(
    ({ fullName, email, company }) => [fullName, email, company].some(
      (field) => field?.toLocaleLowerCase()?.includes(keyword)
    )
  ) : Object.values(attendeeLeads);

  const activeLead = Object.values(attendeeLeads).find(({ uuid: leadUuid }) => uuid === leadUuid);

  const updateLead = (editedLead) => {
    dispatchUpdateAttendeeLead(editedLead, eventUri);
  };

  const currentAttendee = getCurrentAttendee(entities);
  const { attendanceMode } = attendeeTypes[currentAttendee.attendeeType.id];

  return (
    <>
      <div className={classes.root}>
        <Loader loading={isFetching} variant="absolute" className={classes.loader} />
        <Message
          className={classes.message}
          type={message?.type}
          onClose={() => setMessage()}
          message={message?.text}
        />
        {!activeLead && (
          <TabletExitButton />
        )}
        {activeLead ? (
          <AttendeeLeadShow
            attendeeLead={activeLead}
            onSubmit={updateLead}
            defaultValues={activeLead}
          />
        ) : (
          <div className={classes.listContainer}>
            <SubpageTitle text={moduleTitle} />
            {description && (
              <Typography className={classes.moduleDescription} variant="body1">
                {description}
              </Typography>
            )}
            {!emptyLeads ? (
              <div className={classes.actions}>
                <div className={classes.filters}>
                  <SearchInput
                    placeholder={t('global:forms.searchKeyword')}
                    inputRef={searchRef}
                    onSubmit={search}
                    onClear={clearSearch}
                    className={classes.search}
                    fullWidth={false}
                    margin="dense"
                  />
                </div>
                <div className={classes.listActions}>
                  <ResponsiveButton
                    onClick={() => {
                      setDownloadDisabled(true);

                      dispatchDownloadAttendeeLeads(eventUri).then(({ error: downloadError }) => {
                        if (!downloadError) setMessage({
                          type: 'success',
                          text: t('info.downloadSuccess'),
                        });
                        else setDownloadDisabled(false);
                      });
                    }}
                    icon={<GetAppIcon />}
                    label={t('actions.download')}
                    disabled={downloading || downloadDisabled}
                  />
                  <ResponsiveButton
                    onClick={() => {
                      dispatchSyncAttendeeLeads(eventUri).then(({ error: syncError }) => {
                        if (!syncError) setMessage({
                          type: 'success',
                          text: t('info.syncSuccess'),
                        });
                      });
                    }}
                    icon={<SyncIcon />}
                    label={t('actions.syncLeads')}
                    disabled={syncing}
                  />
                </div>
              </div>
            ) : (
              <div className={classes.noLeadsContainer}>
                <img src={noActivitiesImgSrc} className={classes.noLeadsImage} alt="no-activities" />
                <Typography className={classes.noLeadsDescription} variant="body1">
                  {t('info.noLeads')}
                </Typography>
                <Button
                  onClick={dispatchGoToAttendees}
                  className={classes.button}
                >
                  {t('actions.exploreAttendees')}
                </Button>
              </div>
            )}
            <Grid container spacing={2}>
              {filteredLeads.map((attendeeLead) => (
                <Grid item md={6} xs={12} key={attendeeLead.uuid}>
                  <AttendeeLeadCard
                    attendeeLead={attendeeLead}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
      {(!activeLead && attendanceMode !== VIRTUAL) && (
        <ScanQrFab eventUri={eventUri} />
      )}
    </>
  );
};

AttendeeLeads.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  attendeeLeads: PropTypes.objectOf(CustomPropTypes.attendeeLead),
  attendeeTypes: PropTypes.objectOf(CustomPropTypes.attendeeType).isRequired,
  uuid: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloaded: PropTypes.bool.isRequired,
  syncing: PropTypes.bool.isRequired,
  error: PropTypes.string,
  appSettings: CustomPropTypes.appSettings.isRequired,
  syncAttendeeLeads: PropTypes.func.isRequired,
  downloadAttendeeLeads: PropTypes.func.isRequired,
  updateAttendeeLead: PropTypes.func.isRequired,
  goToAttendees: PropTypes.func.isRequired,
};

AttendeeLeads.defaultProps = {
  attendeeLeads: {},
  error: undefined,
  uuid: undefined,
};

const mapStateToProps = ({
  attendeeLeads,
  entities,
  entities: {
    appSettings,
    attendeeTypes,
  },
  fetchStatus: {
    attendeeLeads: {
      isFetching,
      error,
    },
    downloadAttendeeLeads: {
      isFetching: downloading,
      success: downloaded,
    },
    batchUpdateAttendeeLeads: {
      isFetching: syncing,
    },
  },
  app: {
    activeSubpage: {
      uuid,
    },
  },
}) => ({
  entities,
  attendeeTypes,
  attendeeLeads,
  appSettings,
  isFetching,
  error,
  uuid,
  downloading,
  downloaded,
  syncing,
});

export default connect(mapStateToProps, {
  syncAttendeeLeads,
  downloadAttendeeLeads,
  updateAttendeeLead,
  goToAttendees,
})(AttendeeLeads);
