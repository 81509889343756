// import {
//   SET_ACTIVE_SUBPAGE,
//   GO_TO_PREVIOUS_SUBPAGE,
//   SET_ATTENDANCE_TYPE,
// } from '../../actions/app';
import {
  LOAD_ATTENDEE_LEADS,
  ADD_ATTENDEE_LEAD,
  UPDATE_ATTENDEE_LEAD,
  DELETE_ATTENDEE_LEAD,
} from '../../actions/attendeeLeads';
// import subpages from '../../helpers/subpages';

const initialState = {
};

const removeDeleted = (attendeeLeads) => {
  const result = {};
  Object.values(attendeeLeads).forEach((attendeeLead) => {
    if (!attendeeLead.deleted) result[attendeeLead.uuid] = attendeeLead;
  });
  return result;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ATTENDEE_LEADS: {
      return removeDeleted(action.attendeeLeads);
    }
    case UPDATE_ATTENDEE_LEAD:
    case ADD_ATTENDEE_LEAD: {
      const { attendeeLead } = action;
      return {
        ...state,
        [attendeeLead.uuid]: attendeeLead,
      };
    }
    case DELETE_ATTENDEE_LEAD: {
      const { attendeeLead } = action;
      const newState = { ...state };
      delete newState[attendeeLead.uuid];
      return newState;
    }
    default:
      return state;
  }
};
