import React from 'react';
import PropTypes from 'prop-types';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';
import useChannel from '../../hooks/useChannel';

const AttendeeBroadcastProvider = ({ currentAttendeeId, children }) => {
  const broadcast = useChannel(`attendee:${currentAttendeeId}`);

  return (
    <AttendeeBroadcastContext.Provider value={broadcast}>
      {children}
    </AttendeeBroadcastContext.Provider>
  );
};

AttendeeBroadcastProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentAttendeeId: PropTypes.string.isRequired,
};

export default AttendeeBroadcastProvider;
