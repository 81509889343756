import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import useStringTransforms from '../../hooks/useStringTransforms';

import { urlFixed } from '../../helpers/strings';

const urlRegexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const cityString = (value) => {
  if (!value.city) return value;
  const { city, region, country } = value;
  return `${city}${region ? `, ${region}` : ''}${country ? `, ${country}` : ''}`;
};

const CustomFieldValue = ({ field, value, classes }) => {
  const { toSentenceCase } = useStringTransforms();

  const { name, inputType: type } = field;
  switch (type) {
    case 6:
      return value?.originalUrl ? <div><img className={classes.img} src={value.large} alt={name.replace('_', ' ')} /></div> : null;
    case 9:
      return value?.originalUrl ? <a className={classes.anchorValue} target="_blank" href={value?.originalUrl} rel="noopener noreferrer">{value.name}</a> : null;
    case 8:
      return <Typography variant="subtitle1" className={classes.value}>{cityString(value)}</Typography>;
    default:
      if (name === 'city_id') return <Typography variant="subtitle1" className={classes.value}>{cityString(value)}</Typography>;
      if (Array.isArray(value)) return <Typography variant="subtitle1" className={classes.value}>{toSentenceCase(value.join(', '))}</Typography>;
      if (urlRegexp.test(value)) return <Typography variant="subtitle1" className={classes.anchorValue}><a href={urlFixed(value)} target="_blank" rel="noopener noreferrer">{value}</a></Typography>;
      if (emailRegexp.test(value)) return <Typography variant="subtitle1" className={classes.value}><a href={`mailto:${value}`}>{value}</a></Typography>;
      return value && <Typography variant="subtitle1" className={classes.value}><div dangerouslySetInnerHTML={{ __html: value }} /></Typography>;
  }
};

CustomFieldValue.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    inputType: PropTypes.number,
    name: PropTypes.string,
  }),
  value: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.array,
      PropTypes.shape({
        city: PropTypes.string,
        region: PropTypes.string,
        country: PropTypes.string,
      }),
    ]
  ),
  classes: PropTypes.shape({
    value: PropTypes.string,
    img: PropTypes.string,
    anchorValue: PropTypes.string,
  }),
};

CustomFieldValue.defaultProps = {
  field: {},
  value: undefined,
  classes: {},
};

export default CustomFieldValue;
