/* eslint camelcase: 2 */
/* eslint dot-notation: [2, {"allowPattern": "^[a-z]+(_[a-z]+)+$"}] */
import { camelize } from 'humps';
import { getTokenContent } from './auth';
import parseCustomFields from './customFields';

import localeReplacementTable from './localeRemaps';

export const getEventUri = () => window.location.pathname.split('/')[2];

export const getLocale = ({ bypassLocaleMapping = false } = {}) => {
  const windowLocale = window.location.pathname.split('/')[1] || 'en';
  const locale = Object.keys(localeReplacementTable).includes(windowLocale) && !bypassLocaleMapping
    ? localeReplacementTable[windowLocale] : windowLocale; // VS-PT-HACK
  return locale;
};

export const getEntity = (entities, key) => (entities[key] && Object.values(entities[key])[0]);

export const getEntityById = (entities, key, id) => (entities[key] && entities[key][id]);

export const getSponsorByAttendee = (sponsors, attendeeId) => (Object
  .values(sponsors).find(({ attendee }) => attendee?.id === attendeeId)
);

export const getCurrentAttendeeId = () => {
  const eventUri = getEventUri();
  return String(getTokenContent(eventUri)?.['attendee_id']);
};

export const getCurrentAttendee = (entities, options) => {
  const attendeeId = getCurrentAttendeeId();

  let attendee = getEntityById(entities, 'attendees', attendeeId);

  if (options?.parseCustomFields && attendee?.fields) attendee = {
    ...attendee,
    ...parseCustomFields(attendee?.fields, entities.attendeeTypeCustomFields),
  };

  return attendee;
};

export const getCurrentParticipant = (entities, businessConferenceId = null) => {
  const { businessConferenceParticipants } = entities;
  const currentAttendee = getCurrentAttendee(entities);

  if (!currentAttendee) return undefined;

  const { businessConferenceParticipants: associatedParticipants } = currentAttendee;
  const participantId = associatedParticipants.map(({ id }) => id);

  return participantId && businessConferenceParticipants && (
    businessConferenceId ? (
      Object.values(businessConferenceParticipants).find((participant) => {
        const conferenceId = participant?.businessConference?.id;
        return (
          participantId.includes(participant.id) && conferenceId === businessConferenceId
        );
      })
    ) : (
      getEntityById(entities, 'businessConferenceParticipants', participantId[0])
    )
  );
};

export const getAttendanceModeForCurrentAttendee = (entities) => {
  const currentAttendee = getCurrentAttendee(entities);
  const currentAttendeeTypeId = currentAttendee?.attendeeType?.id;
  return entities?.attendeeTypes?.[currentAttendeeTypeId]?.attendanceMode;
};

export const getModuleByType = (modules, type) => (
  modules.find(({ type: moduleType }) => moduleType === type)
);

export const getAvailableModules = (modules, attendeeTypeId, locale,
  activeModules, currentParticipant, visibleWorkshops) => (
  modules.filter((module) => {
    const { availableIn, availableForAttendeeTypes } = module.customParams;

    return module.enabled
      && (!attendeeTypeId || !availableForAttendeeTypes
        || availableForAttendeeTypes.includes(attendeeTypeId))
      && (!locale || !availableIn || availableIn.includes(locale))
      && (activeModules.includes(module.type));
  }).filter(({ type, customParams }) => {
    if (type === 'Attendees') {
      const { chatEnabledParticipantTypes, attendeesListEnabled } = customParams;
      return (
        !!currentParticipant
        || chatEnabledParticipantTypes?.includes(attendeeTypeId)
        || attendeesListEnabled?.includes(attendeeTypeId)
      );
    }
    if (type === 'Workshops') return (
      !!currentParticipant || !!visibleWorkshops.length
    );
    return true;
  }).sort((a, b) => a.order - b.order)
);

export const getCustomAvailableModules = (modules, currentAttendee) => {
  const locale = getLocale();
  return modules?.filter(({
    type, enabled, customParams: { availableIn, availableForAttendeeTypes: allowedTypes },
  }) => (
    type === 'Custom' && enabled && availableIn && availableIn.includes(locale)
    && currentAttendee && allowedTypes.includes(currentAttendee.attendeeType.id)
  )).sort((a, b) => a.order - b.order);
};

export const getGroupedCustomFields = (fields = {}, visibleAttendeeTypes = []) => {
  const groupedFields = {};
  Object.values(fields)
    .forEach(({
      id, internalId, filterable, availablePublicListings, attendeeType: { id: attendeeTypeId },
    }) => {
      if (visibleAttendeeTypes.includes(attendeeTypeId) && typeof internalId === 'number' && filterable
        && availablePublicListings) groupedFields[internalId] = fields[id];
    });
  return Object.values(groupedFields);
};

export const getCustomFieldByName = (attendeeTypeCustomFields, attendeeTypeId, fieldName) => (
  Object.values(attendeeTypeCustomFields).find((customField) => (
    customField.attendeeType.id === attendeeTypeId && customField.name === fieldName)));

export const getModuleNames = (appSettings, moduleType) => {
  const locale = getLocale();
  const [eventSetting] = Object.values(appSettings);
  const { modules } = eventSetting;
  const module = Object.values(modules).filter((mod) => mod.type === moduleType);
  if (module.length > 0) return module[0].name[locale];
  return undefined;
};

export const getCurrentPerson = (
  attendee,
  businessConferenceParticipants,
  attendeeTypes,
  attendeeTypeCustomFields,
  ratings,
  denyList,
  recommendations
) => {
  const {
    businessConferenceParticipants: associatedParticipants,
    attendeeType,
    canScheduleMeetings,
  } = attendee;
  const attendeeTypeId = attendeeType?.id;
  const attendeeTypeName = attendeeTypes[attendeeTypeId]?.name;
  const participantId = associatedParticipants[0]?.id;
  const associatedParticipant = businessConferenceParticipants[participantId];
  const eventUri = getEventUri();

  const aliases = {};

  Object.keys(attendee.fields).forEach((id) => {
    if (attendeeTypeCustomFields[id]) aliases[camelize(
      attendeeTypeCustomFields[id]?.name
    )] = attendeeTypeCustomFields[id]?.alias;
  });

  // HACK
  const hideRating = ['d19d'].includes(eventUri);
  const rating = (!hideRating && ratings) ? Object.values(ratings).find(
    ({ participant }) => participantId === participant.id
  )?.rate : undefined;
  const denied = denyList && !!Object.values(denyList).find(
    ({ blacklistedParticipant }) => participantId === blacklistedParticipant.id
  );
  const recommended = recommendations && recommendations.find(
    ({ businessConferenceParticipantId }) => businessConferenceParticipantId === participantId
  );

  const { attendanceMode } = attendeeTypes[attendee.attendeeType.id] || {};

  const {
    firstName,
    lastName,
    email,
    company,
    jobTitle,
    telephone,
    cityId,
    headshot,
  } = parseCustomFields(attendee.fields, attendeeTypeCustomFields);

  const basicInfo = {
    id: attendee.id,
    uuid: attendee.uuid,
    attendeeTypeId,
    firstName,
    lastName,
    email,
    company,
    attendanceMode,
    position: jobTitle,
    attendeeTypeName,
    headshot,
    cityId,
    telephone,
    fields: attendee.fields,
    aliases,
    canScheduleMeetings,
  };

  if (participantId) return {
    ...basicInfo,
    type: 'participant',
    participantId,
    logo: associatedParticipant?.logo,
    rating,
    denied,
    recommended,
  };
  return {
    ...basicInfo,
    type: 'attendee',
  };
};
