import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import SignatureCanvas from 'react-signature-canvas';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  canvas: {
    border: `1px solid ${theme.palette.lightGrey.dark}`,
    width: '100%',
    height: theme.spacing(26),
  },
  canvasError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  clearButton: {
    textTransform: 'capitalize',
    borderTop: 'none',
    borderRight: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const CanvasField = ({
  error, handleChange, label,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const canvas = useRef();

  const props = {
    ref: canvas,
    canvasProps: { className: clsx(classes.canvas, error && classes.canvasError) },
    clearOnResize: false,
    onEnd: () => handleChange(canvas.current.toDataURL('image/png')),
  };

  const resetCanvas = () => {
    canvas.current.clear();
    handleChange(null);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="body2">{label}</Typography>
      {error && (
        <Typography
          variant="body2"
          className={classes.error}
        >
          {error}
        </Typography>
      )}
      <div className={classes.container}>
        <SignatureCanvas {...props} />

        <Button
          className={classes.clearButton}
          variant="outlined"
          onClick={resetCanvas}
        >
          {t('actions.clear')}
        </Button>
      </div>
    </Grid>
  );
};

CanvasField.propTypes = {
  handleChange: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
};

CanvasField.defaultProps = {
  handleChange: undefined,
  error: undefined,
  label: undefined,
};

export default CanvasField;
