import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { goToPreviousSubpage } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
}));

const BackButton = ({
  className, onClick, goToPreviousSubpage: dispatchGoToPreviousSubpage,
}) => {
  const classes = useStyles();

  const goBack = () => {
    if (typeof onClick === 'function') onClick();
    dispatchGoToPreviousSubpage();
  };

  return (
    <IconButton
      className={clsx(classes.root, className)}
      onClick={goBack}
      color="primary"
    >
      <ChevronLeftIcon fontSize="inherit" />
    </IconButton>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  goToPreviousSubpage: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default connect(null, { goToPreviousSubpage })(BackButton);
