import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ImageIcon from '@material-ui/icons/Image';
import Message from 'eventtia-ui-components/lib/Message';
import Input from 'eventtia-ui-components/lib/Input';
import FileInput from 'eventtia-ui-components/lib/FileInput';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import callApi from '../../actions/callApi';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { SIZE_LIMIT_MB } from '../../helpers/files';
import ImagePreview from '../ImagePreview';

const useStyles = makeStyles((theme) => ({
  post: {
    position: 'relative',
    maxWidth: 600,
    margin: '0px auto 16px auto',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    height: 52,
    width: 52,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
  },
  inputsContainer: {
    flex: 1,
  },
  imageContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  top: {
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SocialPostCreator = ({
  eventUri,
  currentAttendee,
  creating,
  error,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('socialWall');

  const [errorMessage, setErrorMessage] = useState(error);
  useEffect(() => {
    if (error) setErrorMessage(error);
  }, [error]);

  const [message, setMessage] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState(error);
  const [fileInputOpen, setFileInputOpen] = useState(false);
  const changeFile = (val) => {
    const [file] = val;
    if (file?.size > SIZE_LIMIT_MB * 1000 * 1000) setFileErrorMessage(
      t('fileSizeError', { sizeLimit: SIZE_LIMIT_MB })
    );
    else {
      setFileErrorMessage();
      setSelectedFiles(val);
    }
  };
  const clearFiles = () => {
    setFileErrorMessage();
    setSelectedFiles([]);
  };
  const toggleFileInput = () => {
    if (fileInputOpen) clearFiles();
    setFileInputOpen(!fileInputOpen);
  };

  const { headshot, firstName, lastName } = currentAttendee;
  const fullName = `${firstName} ${lastName}`;
  const initials = `${firstName[0]}${lastName[0]}`.toLocaleUpperCase();

  const submit = () => {
    const [image] = selectedFiles;

    const body = new FormData();
    if (message) body.append('message', message);
    if (image) body.append('image', image, image.name);

    dispatchCallApi('createSocialPost', { body, eventUri }).then((res) => {
      if (!res.error) {
        setMessage('');
        clearFiles();
      }
    });
  };

  const submitDisabled = creating || (!message.trim() && !selectedFiles.length);

  return (
    <>
      <Message
        onClose={() => setErrorMessage()}
        type="error"
        message={errorMessage}
        className={classes.errorMessage}
      />
      <div className={classes.post}>
        <Loader loading={creating} variant="absolute" />
        <div className={classes.top}>
          <Avatar
            src={headshot?.filename && headshot.small}
            alt={fullName}
            className={classes.avatar}
          >
            {initials}
          </Avatar>
          <div className={classes.inputsContainer}>
            <Input
              id="postText"
              value={message}
              handleChange={setMessage}
              placeholder={t('newPostPlaceholder')}
              inputProps={{ maxLength: 450, required: true }}
              multiline
              rows={3}
              rowsMax={3}
              fullWidth
            />
            <Collapse in={fileInputOpen} className={classes.imageContainer}>
              {!!selectedFiles.length && (
                <ImagePreview
                  file={selectedFiles[0]}
                  onDelete={clearFiles}
                />
              )}
              <FileInput
                id="postImage"
                type="image"
                accept="image/*"
                value={selectedFiles}
                handleChange={changeFile}
                label={fileErrorMessage}
                error={fileErrorMessage}
              />
            </Collapse>
          </div>
        </div>
        <div className={classes.bottom}>
          <IconButton
            aria-label={t('addImage')}
            onClick={toggleFileInput}
            color="primary"
            size="small"
          >
            <ImageIcon />
          </IconButton>
          <Button
            onClick={submit}
            className={classes.sendButton}
            disabled={submitDisabled}
          >
            {t('createPost')}
          </Button>
        </div>
      </div>
    </>
  );
};

SocialPostCreator.propTypes = {
  eventUri: PropTypes.string.isRequired,
  currentAttendee: CustomPropTypes.person.isRequired,
  creating: PropTypes.bool.isRequired,
  error: PropTypes.string,
  callApi: PropTypes.func.isRequired,
};

SocialPostCreator.defaultProps = {
  error: undefined,
};

const mapStateToProps = ({
  fetchStatus: {
    createSocialPost: {
      isFetching,
      error,
    },
  },
}) => ({
  creating: isFetching,
  error,
});

export default connect(mapStateToProps, { callApi })(SocialPostCreator);
