import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CampariHangerIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m1100.7 802.33-441.81-284.04 46.637-31.09c32.867-21.914 57.812-53.82 71.148-91.004 13.34-37.184 14.359-77.672 2.9102-115.48-11.445-37.809-34.754-70.93-66.477-94.473-31.723-23.539-70.18-36.25-109.68-36.25h-6.8867 0.003906c-48.801 0.054688-95.582 19.465-130.09 53.973-34.508 34.504-53.918 81.285-53.973 130.09v3.4414c0.070312 9.7695 4.0977 19.094 11.168 25.836 7.0664 6.7461 16.57 10.332 26.332 9.9414 10.121-0.38672 19.691-4.6992 26.691-12.016 6.9961-7.3203 10.875-17.078 10.809-27.203 0.035156-28.914 11.535-56.633 31.98-77.078 20.445-20.445 48.164-31.945 77.078-31.98h6.8867-0.003906c31.422 0 61.312 13.551 82.02 37.184 20.707 23.629 30.219 55.039 26.098 86.188-4.1211 31.148-21.477 59.004-47.617 76.434l-566.55 377.67c-32.25 21.633-53.641 56.086-58.73 94.582-5.0859 38.496 6.6211 77.324 32.141 106.59 25.52 29.266 62.391 46.152 101.22 46.352h855.95c39.016 0.003906 76.109-16.934 101.66-46.418 25.551-29.48 37.047-68.609 31.496-107.23-5.5469-38.621-27.594-72.926-60.414-94.023zm-72.746 172.67h-855.95c-17.227-0.19922-33.543-7.7617-44.82-20.781-11.281-13.02-16.445-30.246-14.191-47.324 2.2578-17.078 11.723-32.371 25.996-42.016l451.81-301.21 469.34 301.76c14.52 9.332 24.277 24.512 26.73 41.598 2.457 17.09-2.6289 34.398-13.938 47.441-11.309 13.047-27.723 20.535-44.984 20.531z" />
    </svg>
  </SvgIcon>
);

export default CampariHangerIcon;
