import React, { useState, useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from 'eventtia-ui-components/lib/Card';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import PeopleIcon from '@material-ui/icons/People';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStringTransforms from '../../hooks/useStringTransforms';
import { baseMoment } from '../../helpers/dates';
import { selectEventTimeFormat, selectLogo } from '../../helpers/selectors';
import useRoutes from '../../hooks/useRoutes';
import useTimer from '../../hooks/useTimer';
import { setActiveAttendee } from '../../actions/app';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import { identifyParticipants } from '../../helpers/auth';
import RequestedMeeting from '../RequestedMeeting';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useEventUriHack from '../../hooks/hacks/useEventUriHack';

const LiveBadge = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    backgroundColor: theme.palette.red.light,
    color: theme.palette.red.main,
    fontWeight: 'bold',
    marginRight: 0,
    height: '1.4em',
    '& svg': {
      color: 'inherit',
      height: 8,
      width: 12,
    },
  },
  label: {
    padding: theme.spacing(0, 0.75),
  },
}))(Chip);

const RoundedLinearProgress = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    borderRadius: 10,
    marginBottom: theme.spacing(1),
    background: theme.palette.lightGrey.main,
  },
  bar: {
    borderRadius: 10,
  },
}))(LinearProgress);

const TimeRemaining = ({ startDate, endDate, live, setLive }) => {
  const { t } = useTranslation('stage');
  const [elapsedTime, setElapsedTime] = useState(0);
  const totalTime = baseMoment(endDate).diff(startDate);

  const onTick = useCallback((now, start, end) => {
    if (now.isBefore(start)) setLive(false);
    else if (now.isAfter(end)) setLive(false);
    else {
      setLive(true);
      setElapsedTime(now.diff(start));
    }
  }, [setLive, setElapsedTime]);
  useTimer({ startDate, endDate, onTick });

  if (!live) return null;
  return (
    <>
      <RoundedLinearProgress
        variant="determinate"
        value={(elapsedTime * 100) / totalTime}
        color="secondary"
      />
      <Typography>
        {t('activityTimer.remaining')}: {baseMoment.duration(totalTime - elapsedTime).humanize()}
      </Typography>
    </>
  );
};

TimeRemaining.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  live: PropTypes.bool.isRequired,
  setLive: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
  },
  clickable: {
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  data: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 60,
    width: 60,
    boxShadow: theme.customShadows.small,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: 40,
  },
  peopleButton: {
    all: 'unset',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#0093FF',
  },
  logoAvatar: {
    '& img': {
      objectFit: 'contain',
    },
  },
  text: {
    overflow: 'hidden',
  },
  name: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'bold',
    margin: theme.spacing(0.5),
  },
  blackName: {
    color: theme.palette.darkGrey.dark,
  },
  location: {
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(0.5),
  },
  people: {
    fontSize: theme.typography.caption.fontSize,
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: theme.spacing(0.5),
  },
  timespan: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.light,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: '1.5em',
      marginRight: theme.spacing(1),
    },
  },
  timeRemaining: {
    width: '100%',
    flex: '1 0',
    marginLeft: theme.spacing(0.5),
  },
  rejectedRequests: {
    width: 420,
  },
  popoverTitle: {
    fontSize: theme.spacing(2),
    color: theme.palette.darkGrey.main,
    margin: theme.spacing(3, 4, 2),
    fontWeight: 600,
  },
  requestsCount: {
    margin: theme.spacing(0, 4, 2),
    color: theme.palette.darkGrey.light,
    opacity: 0.7,
  },
}));

const ActivityCard = ({
  people, activity, open, showRequests, businessConferenceParticipants,
  setOpenedRequest, meetingRequests, setActiveAttendee: dispatchSetActiveAttendee,
}) => {
  const { t } = useTranslation(['stage', 'global', 'meeting']);
  const { goTo } = useRoutes();
  const { locale, eventUri } = useParams();
  const classes = useStyles();
  const [live, setLive] = useState(false);
  const { toTitleCase } = useStringTransforms();
  const {
    id,
    businessConferenceId,
    name,
    startDate,
    endDate,
    type,
    location,
    slotId,
    isVirtual: meetingIsVirtual,
    attendanceMode: workshopAttendanceMode,
  } = activity;

  const { currentParticipant } = showRequests ? identifyParticipants(
    eventUri,
    activity,
    businessConferenceParticipants
  ) : {};

  const rejectedRequests = currentParticipant ? Object.values(meetingRequests)
    .filter(({ receivedBy, slotId: reqSlotId, status }) => reqSlotId === slotId && receivedBy.id === currentParticipant.id && (status === 'rejected' || status === 'cancelled')) : [];

  const logo = useSelector(selectLogo());

  const { PHYSICAL } = ATTENDANCE_MODE;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = useCallback(() => {
    if (!open) {
      const route = type === 'meeting' ? 'conference.meeting.show' : 'stage.show';
      const params = type === 'meeting' ? { meetingId: id, businessConferenceId } : { workshopId: id };
      goTo(route, params);
    }
  }, [id, open, goTo, type, businessConferenceId]);

  let peopleElement;
  let avatarElement;
  let avatarObj;
  Object.values(people).forEach(({ avatar: { filename, small } = {}, fullName }) => {
    if (filename && avatarObj === undefined) avatarObj = { src: small, alt: fullName };
  });
  if (people.length > 1) {
    peopleElement = people.map(({ fullName }) => fullName).join(', ');
    avatarElement = (
      <>
        {avatarObj ? (
          <Avatar className={classes.avatar} src={avatarObj?.src} alt={avatarObj?.alt} />
        ) : (
          <Avatar className={classes.avatar}>
            <PeopleIcon />
          </Avatar>
        )}
      </>
    );
  } else if (people.length === 1) {
    const [{
      fullName, avatar, company, position, attendeeId,
    }] = people;
    const pipe = position || company ? ' | ' : '';
    const at = position && company ? ` ${t('global:prepositions.at')} ` : '';
    peopleElement = businessConferenceId && open ? (
      <button
        type="button"
        className={classes.peopleButton}
        disabled={!businessConferenceId}
        onClick={() => dispatchSetActiveAttendee(attendeeId)}
      >
        {`${toTitleCase(fullName)}${pipe}${toTitleCase(position, true)}${at}${toTitleCase(company, true)}`}
      </button>
    ) : (
      <>
        {`${toTitleCase(fullName)}${pipe}${toTitleCase(position, true)}${at}${toTitleCase(company, true)}`}
      </>
    );
    avatarElement = (
      <Avatar src={avatar?.filename && avatar.small} className={classes.avatar}>
        {fullName[0]}
      </Avatar>
    );
  } else avatarElement = (
    <Avatar className={clsx(classes.avatar, classes.logoAvatar)} src={logo}>
      <FreeBreakfastIcon />
    </Avatar>
  );

  const dateFormat = useMemo(() => {
    switch (locale) {
      case 'pt':
        return 'Do MMMM';
      case 'fr':
        return 'Do MMM';
      default:
        return 'MMM Do';
    }
  }, [locale]);

  const timeFormat = useSelector(selectEventTimeFormat());

  const hackedForOnClick = useEventUriHack('hackedForOnClickActivityCard');
  const availableOnClick = (workshopAttendanceMode && workshopAttendanceMode !== PHYSICAL)
  || meetingIsVirtual
  || hackedForOnClick;

  const isPureBlack = useEventUriHack('isPureBlackActivityCard');

  return (
    <Card
      className={clsx(classes.card, (!open && availableOnClick) && classes.clickable)}
      onClick={availableOnClick ? onClick : undefined}
    >
      <div className={classes.content}>
        <div className={classes.data}>
          {avatarElement}
          <div className={classes.text}>
            <Typography className={classes.timespan}>
              <AccessTimeIcon />
              <span>{baseMoment(startDate).format(`${dateFormat}, ${timeFormat}`)} - {baseMoment(endDate).format(timeFormat)}</span>
            </Typography>
            <Typography className={clsx(classes.name, isPureBlack && classes.blackName)} component="div">
              {name}
              {live && <LiveBadge icon={<Brightness1Icon />} label="LIVE" />}
            </Typography>
            {location && (
              <Typography className={classes.location}>
                {toTitleCase(location)}
              </Typography>
            )}
            {peopleElement && (
              <Typography className={classes.people}>
                {peopleElement}
              </Typography>
            )}
            <div className={classes.timeRemaining}>
              <TimeRemaining
                startDate={startDate}
                endDate={endDate}
                live={live}
                setLive={setLive}
              />
            </div>
          </div>
        </div>
        {showRequests && !!rejectedRequests.length && (
          <>
            <IconButton onClick={handleAnchor}>
              <RecentActorsIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className={classes.rejectedRequests}>
                <Typography variant="body2" className={classes.popoverTitle}>
                  {t('meeting:requests.rejectedRequests')}
                </Typography>
                <Typography variant="caption" className={classes.requestsCount}>
                  {rejectedRequests.length} {t('meeting:requests.requests')}
                </Typography>
                {!!rejectedRequests.length && rejectedRequests.map(({ id: requestId }) => (
                  <Fragment key={requestId}>
                    <RequestedMeeting
                      meetingRequest={meetingRequests[requestId]}
                      businessConferenceParticipants={businessConferenceParticipants}
                      setOpenedRequest={setOpenedRequest}
                      compact
                    />
                    <Divider />
                  </Fragment>
                ))}
              </div>
            </Popover>
          </>
        )}
      </div>
    </Card>
  );
};

ActivityCard.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    businessConferenceId: PropTypes.number,
    slotId: PropTypes.number,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    location: PropTypes.string,
    attendanceMode: PropTypes.string,
    isVirtual: PropTypes.bool,
  }).isRequired,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      attendeeId: PropTypes.string,
      fullName: PropTypes.string,
      avatar: PropTypes.shape({
        filename: PropTypes.string,
        small: PropTypes.string,
      }),
      company: PropTypes.string,
      position: PropTypes.string,
    })
  ),
  open: PropTypes.bool,
  showRequests: PropTypes.bool,
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  setOpenedRequest: PropTypes.func,
  meetingRequests: PropTypes.objectOf(CustomPropTypes.meetingRequest),
  setActiveAttendee: PropTypes.func.isRequired,
};

ActivityCard.defaultProps = {
  people: [],
  open: false,
  showRequests: false,
  meetingRequests: {},
  businessConferenceParticipants: {},
  setOpenedRequest: undefined,
};

const mapStateToProps = ({
  entities: {
    businessConferences,
    businessConferenceParticipants,
    meetingRequests,
  },
  fetchStatus: {
    participant: { success: participantSuccess },
  },
  meta: {
    meetingStatuses,
  },
}) => ({
  businessConferenceParticipants,
  conferences: businessConferences,
  participantSuccess,
  meetingStatuses,
  meetingRequests,
});

export default connect(mapStateToProps, { setActiveAttendee })(ActivityCard);
