import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'eventtia-ui-components/lib/Loader';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    minHeight: 100,
    overflowY: 'auto',
  },
  loader: {
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  absoluteLoader: {
    top: 180,
  },
});

const InfiniteScroll = ({
  children, fetchNextPageIfExists, loading, error, className,
}) => {
  const classes = useStyles();
  const rootRef = useRef(null);

  const onScroll = useCallback(() => {
    if (loading) return;
    const rootHeight = rootRef.current.offsetHeight;
    const rootScrollHeight = rootRef.current.scrollHeight - 40;
    const rootBottom = rootHeight + rootRef.current.scrollTop;

    if (rootBottom >= rootScrollHeight) fetchNextPageIfExists();
  }, [loading, fetchNextPageIfExists]);

  useEffect(() => {
    const rootNode = rootRef.current;
    if (rootNode) rootNode.addEventListener('scroll', onScroll);
    return () => {
      if (rootNode) rootNode.removeEventListener('scroll', onScroll);
    };
  }, [loading, fetchNextPageIfExists, onScroll]);

  return (
    <div
      className={clsx(classes.root, className)}
      ref={rootRef}
    >
      {error ? <Typography color="error">{error}</Typography> : children}
      <Loader
        loading={loading}
        className={classes.loader}
      />
    </div>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  fetchNextPageIfExists: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
};

InfiniteScroll.defaultProps = {
  loading: false,
  error: undefined,
  className: undefined,
};

export default InfiniteScroll;
