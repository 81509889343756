import React, { useRef, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import BackButton from '../BackButton';

import { readMessage, NEW_MESSAGE } from '../../actions/messages';
import { getCurrentAttendee } from '../../helpers/getters';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { baseMoment, getEventTimeFormat } from '../../helpers/dates';
import MessageInput from '../MessageInput';
import MessageBubble from '../MessageBubble';
import imgNoChat from '../../assets/ImgNoChat.png';
import useStringTransforms from '../../hooks/useStringTransforms';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    maxHeight: '100%',
  },
  header: {
    backgroundColor: theme.palette.lightGrey.main,
    borderRadius: '10px 10px 0px 0px',
    padding: theme.spacing(0, 2),
  },
  headerItems: {
    display: 'flex',
    alignItems: 'start',
    padding: theme.spacing(2, 4, 2, 0),
  },
  channelItems: {
    marginLeft: theme.spacing(2),
  },
  channelTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 'medium',
    textOverflow: 'ellipsis',
  },
  channelDescription: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
  },
  messages: {
    backgroundColor: theme.palette.lightGrey.main,
    padding: theme.spacing(0, 2),
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
  fullBorder: {
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
  messageTime: {
    fontSize: 10,
    opacity: 0.6,
  },
  noChatImg: {
    width: '40%',
  },
  imageDiv: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'Column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noMessageChat: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.darkGrey.light,
  },
  messageInputContainer: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0, 0, 1, 1),
    backgroundColor: theme.palette.lightGrey.main,
  },
}));

const inputId = (channelTopic) => `${channelTopic}-input`;

const ChatRoom = ({
  entities, broadcast, displayAvatar, messages, channelTopic, events,
  readMessage: dispatchReadMessage, className, lastReadMessage, backButton,
  title, description, showShareContactButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('chat');
  const { toTitleCase } = useStringTransforms();
  const messagesRef = useRef(null);
  const currentAttendee = getCurrentAttendee(entities);
  const timeFormat = getEventTimeFormat(events);

  const hasHeader = !!title || !!description;

  const send = (body) => {
    broadcast(NEW_MESSAGE, { body });
  };
  useEffect(() => {
    if (messagesRef.current) {
      const rootScrollHeight = messagesRef.current.scrollHeight;
      const rootBottom = messagesRef.current.offsetHeight + messagesRef.current.scrollTop;

      if (rootBottom >= rootScrollHeight - 100) messagesRef.current.scrollTo(
        0, messagesRef.current.scrollHeight
      );
      else if (messagesRef.current.scrollTop === 0) {
        const lastRead = document.getElementById(lastReadMessage);
        messagesRef.current.scrollTo(
          0, lastRead?.offsetTop || 0
        );
      }
    }
  }, [messages, lastReadMessage]);

  useEffect(() => {
    if (messages.length) {
      const [lastMessage] = messages.slice(-1);
      if (lastMessage) dispatchReadMessage(lastMessage.uuid, channelTopic);
    }
  }, [messages, channelTopic, dispatchReadMessage]);

  useEffect(() => {
    const input = document.getElementById(inputId(channelTopic));
    if (input) input.focus();
  }, [channelTopic]);

  const getMessageType = (senderId, moderated) => {
    if (moderated) return 'bot';
    return String(senderId) === currentAttendee.id ? 'own' : 'received';
  };

  return (
    <div className={clsx(classes.root, className)}>
      {hasHeader && (
        <div className={classes.header}>
          <div className={classes.headerItems}>
            {tablet && backButton && <BackButton />}
            <div
              className={classes.channelItems}
            >
              <Typography
                className={classes.channelTitle}
                variant="body2"
                gutterBottom={!!description?.trim()}
              >
                {toTitleCase(title)}
              </Typography>
              {!!description?.trim() && (
                <Typography
                  className={classes.channelDescription}
                  variant="caption"
                  color="textSecondary"
                >
                  {description}
                </Typography>
              )}
            </div>
          </div>
          <Divider className={classes.divider} />
        </div>
      )}
      <div
        ref={messagesRef}
        className={clsx(classes.messages, !hasHeader && classes.fullBorder)}
      >
        {(messages.length === 0) && (
          <div className={classes.imageDiv}>
            <img
              src={imgNoChat}
              alt="no-messges"
              className={classes.noChatImg}
            />
            <span className={classes.noMessageChat}>{t('messages.noMessages')}</span>
            <span className={classes.noMessageChat}>{t('messages.makeMessage')}</span>
          </div>
        )}
        {messages.slice(-100).map(({
          uuid, body, attendee_id: senderId, username, avatar,
          timestamp, moderated, attendee_type_id: attendeeTypeId,
        }) => (
          <MessageBubble
            attendeeTypeId={attendeeTypeId}
            id={uuid}
            key={uuid}
            attendeeID={senderId}
            moderated={moderated}
            type={getMessageType(senderId, moderated)}
            info={(
              <div className={classes.messageTime}>
                {baseMoment(timestamp).format(timeFormat)}
              </div>
            )}
            content={moderated ? t('messages.moderatedMessageReceived') : body}
            sender={username}
            avatar={avatar}
            displayAvatar={displayAvatar}
            currentAttendee={currentAttendee}
          />
        ))}
      </div>
      <div className={classes.messageInputContainer}>
        <MessageInput
          id={inputId(channelTopic)}
          onSend={send}
          placeholder={t('messages.typeAMessage')}
          showShareContactButton={showShareContactButton}
          otherAttendeeName={title}
        />
      </div>
    </div>
  );
};

ChatRoom.propTypes = {
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  className: PropTypes.string,
  broadcast: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  displayAvatar: PropTypes.bool,
  messages: PropTypes.arrayOf(CustomPropTypes.message),
  channelTopic: PropTypes.string.isRequired,
  readMessage: PropTypes.func.isRequired,
  lastReadMessage: PropTypes.string,
  backButton: PropTypes.bool,
  showShareContactButton: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

ChatRoom.defaultProps = {
  className: undefined,
  displayAvatar: true,
  messages: [],
  lastReadMessage: undefined,
  backButton: false,
  showShareContactButton: false,
  title: '',
  description: '',
};

const mapStateToProps = ({
  entities: {
    events,
  },
  entities,
  messages,
  lastReadMessages,
}, {
  channelTopic,
}) => ({
  events,
  entities,
  messages: messages[channelTopic],
  lastReadMessage: lastReadMessages[channelTopic],
});

export default connect(mapStateToProps, { readMessage })(ChatRoom);
