import isEmpty from 'lodash/isEmpty';
import { getCurrentAttendee, getModuleByType } from './getters';
import attendanceModehelper from './attendanceModeHelper';

export const getPriceByAttendeeId = (attendeeTypeId, price = {}) => (
  (price && Number(price[attendeeTypeId])) || 0
);

const shouldFilterNonRegistered = (entities) => {
  const { appSettings } = entities;
  const [settings] = Object.values(appSettings || {});
  const { customParams: { itemsToShow } } = getModuleByType(settings.modules, 'Workshops');
  return itemsToShow === 'attendee_type_and_highlight_registered';
};

export const getFilteredVisibleWorkshops = (
  workshops, visibleWorkshops, currentRegistered, entities, attendanceType
) => {
  const { attendeeTypes } = entities;
  const currentAttendee = getCurrentAttendee(entities);
  const attendeeTypeId = currentAttendee?.attendeeType.id;
  const filterNonRegistered = shouldFilterNonRegistered(entities);
  const attendeeTypeAttendanceMode = attendeeTypes[attendeeTypeId]?.attendanceMode;
  const visibleWorkshopsFiltered = visibleWorkshops?.filter((wId) => {
    const workshop = workshops[wId];
    const showWorkshop = attendanceModehelper.showWorkshopAccordingToAttendace(
      workshop.attendanceMode, attendanceType, attendeeTypeAttendanceMode
    );
    return showWorkshop;
  });

  return (filterNonRegistered && currentRegistered) ? visibleWorkshopsFiltered
    .filter((wId) => {
      const showOnRegister = workshops[wId]?.showOnRegister;
      const price = workshops[wId]?.price;
      const freeWorkshop = getPriceByAttendeeId(attendeeTypeId, price) === 0;
      return (currentRegistered.includes(wId)
        && (freeWorkshop || currentAttendee?.paid)) || !showOnRegister;
    }) : visibleWorkshopsFiltered;
};

export const getWorkshopName = (workshop, workshopDefinitions, locale) => {
  const workshopDefinition = workshopDefinitions?.[workshop.workshopDefinitionId];
  return workshopDefinition?.name?.[locale] || workshop.name;
};

export const getWorkshopDescription = (workshop, workshopDefinitions, locale) => {
  const workshopDefinition = workshopDefinitions?.[workshop.workshopDefinitionId];
  return workshopDefinition?.description?.[locale] || workshop.description;
};

export default (entities, skipSponsorActivities = true) => {
  const currentAttendee = getCurrentAttendee(entities);
  const { workshops, attendeeWorkshops } = entities;
  const workshopsWithoutSponsor = {};

  Object.keys(workshops || {}).forEach((wId) => {
    if (
      !workshops[wId].sponsorId || !skipSponsorActivities
    ) workshopsWithoutSponsor[wId] = workshops[wId];
  });
  if (!currentAttendee || isEmpty(workshopsWithoutSponsor)) return {
    visibleWorkshops: [],
    currentAttendeeWorkshops: [],
  };
  const [settings] = Object.values(entities.appSettings);
  const { customParams: { itemsToShow } } = getModuleByType(settings.modules, 'Workshops');

  const currentAttendeeWorkshops = Object.values(attendeeWorkshops || {}).filter(
    ({ attendee: { id: aId } }) => (aId === currentAttendee.id)
  ).filter(
    ({ workshop: { id } }) => !!workshops[id]
  ).map(
    ({ workshop: { id } }) => id
  );

  let visibleWorkshops = Object.keys(workshopsWithoutSponsor);
  switch (itemsToShow) {
    case 'only_attendee':
      return {
        visibleWorkshops: currentAttendeeWorkshops,
        currentAttendeeWorkshops: [],
      };
    case 'attendee_type_and_highlight_registered':
      return {
        visibleWorkshops,
        currentAttendeeWorkshops,
      };
    case 'inactive_register_attendee_type_registered': {
      visibleWorkshops = visibleWorkshops
        .filter((id) => !workshopsWithoutSponsor[id].showOnRegister);
      const uniqueIds = new Set([...visibleWorkshops, ...currentAttendeeWorkshops]);
      return {
        visibleWorkshops: [...uniqueIds],
        currentAttendeeWorkshops: [],
      };
    }
    case 'all':
    case 'only_attendee_type':
    default:
      return {
        visibleWorkshops,
        currentAttendeeWorkshops: [],
      };
  }
};
