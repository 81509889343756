import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { goToMainStage } from '../../actions/app';
import subpages, { getCustomSubpages } from '../../helpers/subpages';
import { getCurrentAttendee } from '../../helpers/getters';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    padding: theme.spacing(
      theme.subpageDrawer.paddings.desktop.vertical,
      theme.subpageDrawer.paddings.desktop.horizontal
    ),
    left: theme.spacing(8),
    maxWidth: `calc(100% - ${theme.spacing(8)}px)`,
    border: 'none',
    backgroundColor: theme.palette.background.light,
    flexDirection: 'initial',
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
      width: '100%',
      padding: theme.spacing(
        theme.subpageDrawer.paddings.mobile.vertical,
        theme.subpageDrawer.paddings.mobile.horizontal
      ),
      maxWidth: '100%',
    },
  },
  noPaddingPaper: {
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      width: 889,
    },
  },
}));

const SubpageDrawer = ({
  goToMainStage: dispatchGoToMainStage, activeModule, entities, appSettings,
  ...otherProps
}) => {
  const classes = useStyles();

  const rootRef = useRef(null);
  const scrollToTop = () => {
    if (rootRef.current) rootRef.current.scrollTo(0, 0);
  };

  const [{ modules }] = Object.values(appSettings);
  const currentAttendee = getCurrentAttendee(entities);
  const customSubpages = getCustomSubpages(modules, currentAttendee);
  const allSubpages = { ...subpages, ...customSubpages };
  const open = !['mainStage', 'Home'].includes(activeModule);
  const Content = allSubpages[activeModule]?.component;
  const subpageProps = allSubpages[activeModule]?.props;
  const { noPaddingDrawer, ...contentProps } = subpageProps || {};
  const onClose = () => {
    dispatchGoToMainStage();
  };

  useEffect(() => {
    if (typeof Content === 'undefined') dispatchGoToMainStage();
  }, [Content]);

  return (
    <Drawer
      className={classes.root}
      classes={{
        paper: clsx(
          classes.paper,
          noPaddingDrawer && classes.noPaddingPaper
        ),
      }}
      open={open}
      onClose={onClose}
      ModalProps={{ disableEnforceFocus: true }}
      PaperProps={{ ref: rootRef }}
    >
      {Content && (
        <Content {...otherProps} {...contentProps} scrollToTop={scrollToTop} />
      )}
    </Drawer>
  );
};

SubpageDrawer.propTypes = {
  goToMainStage: PropTypes.func.isRequired,
  activeModule: PropTypes.string.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  appSettings: PropTypes.objectOf(CustomPropTypes.appSettings),
};

SubpageDrawer.defaultProps = {
  appSettings: {},
};

const mapStateToProps = ({
  app: { activeSubpage: { module } },
  entities,
  entities: { appSettings },
}) => ({
  activeModule: module,
  entities,
  appSettings,
});

export default connect(mapStateToProps, { goToMainStage })(SubpageDrawer);
