import { usedLanguages } from '../locales';

export const BASE_PATH = `/:locale(${usedLanguages.join('|')})/:eventUri`;

const loggedInRoutes = {
  stage: {
    component: 'Stage',
    param: 'workshopId',
    showComponent: 'Stage',
  },
  conference: {
    param: 'businessConferenceId',
    nested: {
      meeting: {
        param: 'meetingId',
        showComponent: 'Meeting',
      },
    },
  },
  home: {
    component: 'LandingPage',
  },
};

const loggedOutRoutes = {
  login: {
    component: 'Login',
  },
};

const routes = {
  loggedInRoutes,
  loggedOutRoutes,
};

export default routes;

const getNestedRoutes = (basePath, key, subTree, parentId) => {
  const { param, component, showComponent, nested } = subTree[key];
  const newBasePath = `${basePath}/${key}`;

  const flattenedRoutes = [];
  const id = parentId ? `${parentId}.${key}` : key;

  if (component) flattenedRoutes.push({
    id,
    path: newBasePath,
    component,
  });

  if (nested) Object.keys(nested).forEach((nestedKey) => {
    const { on } = nested[nestedKey];
    const nestedBasePath = on === 'collection' ? newBasePath : `${newBasePath}/:${param}`;
    flattenedRoutes.push(...getNestedRoutes(nestedBasePath, nestedKey, nested, id));
  });

  if (param && showComponent) flattenedRoutes.push({
    id: `${id}.show`,
    path: `${newBasePath}/:${param}`,
    component: showComponent,
  });

  return flattenedRoutes;
};

const getFlattenedRoutes = (subTree) => (basePath) => {
  const flattenedRoutes = [];

  Object.keys(subTree).forEach((key) => {
    flattenedRoutes.push(...getNestedRoutes(basePath, key, subTree));
  });

  return flattenedRoutes;
};

export const flattenedRoutes = {
  loggedInRoutes: getFlattenedRoutes(loggedInRoutes)(BASE_PATH),
  loggedOutRoutes: getFlattenedRoutes(loggedOutRoutes)(BASE_PATH),
};
