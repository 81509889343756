import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.darkGrey.main,
    padding: theme.spacing(2),
    borderRadius: 10,
    zIndex: 1,
    cursor: 'auto',
    boxShadow: theme.customShadows.default,
    overflow: 'hidden',
    position: 'relative',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: theme.typography.body1.fontSize,
  },
  action: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const FloatingDialog = ({
  title, children, action, secondaryAction, closeDialog, loading, disabled,
}) => {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={closeDialog}>
      <Fade in>
        <div className={classes.dialog}>
          <Loader loading={loading} variant="absolute" />
          <div className={classes.form}>
            {title && (
              <Typography className={classes.title}>
                {title}
              </Typography>
            )}
            {children}
            <div className={classes.buttons}>
              {secondaryAction && (
                <Button
                  small
                  variant="tertiary"
                  className={classes.action}
                  onClick={secondaryAction.onClick}
                >
                  {secondaryAction.name}
                </Button>
              )}
              <Button
                small
                variant="primary"
                className={classes.action}
                onClick={action.onClick}
                disabled={disabled || loading}
              >
                {action.name}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </ClickAwayListener>
  );
};

FloatingDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  secondaryAction: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  closeDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

FloatingDialog.defaultProps = {
  title: undefined,
  loading: false,
  disabled: false,
  secondaryAction: undefined,
};

export default FloatingDialog;
