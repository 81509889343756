import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Block from './Block';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.darkGrey.main,
    '&:hover': {
      opacity: 1,
    },
  },
  blocked: {
    backgroundColor: theme.palette.lightGrey.dark,
    '& $unlockText': {
      display: 'none',
    },
    '& $lockedText': {
      display: 'block',
    },
    '&:hover': {
      '& $unlockText': {
        display: 'block',
      },
      '& $lockedText': {
        display: 'none',
      },
    },
  },
  free: {
    backgroundColor: theme.palette.lightGrey.main,
    opacity: 0.56,
    '& $mainLine': {
      opacity: 0,
    },
    '&:hover': {
      opacity: 1,
      '& $mainLine': {
        opacity: 1,
      },
    },
  },
  mainLine: {
    fontWeight: 900,
    fontSize: 'inherit',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  helperText: {
    fontSize: 10,
    whiteSpace: 'pre-line',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    verticalAlign: 'top',
  },
  lockedText: {},
  unlockText: {},
  disabled: {
    cursor: 'auto',
    '&:hover': {
      opacity: 0.56,
      '& $mainLine': {
        opacity: 0,
      },
    },
  },
}));

const OwnSlot = ({ isBlockedSlot, disabled, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation('meeting');

  const mainLine = isBlockedSlot ? (
    <>
      <Typography className={clsx(classes.mainLine, classes.lockedText)}>
        <LockIcon className={classes.icon} color="primary" />
        <span className={classes.text}>{t('slots.blockedSpace')}</span>
      </Typography>
      <Typography className={clsx(classes.mainLine, classes.unlockText)}>
        <LockOpenIcon className={classes.icon} color="primary" />
        <span className={classes.text}>{t('actions.unblockSpace')}</span>
      </Typography>
    </>
  ) : (
    <Typography className={classes.mainLine}>
      <LockIcon className={classes.icon} />
      {t('actions.blockSpace')}
    </Typography>
  );

  const secondaryLine = !isBlockedSlot ? (
    t('slots.blockedExplanation')
  ) : undefined;

  return (
    <Block
      {...props}
      className={clsx(
        classes.root,
        isBlockedSlot ? classes.blocked : classes.free,
        disabled && classes.disabled
      )}
      mainLine={mainLine}
      secondaryLine={secondaryLine}
      tooltipTitle={secondaryLine}
    />
  );
};

OwnSlot.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isBlockedSlot: PropTypes.bool.isRequired,
};

export default OwnSlot;
