import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DevicesIcon from '@material-ui/icons/Devices';
import PlaceIcon from '@material-ui/icons/Place';
import { useTranslation } from 'react-i18next';
import Loader from 'eventtia-ui-components/lib/Loader';
import Button from 'eventtia-ui-components/lib/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import VideocamIcon from '@material-ui/icons/Videocam';
import Message from 'eventtia-ui-components/lib/Message';
import { useParams } from 'react-router-dom';
import { setActiveMeetingEvaluation, goToPreviousSubpage } from '../../actions/app';
import useDidUpdate from '../../hooks/useDidUpdate';
import useRoutes from '../../hooks/useRoutes';
import { baseMoment } from '../../helpers/dates';
import parseCustomFields from '../../helpers/customFields';
import { identifyParticipants } from '../../helpers/auth';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useStringTransforms from '../../hooks/useStringTransforms';
import Notes from '../../components/Notes';
import BackButton from '../../components/BackButton';
import DownloadActivity from '../../components/DownloadActivity';
import ActivityCard from '../../components/ActivityCard';
import useTimer from '../../hooks/useTimer';
import callApi from '../../actions/callApi';
import MeetingEvaluation from './MeetingEvaluation';
import PeopleFullProfile from '../../components/PeopleFullProfile';
import ConfirmDialog from '../../components/ConfirmDialog';
import MeetingRequestModal from '../../components/MeetingRequestModal';
import RejectionMeetingDialog from '../../components/RejectionMeetingDialog';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import { getCurrentAttendee } from '../../helpers/getters';

const SHOW_NEW_PROPOSAL_FIELDS = 'show_fields';
const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 'bold',
    margin: theme.spacing(5, 0, 3, 0),
    color: theme.palette.darkGrey.main,
  },
  invitation: {
    margin: theme.spacing(5.5, 0, 0, 0),
    fontWeight: 'bold',
    '& i': {
      color: theme.palette.primary.light,
    },
  },
  meetingMessage: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  messageTitle: {
    fontWeight: 600,
    color: theme.palette.darkGrey.main,
  },
  buttons: {
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(3, 0),
      width: '100%',
    },
  },
  button: {
    minWidth: 210,
    padding: theme.spacing(0, 4),
    margin: theme.spacing(3, 2, 0, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(1, 0),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(2.5),
  },
  moduleName: {
    color: theme.palette.darkGrey.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  dialogContainer: {
    position: 'relative',
  },
  dialogContainerDos: {
    position: 'relative',
    top: '40%',
    width: 400,
    margin: 'auto',
  },
  backdropDialog: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  confirmDialogContent: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  messageInput: {
    marginTop: theme.spacing(1),
  },
  meetingMode: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  notesWrapper: {
    width: '100%',
    margin: theme.spacing(4, 0),
  },
  notes: {
    marginTop: theme.spacing(2),
  },
  meetingModeIcon: {
    marginRight: theme.spacing(1),
  },
  iconWithLocation: {
    display: 'flex',
  },
  cancelBtnContainer: {
    width: '45%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Meeting = ({
  meeting, participants, currentParticipant,
  callApi: dispatchCallApi, meetingStatuses, updating,
  participantTypes, surveys, attendees, attendeeTypeCustomFields,
  conferences, activeSurvey, goToPreviousSubpage: dispatchGoToPreviousSubpage,
  setActiveMeetingEvaluation: dispatchSetActiveMeetingEvaluation, meetings, entities,
  setShowNewProposedTimeError, participantSuccess, attendeeTypes,
  changeModeSuccess, scrollToTop,
}) => {
  const classes = useStyles();
  const { toTitleCase } = useStringTransforms();
  const { eventUri } = useParams();

  const [conferenceFinished, setConferenceFinished] = useState(false);
  const [newMeetingMessage, setNewMeetingMessage] = useState('');
  const [openDialogChangeMode, setOpenDialogChangeMode] = useState(false);
  const [meetingModeChangedSuccessMessage, setMeetingModeChangedSuccessMessage] = useState();
  const [warningMeetingMessageIsOpened, setWarningMeetingMessageIsOpened] = useState(true);
  const [scheduleFinishedMessage, setScheduleFinishedMessage] = useState(true);

  const [newProposedSlot, setNewProposedSlot] = useState('');
  const [meetingProposalToggleValue, setMeetingProposalToggleValue] = useState([]);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const closeDialog = () => setRejectionDialogOpen(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const showNewProposalFields = meetingProposalToggleValue.includes(SHOW_NEW_PROPOSAL_FIELDS);

  const { otherParticipant } = identifyParticipants(
    eventUri,
    meeting,
    participants
  );
  const {
    id: meetingId,
    startDate,
    endDate,
    location: meetingLocation,
    hostAttendanceMode,
    participantAttendanceMode: guestAttendanceMode,
    businessConferenceId,
    status: currentStatus,
    userMessage,
    isVirtual,
    host: {
      id: hostId,
    },
  } = meeting;
  const {
    meetingRejectionEnabled,
    bookingDeadlineDate,
    location: conferenceLocation,
    endDate: conferenceEndDate,
    multipleRequestsEnabled,
  } = conferences[businessConferenceId];
  const {
    blockedSlots,
    attendee: {
      id: otherAttendeeId,
    },
  } = otherParticipant;
  const {
    id: participantId,
    blockedSlots: selfBlockedSlots,
  } = currentParticipant;
  const { goTo } = useRoutes();
  const { t } = useTranslation(['schedule', 'stage', 'meeting', 'global']);
  const [showPendingEvaluationMsg, setShowPendingEvaluationMsg] = useState(true);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [openedRequest, setOpenedRequest] = useState(undefined);

  const closeModal = () => setOpenedRequest(undefined);
  const person = attendees[otherAttendeeId];
  const {
    headshot,
  } = parseCustomFields(person?.fields || {}, attendeeTypeCustomFields);
  const answerToMeetingRequest = (status) => {
    dispatchCallApi('updateMeeting', {
      eventUri,
      businessConferenceId,
      meetingId,
      status,
      rejectionReason: rejectionReason || undefined,
    }).then((response) => {
      if (!response.error) if (showNewProposalFields
        && newProposedSlot) dispatchCallApi('requestMeeting', {
        eventUri,
        businessConferenceId,
        participantId: otherParticipant.id,
        slotId: newProposedSlot.toString(),
        message: newMeetingMessage,
      }).then((res) => {
        if (res.error && setShowNewProposedTimeError) setShowNewProposedTimeError(true);
        dispatchGoToPreviousSubpage();
      }); else dispatchGoToPreviousSubpage();
    });
  };
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [meetingFinished, setMeetingFinished] = useState(false);
  const [scheduleFinished, setScheduleFinished] = useState(false);

  const onTick = useCallback((now, start) => {
    setMeetingStarted(now.isSameOrAfter(start, 'second'));
    setMeetingFinished(now.isAfter(endDate, 'second'));
    setConferenceFinished(now.isAfter(conferenceEndDate));
    setScheduleFinished(now.isAfter(bookingDeadlineDate));
  }, [
    setMeetingStarted, setMeetingFinished, endDate,
    setConferenceFinished, conferenceEndDate,
    setScheduleFinished, bookingDeadlineDate,
  ]);

  useTimer({ startDate, onTick });

  const people = [{
    attendeeId: otherParticipant.attendeeId,
    fullName: otherParticipant.profileContactName,
    company: otherParticipant.profileDisplayName,
    position: otherParticipant.jobTitle,
    avatar: headshot || undefined, // needed because null values crash the avatar component
  }];

  const isHost = participantId === hostId;
  const currentAttendeeMeetingMode = isHost ? hostAttendanceMode : guestAttendanceMode;
  const otherHybridAttendeeWillAttendVirtually = isHost ? !!guestAttendanceMode
    : !!hostAttendanceMode;
  const onlyOneIsAttendingVirtually = (!!guestAttendanceMode || !!hostAttendanceMode)
    && !(!!guestAttendanceMode && !!hostAttendanceMode);

  useDidUpdate(() => {
    if (changeModeSuccess) setMeetingModeChangedSuccessMessage(
      t('meeting:meeting.changeModeSuccess', {
        newMeetingAttendanceMode:
        t(`global:attendanceMode.${currentAttendeeMeetingMode ? 'online' : 'offline'}`),
      })
    );
  }, [changeModeSuccess]);

  const onJoin = () => {
    goTo('conference.meeting.show', { businessConferenceId, meetingId });
  };

  const openEvaluation = () => {
    dispatchSetActiveMeetingEvaluation(meetingId, true);
  };

  const currentParticipantType = participantTypes[currentParticipant?.participantTypeId];
  const surveyId = currentParticipantType?.survey?.id;
  const { rejectionEnabled } = currentParticipantType;
  const survey = surveys[surveyId];
  const enabledSurvey = survey?.enabled;
  const unevaluatedMeeting = !survey?.answered?.includes(Number(meetingId));
  const meetingAccepted = currentStatus === meetingStatuses.accepted;
  const showEvaluateMeetingButton = (
    meetingAccepted && meetingStarted && enabledSurvey && unevaluatedMeeting
  );
  // numbers
  const canRequestToTypes = currentParticipantType?.canRequestMeetingToAttendeeTypes;
  const otherAttendeeTypeId = person?.attendeeType.id;
  const canRequestToOtherType = (canRequestToTypes || []).includes(Number(otherAttendeeTypeId));
  const {
    agenda: otherAgenda,
    showContactInfo,
  } = participantTypes[otherParticipant?.participantType.id];
  const myAgenda = currentParticipantType?.agenda;
  const meetingSlots = Object.values(meetings).map(({ slotId }) => slotId);

  const days = {};
  const now = baseMoment();
  if (canRequestToOtherType && (baseMoment(bookingDeadlineDate).diff(now, 'seconds') > 0)) Object
    .keys(myAgenda).sort().forEach((slotEpoch) => {
      const [sD, eD, sId] = myAgenda[slotEpoch];
      const sDay = baseMoment(sD);
      const eDay = baseMoment(eD);
      const skip = meetingSlots.includes(sId)
        || sDay.diff(now, 'seconds') <= 0
        || selfBlockedSlots.includes(slotEpoch)
        || !otherAgenda[slotEpoch]
        || blockedSlots.includes(slotEpoch);

      if (!skip) {
        const day = sDay.format('YYYY-MM-DD');
        const slotInfo = {
          value: slotEpoch,
          label: `${sDay.format('h:mm A')} - ${eDay.format('h:mm A [GMT] Z')}`,
        };
        if (days[day]) days[day].push(slotInfo);
        if (!days[day]) days[day] = [slotInfo];
      }
    });

  const {
    participantAttended,
    hostAttended,
  } = meeting;

  const markAttended = isHost ? hostAttended : participantAttended;

  const anyoneChangedToVirtual = !!hostAttendanceMode || !!guestAttendanceMode;
  const isHappeningVirtually = isVirtual || anyoneChangedToVirtual;

  const checkInMeeting = () => {
    dispatchCallApi('meetingCheckIn', { eventUri, businessConferenceId, meetingId });
  };

  const handleChangeMeetingMode = () => {
    const newMeetingMode = Number(!currentAttendeeMeetingMode);
    dispatchCallApi('changeMeetingAttendanceMode', { eventUri, businessConferenceId, meetingId, mode: newMeetingMode });
  };

  const newProposedDays = useMemo(() => {
    const newDays = [];
    Object.keys(days).forEach((day) => {
      const formattedDay = baseMoment(day).format('dddd[,] MMMM Do');
      newDays.push({
        label: formattedDay,
        value: day,
      });
    });
    return newDays;
  }, [days]);

  const handleRejection = () => {
    if (multipleRequestsEnabled) setConfirmDialogOpen(true);
    if (participantId === hostId && !participantSuccess) dispatchCallApi('participant',
      { eventUri, businessConferenceId, participantId: otherParticipant.id });
    if (!meetingRejectionEnabled && (participantId !== hostId)) setConfirmDialogOpen(true);
    else if (!meetingRejectionEnabled
      && (participantId === hostId)
      && !newProposedDays?.length) setConfirmDialogOpen(true);
    else if (!multipleRequestsEnabled) setRejectionDialogOpen(true);
  };

  const { VIRTUAL, PHYSICAL, HYBRID } = ATTENDANCE_MODE;

  const currentAttendee = getCurrentAttendee(entities);
  const { attendeeType: { id: attendeeTypeId } } = currentAttendee;
  const { attendanceMode: currentParticipantAttendanceMode } = attendeeTypes[attendeeTypeId];

  const { attendeeType: { id: otherParticipantAttendeeTypeId } } = attendees[otherAttendeeId];
  const {
    attendanceMode: otherParticipantAttendanceMode,
  } = attendeeTypes[otherParticipantAttendeeTypeId];

  const bothParticipantsAreHybrid = (currentParticipantAttendanceMode === HYBRID
    && otherParticipantAttendanceMode === HYBRID);

  const showChangeModeButton = meetingAccepted && bothParticipantsAreHybrid && !meetingStarted;

  let inPersonLocation = '';
  if (conferenceLocation) {
    inPersonLocation = conferenceLocation;
    if (meetingLocation) inPersonLocation += `, ${meetingLocation}`;
  } else if (meetingLocation) inPersonLocation = meetingLocation;

  const LocationIcon = isHappeningVirtually ? DevicesIcon : PlaceIcon;
  const hibrydMeetingModeAndLocation = isHappeningVirtually ? (
    `${t('global:attendanceMode.online')} | ${t('meeting:meeting.onlineMeeting')}`
  ) : (
    `${t('global:attendanceMode.offline')} | ${inPersonLocation}`
  );

  useEffect(() => {
    let attendeePollingInterval;
    if (bothParticipantsAreHybrid && meetingAccepted) attendeePollingInterval = setInterval(() => {
      dispatchCallApi('meeting', { eventUri, businessConferenceId, meetingId });
    }, 60000);

    return () => {
      if (attendeePollingInterval) clearInterval(attendeePollingInterval);
    };
  }, [
    bothParticipantsAreHybrid, eventUri, businessConferenceId, meetingId,
    dispatchCallApi, meetingAccepted,
  ]);

  const [event] = Object.values(entities.events);
  const { attendanceMode: eventAttendanceMode } = event;

  return activeSurvey ? (
    <>
      <div className={classes.header}>
        <BackButton />
        <Typography className={classes.moduleName} variant="subtitle1">
          {t('meeting:meeting.meetingEvaluation')}
        </Typography>
      </div>
      <MeetingEvaluation
        meetingId={meetingId}
        closeEvaluation={dispatchGoToPreviousSubpage}
        survey={survey}
        scrollToTop={scrollToTop}
      />
    </>
  ) : (
    <>
      {showEvaluateMeetingButton && showPendingEvaluationMsg && (
        <Message
          className={classes.message}
          onClose={() => setShowPendingEvaluationMsg(false)}
          type="warning"
          title={t('global:title.reminder')}
          message={t('meeting:meeting.pendingMeetingEvaluation')}
        />
      )}
      {meetingModeChangedSuccessMessage && (
        <Message
          className={classes.message}
          onClose={() => setMeetingModeChangedSuccessMessage(false)}
          type="success"
          message={meetingModeChangedSuccessMessage}
        />
      )}
      {otherHybridAttendeeWillAttendVirtually && onlyOneIsAttendingVirtually && (
        <Message
          className={classes.message}
          type="warning"
          title={t('global:title.announcement')}
          message={warningMeetingMessageIsOpened ? t('meeting:meeting.diferentAttendanceMode') : undefined}
          onClose={() => setWarningMeetingMessageIsOpened(false)}
        />
      )}
      <Message
        className={classes.message}
        type="warning"
        message={
          (scheduleFinished && scheduleFinishedMessage) ? t('meeting:meeting.schedulingEnded') : ''
        }
        onClose={() => setScheduleFinishedMessage(false)}
      />
      <ActivityCard
        open
        showRequests={multipleRequestsEnabled}
        activity={meeting}
        people={people}
        setOpenedRequest={setOpenedRequest}
      />
      <div className={classes.meetingMessage}>
        {eventAttendanceMode === HYBRID && (
          <Typography className={classes.messageTitle} variant="subtitle2" gutterBottom>
            {t('meeting:meeting.mode')}
          </Typography>
        )}
        <Typography className={classes.meetingMode} component="div" variant="body2" gutterBottom>
          {eventUri !== 'recntm2023' && (
            <div className={classes.iconWithLocation}>
              <LocationIcon color="primary" fontSize="small" className={classes.meetingModeIcon} />
              {eventAttendanceMode === HYBRID && hibrydMeetingModeAndLocation}
              {eventAttendanceMode === PHYSICAL && inPersonLocation}
              {eventAttendanceMode === VIRTUAL && t('meeting:meeting.onlineMeeting')}
            </div>
          )}
          {showChangeModeButton && (
            <Button
              small
              variant="tertiary"
              onClick={() => setOpenDialogChangeMode(true)}
            >
              {t('meeting:meeting.changeAttendanceMode', {
                newMeetingMode: t(`global:attendanceMode.${!currentAttendeeMeetingMode ? 'online' : 'offline'}`),
              })}
            </Button>
          )}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <PeopleFullProfile
        person={person}
        otherParticipant={otherParticipant}
        showContactInfo={showContactInfo}
        attendeeTypeCustomFields={attendeeTypeCustomFields}
      />
      {userMessage && (
        <div className={classes.meetingMessage}>
          <Typography className={classes.messageTitle} variant="subtitle2" gutterBottom>
            {t('meeting:meeting.message')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {userMessage}
          </Typography>
        </div>
      )}
      {!isHappeningVirtually && (
        <div className={classes.notesWrapper}>
          <Typography className={classes.messageTitle} variant="subtitle2" gutterBottom>
            {t('global:forms.notes')}
          </Typography>
          <div className={classes.notes}>
            <Notes meeting={meeting} event={event} />
          </div>
        </div>
      )}
      <Loader loading={updating} />
      {currentStatus === meetingStatuses.requested && !scheduleFinished && !meetingFinished ? (
        <>
          <Divider className={classes.divider} />
          <div className={classes.buttons}>
            {participantId === hostId ? (
              <>
                <Button
                  className={classes.button}
                  disabled={updating || conferenceFinished}
                  onClick={() => answerToMeetingRequest(meetingStatuses.accepted)}
                >
                  {t('meeting:actions.approve')}
                </Button>
                <Button
                  variant="secondary"
                  className={classes.button}
                  disabled={updating || conferenceFinished}
                  onClick={handleRejection}
                >
                  {t('meeting:actions.reject')}
                </Button>
              </>
            ) : (
              rejectionEnabled && (
                <Button
                  className={classes.button}
                  disabled={updating}
                  onClick={handleRejection}
                >
                  {t('meeting:actions.cancel')}
                </Button>
              )
            )}
          </div>
        </>
      ) : (
        meetingAccepted && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.buttons}>
              {!meetingStarted && (
                <DownloadActivity
                  id={meetingId}
                  name={t('meeting:meeting.with', {
                    participant: toTitleCase(otherParticipant.profileContactName),
                  })}
                  businessConferenceId={businessConferenceId}
                  className={classes.button}
                  button
                />
              )}
              {isHappeningVirtually ? (
                <Button
                  className={classes.button}
                  onClick={onJoin}
                  icon={<VideocamIcon />}
                >
                  {t('meeting:actions.join')}
                </Button>
              ) : (false && ( // InPersonMeetingCheckIn: I smell a reroll at some point
                <Button
                  className={classes.button}
                  onClick={checkInMeeting}
                  disabled={markAttended}
                >
                  {t('meeting:actions.checkInMeeting')}
                </Button>
              ))}
              {(rejectionEnabled && !scheduleFinished && !meetingFinished) && (
                <div className={classes.cancelBtnContainer}>
                  <Typography variant="body2" gutterBottom>
                    {t('meeting:meeting.cancelMeetingLabel')}
                  </Typography>
                  <Button
                    variant="secondary"
                    className={classes.button}
                    disabled={updating}
                    onClick={handleRejection}
                  >
                    {t('meeting:actions.cancelMeeting')}
                  </Button>
                </div>
              )}
              {showEvaluateMeetingButton && (
                <Button
                  className={classes.button}
                  onClick={openEvaluation}
                >
                  {t('meeting:actions.evaluateMeeting')}
                </Button>
              )}
            </div>
          </>
        )
      )}
      {openDialogChangeMode && (
        <div className={classes.backdropDialog}>
          <div className={classes.dialogContainerDos}>
            <ConfirmDialog
              open={openDialogChangeMode}
              handleClose={() => setOpenDialogChangeMode(false)}
              secondaryAction={{
                name: t('global:actions.cancel'),
                onClick: () => setOpenDialogChangeMode(false),
              }}
              action={{
                name: t('meeting:meeting.change'),
                onClick: () => {
                  handleChangeMeetingMode();
                  setOpenDialogChangeMode(false);
                },
              }}
            >
              <Typography>
                {t('meeting:meeting.changeModeInfo')}
              </Typography>
            </ConfirmDialog>
          </div>
        </div>
      )}
      <RejectionMeetingDialog
        meeting={meeting}
        closeDialog={closeDialog}
        attendees={attendees}
        answerToMeetingRequest={answerToMeetingRequest}
        newMeetingMessage={newMeetingMessage}
        setNewMeetingMessage={setNewMeetingMessage}
        newProposedSlot={newProposedSlot}
        setNewProposedSlot={setNewProposedSlot}
        meetingProposalToggleValue={meetingProposalToggleValue}
        setMeetingProposalToggleValue={setMeetingProposalToggleValue}
        rejectionDialogOpen={rejectionDialogOpen}
        setRejectionDialogOpen={setRejectionDialogOpen}
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        rejectionReason={rejectionReason}
        setRejectionReason={setRejectionReason}
        multipleRequestsEnabled={multipleRequestsEnabled}
      />
      {!!openedRequest && (
        <MeetingRequestModal
          attendees={attendees}
          closeModal={closeModal}
          openedRequest={openedRequest}
          attendeeTypeCustomFields={attendeeTypeCustomFields}
          businessConferenceParticipants={participants}
          disabledButtons
        />
      )}
    </>
  );
};

Meeting.propTypes = {
  setActiveMeetingEvaluation: PropTypes.func.isRequired,
  activeSurvey: PropTypes.bool,
  meeting: CustomPropTypes.meeting.isRequired,
  participants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  conferences: PropTypes.objectOf(
    CustomPropTypes.conference
  ).isRequired,
  participantTypes: CustomPropTypes.participantType,
  surveys: PropTypes.objectOf(
    CustomPropTypes.survey
  ),
  currentParticipant: CustomPropTypes.participant.isRequired,
  meetings: PropTypes.objectOf(CustomPropTypes.meeting),
  callApi: PropTypes.func.isRequired,
  goToPreviousSubpage: PropTypes.func.isRequired,
  meetingStatuses: PropTypes.shape({
    requested: PropTypes.number,
    accepted: PropTypes.number,
    rejected: PropTypes.number,
    expired: PropTypes.number,
  }).isRequired,
  setShowNewProposedTimeError: PropTypes.func,
  updating: PropTypes.bool.isRequired,
  participantSuccess: PropTypes.bool.isRequired,
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ),
  entities: CustomPropTypes.entities.isRequired,
  changeModeSuccess: PropTypes.bool.isRequired,
  scrollToTop: PropTypes.func.isRequired,
};

Meeting.defaultProps = {
  setShowNewProposedTimeError: undefined,
  activeSurvey: false,
  participants: {},
  participantTypes: {},
  surveys: {},
  meetings: {},
  attendees: {},
  attendeeTypeCustomFields: {},
  attendeeTypes: {},
};

const mapStateToProps = ({
  app: {
    activeSubpage: {
      activeSurvey,
    },
  },
  entities,
  entities: {
    attendeeTypes,
    businessConferences,
    businessConferenceMeetings,
    businessConferenceParticipants,
    businessConferenceParticipantTypes,
    surveys,
  },
  meta: {
    meetingStatuses,
  },
  fetchStatus: {
    updateMeeting: { isFetching: updating, success: updateSuccess },
    participant: { success: participantSuccess },
    changeMeetingAttendanceMode: { success: changeModeSuccess },
  },
}) => ({
  activeSurvey: !!activeSurvey,
  conferences: businessConferences,
  participants: businessConferenceParticipants,
  participantTypes: businessConferenceParticipantTypes,
  meetings: businessConferenceMeetings,
  surveys,
  meetingStatuses,
  updating,
  updateSuccess,
  participantSuccess,
  attendeeTypes,
  entities,
  changeModeSuccess,
});

export default connect(
  mapStateToProps,
  { callApi, setActiveMeetingEvaluation, goToPreviousSubpage }
)(Meeting);
