export default {
  en: {
    message: {
      quizNotStarted: 'The question isn\'t available yet',
      answerdSent: 'Your answer has been sent',
      selectAnswer: 'You must select an answer',
    },
  },
  es: {
    message: {
      quizNotStarted: 'La pregunta aún no está disponible',
      answerdSent: 'Su respuesta ha sido enviada',
      selectAnswer: 'Debe seleccionar una respuesta',
    },
  },
  fr: {
    message: {
      quizNotStarted: "La question n'est pas encore disponible",
      answerdSent: 'Votre réponse a été envoyée',
      selectAnswer: 'Vous devez sélectionner une réponse',
    },
  },
  ca: {
    message: {
      quizNotStarted: 'La pregunta no està disponible encara',
      answerdSent: 'S’ha enviat  la pregunta',
      selectAnswer: 'Has de seleccionar una resposta',
    },
  },
  pt: {
    message: {
      quizNotStarted: 'A pergunta ainda não está disponível',
      answerdSent: 'Sua resposta foi enviada',
      selectAnswer: 'Você deve selecionar uma resposta',
    },
  },
  it: {
    message: {
      quizNotStarted: 'La domanda non è ancora disponibile',
      answerdSent: 'La sua risposta è stata inviata',
      selectAnswer: 'Deve scegliere una risposta',
    },
  },
  de: {
    message: {
      quizNotStarted: 'Die Frage ist noch nicht verfügbar',
      answerdSent: 'Ihre Antwort wurde gesendet.',
      selectAnswer: 'Sie müssen eine Antwort wählen.',
    },
  },
  ja: {
    message: {
      quizNotStarted: '質問はまだありません',
      answerdSent: 'あなたの回答が送信されました',
      selectAnswer: '答えを選ばなければなりません',
    },
  },
  zh: {
    message: {
      quizNotStarted: '问题还不可用',
      answerdSent: '您的答案已发送',
      selectAnswer: '你必须选择一个答案',
    },
  },
  tr: {
    message: {
      quizNotStarted: 'Soru henüz mevcut değil',
      answerdSent: 'Cevabınız gönderildi',
      selectAnswer: 'Bir cevap seçmelisiniz',
    },
  },
  ar: {
    message: {
      quizNotStarted: 'السؤال غير متاح حتى الآن',
      answerdSent: 'تم إرسال إجابتك',
      selectAnswer: 'يجب عليك تحديد إجابة',
    },
  },
};
