import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { useParams } from 'react-router-dom';
import ScrollableItems from '../ScrollableItems';
import { getModuleByType } from '../../helpers/getters';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import useParams from '../../hooks/useParams'; // VS-PT-HACK
import { setActiveSponsor } from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    height: 50,
    margin: theme.spacing(2.5),
  },
  logo: {
    height: '100%',
  },
  sponsoredBy: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.darkGrey.light,
  },
  sponsorsContainer: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.5, 0, 0.5, 1.5),
    borderRadius: 12,
    minWidth: 136,
  },
  button: {
    all: 'unset',
    cursor: 'pointer',
    height: 50,
  },
}));

export const Component = ({
  sponsors,
  appSettings,
  order,
  showTitle,
  hasPadding,
  isLogin,
  workshopId,
  setActiveSponsor: dispatchGoToSponsor,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { locale } = useParams(); // VS-PT-HACK

  const [settings] = Object.values(appSettings);
  const sponsorsModule = getModuleByType(settings.modules, 'Sponsors');

  const sponsorsTitle = sponsorsModule?.name[locale || 'en'] || t('sponsors.sponsoredBy');

  const sponsorsWithLogo = order?.map((id) => sponsors[id])
    .filter((sponsor) => sponsor.logo.filename);
  let logosToShow;

  if (sponsorsWithLogo && isLogin) logosToShow = sponsorsWithLogo.filter((sponsor) => sponsor.sections && sponsor.sections.includes('login_page'));
  else if (sponsorsWithLogo) logosToShow = sponsorsWithLogo.filter(
    (sponsor) => sponsor.sections && (sponsor.sections.includes('all_activities')
    || (sponsor.sections.includes('custom_activities') && sponsor.activitiesList.includes(workshopId)))
  );
  const urlFixed = (url) => {
    if (!/^https?:\/\//i.test(url)) return `http://${url}`;
    return url;
  };

  return logosToShow?.length > 0 ? (
    <div className={isLogin ? undefined : classes.sponsorsContainer}>
      {showTitle && (
        <Typography className={classes.sponsoredBy}>
          {sponsorsTitle}
        </Typography>
      )}
      <ScrollableItems className={clsx(hasPadding && classes.sponsorsWrapper)}>
        {logosToShow.map(({ id, name, logo, website }) => (
          <div className={classes.logoWrapper} key={name}>
            {isLogin ? (
              <a href={website ? urlFixed(website) : undefined} rel="noopener noreferrer" target="_blank">
                <img className={classes.logo} src={logo.medium} alt={name} />
              </a>
            ) : (
              <button type="button" className={classes.button} onClick={() => dispatchGoToSponsor(id)}>
                <img className={classes.logo} src={logo.medium} alt={name} />
              </button>
            )}
          </div>
        ))}
      </ScrollableItems>
    </div>
  ) : null;
};

Component.propTypes = {
  setActiveSponsor: PropTypes.func.isRequired,
  appSettings: PropTypes.objectOf(CustomPropTypes.appSettings).isRequired,
  sponsors: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape({
        medium: PropTypes.string,
        filename: PropTypes.string,
      }),
    })
  ),
  order: PropTypes.arrayOf(PropTypes.string),
  showTitle: PropTypes.bool,
  hasPadding: PropTypes.bool,
  isLogin: PropTypes.bool,
  workshopId: PropTypes.string,
};

Component.defaultProps = {
  sponsors: {},
  order: [],
  showTitle: true,
  hasPadding: false,
  isLogin: false,
  workshopId: undefined,
};

const mapStateToProps = ({
  fetchStatus: { sponsors: { order } },
  entities: { sponsors, appSettings },
}) => ({
  sponsors,
  appSettings,
  order,
});

export default connect(mapStateToProps, { setActiveSponsor })(Component);
