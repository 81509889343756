/* eslint-disable comma-dangle */
export default {
  en: {
    title: {
      detail: 'Detail',
      activityDescription: 'Activity description',
      attachments: 'Attachments',
      eventSchedule: 'Event schedule',
      reminder: 'Reminder',
      error: 'Error',
      physicallyOnlyActivity: 'Venue only activity',
      virtuallyOnlyActivity: 'Virtual only activity',
      location: 'Location',
      price: 'Price',
    },
    actions: {
      scheduleMeeting: 'Schedule meeting',
      blockMeetingSpace: 'Block meeting space',
    },
    activities: {
      viewAllActivities: 'View all activities',
      listView: 'List view',
      dayView: 'Day view',
      onsite: 'Onsite',
    },
    activityType: {
      other: 'Sponsor Activities',
      all: 'All',
      activities: 'Activities',
      meetings: 'Meetings',
      onlyViewVirtualActivity: 'Only view virtual {{activityType}}',
      onlyViewInPersonActivity: 'Only view in person {{activityType}}',
      activityTypesFilter: {
        activities: 'activities',
        meetings: 'meetings',
        other: 'sponsor activities',
      },
    },
    info: {
      noAvailableSlot: 'Your meeting request couldn\'t be sent, try another available slot.',
      noActivities: 'There are no activities',
      noSuchActivities: 'There are no activities of this type, try selecting a different date or type.',
      pendingMeetingEvaluations: 'Complete your missing meeting evaluations',
      pendingMeetings: 'You have meetings pending to accept.',
      physicallyOnlyActivity: 'This activity is only available in person',
      virtuallyOnlyActivity: 'This activity is only available virtually',
      location: 'Event location map',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Registration Success!',
      registeredOnWorkshopDescription: 'Your registration to the activity was successful!',
      errorUnregisterOnWorkshopTitle: 'Error on your request',
      errorRegisteredOnWorkshopTitle: 'Error during your registration',
    },
  },
  es: {
    title: {
      detail: 'Detalle',
      activityDescription: 'Descripción de la actividad',
      attachments: 'Adjuntos',
      eventSchedule: 'Calendario del evento',
      reminder: 'Recordatorio',
      error: 'Error',
      physicallyOnlyActivity: 'Actividad solo presencial',
      virtuallyOnlyActivity: 'Actividad solo virtual',
      location: 'Ubicación',
      price: 'Precio',
    },
    actions: {
      scheduleMeeting: 'Agenda una reunión',
      blockMeetingSpace: 'Bloquea espacios de reunión',
    },
    activities: {
      viewAllActivities: 'Ver todas las actividades',
      listView: 'Vista de lista',
      dayView: 'Vista por día',
      onsite: 'In situ',
    },
    activityType: {
      other: 'Actividades de patrocinadores',
      all: 'Todas',
      activities: 'Actividades',
      meetings: 'Reuniones',
      onlyViewVirtualActivity: 'Ver solo {{activityType}} virtuales',
      onlyViewInPersonActivity: 'Ver solo {{activityType}} presenciales',
      activityTypesFilter: {
        activities: 'Actividades',
        meetings: 'Reuniones',
        other: 'Actividades de patrocinadores',
      },
    },
    info: {
      noAvailableSlot: 'No se pudo enviar tu solicitud de cita, intenta con otro horario disponible.',
      noActivities: 'No hay actividades',
      noSuchActivities: 'No hay actividades de este tipo, intenta cambiar la fecha o el tipo',
      pendingMeetingEvaluations: 'Completa tus evaluaciones de reuniones restantes',
      pendingMeetings: 'Tienes solicitudes de reunión pendientes por aceptar.',
      physicallyOnlyActivity: 'Esta actividad solo está disponible presencialmente',
      virtuallyOnlyActivity: 'Esta actividad solo está disponible virtualmente',
      location: 'Mapa de ubicación del evento',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Registro Exitoso! ',
      registeredOnWorkshopDescription: 'Tu registro a la actividad fue exitoso!',
      errorUnregisterOnWorkshopTitle: 'Error en tu requerimiento',
      errorRegisteredOnWorkshopTitle: 'Error durante tu registro',
    },
  },
  fr: {
    title: {
      detail: 'Détail',
      activityDescription: 'Description de l\'activité',
      attachments: 'Annexes',
      eventSchedule: 'Calendrier des événements',
      reminder: 'Rappel',
      error: 'Erreur',
      physicallyOnlyActivity: 'Activité uniquement du lieu',
      virtuallyOnlyActivity: 'Activité uniquement virtuelle',
      location: 'Emplacement',
      price: 'Prix',
    },
    actions: {
      scheduleMeeting: 'Organiser une réunion',
      blockMeetingSpace: 'Bloquer un espace de réunion',
    },
    activities: {
      viewAllActivities: 'Voir toutes les activités',
      listView: 'Voir la liste',
      dayView: 'Voir la journée',
      onsite: 'Sur place',
    },
    activityType: {
      other: 'Activités de parrainage',
      all: 'Tous',
      activities: 'Activités',
      meetings: 'Réunions',
      onlyViewVirtualActivity: 'Afficher uniquement les {{activityType}} virtuelles',
      onlyViewInPersonActivity: 'Afficher uniquement les {{activityType}} en personne',
      activityTypesFilter: {
        activities: 'Activités',
        meetings: 'Réunions',
        other: 'Activités de parrainage',
      },
    },
    info: {
      noAvailableSlot: 'Votre demande de réunion n\'a pas pu être envoyée, essayez un autre créneau disponible.',
      noActivities: 'Aucune activité',
      noSuchActivities: 'Il n\'y a pas d\'activités de ce type, essayez de sélectionner une date ou un type différent',
      pendingMeetingEvaluations: 'Complétez vos évaluations de réunions manquantes',
      pendingMeetings: 'Vous avez des réunions en attente d\'acceptation.',
      physicallyOnlyActivity: 'Cette activité est disponible uniquement en personne',
      virtuallyOnlyActivity: 'Cette activité n\'est disponible que virtuellement',
      location: 'Carte des lieux de l\'événement',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Enregistrement réussi ',
      registeredOnWorkshopDescription: 'Votre enregistrement à l\'activité est bien pris en compte.',
      errorUnregisterOnWorkshopTitle: 'Nous avons rencontré une erreur.',
      errorRegisteredOnWorkshopTitle: 'Nous avons rencontré une erreur pendant l\'enregistrement.',
    },
  },
  ca: {
    title: {
      detail: 'Detall',
      activityDescription: 'Descripció de l’activitat',
      attachments: 'Document adjunt',
      eventSchedule: 'Programa de l’esdeveniment',
      reminder: 'Recordatori',
      error: 'Error',
      physicallyOnlyActivity: 'Activitat exclusivament física',
      virtuallyOnlyActivity: 'Activitat exclusivament en línia',
      location: 'Lloc',
      price: 'Preu',
    },
    actions: {
      scheduleMeeting: 'Agenda una reunió',
      blockMeetingSpace: 'Bloqueja espai de reunió',
    },
    activities: {
      viewAllActivities: 'Vista de totes les activitats',
      listView: 'Vista de llista',
      dayView: 'Vista de dia',
      onsite: 'En el lloc',
    },
    activityType: {
      other: 'activitats patrocinades',
      all: 'Tot',
      activities: 'Activitats',
      meetings: 'Reunions',
      onlyViewVirtualActivity: 'Només vista en línia {{activityType}}',
      onlyViewInPersonActivity: 'Només vista en persona {{activityType}} ',
      activityTypesFilter: {
        activities: 'activitats',
        meetings: 'reunions',
        other: 'activitats patrocinades',
      },
    },
    info: {
      noAvailableSlot:
        'La teva sol·licitud de reunió no s’ha pogut enviar, intenta-ho amb un altre slot disponible.',
      noActivities: 'No hi ha activitats',
      noSuchActivities:
        'No hi ha activitats d’aquest tipus, intenta seleccionar una data diferent o canvia el tipus d’activitat.',
      pendingMeetingEvaluations: 'Completa les avaluacions que falten',
      pendingMeetings: 'Tens reunions pendents d’acceptar.',
      physicallyOnlyActivity:
        'Aquesta activitat només està disponible en persona.',
      virtuallyOnlyActivity:
        'Aquesta activitat només està disponible en línia.',
      location: 'Mapa de localitzacions d’esdeveniments',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Inscripció realitzada correctament!',
      registeredOnWorkshopDescription:
        'La teva inscripció a l’activitat s’ha registrat correctament',
      errorUnregisterOnWorkshopTitle: 'Error en la teva sol·licitud',
      errorRegisteredOnWorkshopTitle: 'Error durant la teva inscripció',
    },
  },
  pt: {
    title: {
      detail: 'Detalhe',
      activityDescription: 'Descrição da atividade',
      attachments: 'Anexos',
      eventSchedule: 'Agenda do evento',
      reminder: 'Lembrete',
      error: 'Erro',
      physicallyOnlyActivity: 'Atividade apenas do lugar',
      virtuallyOnlyActivity: 'Atividade apenas virtual',
      location: 'Localização',
      price: 'Preço',
    },
    actions: {
      scheduleMeeting: 'Agendar reunião',
      blockMeetingSpace: 'Bloquear agenda para reuniões',
    },
    activities: {
      viewAllActivities: 'Ver todas as atividades',
      listView: 'Exibição de lista',
      dayView: 'Agenda do día',
      onsite: 'No local',
    },
    activityType: {
      other: 'Atividades do Patrocinador',
      all: 'Todos',
      activities: 'Atividades',
      meetings: 'Reuniões',
      onlyViewVirtualActivity: 'Veja apenas {{activityType}} virtuais',
      onlyViewInPersonActivity: 'Veja apenas {{activityType}} presenciais',
      activityTypesFilter: {
        activities: 'Atividades',
        meetings: 'Encontros',
        other: 'Atividades do Patrocinador',
      },
    },
    info: {
      noAvailableSlot: 'Não foi possível enviar sua solicitação de agendamento, tente outro horário disponível.',
      noActivities: 'Não há atividades',
      noSuchActivities: 'Não há atividades desse tipo, tente selecionar uma data ou tipo diferente.',
      pendingMeetingEvaluations: 'Conclua suas avaliações de reunião ausentes',
      pendingMeetings: 'Você tem reuniões pendentes para aceitar.',
      physicallyOnlyActivity: 'Esta atividade só está disponível pessoalmente',
      virtuallyOnlyActivity: 'Esta atividade só está disponível virtualmente',
      location: 'Mapa de localização do evento',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Registro Sucesso! ',
      registeredOnWorkshopDescription: 'Seu registro para a atividade foi bem sucedido!',
      errorUnregisterOnWorkshopTitle: 'Erro no seu pedido',
      errorRegisteredOnWorkshopTitle: 'Erro durante o seu registo',
    },
  },
  it: {
    actions: {
      scheduleMeeting: 'Pianifica la riunione',
      blockMeetingSpace: 'Blocca spazio per riunioni'
    },
    title: {
      detail: 'Dettaglio',
      error: 'Errore',
      eventSchedule: 'Calendario degli eventi',
      attachments: 'Allegati',
      activityDescription: "Descrizione dell'attività",
      reminder: 'Promemoria',
      physicallyOnlyActivity: 'Attività solo in sede',
      virtuallyOnlyActivity: 'Attività solo virtuale',
      location: 'Posizione',
      price: 'Prezzo',
    },
    activityType: {
      other: 'Attività di sponsorizzazione',
      all: 'Tutti',
      activities: 'Attività',
      meetings: 'Incontro',
      onlyViewVirtualActivity: 'Vedi solo {{activityType}} virtuali',
      onlyViewInPersonActivity: 'Guarda solo le {{activityType}} faccia a faccia',
      activityTypesFilter: {
        activities: 'Attività',
        meetings: 'Incontro',
        other: 'Attività di sponsorizzazione',
      },
    },
    activities: {
      listView: 'Visualizzazione elenco',
      viewAllActivities: 'Visualizza tutte le attività',
      dayView: 'Vista giornaliera',
      onsite: 'In loco',
    },
    info: {
      noActivities: 'Non ci sono attività',
      noSuchActivities: 'Non ci sono attività di questo tipo, prova a selezionare una data o un tipo diverso.',
      pendingMeetings: 'Hai riunioni in sospeso per essere accettate.',
      pendingMeetingEvaluations: 'Completa le valutazioni delle riunioni mancanti',
      noAvailableSlot: 'Impossibile inviare la tua richiesta di riunione, prova un altro slot disponibile.',
      physicallyOnlyActivity: 'Questa attività è disponibile solo di persona',
      virtuallyOnlyActivity: 'Questa attività è disponibile solo virtualmente',
      location: 'Mappa della posizione dell\'evento',
    },
    registrationMessages: {
      errorRegisteredOnWorkshopTitle: 'Errore durante la registrazione',
      errorUnregisterOnWorkshopTitle: 'Errore nella tua richiesta',
      registeredOnWorkshopTitle: 'Registrazione riuscita!',
      registeredOnWorkshopDescription: "La tua registrazione all'attività è stata completata con successo!"
    }
  },
  ja: {
    title: {
      attachments: '添付ファイル',
      error: 'エラー',
      reminder: 'リマインダー',
      activityDescription: 'アクティビティの説明',
      eventSchedule: 'イベントスケジュール',
      detail: '詳細',
      physicallyOnlyActivity: '会場のみのアクティビティ',
      virtuallyOnlyActivity: '仮想のみのアクティビティ',
      location: '位置',
      price: '価格',
    },
    actions: {
      blockMeetingSpace: 'ミーティングスペースをブロックする',
      scheduleMeeting: 'ミーティングをスケジュールする'
    },
    activityType: {
      other: 'スポンサー活動',
      all: '全て',
      activities: 'アクティビティ',
      meetings: 'ミーティング',
      onlyViewVirtualActivity: 'バーチャル{{activityType}}のみを表示',
      onlyViewInPersonActivity: '対面{{activityType}}のみを表示',
      activityTypesFilter: {
        other: 'スポンサー活動',
        meetings: 'ミーティング',
        activities: 'アクティビティ'
      },
    },
    info: {
      noActivities: 'アクティビティはありません',
      noSuchActivities: 'このタイプのアクティビティはありません。別の日付またはタイプを選択してください。',
      pendingMeetings: '承諾待ちの会議があります。',
      pendingMeetingEvaluations: '不足している会議の評価を完了する',
      noAvailableSlot: '会議出席依頼を送信できませんでした。別の空きスロットを試してください。',
      physicallyOnlyActivity: 'このアクティビティは直接ご利用いただけます',
      virtuallyOnlyActivity: 'このアクティビティは事実上のみ利用可能です',
      location: 'イベントロケーションマップ',
    },
    activities: {
      dayView: '日表示',
      onsite: 'オンサイト',
      viewAllActivities: 'すべてのアクティビティを表示',
      listView: 'リストビュー'
    },
    registrationMessages: {
      registeredOnWorkshopTitle: '登録成功！',
      errorUnregisterOnWorkshopTitle: 'リクエストに誤りがあります',
      errorRegisteredOnWorkshopTitle: '登録中にエラーが発生しました',
      registeredOnWorkshopDescription: 'アクティビティへの登録は成功しました！'
    }
  },
  zh: {
    title: {
      detail: '详情',
      activityDescription: '活动描述',
      eventSchedule: '活动时间表',
      error: '错误',
      reminder: '提醒',
      attachments: '附件',
      physicallyOnlyActivity: '仅限场地活动',
      virtuallyOnlyActivity: '仅虚拟活动',
      location: '地点',
      price: '价格',
    },
    activityType: {
      other: '赞助活动',
      all: '全部',
      activities: '活动',
      meetings: '会议',
      onlyViewVirtualActivity: '僅查看{{activityType}}虛擬',
      onlyViewInPersonActivity: '僅見{{activityType}}本人',
      activityTypesFilter: {
        other: '赞助活动',
        activities: '活动',
        meetings: '会议',
      },
    },
    activities: {
      viewAllActivities: '查看所有活动',
      dayView: '日视图',
      onsite: '现场',
      listView: '列表视图'
    },
    actions: {
      blockMeetingSpace: '封锁会议空间',
      scheduleMeeting: '安排会议'
    },
    info: {
      noActivities: '没有活动',
      pendingMeetings: '您有待接受的会议。',
      noAvailableSlot: '无法发送您的会议请求，请尝试另一个可用的插槽。',
      pendingMeetingEvaluations: '完成缺少的会议评估',
      noSuchActivities: '没有此类活动，请尝试选择其他日期或类型。',
      physicallyOnlyActivity: '此活动仅限本人参加',
      virtuallyOnlyActivity: '此活动仅虚拟可用',
      location: '活动地点图',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: '注册成功！',
      errorUnregisterOnWorkshopTitle: '你的请求出错',
      registeredOnWorkshopDescription: '你的活动注册成功了！',
      errorRegisteredOnWorkshopTitle: '注册过程中出错',
    },
  },
  ar: {
    title: {
      detail: 'التفاصيل',
      activityDescription: 'وصف النشاط',
      attachments: 'المرفقات',
      eventSchedule: 'جدول الأحداث',
      reminder: 'تذكير',
      error: 'خطأ',
      physicallyOnlyActivity: 'نشاط المكان فقط',
      virtuallyOnlyActivity: 'نشاط افتراضي فقط',
      location: 'الموقع',
      price: 'السعر',
    },
    actions: {
      scheduleMeeting: 'جدول الاجتماع',
      blockMeetingSpace: 'حظر مساحة الاجتماعات',
    },
    activities: {
      viewAllActivities: 'عرض جميع الأنشطة',
      listView: 'طريقة عرض القائمة',
      dayView: 'عرض اليوم',
      onsite: 'بالموقع',
    },
    activityType: {
      other: 'أنشطة الراعي',
      all: 'جميع',
      activities: 'الأنشطة',
      meetings: 'الاجتماعات',
      onlyViewVirtualActivity: 'عرض الظاهري فقط {{activityType}}',
      onlyViewInPersonActivity: 'العرض الشخصي فقط {{activityType}}',
      activityTypesFilter: {
        activities: 'نشاطات',
        meetings: 'اجتماعات',
        other: 'أنشطة الراعي',
      },
    },
    info: {
      noAvailableSlot: 'تعذر إرسال طلب الاجتماع الخاص بك، جرب فتحة أخرى متاحة.',
      noActivities: 'لا توجد أنشطة',
      noSuchActivities: 'لا توجد أنشطة من هذا النوع، حاول تحديد تاريخ أو نوع مختلف.',
      pendingMeetingEvaluations: 'أكمل تقييمات الاجتماع المفقودة',
      pendingMeetings: 'لديك اجتماعات معلقة للقبول.',
      physicallyOnlyActivity: 'هذا النشاط متاح شخصيًا فقط',
      virtuallyOnlyActivity: 'هذا النشاط متاح فقط تقريبًا.',
      location: 'خريطة موقع الحدث',
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'نجاح التسجيل!',
      registeredOnWorkshopDescription: 'كان تسجيلك في النشاط ناجحًا!',
      errorUnregisterOnWorkshopTitle: 'خطأ في طلبك',
      errorRegisteredOnWorkshopTitle: 'خطأ أثناء التسجيل',
    },
  },
  tr: {
    title: {
      detail: 'Detay',
      activityDescription: 'Etkinlik açıklaması',
      attachments: 'Ekler',
      eventSchedule: 'Etkinlik takvimi',
      reminder: 'Hatırlatma',
      error: 'Hata',
      physicallyOnlyActivity: 'Sadece Mekan Etkinliği',
      virtuallyOnlyActivity: 'Yalnızca sanal etkinlik',
      location: 'Konum',
      price: 'Fiyat',
    },
    actions: {
      scheduleMeeting: 'Toplantı zamanlama',
      blockMeetingSpace: 'Meşgul olarak işaretle',
    },
    activities: {
      viewAllActivities: 'Tüm etkinlikleri görüntüle',
      listView: 'Liste görünümü',
      dayView: 'Gün görünümü',
      onsite: 'Yerinde',
    },
    activityType: {
      other: 'Sponsor Etkinlikleri',
      all: 'Tümü',
      activities: 'Etkinlikler',
      meetings: 'Toplantılar',
      onlyViewVirtualActivity: 'Yalnızca sanal {{activityType}} görüntüleyin',
      onlyViewInPersonActivity: 'Sadece kişide görüntüle {{activityType}}',
      activityTypesFilter: {
        activities: 'etkinlikler ',
        meetings: 'toplantılar',
        other: 'sponsor etkinlikler'
      }
    },
    info: {
      noAvailableSlot: 'Toplantı isteğiniz gönderilemedi, başka bir kullanılabilir yuvası deneyin.',
      noActivities: 'Aktiviteler yok',
      noSuchActivities: 'Bu türden herhangi bir etkinlik yok, farklı bir tarih veya tür seçmeyi deneyin.',
      pendingMeetingEvaluations: 'Eksiksiz toplantı değerlendirmelerinizi tamamlayın',
      pendingMeetings: 'Kabul edilmek üzere toplantılar var.',
      physicallyOnlyActivity: 'Bu etkinlik yalnızca şahsen kullanılabilir',
      virtuallyOnlyActivity: 'Bu etkinlik sadece neredeyse kullanılabilir',
      location: 'Etkinlik konum haritası'
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Kayıt Başarısı!',
      registeredOnWorkshopDescription: 'Faaliyete kaydınız başarılı oldu!',
      errorUnregisterOnWorkshopTitle: 'Talebinizde hata',
      errorRegisteredOnWorkshopTitle: 'Kayıt sırasında hata'
    }
  },
  de: {
    title: {
      detail: 'Detail',
      activityDescription: 'Beschreibung der Aktivität',
      attachments: 'Anbaugeräte',
      eventSchedule: 'Zeitplan der Veranstaltung',
      reminder: 'Erinnerung',
      error: 'Fehler',
      physicallyOnlyActivity: 'Aktivität nur am Veranstaltungsort',
      virtuallyOnlyActivity: 'Reine virtuelle Aktivität',
      location: 'Standort',
      price: 'Preis',
    },
    actions: {
      scheduleMeeting: 'Meeting planen',
      blockMeetingSpace: 'Meeting-Raum blockieren'
    },
    activities: {
      viewAllActivities: 'Alle Aktivitäten ansehen',
      listView: 'Ansicht auflisten',
      dayView: 'Blick auf den Tag',
      onsite: 'Vor Ort',
    },
    activityType: {
      other: 'Sponsoren-Aktivitäten',
      all: 'Alle',
      activities: 'Aktivitäten',
      meetings: 'Besprechungen',
      onlyViewVirtualActivity: 'Nur virtuell ansehen {{activityType}}',
      onlyViewInPersonActivity: 'Nur persönlich ansehen {{activityType}}',
      activityTypesFilter: {
        activities: 'Aktivitäten',
        meetings: 'Versammlungen',
        other: 'Sponsoren-Aktivitäten'
      }
    },
    info: {
      noAvailableSlot: 'Ihre Besprechungsanfrage konnte nicht gesendet werden. Probieren Sie einen anderen verfügbaren Slot aus.',
      noActivities: 'Es gibt keine Aktivitäten',
      noSuchActivities: 'Es gibt keine Aktivitäten dieses Typs. Wählen Sie ein anderes Datum oder einen anderen Typ aus.',
      pendingMeetingEvaluations: 'Vervollständigen Sie Ihre fehlenden Besprechungsbewertungen',
      pendingMeetings: 'Es stehen noch Besprechungen zur Annahme aus.',
      physicallyOnlyActivity: 'Diese Aktivität ist nur persönlich verfügbar',
      virtuallyOnlyActivity: 'Diese Aktivität ist nur virtuell verfügbar',
      location: 'Lageplan der Veranstaltung'
    },
    registrationMessages: {
      registeredOnWorkshopTitle: 'Registrierung erfolgreich!',
      registeredOnWorkshopDescription: 'Ihre Registrierung für die Aktivität war erfolgreich!',
      errorUnregisterOnWorkshopTitle: 'Fehler bei Ihrer Anfrage',
      errorRegisteredOnWorkshopTitle: 'Fehler bei der Registrierung'
    }
  }
};
