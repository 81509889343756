import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export default (state = {}, action) => {
  if (!action?.response?.entities) return state;

  const newEntities = action.response.entities;
  let newState;

  if (action.method === 'DELETE') {
    newState = cloneDeep(state);

    Object.keys(newEntities).forEach((entityType) => (
      Object.keys(newEntities[entityType]).forEach((id) => delete newState[entityType][id])
    ));
  } else {
    newState = merge({}, state, newEntities);

    if (action.type === 'UPDATE_PARTICIPANT_SUCCESS') {
      const { businessConferenceParticipants: participants } = newEntities;
      if (participants) {
        const [participantId] = Object.keys(participants);
        const responseBlockedSlots = participants[participantId]?.blockedSlots;
        newState.businessConferenceParticipants[participantId]
          .blockedSlots = responseBlockedSlots;
      }
    } else if ([
      'TOGGLE_QA_QUESTION_LIKE_SUCCESS',
      'QA_SESSION_QUESTIONS_SUCCESS',
    ].includes(action.type)) {
      const { eventQaSessionQuestions: questions } = newEntities;
      if (questions) Object.keys(questions).forEach((questionId) => {
        const responseLikes = questions[questionId]?.likedBy;
        newState.eventQaSessionQuestions[questionId]
          .likedBy = responseLikes;
      });
    }
  }

  if (typeof action.additionalReduction === 'function') newState = action.additionalReduction(newState);

  return newState;
};
