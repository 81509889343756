/* eslint-disable comma-dangle */
export default {
  en: {
    title: 'Event chat',
    channelName: 'Channel name',
    channelDescription: 'Channel description',
    description: 'Discuss topics, talks and workshops with other attendees and speakers.',
    sections: {
      channels: 'Channels',
      privateChats: 'Private chats',
    },
    actions: {
      createChannel: 'Create new channel',
      createChat: 'Create new chat',
    },
    channels: {
      public: 'Public',
      new: 'New!',
    },
    messages: {
      channelNamePlaceholder: 'Ex: Introductions',
      channelDescriptionPlaceholder: 'Ex: Use this channel to say hi to everyone!',
      channelInfo: 'This channel will be public and any attendee can join and participate.',
      moderatedMessageReceived: 'Your message has been sent successfully.',
      typeAMessage: 'Type a message...',
      noMessages: 'There are no messages in this chat yet.',
      makeMessage: 'Be the first to send a message!',
      maxChannelLength: 'Maximum {{maxLength}} characters.',
    },
    errors: {
      channelNameExists: 'This channel name is already in use.',
    },
  },
  es: {
    title: 'Chat del evento',
    channelName: 'Nombre del canal',
    channelDescription: 'Descripción del canal',
    description: 'Discute temas, charlas y talleres con otros asistentes y ponentes.',
    sections: {
      channels: 'Canales',
      privateChats: 'Chats privados',
    },
    actions: {
      createChannel: 'Crear canal',
      createChat: 'Crear chat privado',
    },
    channels: {
      public: 'Público',
      new: '¡Nuevo!',
    },
    messages: {
      channelNamePlaceholder: 'Ej: Presentaciones',
      channelDescriptionPlaceholder: 'Ej: ¡Utiliza este canal para saludar a los demás participantes!',
      channelInfo: 'Este canal será público y cualquier participante podrá unirse.',
      moderatedMessageReceived: 'Tu mensaje ha sido enviado correctamente.',
      typeAMessage: 'Escribe un mensaje...',
      noMessages: 'Aún no hay mensajes en este chat.',
      makeMessage: 'Sé el primero en enviar uno!',
      maxChannelLength: 'Máximo {{maxLength}} caracteres.',
    },
    errors: {
      channelNameExists: 'Este nombre de canal ya está en uso.',
    },
  },
  fr: {
    title: 'Chat de l\'événement',
    channelName: 'Nom du canal',
    channelDescription: 'Description du canal',
    description: 'Discutez des sujets, des conférences et des ateliers avec d\'autres participants et intervenants.',
    sections: {
      channels: 'Canaux',
      privateChats: 'Discussions privées',
    },
    actions: {
      createChannel: 'Créer une nouvel canal',
      createChat: 'Créer un nouveau chat',
    },
    channels: {
      public: 'Chat public',
      new: 'Nouveau!',
    },
    messages: {
      channelNamePlaceholder: 'Ex: Introductions',
      channelDescriptionPlaceholder: 'Ex : Utilisez ce canal pour dire bonjour à tout le monde !',
      channelInfo: 'Ce canal est public et un participant peut le rejoindre et y participer.',
      moderatedMessageReceived: 'Votre message a été envoyé avec succès.',
      typeAMessage: 'Tapez un message...',
      noMessages: 'Il n\'y a pas encore de messages dans ce chat.',
      makeMessage: 'Soyez le premier à envoyer un message!',
      maxChannelLength: 'Maximum {{maxLength}} caractères.',
    },
    errors: {
      channelNameExists: 'Ce nom de canal est déjà utilisé.',
    },
  },
  ca: {
    title: "Xat de l'esdeveniment",
    channelName: 'Nom del canal',
    channelDescription: 'Descripció del canal',
    description:
      'Parla de temes, xerrades i tallers amb altres assistents i ponents.',
    sections: { channels: 'Canals', privateChats: 'Xats privats' },
    actions: {
      createChannel: 'Crea un nou canal',
      createChat: 'Crea un nou xat',
    },
    channels: { public: 'Públic', new: 'Nou!' },
    messages: {
      channelNamePlaceholder: 'Ex: Presentacions',
      channelDescriptionPlaceholder:
        'Ex: Empra aquest canal per dir hola a tothom!',
      channelInfo:
        "Aquest canal serà públic i qualsevol assistent s'hi pot afegir i participar",
      moderatedMessageReceived: "S'ha enviat aquest missatge correctament.",
      typeAMessage: 'Escriu un missatge…',
      noMessages: 'No hi ha missatges en aquest xat encara.',
      makeMessage: "Sigues el primer a escriure'n un missatge!",
      maxChannelLength: 'Màxim {{maxLength}} caràcters.',
    },
    errors: { channelNameExists: "El nom d'aquest canal ja existeix." },
  },
  pt: {
    title: 'Chat de eventos',
    channelName: 'Nome do canal',
    channelDescription: 'Descrição do canal',
    description: 'Discuta tópicos, palestras e workshops com outros convidados e palestrantes.',
    sections: {
      channels: 'Canais',
      privateChats: 'Chats privados',
    },
    actions: {
      createChannel: 'Criar novo canal',
      createChat: 'Criar novo chat',
    },
    channels: {
      public: 'Público',
      new: 'Novo!',
    },
    messages: {
      channelNamePlaceholder: 'Ex: Introduções',
      channelDescriptionPlaceholder: 'Ex: Use este canal para dizer olá a todos!',
      channelInfo: 'Este canal será público e qualquer participante poderá participar e participar.',
      moderatedMessageReceived: 'Sua mensagem foi enviada com sucesso.',
      typeAMessage: 'Digite uma mensagem...',
      noMessages: 'Ainda não há mensagens neste chat.',
      makeMessage: 'Seja o primeiro a enviar uma mensagem!',
      maxChannelLength: 'Máximo de {{maxLength}} caracteres.',
    },
    errors: {
      channelNameExists: 'Este nome de canal já está a ser utilizado.',
    },
  },
  it: {
    channels: {
      public: 'pubblico',
      new: 'Nuovo!'
    },
    channelDescription: 'Descrizione del canale',
    messages: {
      channelNamePlaceholder: 'Es: presentazioni',
      channelDescriptionPlaceholder: 'Es: Usa questo canale per salutare tutti!',
      channelInfo: 'Questo canale sarà pubblico e ogni partecipante potrà partecipare e partecipare.',
      noMessages: 'Non ci sono ancora messaggi in questa chat.',
      moderatedMessageReceived: 'Il tuo messaggio è stato inviato con successo.',
      typeAMessage: 'Digita un messaggio...',
      maxChannelLength: 'Massimo {{maxLength}} caratteri.',
      makeMessage: 'Puoi essere il primo a inviare un messaggio!'
    },
    channelName: 'Nome del canale',
    description: 'Discutere argomenti, conferenze e workshop con altri partecipanti e relatori.',
    title: 'Chat evento',
    sections: {
      privateChats: 'Chat private',
      channels: 'Canali'
    },
    actions: {
      createChat: 'Crea nuova chat',
      createChannel: 'Crea nuovo canale'
    },
    errors: {
      channelNameExists: 'Il nome del canale è già in uso.'
    }
  },
  ja: {
    channelName: 'チャンネル名',
    title: 'イベントチャット',
    channelDescription: 'チャンネルの説明',
    sections: {
      privateChats: 'プライベートチャット',
      channels: 'チャネル'
    },
    actions: {
      createChannel: '新しいチャンネルを作成する',
      createChat: 'チャットを新規作成する'
    },
    channels: {
      new: '新品！',
      public: 'パブリック'
    },
    description: '他の出席者や講演者とトピック、講演、ワークショップについて話し合う。',
    messages: {
      channelNamePlaceholder: '例:イントロダクション',
      channelDescriptionPlaceholder: '例：このチャンネルを使ってみんなに挨拶しよう！',
      channelInfo: 'このチャンネルは公開され、参加者は誰でも参加できます。',
      noMessages: 'このチャットにはまだメッセージがありません。',
      makeMessage: 'メッセージを最初に送ってください！',
      typeAMessage: 'メッセージを入力...',
      maxChannelLength: '最大 {{maxLength}} 文字です。',
      moderatedMessageReceived: 'メッセージは正常に送信されました。'
    },
    errors: {
      channelNameExists: 'このチャンネル名は既に使用されています。'
    }
  },
  zh: {
    channelDescription: '频道描述',
    channelName: '频道名',
    sections: {
      privateChats: '私聊',
      channels: '频道'
    },
    title: '活动聊天',
    channels: {
      public: '公开',
      new: '新！'
    },
    actions: {
      createChannel: '创建新频道',
      createChat: '创建新的聊天'
    },
    messages: {
      typeAMessage: '键入一条消息...',
      channelNamePlaceholder: '例如：简介',
      moderatedMessageReceived: '您的消息已成功发送。',
      noMessages: '此聊天中还没有消息。',
      channelDescriptionPlaceholder: '例如：使用这个频道向所有人打个招呼！',
      maxChannelLength: '最多 {{maxLength}} 个字符。',
      makeMessage: '成为第一个发送消息的人！',
      channelInfo: '该频道将是公开的，任何与会者都可以加入和参与。'
    },
    errors: {
      channelNameExists: '此频道名称已被使用。'
    },
    description: '与其他与会者和演讲者讨论主题、讲座和研讨会。'
  },
  ar: {
    title: 'دردشة الحدث',
    channelName: 'اسم القناة',
    channelDescription: 'وصف القناة',
    description: 'ناقش الموضوعات والمحادثات وورش العمل مع الحاضرين والمتحدثين الآخرين.',
    sections: {
      channels: 'قنوات',
      privateChats: 'الدردشات الخاصة',
    },
    actions: {
      createChannel: 'إنشاء قناة جديدة',
      createChat: 'إنشاء دردشة جديدة',
    },
    channels: {
      public: 'عام',
      new: 'جديد!',
    },
    messages: {
      channelNamePlaceholder: 'مثال: مقدمات',
      channelDescriptionPlaceholder: 'على سبيل المثال: استخدم هذه القناة لتقول مرحبًا للجميع!',
      channelInfo: 'ستكون هذه القناة عامة ويمكن لأي حاضر الانضمام والمشاركة.',
      moderatedMessageReceived: 'تم إرسال رسالتك بنجاح.',
      typeAMessage: 'اكتب رسالة...',
      noMessages: 'لا توجد رسائل في هذه الدردشة حتى الآن.',
      makeMessage: 'كن أول من يرسل رسالة!',
      maxChannelLength: 'الحد الأقصى للحروف {{maxLength}}}.',
    },
    errors: {
      channelNameExists: 'اسم القناة هذا قيد الاستخدام بالفعل.',
    },
  },
  tr: {
    title: 'Etkinlik sohbeti',
    channelName: 'Kanal adı',
    channelDescription: 'Kanal açıklaması',
    description: 'Diğer katılımcılar ve konuşmacılarla konuları, görüşmeleri ve atölyeleri tartışın.',
    sections: {
      channels: 'Kanallar',
      privateChats: 'Özel sohbetler'
    },
    actions: {
      createChannel: 'Yeni kanal oluştur',
      createChat: 'Yeni sohbet oluştur'
    },
    channels: {
      public: 'Kamu',
      new: 'Yeni!'
    },
    messages: {
      channelNamePlaceholder: 'Ex: Tanıtımlar',
      channelDescriptionPlaceholder: 'Ex: Herkese merhaba demek için bu kanalı kullan!',
      channelInfo: 'Bu kanal herkese açık olacak ve katılımcı katılabilir ve katılabilir.',
      moderatedMessageReceived: 'Mesajınız başarıyla gönderildi.',
      typeAMessage: 'Mesaj yazın...',
      noMessages: 'Bu sohbette henüz mesaj yok.',
      makeMessage: 'İlk mesaj gönderen sen ol!',
      maxChannelLength: 'Maksimum {{maxLength}} karakter.'
    },
    errors: {
      channelNameExists: 'Bu kanal adı zaten kullanımda.'
    }
  },
  de: {
    title: 'Event-Chat',
    channelName: 'Name des Kanals',
    channelDescription: 'Beschreibung des Kanals',
    description: 'Diskutieren Sie Themen, Vorträge und Workshops mit anderen Teilnehmern und Referenten.',
    sections: {
      channels: 'Kanäle',
      privateChats: 'Private Chats'
    },
    actions: {
      createChannel: 'Neuen Kanal erstellen',
      createChat: 'Neuen Chat erstellen'
    },
    channels: {
      public: 'Öffentlich',
      new: 'Neu!'
    },
    messages: {
      channelNamePlaceholder: 'Beispiel: Einführungen',
      channelDescriptionPlaceholder: 'Beispiel: Benutze diesen Kanal, um allen Hallo zu sagen!',
      channelInfo: 'Dieser Kanal ist öffentlich und jeder Teilnehmer kann teilnehmen und teilnehmen.',
      moderatedMessageReceived: 'Ihre Nachricht wurde erfolgreich gesendet.',
      typeAMessage: 'Gib eine Nachricht ein...',
      noMessages: 'In diesem Chat gibt es noch keine Nachrichten.',
      makeMessage: 'Sei der Erste, der eine Nachricht sendet!',
      maxChannelLength: 'Maximal {{maxLength}} Zeichen.'
    },
    errors: {
      channelNameExists: 'Dieser Kanalname wird bereits verwendet.'
    }
  },
};
