import baseMoment from 'moment-timezone';
import i18n from 'i18next';

baseMoment.locale(i18n.language);

export { baseMoment };

const moment = (date) => baseMoment.parseZone(date).tz('America/Bogota', true);
export default moment;

export const getCompatibleDateFormat = (date, includeTime = false) => {
  if (!date) return null;
  const splittedDate = date.split('/');
  const joinedDate = [splittedDate[1], splittedDate[0], splittedDate[2]].join('/');
  return includeTime ? joinedDate.split(' - ').join(' ') : joinedDate;
};

// TODO use baseMoment instead moment when date fields support timezone param
// this format is only used in requests
export const getEventtiaDateFormat = (date, includeTime = false) => (
  moment(date).format(`DD/MM/YYYY${includeTime ? ' - HH:mm' : ''}`)
);

export const getEventTimeFormat = (events, options = {}) => {
  const { includeSeconds = false, includeMinutes = true } = options;
  const timeFormat = Object.values(events)[0]?.timeFormat;
  const seconds = includeSeconds ? ':ss' : '';
  const minutes = (includeSeconds || includeMinutes) ? ':mm' : '';
  return timeFormat === 'hours_24' ? `HH${minutes}${seconds}` : `h${minutes}${seconds} A`;
};

export const showInEventTimezone = (appSettings) => {
  const { timeVisualization = 'user_timezone' } = appSettings;
  return timeVisualization === 'event_timezone';
};
