import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import parseCustomFields from '../../helpers/customFields';
import { MEETING_REQUEST_STATUSES } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    color: theme.palette.darkGrey.main,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'row',
  },
  border: {
    marginBottom: theme.spacing(0.5),
    border: `1px solid ${theme.palette.lightGrey.dark}`,
    borderRadius: theme.spacing(0.5),

  },
  avatar: {
    marginRight: theme.spacing(1.5),
  },
  info: {
    display: 'flex',
    width: '100%',
    minWidth: 'calc(100% - 124px)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  participantInfo: {
    width: 'calc(100% - 75px)',
  },
  name: {
    fontWeight: 600,
  },
  status: {
    height: 20,
    fontSize: 12,
    borderRadius: 10,
    padding: theme.spacing(0.3, 1),
    marginLeft: theme.spacing(1),
  },
  rejected: {
    backgroundColor: theme.palette.lightGrey.dark,
    color: theme.palette.darkGrey.main,
  },
  cancelled: {
    color: 'white',
    backgroundColor: theme.palette.red.main,
  },
  caption: {
    color: theme.palette.darkGrey.light,
    opacity: 0.7,
  },
  jobTitle: {
    display: 'block',
  },
  startDate: {
    alignSelf: 'flex-end',
    textWrap: 'nowrap',
  },
}));

const { REJECTED, CANCELLED } = MEETING_REQUEST_STATUSES;

const RequestedMeeting = ({
  meetingRequest, attendees, attendeeTypeCustomFields, businessConferenceParticipants,
  setOpenedRequest, compact, sentByMe,
}) => {
  const { t } = useTranslation('meeting');
  const classes = useStyles();

  const {
    sentBy: { id: senderId },
    receivedBy: { id: receiverId },
    createdAt,
    status,
  } = meetingRequest;

  const otherParticipant = businessConferenceParticipants[sentByMe ? receiverId : senderId];
  const otherAttendee = attendees[otherParticipant.attendee.id];
  const { profileContactName, profileDisplayName, jobTitle } = otherParticipant;

  const fields = parseCustomFields(otherAttendee?.fields || {}, attendeeTypeCustomFields);
  const { headshot } = fields || {};
  return (
    <div
      className={clsx(classes.root, !compact && classes.border)}
      onClick={() => setOpenedRequest(meetingRequest)}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') setOpenedRequest(meetingRequest);
      }}
    >
      <Avatar src={headshot?.filename && headshot?.thumb} className={classes.avatar}>
        <PeopleIcon />
      </Avatar>
      <div className={classes.info}>
        <div className={classes.participantInfo}>
          <Typography variant="body2" className={classes.name}>
            {profileContactName}
            {(status === REJECTED || status === CANCELLED) && (
              <span className={clsx(
                classes.status, status === REJECTED ? classes.rejected : classes.cancelled
              )}
              >
                {t(`requests.status.${status}`)}
              </span>
            )}
          </Typography>
          <Typography noWrap variant="caption" className={clsx(classes.caption, classes.jobTitle)}>
            {jobTitle && t('stage:timeline.positionAt', { position: jobTitle })}
            {profileDisplayName}
          </Typography>

        </div>
        {!compact && (
          <Typography variant="caption" className={clsx(classes.caption, classes.startDate)}>
            {moment(createdAt).format('MMM DD, h:mm')}
          </Typography>
        )}
      </div>
    </div>
  );
};

RequestedMeeting.propTypes = {
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ).isRequired,
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ).isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  meetingRequest: CustomPropTypes.meetingRequest.isRequired,
  setOpenedRequest: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  sentByMe: PropTypes.bool,
};

RequestedMeeting.defaultProps = {
  attendeeTypeCustomFields: {},
  compact: false,
  sentByMe: false,
};

const mapStateToProps = ({
  entities: {
    attendees,
    attendeeTypeCustomFields,
  },
}) => ({
  attendees,
  attendeeTypeCustomFields,
});

export default connect(mapStateToProps)(RequestedMeeting);
