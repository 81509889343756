import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Card from 'eventtia-ui-components/lib/Card';
import Message from 'eventtia-ui-components/lib/Message';
import Button from 'eventtia-ui-components/lib/Button';
import { CircularProgress } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Chip from '@material-ui/core/Chip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useDidUpdate from '../../hooks/useDidUpdate';
import useRoutes from '../../hooks/useRoutes';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { baseMoment } from '../../helpers/dates';
import DownloadActivity from '../DownloadActivity';
import { urlFixed } from '../../helpers/strings';
import useTraces, { ENTITY_TYPES } from '../../hooks/useTraces';
import callApi from '../../actions/callApi';
import useTimer from '../../hooks/useTimer';

const LiveBadge = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.caption.fontSize,
    backgroundColor: theme.palette.red.light,
    color: theme.palette.red.main,
    fontWeight: 'bold',
    marginRight: 0,
    height: '1.4em',
    '& svg': {
      color: 'inherit',
      height: 8,
      width: 12,
    },
  },
  label: {
    padding: theme.spacing(0, 0.75),
  },
}))(Chip);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeigth: 275,
    margin: theme.spacing(1, 2, 1, 0),
    scrollSnapAlign: 'start',
  },
  rootpc: {
    width: '48.5%',
  },
  rootm: {
    width: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  activityName: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.darkGrey.light,
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  activityDescriptions: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.darkGrey.light,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 4,
    overflow: 'hidden',
    height: 70,
  },
  activityLive: {
    display: 'flex',
    margin: theme.spacing(0.5),
    flexWrap: 'wrap',
  },
  people: {
    fontSize: theme.typography.caption.fontSize,
    opacity: 0.6,
    overflow: 'hidden',
    margin: theme.spacing(0, 0, 1),
    textTransform: 'capitalize',
  },
  timespan: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.light,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: '1.5em',
      marginRight: theme.spacing(1),
    },
  },
  timeRemaining: {
    width: '100%',
    flex: '1 0',
  },
  button: {
    margin: theme.spacing(1, 1, 0.5, 0),
    flex: 1,
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  notificacionMessage: {
    position: 'absolute',
    width: '85%',
  },
}));

const SponsorActivityCard = ({
  sponsorActivity,
  callApi: dispatchCallApi,
  attendeeRegisterError,
  attendeeRegisterSuccess,
  registeredWorkshops,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['schedule', 'stage', 'global']);
  const { goTo } = useRoutes();
  const { eventUri } = useParams();
  const {
    id: wId,
    name,
    description,
    startDate,
    endDate,
    streamingUrl,
    streamingType,
    showOnRegister,
    availability,
    usedSeats,
    sponsorId,
  } = sponsorActivity;
  const registered = registeredWorkshops?.includes(wId);
  const url = urlFixed(streamingUrl);
  const [live, setLive] = useState(false);
  const difference = baseMoment(endDate).diff(baseMoment(startDate), 'days');
  const [activeId, setActiveId] = useState();
  const [registering, setRegistering] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState();
  const formattedType = 'DD MMMM, hh:mm a';

  const onTick = useCallback((now) => {
    if (now.isBefore(startDate)) setLive(false);
    else if (now.isAfter(endDate)) setLive(false);
    else setLive(true);
  }, [setLive]);
  useTimer({ startDate, endDate, onTick });

  const theme = useTheme();
  const { trackOnce } = useTraces(wId, ENTITY_TYPES.WORKSHOP, false);

  const onJoin = () => {
    const push = trackOnce(0);
    if (push) push.receive('ok').receive('error', ({ reason }) => {
      // eslint-disable-next-line no-console
      console.error('failed to join channel', reason);
    });
    if (streamingType === 'daily_co') goTo('stage.show', { workshopId: wId });
    else window.open(url, '_blank', 'noopener noreferrer');
  };
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useDidUpdate(() => {
    if (attendeeRegisterError && (wId === activeId)) {
      setNotificationMessage({ title: t('schedule:registrationMessages.errorRegisteredOnWorkshopTitle'), message: attendeeRegisterError, type: 'warning' });
      setActiveId();
    }
  }, [attendeeRegisterError, t]);

  useDidUpdate(() => {
    if (attendeeRegisterSuccess && (wId === activeId)) {
      registeredWorkshops.push(wId);
      setNotificationMessage({ title: t('schedule:registrationMessages.registeredOnWorkshopTitle'), message: t('schedule:registrationMessages.registeredOnWorkshopDescription'), type: 'success' });
      setActiveId();
    }
  }, [attendeeRegisterSuccess, t]);

  const onRegister = async () => {
    setRegistering(true);
    setActiveId(wId);
    await dispatchCallApi('registerAttendee', { eventUri, workshopId: wId });
    await dispatchCallApi('sponsorActivities', { eventUri, sponsorId });
    setRegistering(false);
  };

  const onClose = () => setNotificationMessage();
  return (
    <Card className={clsx(classes.root, mobile && classes.rootm, !mobile && classes.rootpc)}>

      {notificationMessage && (
        <Message
          type={notificationMessage?.type}
          title={notificationMessage?.title}
          message={notificationMessage?.message}
          onClose={onClose}
          className={classes.notificacionMessage}
        />
      )}
      <div className={classes.body}>
        <div className={classes.infoWrapper}>
          <Typography className={classes.timespan}>
            <AccessTimeIcon />
            <span>{baseMoment(startDate).format(formattedType) } - {baseMoment(endDate).format(difference ? formattedType : 'h:mm a')}</span>
          </Typography>
          <div className={classes.activityLive}>
            <Typography className={classes.activityName} component="div">
              {name}
            </Typography>
            {live && <LiveBadge icon={<Brightness1Icon />} label="LIVE" />}
          </div>
          <Typography
            className={classes.activityDescriptions}
          >
            {description}
          </Typography>
        </div>
        <div className={classes.buttons}>
          {((streamingUrl && registered) || (!showOnRegister && streamingUrl)) && (
            <Button
              className={classes.button}
              icon={<ExitToAppIcon />}
              onClick={onJoin}
            >
              {t('stage:actions.join')}
            </Button>
          )}
          {(showOnRegister && !registered) && (
            <Button
              className={classes.button}
              icon={<ExitToAppIcon />}
              onClick={onRegister}
              disabled={
                registering || (usedSeats >= availability && !!availability)
              }
            >
              {
                registering
                  ? <CircularProgress />
                  : t('stage:actions.register')
              }
            </Button>
          )}
          <DownloadActivity
            id={wId}
            name={name}
            className={classes.button}
            button
          />
        </div>
      </div>
    </Card>
  );
};

SponsorActivityCard.propTypes = {
  sponsorActivity: PropTypes.objectOf(
    CustomPropTypes.workshop
  ),
  callApi: PropTypes.func.isRequired,
  attendeeRegisterError: PropTypes.string,
  attendeeRegisterSuccess: PropTypes.bool,
  registeredWorkshops: PropTypes.arrayOf(PropTypes.string),
};

SponsorActivityCard.defaultProps = {
  attendeeRegisterError: undefined,
  attendeeRegisterSuccess: undefined,
  sponsorActivity: {},
  registeredWorkshops: [],
};

const mapStateToProps = ({
  fetchStatus: {
    registerAttendee: {
      error: attendeeRegisterError,
      success: attendeeRegisterSuccess,
    },
  },
}) => ({
  attendeeRegisterError,
  attendeeRegisterSuccess,
});

export default connect(mapStateToProps, { callApi })(SponsorActivityCard);
