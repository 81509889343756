import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Input from 'eventtia-ui-components/lib/Input';
import ContextMenu from 'eventtia-ui-components/lib/ContextMenu';
import Popover from '@material-ui/core/Popover';
import usePopoverState from 'eventtia-ui-components/lib/hooks/usePopoverState';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import ContactMailAltIcon from '../../assets/ContactMailAltIcon';
import ShareContactDialog from '../ShareContactDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  inputContainer: {
    width: '100%',
    marginRight: theme.spacing(0.5),
  },
  input: {
    padding: theme.spacing(1.5),
    fontSize: theme.typography.body1.fontSize,
    boxShadow: theme.customShadows.small,
  },
  moreOptions: {
    position: 'relative',
    top: 0,
    right: 0,
    padding: 3,
  },
}));

const MessageInput = ({
  className, onSend, placeholder, maxLength, id, showShareContactButton,
  otherAttendeeName, ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation('chat');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { buttonProps, popoverProps } = usePopoverState();

  const inputRef = useRef(null);

  const send = () => {
    if (inputRef.current.value) onSend(inputRef.current.value);
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  const sendMessageOnEnter = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      send();
      event.preventDefault();
    }
  };

  const appendEmoji = (emoji) => {
    if (inputRef.current) inputRef.current.value += emoji.native;
  };

  const menuItems = [];
  const adornmentRef = useRef();
  const [shareInfoAnchor, setShareInfoAnchor] = useState();
  const closeShareInfo = () => {
    setShareInfoAnchor();
  };
  if (showShareContactButton) menuItems.push({
    name: t('global:actions.shareMyContact'),
    onClick: () => {
      setShareInfoAnchor(adornmentRef.current);
    },
    icon: <ContactMailAltIcon />,
  });
  const actionsMenu = menuItems.length ? (
    <InputAdornment position="start" ref={adornmentRef}>
      <ContextMenu
        aria-label={t('global:actions.moreOptions')}
        items={menuItems}
        iconButtonClassName={classes.moreOptions}
        iconButtonProps={{
          edge: 'start',
          color: 'primary',
          size: 'small',
        }}
        iconButtonIcon={<AddCircleOutlineIcon />}
        popperProps={{ placement: 'top-start' }}
      />
    </InputAdornment>
  ) : undefined;

  return (
    <>
      <Popover
        {...popoverProps}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Picker
          set="google"
          color={theme.palette.primary.main}
          showPreview={false}
          showSkinTones={false}
          skin={1}
          emoji=""
          title=""
          native
          onSelect={appendEmoji}
        />
      </Popover>
      {showShareContactButton && (
        <ShareContactDialog
          anchorEl={shareInfoAnchor}
          closeDialog={closeShareInfo}
          otherAttendeeName={otherAttendeeName}
          send={onSend}
        />
      )}
      <div
        className={clsx(classes.root, className)}
        {...props}
      >
        <div className={classes.inputContainer}>
          <Input
            id={id}
            className={classes.input}
            inputRef={inputRef}
            onKeyDown={sendMessageOnEnter}
            autoFocus
            inputProps={{ maxLength, required: true }}
            placeholder={placeholder}
            multiline
            rows={1}
            rowsMax={2}
            startAdornment={actionsMenu}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="send"
                  onClick={send}
                  edge="end"
                  color="primary"
                  size="small"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            )}
          />
        </div>
        {!mobile && (
          <IconButton className={classes.emojiPickerIcon} size="small" {...buttonProps}>
            <InsertEmoticonIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};

MessageInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  showShareContactButton: PropTypes.bool,
  otherAttendeeName: PropTypes.string,
};

MessageInput.defaultProps = {
  className: '',
  placeholder: '',
  maxLength: '300',
  showShareContactButton: false,
  otherAttendeeName: undefined,
};

export default MessageInput;
