import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const hack = {
  ic2021: {
    login: 'https://s3.amazonaws.com/eventtia/event_files/71969/original/LOGIN_PAGE_1920x780.mp4?1619545212',
    meeting: 'https://s3.amazonaws.com/eventtia/event_files/71969/original/LOGIN_PAGE_1920x780.mp4?1619545212',
    workshop: {
      128337: 'https://s3.amazonaws.com/eventtia/event_files/72643/original/ADERMA_V2_1366x768.mp4?1620140695',
      128336: 'https://s3.amazonaws.com/eventtia/event_files/72638/original/AVENE_V2_1366x768.mp4?1620140630',
      128338: 'https://s3.amazonaws.com/eventtia/event_files/71965/original/DUCRAY_1366x768.mp4?1619545001',
      128350: 'https://s3.amazonaws.com/eventtia/event_files/71966/original/IC_INTRO_SESSION_1366x768.mp4?1619545094',
      128340: 'https://s3.amazonaws.com/eventtia/event_files/71968/original/KLORANE_1366x768.mp4?1619545180',
      128343: 'https://s3.amazonaws.com/eventtia/event_files/71970/original/PERSONAL_CARE_1366x768.mp4?1619545230',
      128359: 'https://s3.amazonaws.com/eventtia/event_files/71971/original/PFD_1366x768.mp4?1619545255',
      128360: 'https://s3.amazonaws.com/eventtia/event_files/72640/original/PHARMA_CARE_V2_1366x768.mp4?1620140661',
      128347: 'https://s3.amazonaws.com/eventtia/event_files/71973/original/RENE_FURTERER_1366x768.mp4?1619545317',
      128364: 'https://s3.amazonaws.com/eventtia/event_files/71967/original/INNO_ONCO_1366x768.mp4?1619545163',
      135448: 'https://s3.amazonaws.com/eventtia/event_files/71966/original/IC_INTRO_SESSION_1366x768.mp4?1619545094',
      135436: 'https://s3.amazonaws.com/eventtia/event_files/71966/original/IC_INTRO_SESSION_1366x768.mp4?1619545094',
      135438: 'https://s3.amazonaws.com/eventtia/event_files/71965/original/DUCRAY_1366x768.mp4?1619545001',
      135450: 'https://s3.amazonaws.com/eventtia/event_files/71965/original/DUCRAY_1366x768.mp4?1619545001',
      135452: 'https://s3.amazonaws.com/eventtia/event_files/71968/original/KLORANE_1366x768.mp4?1619545180',
      135440: 'https://s3.amazonaws.com/eventtia/event_files/71968/original/KLORANE_1366x768.mp4?1619545180',
      135453: 'https://s3.amazonaws.com/eventtia/event_files/71973/original/RENE_FURTERER_1366x768.mp4?1619545317',
      135441: 'https://s3.amazonaws.com/eventtia/event_files/71973/original/RENE_FURTERER_1366x768.mp4?1619545317',
      135454: 'https://s3.amazonaws.com/eventtia/event_files/71970/original/PERSONAL_CARE_1366x768.mp4?1619545230',
      135442: 'https://s3.amazonaws.com/eventtia/event_files/71970/original/PERSONAL_CARE_1366x768.mp4?1619545230',
      135456: 'https://s3.amazonaws.com/eventtia/event_files/71971/original/PFD_1366x768.mp4?1619545255',
      135445: 'https://s3.amazonaws.com/eventtia/event_files/71971/original/PFD_1366x768.mp4?1619545255',
      135449: 'https://s3.amazonaws.com/eventtia/event_files/72638/original/AVENE_V2_1366x768.mp4?1620140630',
      135437: 'https://s3.amazonaws.com/eventtia/event_files/72638/original/AVENE_V2_1366x768.mp4?1620140630',
      135451: 'https://s3.amazonaws.com/eventtia/event_files/72643/original/ADERMA_V2_1366x768.mp4?1620140695',
      135439: 'https://s3.amazonaws.com/eventtia/event_files/72643/original/ADERMA_V2_1366x768.mp4?1620140695',
      135455: 'https://s3.amazonaws.com/eventtia/event_files/71972/original/PHARMA_CARE_1366x768.mp4?1619545274',
      135443: 'https://s3.amazonaws.com/eventtia/event_files/71972/original/PHARMA_CARE_1366x768.mp4?1619545274',
      135457: 'https://s3.amazonaws.com/eventtia/event_files/71967/original/INNO_ONCO_1366x768.mp4?1619545163',
      135446: 'https://s3.amazonaws.com/eventtia/event_files/71967/original/INNO_ONCO_1366x768.mp4?1619545163',
    },
  },
  netwirkig: {
    login: 'https://s3.amazonaws.com/eventtia/event_files/71898/original/VID_20210426_121910_542_2.mp4?1619478069',
    workshop: {
      98106: 'https://s3.amazonaws.com/eventtia/event_files/71963/original/ADERMA_1366x768.mp4?1619544949',
    },
  },
};

export const useVideoBackground = (eventUri, place, id) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  if (isMobile) return undefined;

  const url = hack[eventUri]?.[place];
  return (typeof url === 'string') ? url : url?.[id];
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    top: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: theme.palette.background.secondary,
  },
}));

const VideoBackground = ({ src }) => {
  const classes = useStyles();

  return src ? (
    <video
      key={src}
      className={classes.root}
      autoPlay
      loop
      muted
      playsInline
    >
      <source src={src} type="video/mp4" />
    </video>
  ) : null;
};

VideoBackground.propTypes = {
  src: PropTypes.string,
};

VideoBackground.defaultProps = {
  src: undefined,
};

export default VideoBackground;
