import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PersonIcon from '@material-ui/icons/Person';

import CustomPropTypes from '../../helpers/CustomPropTypes';
import parseCustomFields from '../../helpers/customFields';
import { baseMoment } from '../../helpers/dates';
import useStringTransforms from '../../hooks/useStringTransforms';
import { getCurrentAttendee } from '../../helpers/getters';

import callApi from '../../actions/callApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.common.white,
  },
  avatar: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.darkGrey.light,
  },
  questionBody: {
    flex: 1,
  },
  questionInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  attendeeName: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
    color: theme.palette.darkGrey.light,
  },
  ownName: {
    color: theme.palette.primary.main,
  },
  date: {
    marginRight: theme.spacing(2),
  },
  questionContent: {
    margin: theme.spacing(0.5, 0),
    color: theme.palette.darkGrey.light,
  },
  likes: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  likeButton: {
    borderRadius: theme.spacing(2),
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.darkGrey.light,
    fontWeight: 'bold',
  },
  liked: {
    color: theme.palette.primary.main,
  },
  answeredChip: {
    backgroundColor: theme.palette.green.light,
    color: theme.palette.green.main,
    fontWeight: 'bold',
    fontSize: theme.typography.caption.fontSize,
    marginRight: 'auto',
  },
}));

const QAQuestion = ({
  qaQuestion,
  entities,
  attendees,
  attendeeTypeCustomFields,
  isTogglingLike,
  callApi: dispatchCallApi,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('qaSessions');
  const { toTitleCase } = useStringTransforms();

  const { eventUri } = useParams();

  const currentAttendee = getCurrentAttendee(entities, { parseCustomFields: true });

  const { likedBy, createdAt, question, status } = qaQuestion;

  const attendee = attendees[qaQuestion?.attendee?.id];
  const attendeeInformation = parseCustomFields(
    attendee?.fields || {}, attendeeTypeCustomFields
  );

  const isOwnQuestion = !!attendee && currentAttendee.id === attendee.id;
  const likedByMe = likedBy.includes(parseInt(currentAttendee.id, 10));

  const attendeeFullName = !!attendee && toTitleCase(`${attendeeInformation.firstName} ${attendeeInformation.lastName}`);
  const fallbackAvatar = attendee
    ? `${attendeeInformation.firstName?.[0]}${attendeeInformation.lastName?.[0]}`.toLocaleUpperCase()
    : <PersonIcon />;

  return (
    <div
      className={classes.root}
    >
      <Avatar
        className={classes.avatar}
        alt={attendeeFullName || ''}
        src={attendeeInformation?.headshot?.small}
      >
        {fallbackAvatar}
      </Avatar>
      <div className={classes.questionBody}>
        <div className={classes.questionInfo}>
          <Typography
            className={clsx(classes.attendeeName, isOwnQuestion && classes.ownName)}
            variant="body2"
          >
            {attendeeFullName || t('anonymous')}
          </Typography>
          <Typography className={classes.date} variant="caption" color="textSecondary">
            {baseMoment(createdAt).fromNow()}
          </Typography>
        </div>
        <Typography
          className={classes.questionContent}
          variant="body1"
          color="textPrimary"
        >
          {question}
        </Typography>
        <div className={classes.likes}>
          {/* TODO: check this once the serializer attribute is added */}
          {status === 'answered' && (
            <Chip
              size="small"
              className={classes.answeredChip}
              label={t('status.answered')}
            />
          )}
          <Button
            className={clsx(classes.likeButton, likedByMe && classes.liked)}
            startIcon={likedByMe ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            size="small"
            disabled={isTogglingLike}
            onClick={() => dispatchCallApi('toggleQaQuestionLike', {
              eventUri,
              sessionId: qaQuestion.eventQaSession.id,
              id: qaQuestion.id,
            })}
          >
            {likedBy.length}
          </Button>
        </div>
      </div>
    </div>
  );
};

QAQuestion.propTypes = {
  qaQuestion: CustomPropTypes.qaQuestion.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  attendees: PropTypes.objectOf(CustomPropTypes.attendee).isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(CustomPropTypes.attendeeTypeCustomField).isRequired,
  isTogglingLike: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  entities,
  entities: {
    attendees,
    attendeeTypeCustomFields,
  },
  fetchStatus: {
    toggleQaQuestionLike: {
      isFetching: isTogglingLike,
    },
  },
}) => ({
  entities,
  attendees,
  attendeeTypeCustomFields,
  isTogglingLike,
});

export default connect(mapStateToProps, { callApi })(QAQuestion);
