import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from 'eventtia-ui-components/lib/Button';

import isEmpty from 'lodash/isEmpty';

import withForm from '../../hocs/withForm';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import CustomField from '../CustomField';
import CanvasField from '../CanvasField';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  formContainer: {
    '& label, & p': {
      fontSize: 14,
      color: theme.palette.darkGrey.dark,
    },
  },
}));

// TODO: vanish this from existence
const idToWeirdId = (id) => (`_${id}`);

const SurveyForm = ({
  registerInput, errors, customFields, setFormValue, disabled,
  watchFormValues, setError, customErrors, clearError, survey,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const theme = useTheme();
  const submitButtonStyles = {
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    textTransform: 'capitalize',
  };

  const surveyCustomFieldIds = useMemo(() => (
    survey?.customFields.map(({ id }) => (id))
  ), []);

  const { enabledSignature: signatureIsEnabled = false } = survey || {};

  useEffect(() => {
    customErrors.forEach(({ name, type, message }) => setError(name, { type, message }));
  }, [customErrors]);

  useEffect(() => {
    registerInput({ name: 'signature' });
  }, [registerInput]);

  const surveyCustomFields = [];
  surveyCustomFieldIds.forEach((fieldId) => {
    const field = customFields[fieldId];
    if (field) {
      const { optionsStatus: { required, children }, ...otherProps } = field;
      const parsedField = {
        required,
        ...otherProps,
      };
      const answersErrors = errors?.answers || {};
      surveyCustomFields.push({
        fieldId,
        children,
        parsedField,
        answerErrorMessage: answersErrors[idToWeirdId(fieldId)]?.types?.message,
      });
    }
  });

  const childrenNodes = {};
  surveyCustomFields.forEach(({ children: nestedFields }) => {
    if (!isEmpty(nestedFields)) Object.values(nestedFields).forEach((childrenIds) => {
      childrenIds.forEach((id) => {
        childrenNodes[id] = true;
      });
    });
  });

  const rootNodes = surveyCustomFieldIds.filter((id) => !childrenNodes[id]);

  const visibleFields = [...rootNodes];

  const appendQuestionSubtree = (currentNodeId) => {
    const { optionsStatus: { children: currentNodeChildren } } = customFields[currentNodeId];
    const currentValue = watchFormValues(idToWeirdId(currentNodeId))?.toString()?.toLowerCase();

    if (!isEmpty(currentNodeChildren?.[currentValue])) currentNodeChildren[currentValue].forEach(
      (childrenId) => {
        visibleFields.push(childrenId.toString());
        appendQuestionSubtree(childrenId);
      }
    );
  };

  rootNodes.forEach((id) => appendQuestionSubtree(id));

  return (
    <>
      <Grid container spacing={2} className={classes.formContainer}>
        {surveyCustomFields.map((
          { fieldId, parsedField, answerErrorMessage }
        ) => visibleFields.includes(fieldId) && (
          <Grid item xs={12} key={fieldId}>
            <CustomField
              getIdentifier={idToWeirdId}
              field={parsedField}
              inputRef={registerInput}
              watchFormValues={watchFormValues}
              setFormValue={setFormValue}
              error={answerErrorMessage}
            />
          </Grid>
        ))}
        {signatureIsEnabled && (
          <CanvasField
            handleChange={(val) => { setFormValue('signature', val); }}
            label={t('forms.signature')}
            error={errors.signature?.types?.message}
          />
        )}
        <input
          name="visibleFields"
          type="hidden"
          ref={registerInput}
          value={visibleFields.join('-')}
        />
        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            type="submit"
            small
            disabled={disabled}
            onClick={() => clearError()}
            style={{
              backgroundColor: disabled ? theme.palette.lightGrey.dark
                : theme.palette.secondary.main,
              ...submitButtonStyles,
            }}
          >
            {disabled ? t('status.saving') : t('actions.saveChanges')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SurveyForm.propTypes = {
  survey: CustomPropTypes.survey,
  registerInput: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  customErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    })
  ),
  watchFormValues: PropTypes.func.isRequired,
  errors: CustomPropTypes.formErrors.isRequired,
  customFields: PropTypes.objectOf(
    CustomPropTypes.customField
  ),
  disabled: PropTypes.bool,
};

SurveyForm.defaultProps = {
  disabled: false,
  customErrors: [],
  customFields: {},
  survey: undefined,
};

const mapStateToProps = ({
  entities: {
    customFields,
  },
}) => ({
  customFields,
});

export default compose(
  withForm({ required: false, autoComplete: 'off' }),
  connect(mapStateToProps)
)(SurveyForm);
