import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import DevicesIcon from '@material-ui/icons/Devices';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const { VIRTUAL, PHYSICAL, HYBRID } = ATTENDANCE_MODE;

const useStyles = makeStyles((theme) => ({
  location: {
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: theme.spacing(1),
  },
  locationText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: 18,
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },
}));

const ActivityLocation = ({
  activity,
  className,
}) => {
  const { t } = useTranslation('schedule');
  const classes = useStyles();

  const {
    location,
    attendanceMode,
  } = activity;

  const workshopLocation = useMemo(() => {
    switch (attendanceMode) {
      case VIRTUAL:
        return t('events:type.online');
      case PHYSICAL:
        return location || t('activities.onsite');
      case HYBRID:
      default:
        return location ? `${t('title.location')}: ${location} / ${t('events:type.online')}`
          : ` ${t('title.location')}: ${t('events:type.offline')} / ${t('events:type.online')}`;
    }
  }, [attendanceMode, location]);

  const LocationIcon = useMemo(() => {
    switch (attendanceMode) {
      case VIRTUAL:
        return DevicesIcon;
      case PHYSICAL:
      case HYBRID:
      default:
        return PlaceIcon;
    }
  }, [attendanceMode]);

  return (
    <span className={clsx(classes.location, className)}>
      <LocationIcon color="primary" className={classes.icon} />
      <span className={classes.locationText}>{workshopLocation}</span>
    </span>
  );
};

ActivityLocation.propTypes = {
  className: PropTypes.string,
  activity: PropTypes.shape({
    location: PropTypes.string,
    attendanceMode: PropTypes.string,
  }).isRequired,

};

ActivityLocation.defaultProps = {
  className: undefined,
};

export default ActivityLocation;
