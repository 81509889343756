import callApi from '../callApi';
import {
  storeAttendeeLeads,
  getStoredAttendeeLeads,
  mergeFetchedWithStored,
  setLeadAsPending,
} from '../../helpers/attendeeLeads';

export const LOAD_ATTENDEE_LEADS = 'LOAD_ATTENDEE_LEADS';
export const UPDATE_ATTENDEE_LEAD = 'UPDATE_ATTENDEE_LEAD';
export const ADD_ATTENDEE_LEAD = 'ADD_ATTENDEE_LEAD';
export const DELETE_ATTENDEE_LEAD = 'DELETE_ATTENDEE_LEAD';

export const fetchAttendeeLeads = (eventUri) => (dispatch) => {
  const stored = getStoredAttendeeLeads();

  dispatch({
    type: LOAD_ATTENDEE_LEADS,
    attendeeLeads: stored,
  });

  return dispatch(callApi(
    'attendeeLeads', { eventUri }
  )).then((res) => {
    const { response } = res;
    if (response?.entities) {
      const merged = mergeFetchedWithStored(response.entities.attendeeLeads);

      storeAttendeeLeads(merged);

      dispatch({
        type: LOAD_ATTENDEE_LEADS,
        attendeeLeads: merged,
      });
    }
    return res;
  });
};

export const syncAttendeeLeads = (eventUri) => (dispatch) => {
  const stored = getStoredAttendeeLeads();
  const leadsPendingSync = Object.values(stored).filter(({ pendingSync }) => pendingSync);

  if (!leadsPendingSync.length) return new Promise((resolve) => {
    resolve('No pending changes');
  });
  return dispatch(callApi(
    'batchUpdateAttendeeLeads', { eventUri, attendeeLeads: leadsPendingSync }
  )).then((res) => {
    const { error, response } = res;
    if (!error) {
      const fetched = response?.entities?.attendeeLeads || {};
      const merged = mergeFetchedWithStored(fetched);

      storeAttendeeLeads(merged);

      dispatch({
        type: LOAD_ATTENDEE_LEADS,
        attendeeLeads: merged,
      });
    }
    return res;
  });
};

export const downloadAttendeeLeads = (eventUri) => (dispatch) => dispatch(callApi(
  'downloadAttendeeLeads', { eventUri }
));

export const addAttendeeLead = (attendeeLead, eventUri) => (dispatch) => {
  const stored = getStoredAttendeeLeads();
  // store locally with sync pending in case the request fails
  storeAttendeeLeads({
    ...stored,
    [attendeeLead.uuid]: setLeadAsPending(attendeeLead),
  });
  dispatch({
    type: ADD_ATTENDEE_LEAD,
    attendeeLead,
  });

  return dispatch(callApi(
    'createOrUpdateAttendeeLead', { uuid: attendeeLead.uuid, eventUri, attendeeLead }
  )).then((res) => {
    const { response } = res;
    // store from response to get the latest update timestamp and mark as synced
    if (response?.entities) {
      const [newLead] = Object.values(response.entities.attendeeLeads);
      storeAttendeeLeads({
        ...stored,
        [newLead.uuid]: newLead,
      });
      dispatch({
        type: ADD_ATTENDEE_LEAD,
        attendeeLead: newLead,
      });
    }
    return res;
  });
};

export const updateAttendeeLead = (attendeeLead, eventUri) => (dispatch) => {
  const stored = getStoredAttendeeLeads();
  // store locally with sync pending in case the request fails
  storeAttendeeLeads({
    ...stored,
    [attendeeLead.uuid]: setLeadAsPending(attendeeLead),
  });
  dispatch({
    type: UPDATE_ATTENDEE_LEAD,
    attendeeLead,
  });

  return dispatch(callApi(
    'createOrUpdateAttendeeLead', { uuid: attendeeLead.uuid, eventUri, attendeeLead }
  )).then((res) => {
    const { response } = res;
    // store from response to get the latest update timestamp and mark as synced
    if (response?.entities) {
      const [newLead] = Object.values(response.entities.attendeeLeads);
      storeAttendeeLeads({
        ...stored,
        [newLead.uuid]: newLead,
      });
      dispatch({
        type: UPDATE_ATTENDEE_LEAD,
        attendeeLead: newLead,
      });
    }
    return res;
  });
};

export const deleteAttendeeLead = (attendeeLead, eventUri) => (dispatch) => {
  const stored = getStoredAttendeeLeads();
  // store locally with sync pending in case the request fails
  storeAttendeeLeads({
    ...stored,
    [attendeeLead.uuid]: setLeadAsPending(attendeeLead, { deleted: true }),
  });
  dispatch({
    type: DELETE_ATTENDEE_LEAD,
    attendeeLead,
  });

  return dispatch(callApi(
    'deleteAttendeeLead', { uuid: attendeeLead.uuid, eventUri }
  )).then((res) => {
    const { response } = res;
    if (response?.entities) {
      const cleaned = { ...stored };
      Object.values(response.entities.attendeeLeads).forEach(({ uuid }) => {
        delete cleaned[uuid];
      });
      storeAttendeeLeads(cleaned);
    }
    return res;
  });
};
