import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from 'eventtia-ui-components/lib/Button';
import AttendeeLeadCardHeader from '../AttendeeLeadCard/AttendeeLeadCardHeader';
import AttendeeLeadCardContactInfo from '../AttendeeLeadCard/AttendeeLeadCardContactInfo';
import { parseQrContent, isLeadAdded } from '../../helpers/attendeeLeads';
import { getModuleByType } from '../../helpers/getters';
import { setActiveLead } from '../../actions/app';
import { addAttendeeLead } from '../../actions/attendeeLeads';
import CustomPropTypes from '../../helpers/CustomPropTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(-1, -2),
  },
  cardWrapper: {
    margin: theme.spacing(0, -1),
  },
  content: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  info: {
    fontSize: theme.typography.caption.fontSize,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(-2, 3, 1),
  },
  button: {
    display: 'block',
    margin: `${theme.spacing(1)}px auto`,
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
}));

const LeadBody = ({
  type,
  content,
  info,
  entities,
  isAddingLead,
  setActiveLead: dispatchSetActiveLead,
  addAttendeeLead: dispatchAddAttendeeLead,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendeeLeads');
  const { eventUri } = useParams();

  const attendeeLead = parseQrContent(content);
  const {
    uuid,
    fullName,
    email,
    company,
    phone,
  } = attendeeLead;

  const [settings] = Object.values(entities.appSettings);
  const canAddLeads = getModuleByType(settings.modules, 'AttendeeLeads')?.enabled;
  const isLead = isLeadAdded(uuid, entities);
  const addLead = () => {
    if (isLead) dispatchSetActiveLead(uuid);
    else dispatchAddAttendeeLead(attendeeLead, eventUri).then(() => {
      dispatchSetActiveLead(uuid);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.cardWrapper}>
        <AttendeeLeadCardHeader
          fullName={fullName}
          company={company}
        />
        <AttendeeLeadCardContactInfo
          email={email}
          phone={phone}
        />
        <div className={classes.info}>{info}</div>
      </div>
      {type !== 'own' && canAddLeads && (
        <>
          <Divider />
          <Button
            fullWidth
            onClick={addLead}
            className={classes.button}
            variant="primary"
            disabled={isAddingLead}
            small
          >
            {t(isLead ? 'actions.seeLeadDetails' : 'actions.addLead')}
          </Button>
        </>
      )}
    </div>
  );
};

LeadBody.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  info: PropTypes.string,
  entities: CustomPropTypes.entities.isRequired,
  isAddingLead: PropTypes.bool.isRequired,
  setActiveLead: PropTypes.func.isRequired,
  addAttendeeLead: PropTypes.func.isRequired,
};

LeadBody.defaultProps = {
  info: undefined,
};

const mapStateToProps = ({
  entities,
  fetchStatus: {
    createOrUpdateAttendeeLead: { isFetching: isAddingLead },
  },
}) => ({
  entities,
  isAddingLead,
});

export default connect(
  mapStateToProps, { setActiveLead, addAttendeeLead }
)(LeadBody);
