import { NEW_ANNOUNCEMENT, DELETE_ANNOUNCEMENT } from '../../actions/messages';

const initialState = [
];

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_ANNOUNCEMENT: {
      const { payload } = action;
      return [
        ...state, payload,
      ];
    }
    case DELETE_ANNOUNCEMENT: {
      return state.slice(1);
    }
    default:
      return state;
  }
};
