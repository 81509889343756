import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { baseMoment } from '../../helpers/dates';
import ScheduleListItem from '../ScheduleListItem';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import ScheduleNoActivities from '../ScheduleNoActivities';

const ScheduleList = ({
  activities,
  activeType,
  keyword,
  filters,
  events,
}) => {
  const { endDate: eventEndDate } = Object.values(events)[0];

  const filteredByKeyword = keyword
    ? Object.values(activities).flat()
      .filter(({ name }) => name?.toLowerCase().includes(keyword.toLowerCase()))
      .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
    : activities;

  const filteredByDate = filters.dateFrom ? Object.values(filteredByKeyword)
    .flat().filter(({ startDate }) => baseMoment(startDate)
      .isBetween(filters.dateFrom, eventEndDate, 'minute', '[)'))
    : filteredByKeyword;

  const filteredByCategories = filters.categories?.length ? Object.values(filteredByDate).flat()
    .filter((act) => act.workshopCategories
      ?.some((category) => filters.categories.includes(category.id)))
    : filteredByDate;

  const filteredBySpeakers = filters.speakers?.length ? Object.values(filteredByCategories).flat()
    .filter((act) => act.speakers?.some((cat) => filters.speakers.includes(cat.id)))
    : filteredByCategories;

  const orderedAllActivities = Object.values(filteredBySpeakers).flat().sort((a, b) => {
    if (baseMoment(a.startDate).isAfter(b.startDate, 'hour')) return 1;
    if (baseMoment(a.startDate).isBefore(b.startDate, 'hour')) return -1;
    return 0;
  });

  return (
    orderedAllActivities.length ? (
      orderedAllActivities.map((activity) => (
        <ScheduleListItem activity={activity} key={activity.id} />
      ))
    ) : (
      <ScheduleNoActivities
        activeType={activeType}
      />
    )
  );
};

ScheduleList.propTypes = {
  activities: PropTypes.objectOf(
    PropTypes.arrayOf(
      CustomPropTypes.workshop
    )
  ),
  activeType: PropTypes.string.isRequired,
  keyword: PropTypes.string,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.string
    ),
    speakers: PropTypes.arrayOf(
      PropTypes.string
    ),
  }),
  events: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
};

ScheduleList.defaultProps = {
  activities: {},
  keyword: undefined,
  filters: {},
};

const mapStateToProps = ({ entities: { events } }) => ({ events });

export default connect(mapStateToProps)(ScheduleList);
