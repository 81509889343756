import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Input from 'eventtia-ui-components/lib/Input';
import PhoneInput from 'eventtia-ui-components/lib/PhoneInput';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from 'eventtia-ui-components/lib/Button';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import { camelize } from 'humps';
import withForm from '../../hocs/withForm';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import CountryField from '../CountryField';
import CustomField from '../CustomField';
import FileModal from '../FileModal';
import MyQrButton from '../MyQrButton';
import { getCurrentAttendee } from '../../helpers/getters';
import parseCustomFields from '../../helpers/customFields';
import emailRegexp from '../../helpers/strings';
import moduleEnabledForCurrentAttendeeType from '../../helpers/moduleEnabledForCurrentAttendeeType';
import useStringTransforms from '../../hooks/useStringTransforms';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const { VIRTUAL } = ATTENDANCE_MODE;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  simpleInput: {
    background: 'none',
    fontWeight: 'normal',
  },
  profileHeader: {
    alignItems: 'center',
    margin: theme.spacing(0, 0, 2.5, 0),
    display: 'flex',
    width: '100%',
  },
  avatarWrapper: {
    position: 'relative',
  },
  editPhotoButton: {
    position: 'absolute',
    width: 32,
    height: 32,
    zIndex: 100,
    right: 10,
    top: -4,
    backgroundColor: theme.palette.lightGrey.main,
    '&:hover': {
      backgroundColor: theme.palette.lightGrey.main,
    },
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  avatar: {
    height: 80,
    width: 80,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
  },
  nameAndJob: {
    flex: 1,
  },
  name: {
    fontSize: 16,
    color: theme.palette.darkGrey.dark,
    fontWeight: 'bold',
  },
  job: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
  },
  profileHeaderHybrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  profileHeaderHybridMobile: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  divider: {
    margin: theme.spacing(2, -3),
  },
}));

const MyProfileForm = ({
  registerInput, errors, entities, attendeeTypeCustomFields, setFormValue,
  watchFormValues, setError, customErrors, clearError, disabled, attendeeTypes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { toTitleCase } = useStringTransforms();
  const submitButtonStyles = {
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
  };
  const [openEditPhoto, setOpenEditPhoto] = useState(false);

  const currentAttendee = getCurrentAttendee(entities);
  const customFields = parseCustomFields(currentAttendee.fields, attendeeTypeCustomFields);
  const {
    firstName,
    lastName,
    email,
    company,
    jobTitle,
    cityId,
    headshot,
    telephone,
  } = customFields;
  const { attendanceMode } = attendeeTypes[currentAttendee.attendeeType.id];

  const fullName = `${firstName} ${lastName}`;
  const pipe = jobTitle && company ? ' | ' : '';
  const job = (jobTitle || company) ? `${toTitleCase(company, true)}${pipe}${toTitleCase(jobTitle, true)}` : '';

  const phone = watchFormValues('attendee[telephone]') || telephone;
  const locationInfo = watchFormValues('attendee[city_id]') || cityId;
  const [selectedHeadshot, setSelectedHeadshot] = useState([]);

  const showLeadsOptions = moduleEnabledForCurrentAttendeeType(entities, 'AttendeeLeads');
  const filename = useMemo(() => headshot?.filename, [headshot]);

  useEffect(() => {
    setSelectedHeadshot([]);
  }, [filename]);

  useEffect(() => {
    customErrors.forEach(({ name, type, message }) => setError(name, { type, message }));
  }, [customErrors]);

  useEffect(() => {
    registerInput({ name: 'attendee[telephone]' }, { required: true });
    registerInput({ name: 'attendee[city_id]' });
    registerInput({ name: 'attendee[headshot]' });
  }, [registerInput]);

  const attendeeFields = useMemo(() => (Object.keys(currentAttendee.fields)),
    [currentAttendee.fields]);

  const defaultFieldsProps = useMemo(() => {
    if (!attendeeFields) return {};
    const names = {};
    attendeeFields.forEach((id) => {
      const { name, alias, display } = attendeeTypeCustomFields[id];
      names[name] = {
        name: alias || (name === 'city_id' ? '' : t(`forms.${camelize(name)}`)),
        display,
      };
    });
    return names;
  }, [attendeeFields]);

  const modalClose = () => {
    setOpenEditPhoto(false);
    setSelectedHeadshot([]);
    if (!headshot) setFormValue('attendee[headshot]', []);
  };

  return (
    <>
      <FileModal
        title={t('actions.editPhoto')}
        open={openEditPhoto}
        handleClose={modalClose}
        action={{
          name: t('actions.accept'),
          onClick: () => setOpenEditPhoto(false),
          disabled: !selectedHeadshot.length,
        }}
        secondaryAction={{
          name: t('actions.cancel'),
          onClick: modalClose,
        }}
        fileInputProps={{
          id: 'attendee[headshot]',
          name: 'attendee[headshot]',
          label: errors?.attendee?.['headshot']?.message || t('forms.photo'),
          type: 'image',
          accept: 'image/*',
          error: errors?.attendee?.['headshot']?.message,
          value: selectedHeadshot,
          handleChange: (val) => {
            setSelectedHeadshot(val);
            setFormValue('attendee[headshot]', val);
          },
        }}
      />
      <div className={clsx(classes.profileHeaderHybrid,
        mobile && classes.profileHeaderHybridMobile)}
      >
        <div className={classes.profileHeader}>
          <div className={classes.avatarWrapper}>
            <IconButton
              className={classes.editPhotoButton}
              aria-label="edit-photo"
              onClick={() => setOpenEditPhoto(true)}
              edge="end"
              color="primary"
              size="small"
            >
              <CreateIcon />
            </IconButton>
            <Avatar
              src={headshot?.filename && headshot.small}
              className={classes.avatar}
            />
          </div>
          <div className={classes.nameAndJob}>
            <Typography className={classes.name} variant="subtitle1">
              {fullName}
            </Typography>
            {job && (
              <Typography className={classes.job} variant="body2">
                {job}
              </Typography>
            )}
          </div>
          {(attendanceMode !== VIRTUAL && showLeadsOptions) && (
            <MyQrButton
              attendeeInfo={{ ...customFields, uuid: currentAttendee.uuid }}
            />
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        {Object.values(attendeeTypeCustomFields).filter(({ id }) => (
          attendeeFields.includes(id))).sort((a, b) => (a.order - b.order))
          .map((field) => {
            if (field && field.display) if (field.default) {
              const { name } = field;
              switch (name) {
                case 'first_name':
                  return defaultFieldsProps.first_name.display ? (
                    <Grid item xs={12} key={field.id}>
                      <Input
                        className={classes.simpleInput}
                        id="attendee[first_name]"
                        name="attendee[first_name]"
                        label={`${defaultFieldsProps.first_name.name}*`}
                        placeholder={defaultFieldsProps.first_name.name}
                        defaultValue={firstName}
                        inputRef={registerInput({
                          required: { value: true, message: t('formError.requiredField') },
                        })}
                        error={errors?.attendee?.['first_name']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'last_name':
                  return defaultFieldsProps.last_name.display ? (
                    <Grid item xs={12} key={field.id}>
                      <Input
                        className={classes.simpleInput}
                        id="attendee[last_name]"
                        name="attendee[last_name]"
                        label={`${defaultFieldsProps.last_name.name}*`}
                        placeholder={defaultFieldsProps.last_name.name}
                        defaultValue={lastName}
                        inputRef={registerInput({
                          required: { value: true, message: t('formError.requiredField') },
                        })}
                        error={errors?.attendee?.['last_name']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'email':
                  return defaultFieldsProps.email.display ? (
                    <Grid item xs={12} key={field.id}>
                      <Input
                        className={classes.simpleInput}
                        id="attendee[email]"
                        name="attendee[email]"
                        label={`${defaultFieldsProps.email.name}*`}
                        placeholder={defaultFieldsProps.email.name}
                        defaultValue={email}
                        inputRef={registerInput({
                          required: { value: true, message: t('formError.requiredField') },
                          pattern: { value: emailRegexp, message: t('formError.enterValidEmail') },
                        })}
                        error={errors?.attendee?.['email']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'telephone':
                  return defaultFieldsProps.telephone.display ? (
                    <Grid item xs={12} key={field.id}>
                      <PhoneInput
                        className={classes.simpleInput}
                        id="attendee[telephone]"
                        name="attendee[telephone]"
                        label={defaultFieldsProps.telephone.name}
                        value={phone || ''}
                        handleChange={(val) => setFormValue('attendee[telephone]', val)}
                        error={errors?.attendee?.['telephone']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'company':
                  return defaultFieldsProps.company.display ? (
                    <Grid item xs={12} key={field.id}>
                      <Input
                        className={classes.simpleInput}
                        id="attendee[company]"
                        name="attendee[company]"
                        label={defaultFieldsProps.company.name}
                        placeholder={defaultFieldsProps.company.name}
                        defaultValue={company}
                        inputRef={registerInput()}
                        error={errors?.attendee?.['company']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'job_title':
                  return defaultFieldsProps.job_title.display ? (
                    <Grid item xs={12} key={field.id}>
                      <Input
                        className={classes.simpleInput}
                        id="attendee[job_title]"
                        name="attendee[job_title]"
                        label={defaultFieldsProps.job_title.name}
                        placeholder={defaultFieldsProps.job_title.name}
                        defaultValue={jobTitle}
                        inputRef={registerInput()}
                        error={errors?.attendee?.['job_title']?.message}
                      />
                    </Grid>
                  ) : null;
                case 'city_id':
                  return defaultFieldsProps.city_id.display ? (
                    <CountryField
                      handleChange={(val) => setFormValue('attendee[city_id]', val)}
                      identifiers={{
                        city: 'attendee[city_id]',
                        region: 'attendee[region_id]',
                        country: 'attendee[country_id]',
                      }}
                      error={errors?.attendee?.['city_id']?.message}
                      value={locationInfo}
                      key={field.id}
                    />
                  ) : null;
                default:
                  return null;
              }
            } else {
              return (
                <Grid item xs={12} key={field.id}>
                  <CustomField
                    getIdentifier={(id) => `attendee[customFields]._${id}`}
                    field={field}
                    value={currentAttendee.fields[field.id]}
                    inputRef={registerInput}
                    watchFormValues={watchFormValues}
                    setFormValue={setFormValue}
                    error={errors?.attendee?.customFields?.[`_${field.id}`]?.types?.message}
                    disabled={field.editAdminOnly}
                  />
                </Grid>
              );
            }
            return null;
          })}
        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            type="submit"
            disabled={disabled}
            small
            onClick={() => clearError()}
            style={{
              backgroundColor: disabled ? theme.palette.lightGrey.dark
                : theme.palette.secondary.main,
              ...submitButtonStyles,
            }}
          >
            {disabled ? t('status.saving') : t('actions.saveChanges')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

MyProfileForm.propTypes = {
  disabled: PropTypes.bool,
  registerInput: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  customErrors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    })
  ),
  watchFormValues: PropTypes.func.isRequired,
  errors: CustomPropTypes.formErrors.isRequired,
  entities: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ).isRequired,
};

MyProfileForm.defaultProps = {
  disabled: false,
  customErrors: [],
  attendeeTypeCustomFields: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    attendeeTypes,
    attendeeTypeCustomFields,
  },
}) => ({
  entities,
  attendeeTypes,
  attendeeTypeCustomFields,
});

export default compose(
  withForm({ required: false, autoComplete: 'off' }),
  connect(mapStateToProps)
)(MyProfileForm);
