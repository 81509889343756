const HotspotTypes = {
  hotspot: 'hotspot',
  types: {
    file: 'file',
    video: 'video',
    url: 'url',
    // info: 'info',
    // product: 'product',
  },
};

export default HotspotTypes;
