import { getDatabase, ref, set } from 'firebase/database';
import { getCurrentAttendeeId } from '../../helpers/getters';
import { SET_VIEWED_ATTENDEE } from '../../actions/viewedAttendees';

export default (store) => (next) => (action) => {
  const { type } = action;
  const result = next(action);

  if (type === SET_VIEWED_ATTENDEE) {
    const db = getDatabase();
    const { entities: { events = {} } = {} } = store.getState() || {};
    const eventId = Object.keys(events)?.[0];
    const attendeeId = getCurrentAttendeeId();
    const { viewedAttendeeId } = action.payload;

    const attendeeViewedPath = `viewedAttendees/${eventId}/${attendeeId}/${viewedAttendeeId}`;
    const attendeeViewedRef = ref(db, attendeeViewedPath);
    set(attendeeViewedRef, true);
  }

  return result;
};
