import { combineReducers } from 'redux';
import entities from './entities';
import meta from './meta';
import fetchStatus from './fetchStatus';
import filters from './filters';
import chat from './chat';
import messages from './messages';
import questions from './questions';
import reactions from './reactions';
import announcements from './announcements';
import lastReadMessages from './lastReadMessages';
import attendeeLeads from './attendeeLeads';
import viewedAttendees from './viewedAttendees';
import app from './app';
import { LOGOUT } from '../actions/app';

const appReducer = combineReducers({
  entities,
  meta,
  fetchStatus,
  filters,
  chat,
  messages,
  reactions,
  announcements,
  questions,
  lastReadMessages,
  viewedAttendees,
  attendeeLeads,
  app,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) return appReducer({
    entities: {
      events: state.entities.events,
      appSettings: state.entities.appSettings,
      sponsors: state.entities.sponsors,
      sponsorCategories: state.entities.sponsorCategories,
      speakers: state.entities.speakers,
      // attendees and attendeeTypeCustomFields are here due to a bug in sponsors where the
      // rep would be undefined and some information wouldnt show when you logout
      // and log back in without refreshing
      attendees: state.entities.attendees,
      attendeeTypeCustomFields: state.entities.attendeeTypeCustomFields,
    },
    fetchStatus: {
      settings: state.fetchStatus.settings,
      sponsors: state.fetchStatus.sponsors,
      speakers: state.fetchStatus.speakers,
    },
  }, action);

  return appReducer(state, action);
};

export default rootReducer;
