/* global window */
import { stringify } from 'qs';
import isEmpty from 'lodash/isEmpty';
import {
  SET_ACTIVE_SUBPAGE,
  GO_TO_PREVIOUS_SUBPAGE,
} from '../../actions/app';

export default (store) => (next) => (action) => {
  const { type, subpage } = action;
  if ([SET_ACTIVE_SUBPAGE, GO_TO_PREVIOUS_SUBPAGE].includes(type)) {
    const { app: { subpageHistory } } = store.getState();

    const destination = (type === SET_ACTIVE_SUBPAGE)
      ? subpage
      : subpageHistory[subpageHistory.length - 1];

    const { pathname } = window.location;
    const hasAutoLogin = !!destination?.email || !!destination?.token || destination?.ssoToken;
    let query = '';
    if (
      !isEmpty(destination) && !['mainStage', 'Home'].includes(destination?.module)
    ) query = `?${stringify(destination, { encode: false })}`;
    else if (!isEmpty(destination) && hasAutoLogin) query = `?${stringify({ email: destination.email, token: destination.token, ssoToken: destination.ssoToken }, { encode: true })}`;

    const deepLink = `${pathname}${query}`;
    window.history.replaceState(null, null, deepLink);
  }

  const result = next(action);
  return result;
};
