/* eslint-disable comma-dangle */
export default {
  en: {
    title: {
      menu: 'Menu',
    },
    subpages: {
      myProfile: 'My Profile',
      mainStage: 'Main Stage',
      Workshops: 'Event Schedule',
      Attendees: 'Attendees',
      chat: 'Chat',
      Sponsors: 'Sponsors',
      Messages: 'Chat',
      settings: 'Settings',
    },
    actions: {
      logOut: 'Log out',
    },
  },
  es: {
    title: {
      menu: 'Menú',
    },
    subpages: {
      myProfile: 'Mi Perfil',
      mainStage: 'Escenario principal',
      chat: 'Chat',
      Sponsors: 'Patrocinadores',
      Workshops: 'Calendario del evento',
      Attendees: 'Participantes',
      Messages: 'Chat',
      settings: 'Ajustes',
    },
    actions: {
      logOut: 'Salir',
    },
  },
  fr: {
    title: {
      menu: 'Menu',
    },
    subpages: {
      myProfile: 'Mon profil',
      mainStage: 'Scène principale',
      chat: 'Chat',
      Sponsors: 'Sponsors',
      Workshops: 'Calendrier de l\'événement',
      Attendees: 'Participants',
      Messages: 'Chat',
      settings: 'Paramètres',
    },
    actions: {
      logOut: 'Se déconnecter',
    },
  },
  ca: {
    title: { menu: 'Menu' },
    subpages: {
      myProfile: 'El meu perfil',
      mainStage: 'Escenari principal',
      Workshops: 'Programa de l’esdeveniment',
      Attendees: 'Assistents',
      chat: 'Xat',
      Sponsors: 'Patrocinadors',
      Messages: 'Xat',
      settings: 'Configuració',
    },
    actions: { logOut: 'Surt' },
  },
  pt: {
    title: {
      menu: 'Menu',
    },
    subpages: {
      myProfile: 'Meu perfil',
      mainStage: 'Palco principal',
      Workshops: 'Agenda de Eventos',
      Attendees: 'Participantes',
      chat: 'Bate-papo',
      Sponsors: 'Patrocinadores',
      Messages: 'Bate-papo',
      settings: 'Configurações',
    },
    actions: {
      logOut: 'Fazer logout',
    },
  },
  it: {
    subpages: {
      myProfile: 'Il mio profilo',
      Sponsors: 'Sponsor',
      mainStage: 'Stadio principale',
      Attendees: 'Partecipanti',
      Workshops: 'Calendario degli eventi',
      chat: 'Chatta',
      Messages: 'Chatta',
      settings: 'Impostazioni',
    },
    title: {
      menu: 'Menù'
    },
    actions: {
      logOut: 'Esci'
    }
  },
  ja: {
    subpages: {
      mainStage: 'メインステージ',
      Workshops: 'イベントスケジュール',
      Messages: 'チャット',
      settings: '設定',
      Attendees: '出席者',
      Sponsors: 'スポンサー',
      chat: 'チャット',
      myProfile: 'マイプロフィール',
    },
    title: {
      menu: 'メニュー'
    },
    actions: {
      logOut: 'ログアウト'
    }
  },
  zh: {
    subpages: {
      chat: '聊天',
      Sponsors: '赞助商',
      myProfile: '我的简介',
      Attendees: '与会者',
      Workshops: '活动时间表',
      mainStage: '主舞台',
      Messages: '聊天',
      settings: '设置',
    },
    title: {
      menu: '菜单'
    },
    actions: {
      logOut: '注销'
    }
  },
  ar: {
    title: {
      menu: 'قائمة',
    },
    subpages: {
      myProfile: 'ملفي الشخصي',
      mainStage: 'المرحلة الرئيسية',
      Workshops: 'جدول الأحداث',
      Attendees: 'الحاضرون',
      chat: 'دردشة',
      Sponsors: 'الرعاة',
      Messages: 'دردشة',
      settings: 'إعدادات',
    },
    actions: {
      logOut: 'تسجيل الخروج',
    },
  },
  tr: {
    title: {
      menu: 'Menü'
    },
    subpages: {
      myProfile: 'Profilim',
      mainStage: 'Ana Sahne',
      Workshops: 'Etkinlik Takvimi',
      Attendees: 'Katılımcılar',
      chat: 'Sohbet',
      Sponsors: 'Sponsorlar',
      Messages: 'Sohbet',
      settings: 'Ayarlar',
    },
    actions: {
      logOut: 'Oturumu Kapat'
    }
  },
  de: {
    title: {
      menu: 'Speisekarte'
    },
    subpages: {
      myProfile: 'Mein Profil',
      mainStage: 'Main Stage',
      Workshops: 'Zeitplan der Veranstaltung',
      Attendees: 'Teilnehmer',
      chat: 'Chat',
      Sponsors: 'Förderer',
      Messages: 'Chat'
    },
    actions: {
      logOut: 'Abmelden'
    }
  },
};
